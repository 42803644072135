import React from 'react';
import { TextField } from 'factor';
import styles from './styles.module.scss';

interface Props {
  label: string | JSX.Element;
  data: string | number;
  startIcon?: string;
}

export const NonEditableTextField = (props: Props) => {
  const { label, data, startIcon } = props;

  return (
    <>
      <TextField
        className={styles.nonEditableTextField}
        iconName={startIcon}
        label={label}
        value={data}
        disabled
      />
    </>
  );
};
