import { datadogRum } from 'iqm-framework';
import get from 'lodash/get';
import { AxiosResponse } from 'axios';

import { getApiErrorMsg } from '../../utils';
import { SUCCESS, FAILED, service } from '../../constants';

const actions = {
  searchAudience: {
    id: 'searchAudiences',
    text: 'Search Audiences',
  },
};

interface TSearchAudienceParams {
  searchTerm: string;
  axiosResponse: AxiosResponse;
  success: boolean;
  latency?: number;
}

export const searchAudience = (params: TSearchAudienceParams) => {
  datadogRum.addAction(`${actions.searchAudience.text} ${params.success ? SUCCESS : FAILED}`, {
    action: `${service}/${actions.searchAudience.id}`,
    success: params.success,
    errorMsg: !params.success ? getApiErrorMsg(params.axiosResponse) : undefined,
    latency: params.latency || null,
    statusCode: get(params, 'axiosResponse.status'),
    info: {
      queryWord: params.searchTerm,
      queryLength: params.searchTerm?.length,
    },
  });
};
