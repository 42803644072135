import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { campaignAudienceConstants } from './constants';

export interface CampaignAudienceState {
  audienceName: string;
  submitInProgress: boolean;
}

const defaultCampaignAudienceState: CampaignAudienceState = {
  audienceName: '',
  submitInProgress: false,
};

const startCreating = (state: CampaignAudienceState): CampaignAudienceState => {
  return {
    ...state,
    submitInProgress: true,
  };
};

const endCreating = (state: CampaignAudienceState): CampaignAudienceState => {
  return {
    ...state,
    submitInProgress: false,
  };
};

const startUpdating = (state: CampaignAudienceState): CampaignAudienceState => {
  return {
    ...state,
    submitInProgress: true,
  };
};

const endUpdating = (state: CampaignAudienceState): CampaignAudienceState => {
  return {
    ...state,
    submitInProgress: false,
  };
};

const reset = (): CampaignAudienceState => {
  return {
    ...defaultCampaignAudienceState,
  };
};

const reducer = reducerFromMap<CampaignAudienceState>(defaultCampaignAudienceState, {
  [campaignAudienceConstants.START_CREATING]: startCreating,
  [campaignAudienceConstants.END_CREATING]: endCreating,
  [campaignAudienceConstants.START_UPDATING]: startUpdating,
  [campaignAudienceConstants.END_UPDATING]: endUpdating,
  [campaignAudienceConstants.RESET]: reset,
});

export const campaignAudience = (
  state: CampaignAudienceState = defaultCampaignAudienceState,
  action: Action<any>,
) => reducer(state, action);
