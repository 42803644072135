import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { auth, AuthState } from './auth/reducer';
import { app, ApplicationState } from './app/reducer';
import { table, TableState } from './table/reducer';
import { matchedAudience, MatchedAudienceState } from './matchedAudience/reducer';
import { retargetedAudience, RetargetedAudienceState } from './retargetedAudience/reducer';
import { segmentedAudience, SegmentedAudienceState } from './segmentedAudience/reducer';
import { toast, ToastState } from './toast/reducers';
import { snackbar, SnackbarState } from './snackbar/reducers';
import { contextualAudience, ContextualAudienceState } from './contextualAudience/reducer';
import { lookalikeAudience, LookalikeAudienceState } from './lookalikeAudience/reducer';
import { geofarmedAudience, GeofarmedAudienceState } from './geofarmedAudience/reducer';
import { campaignAudience, CampaignAudienceState } from './campaignAudience/reducer';

export interface AppState {
  auth: AuthState;
  app: ApplicationState;
  table: TableState;
  toast: ToastState;
  matchedAudience: MatchedAudienceState;
  retargetedAudience: RetargetedAudienceState;
  segmentedAudience: SegmentedAudienceState;
  contextualAudience: ContextualAudienceState;
  lookalikeAudience: LookalikeAudienceState;
  geofarmedAudience: GeofarmedAudienceState;
  campaignAudience: CampaignAudienceState;
  snackbar: SnackbarState;
}

const reducer = combineReducers({
  auth,
  app,
  table,
  toast,
  matchedAudience,
  retargetedAudience,
  segmentedAudience,
  contextualAudience,
  lookalikeAudience,
  geofarmedAudience,
  campaignAudience,
  snackbar,
});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore<any, any, any, any>(
  reducer,
  composeEnhancers(applyMiddleware(thunk)),
);
