import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { retargetedAudienceConstants } from './constants';

export interface RetargetedAudienceState {
  audienceName: string;
  submitInProgress: boolean;
  tagScript: string;
}

const defaultRetargetedAudienceState: RetargetedAudienceState = {
  audienceName: '',
  submitInProgress: false,
  tagScript: '',
};

function saveAudienceData(
  state: RetargetedAudienceState,
  action: Action<{ audienceName: string; tagScript: string }>,
): RetargetedAudienceState {
  return {
    ...state,
    audienceName: action.payload.audienceName,
    tagScript: action.payload.tagScript,
  };
}

function reset(): RetargetedAudienceState {
  return {
    ...defaultRetargetedAudienceState,
  };
}

function startCreating(state: RetargetedAudienceState): RetargetedAudienceState {
  return {
    ...state,
    submitInProgress: true,
  };
}

function endCreating(state: RetargetedAudienceState): RetargetedAudienceState {
  return {
    ...state,
    submitInProgress: false,
  };
}

function updateTagScript(
  state: RetargetedAudienceState,
  action: Action<string>,
): RetargetedAudienceState {
  return {
    ...state,
    tagScript: action.payload,
  };
}

const reducer = reducerFromMap<RetargetedAudienceState>(defaultRetargetedAudienceState, {
  [retargetedAudienceConstants.SAVE_AUDIENCE_DATA]: saveAudienceData,
  [retargetedAudienceConstants.START_CREATING]: startCreating,
  [retargetedAudienceConstants.END_CREATING]: endCreating,
  [retargetedAudienceConstants.UPDATE_TAG_SCRIPT]: updateTagScript,
  [retargetedAudienceConstants.RESET]: reset,
});

export const retargetedAudience = (
  state: RetargetedAudienceState = defaultRetargetedAudienceState,
  action: Action<any>,
) => reducer(state, action);
