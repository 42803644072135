export const tableConstants = {
  CHANGE_SORTING_COLUMNS: 'CHANGE_SORTING_COLUMNS',
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_SELECTED_AUDIENCES: 'SET_SELECTED_AUDIENCES',
  UPDATE_AUDIENCE_NAME: 'UPDATE_AUDIENCE_NAME',
  UPDATE_DELETE_PROGRESS: 'UPDATE_DELETE_PROGRESS',
  UPDATE_SEARCHTEXT: 'UPDATE_SEARCHTEXT',
  UPDATE_SORTING: 'UPDATE_SORTING',
  SET_AUDIENCE_TYPE_IDS: 'SET_AUDIENCE_TYPE_IDS',
  SET_AUDIENCES_BY_TYPE: 'SET_AUDIENCES_BY_TYPE',
  SET_AUDIENCES_STATUSES: 'SET_AUDIENCES_STATUSES',
  SET_AUDIENCES_SELECTED_STATUSES: 'SET_AUDIENCES_SELECTED_STATUSES',
  SET_LOADING_STATUS: 'SET_LOADING_STATUS',
  SET_TABLE_TOTAL_ITEMS: 'SET_TABLE_TOTAL_ITEMS',
  SET_SEARCH_OPEN: 'SET_SEARCH_OPEN',
  SET_INSIGHTS_DATA: 'SET_INSIGHTS_DATA',
};
