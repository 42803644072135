export const getInsightsStatusQueryKey = {
  keys: (args: { scope: string }) => [args],
};

export const getPreBidAudienceInfoQueryKey = {
  keys: (args: { scope: string; audienceId: number }) => [args],
};

export const getSegmentedAudienceInfoQueryKey = {
  keys: (args: { scope: string; audienceId: number }) => [args],
};

export const getSegmentedAudienceBreakdownQueryKey = {
  keys: (args: { scope: string; audienceId: number }) => [args],
};

export const getSegmentsByAudienceIdKey = {
  keys: (args: { scope: string; audienceIds: number[]; segmentListSize?: number }) => [args],
};

export const getInsertionOrderListKey = {
  keys: (args: {
    scope: 'getInsertionOrderList';
    searchField?: string;
    sortBy?: string;
    ioStatusIdsList?: number[];
    noOfEntries?: number;
  }) => [args],
};
