import React from 'react';
import { Icon, Tooltip } from 'factor';
import { Tooltip as AppliftTooltip, Box } from '@applift/factor';
import { AlertTriangle, InfoCircle } from '@applift/icons';
import moment from 'moment';
import { History } from 'history';
import { audienceIDMap, AudienceTypeId, TableAudienceItem } from 'models/Table';
import { OpenDialog } from 'store/app/actions';
import { OpenSnackbar } from 'store/snackbar/actions';
import { formatDate } from 'utils/format';
import {
  AUDIENCE_STATUSES,
  PRE_BID_AUDIENCE_TYPE_ID,
  SEGMENTED_AUDIENCE_TYPE_ID,
} from 'constants/audiences';
import { SEGMENTED_AGE_WARNING } from 'constants/tooltips';

import { tableStatusIconsMap } from '../../consts';
import { User } from '../../../models/User';
import { getAllBodyMapper, getDataCostCell } from '../AllAudience/AllAudienceTable/getBodyMapper';
import getRetargetedBodyMapper from '../RetargetedAudience/RetargetedAudienceTable/getBodyMapper';
import getSegmentedBodyMapper from '../SegmentedAudience/SegmentedAudienceTable/getBodyMapper';
import getMatchedBodyMapper from '../MatchedAudience/MatchedAudienceTable/getBodyMapper';
import getContextualBodyMapper from '../ContextualAudience/ContextualAudienceTable/getBodyMapper';
import getCampaignBodyMapper from '../CampaignAudience/CampaignAudienceTable/getBodyMapper';
import getGeofarmedBodyMapper from '../GeofarmedAudience/GeofarmedAudienceTable/getBodyMapper';
import getLookalikeBodyMapper from '../LookalikeAudience/LookalikeAudienceTable/getBodyMapper';
import getPreBidBodyMapper from '../PreBidAudience/components/PreBidAudienceTable/getBodyMapper';
import { SegmentsList } from '../SegmentedAudience/SegmentsList';

import styles from './styles.module.scss';

interface Props extends OpenDialog, OpenSnackbar {
  user: User | null;
  audienceTypeIds: AudienceTypeId;
  isSidebarOpened: boolean;
  confirmName: (audienceTypeId: number, id: number, newValue: string) => void;
  history: History;
}

const audiencesMappers = {
  '': getAllBodyMapper,
  '1': getMatchedBodyMapper,
  '2': getSegmentedBodyMapper,
  '3': getRetargetedBodyMapper,
  '4': getGeofarmedBodyMapper,
  '5': getContextualBodyMapper,
  '6': getLookalikeBodyMapper,
  '7': getCampaignBodyMapper,
  '8': getPreBidBodyMapper,
};

export const getBodyMapper = ({ user, audienceTypeIds, openDialog, openSnackbar }: Props) => ({
  id: {
    key: (data: TableAudienceItem) => {
      if (data.audienceTypeId === SEGMENTED_AUDIENCE_TYPE_ID) {
        return (
          <AppliftTooltip
            arrow
            title={
              <Box style={{ maxWidth: '320px' }}>
                <SegmentsList count={5} audienceId={data.id} />
              </Box>
            }
          >
            <Box>{data.id}</Box>
          </AppliftTooltip>
        );
      }
      return data.id;
    },
    className: 'w-100-100 _editable',
  },
  audienceName: {
    key: ({
      audienceTypeId,
      id,
      audienceType,
      audienceName,
      status,
      createdOn,
    }: TableAudienceItem) => {
      const { icon } = audienceIDMap[audienceTypeId];
      return (
        <div
          className={styles.audienceName}
          onClick={() => openDialog({ audienceId: id, audienceTypeId, type: 'view', status })}
        >
          <span className={styles.audienceNameIcon}>
            <Tooltip label={audienceType} position="top" auto={false}>
              <Icon name={icon} />
            </Tooltip>
          </span>
          <Tooltip
            className={styles.nameWrapper}
            label="Click to view details"
            position="top"
            auto={false}
          >
            <span className="_name-inner">{audienceName}</span>
          </Tooltip>
          {!!createdOn && moment.unix(createdOn).isBefore(moment().add(-1, 'year')) ? (
            <AppliftTooltip arrow title={SEGMENTED_AGE_WARNING}>
              <AlertTriangle sx={{ textColor: 'warning-400' }} fontSize={24} />
            </AppliftTooltip>
          ) : null}
        </div>
      );
    },
    isFocusOnClick: true,
    clickFocusRefPropName: 'inputRef',
    className: `_audience-name _editable w-150-300 ${audienceTypeIds === '8' &&
      '_audience-name-stretched'}`,
  },
  created: {
    key: (data: TableAudienceItem) => formatDate(data.createdOn, user?.timezoneName),
    className: '_right',
  },
  cost: {
    key: getDataCostCell,
    className: `_right ${styles.boldtext} _dataCost`,
  },
  // @ts-ignore
  ...audiencesMappers[audienceTypeIds]({
    user,
    openSnackbar,
    openDialog,
  }),
  status: {
    key: (data: TableAudienceItem) => {
      if (data.status && data.audienceTypeId !== PRE_BID_AUDIENCE_TYPE_ID) {
        return data.status === AUDIENCE_STATUSES.REJECTED ? (
          <Tooltip
            className={styles.toolTip}
            label={data.rejectionReason}
            position="top"
            auto={false}
            portal
          >
            <div className="d-flex align-items-center">
              {Object.keys(tableStatusIconsMap).indexOf(data.status) !== -1 ? (
                <Icon name={tableStatusIconsMap[data.status]} className="_size-16" />
              ) : (
                ''
              )}
              <span className="ml-1">{data.status}</span>
            </div>
          </Tooltip>
        ) : (
          <div className="d-flex align-items-center">
            {Object.keys(tableStatusIconsMap).indexOf(data.status) !== -1 ? (
              <Icon name={tableStatusIconsMap[data.status]} className="_size-16" />
            ) : (
              ''
            )}
            <span className="ml-1">{data.status}</span>
            {data.audienceTypeId === SEGMENTED_AUDIENCE_TYPE_ID &&
            data.status === AUDIENCE_STATUSES.PROCESSING ? (
              <AppliftTooltip arrow title="This audience will be ready in the next 24-48 hours">
                <InfoCircle sx={{ textColor: 'neutral-400', ml: 8 }} fontSize={24} />
              </AppliftTooltip>
            ) : null}
          </div>
        );
      }

      return '—';
    },
  },
  incExc: {
    key: (data: TableAudienceItem) => {
      return data.audienceTypeId !== PRE_BID_AUDIENCE_TYPE_ID ? (
        <div
          onClick={() =>
            openDialog({
              audienceId: data.id,
              type: 'addToCampaign',
              otherInfo: {
                includedCampaignIds: data.includedCampaignList ?? [],
                excludedCampaignIds: data.excludedCampaignList ?? [],
                audienceName: data.audienceName,
                selectedAudienceIds: [data.id],
                audienceStatus: data.status,
                fetchAssociatedCampaigns: true,
              },
            })
          }
          className={styles.includeExclude}
        >
          <Tooltip
            className={styles.toolTip}
            label={<span className={styles.tooltipText}>Click to add to campaign</span>}
            position="top"
            auto={false}
          >
            <span className={styles.included}>{data.included}</span>
            <span className={styles.excluded}>{data.excluded}</span>
          </Tooltip>
        </div>
      ) : (
        '—'
      );
    },
    className: `_right ${styles.incExc} ${styles.boldtext}`,
  },
});
