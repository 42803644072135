import React, { useEffect, useRef, ComponentProps } from 'react';
import { ICloudProviderProps } from 'models/Cloud';
import DropboxIcon from './Dropbox.svg';
import styles from './styles.module.scss';

const DropboxBtn = ({ select, onClick }: ComponentProps<'button'> & { select?: boolean }) => (
  <button type="button" onClick={onClick} className={styles.button}>
    <img src={DropboxIcon} alt="Dropbox Logo" style={{ height: 20 }} />
    {select && 'Dropbox'}
  </button>
);

const DropboxUploader = (props: ICloudProviderProps) => {
  const { acceptFormats, setUnsupportedFiles, select } = props;

  useEffect(() => {
    const listener = async (event: MessageEvent) => {
      if (event.data.type !== 'dropbox-relay') return;

      const { files } = event.data as { files: { url: string; name: string; type: string }[] };
      setUnsupportedFiles(
        (
          await Promise.all(
            files.map(async (file) => {
              const res = await fetch(file.url);
              if (!res.ok) return null;

              return new File([await res.blob()], file.name, {
                type: res.headers.get('content-type')?.split(';')[0] || file.type,
              });
            }),
          )
        ).filter((file): file is File => file != null),
      );
    };

    window.addEventListener('message', listener);

    return () => window.removeEventListener('message', listener);
  }, [setUnsupportedFiles]);

  const url = new URL('https://marketplace.iqm.com/integrations/dropbox-relay.html');
  acceptFormats
    .split(',')
    .forEach((format) => url.searchParams.append('extensions', format.trim()));

  const ref = useRef<HTMLIFrameElement>(null);

  return (
    <>
      <DropboxBtn
        select={select}
        onClick={() => ref.current?.contentWindow?.postMessage('open', '*')}
      />
      <iframe ref={ref} src={url.toString()} className={styles.iframe} />
    </>
  );
};

export default DropboxUploader;
