import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { segmentedAudienceConstants } from './constants';

export interface SegmentedAudienceState {
  submitInProgress: boolean;
}

const defaultSegmentedAudienceState: SegmentedAudienceState = {
  submitInProgress: false,
};

function reset(state: SegmentedAudienceState): SegmentedAudienceState {
  return {
    ...defaultSegmentedAudienceState,
    ...state,
  };
}

function startCreating(state: SegmentedAudienceState): SegmentedAudienceState {
  return {
    ...state,
    submitInProgress: true,
  };
}

function endCreating(state: SegmentedAudienceState): SegmentedAudienceState {
  return {
    ...state,
    submitInProgress: false,
  };
}

const reducer = reducerFromMap<SegmentedAudienceState>(defaultSegmentedAudienceState, {
  [segmentedAudienceConstants.RESET]: reset,
  [segmentedAudienceConstants.START_CREATING]: startCreating,
  [segmentedAudienceConstants.END_CREATING]: endCreating,
});

export const segmentedAudience = (
  state: SegmentedAudienceState = defaultSegmentedAudienceState,
  action: Action<any>,
) => reducer(state, action);
