import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { initDataDog, enableLogRocket, ORIGIN_URL } from 'iqm-framework';
import '@applift/factor/dist/style.css';
import '@applift/datagrid/dist/style.css';
import '@applift/insight-app/lib/style.css';
import '@applift/platform/dist/style.css';

import messagesEn from './translations/en.json';
import { logWarningXSS } from './utils/log';
import { App } from './components/App';
import { store } from './store';
import * as serviceWorker from './serviceWorker';
import { isProd, isPreprod } from './config';

if (isProd) {
  logWarningXSS();
  initDataDog('audiences', 'prod', {
    allowedTracingOrigins: [ORIGIN_URL],
    trackViewsManually: true,
  });
  enableLogRocket('audiences');
} else if (isPreprod) {
  initDataDog('audiences', 'preprod', {
    allowedTracingOrigins: [ORIGIN_URL],
    trackViewsManually: true,
  });
} else {
  initDataDog('audiences', 'stage', {
    allowedTracingOrigins: [ORIGIN_URL],
    trackViewsManually: true,
  });
}

const language: string = navigator.language.split(/[-_]/)[0];

interface IMessages {
  [key: string]: {
    [key: string]: string;
  };
}

const messages: IMessages = {
  ru: messagesEn,
  en: messagesEn,
};

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <Provider store={store}>
      <App />
    </Provider>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
