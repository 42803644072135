import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Alert,
  Avatar,
  bindPopover,
  bindTrigger,
  Box,
  Button,
  Chip,
  Col,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  LoadingButton,
  Row,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  TypoTooltip,
  usePopupState,
} from '@applift/factor';
import {
  Add,
  CurrencyDollar,
  Delete,
  ExternalLink,
  InfoCircle,
  Search,
  SemanticWarning,
} from '@applift/icons';
import {
  PrebidNormalizedSegmentData,
  SelectPrebidSegment,
  SelectPrebidSegmentApiRefType,
} from '@applift/platform';

import { applicationActions, ResetSidebar } from 'store/app/actions';
import { TDialog } from 'store/app/reducer';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { tableActions, UpdateSorting } from 'store/table/actions';
import {
  ALL_CATEGORY_CREATIVE_TYPE_IDS,
  DISPLAY_CATEGORY_CREATIVE_TYPE_IDS,
  NAME_MAX_CHARS,
  PRE_BID_AUDIENCE_TYPE_ID,
  VIDEO_CATEGORY_CREATIVE_TYPE_IDS,
} from 'constants/audiences';

interface Props extends ResetSidebar, OpenSnackbar, UpdateSorting {
  handleClose: () => void;
  dialog: TDialog;
  title: string;
  isLoading?: boolean;
  onCompletion: ({ audienceName, ids }: { audienceName: string; ids: number[] }) => void;
  initialAudienceInfo?: {
    selectedData: Record<string, PrebidNormalizedSegmentData>;
    audienceName: string;
  };
}

const PreBidAudienceDialogComponent = (props: Props) => {
  const {
    handleClose,
    resetSidebar,
    dialog,
    title,
    isLoading,
    onCompletion,
    initialAudienceInfo,
  } = props;

  const history = useHistory();
  const [audienceName, setAudienceName] = useState<string>('');
  const isEdit = dialog.type === 'edit';
  const [nameHasError, setNameError] = React.useState(false);
  const apiRef = React.useRef<SelectPrebidSegmentApiRefType>();
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'IASPopper',
  });
  const [selectedSegmentInfo, setSelectedSegmentInfo] = React.useState<
    PrebidNormalizedSegmentData[]
  >([]);

  const resetAndClose = useCallback(() => {
    handleClose();
    resetSidebar();
    if (isEdit) {
      history.goBack();
    }
  }, [handleClose, resetSidebar, history, isEdit]);

  const differentCreativeGroupsSelected = React.useMemo(() => {
    let leastLengthCreativeTypeIds = [11, 13, 14];
    selectedSegmentInfo.forEach((obj) => {
      leastLengthCreativeTypeIds =
        (obj.creativeTypeIdList as number[]).length < leastLengthCreativeTypeIds.length
          ? (obj.creativeTypeIdList as number[])
          : leastLengthCreativeTypeIds;
    });
    return !selectedSegmentInfo.every((data) =>
      leastLengthCreativeTypeIds.every((item) => data.creativeTypeIdList?.includes(item)),
    );
  }, [selectedSegmentInfo]);

  const selectedSegmentIds = selectedSegmentInfo.map((item) => item.preservedValue) ?? [];
  const shouldDisableBtn =
    !selectedSegmentInfo.length ||
    !audienceName ||
    nameHasError ||
    (isEdit
      ? Object.values(initialAudienceInfo?.selectedData ?? {})?.every((item) =>
          selectedSegmentIds.includes(item.preservedValue),
        ) &&
        Object.values(initialAudienceInfo?.selectedData ?? {}).length ===
          selectedSegmentInfo.length &&
        initialAudienceInfo?.audienceName === audienceName
      : false);

  React.useEffect(() => {
    if (initialAudienceInfo) {
      apiRef.current?.reinitializeRowSelection(initialAudienceInfo.selectedData);
      setAudienceName(initialAudienceInfo.audienceName as string);
    }
  }, [initialAudienceInfo]);

  const groupedData: { [key: string]: PrebidNormalizedSegmentData[] } = {};
  selectedSegmentInfo.forEach((obj) => {
    if (!groupedData[`${obj.parentPathKey}|${obj.providerId}`]) {
      groupedData[`${obj.parentPathKey}|${obj.providerId}`] = [];
    }
    groupedData[`${obj.parentPathKey}|${obj.providerId}`].push(obj);
  });
  const segmentsGroupedByParentPathKey = Object.values(groupedData);

  const shouldShowWarning = (group: PrebidNormalizedSegmentData[]) => {
    if (group[0].creativeTypeIdList.sort().join(',') === ALL_CATEGORY_CREATIVE_TYPE_IDS) {
      return false;
    }
    const isDisplayCategorySelectionPresent = selectedSegmentInfo.some(
      (segment) =>
        segment.creativeTypeIdList.sort().join(',') === DISPLAY_CATEGORY_CREATIVE_TYPE_IDS,
    );

    const isVideoCategorySelectionPresent = selectedSegmentInfo.some(
      (segment) => segment.creativeTypeIdList.sort().join(',') === VIDEO_CATEGORY_CREATIVE_TYPE_IDS,
    );

    return isDisplayCategorySelectionPresent && isVideoCategorySelectionPresent;
  };

  return (
    <Dialog
      open={dialog?.audienceTypeId === PRE_BID_AUDIENCE_TYPE_ID}
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { height: 100 } }}
    >
      <DialogTitle onClose={resetAndClose}>{title}</DialogTitle>
      <DialogContent
        dividers
        sx={{ height: 100, overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}
      >
        <Row
          sx={{
            width: 100,
            mb: 24,
          }}
        >
          <Col xs={6}>
            <TextField
              value={audienceName}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setNameError(e.target.value.trim().length > NAME_MAX_CHARS);
                setAudienceName(e.target.value.trim() ? e.target.value : '');
              }}
              label="Audience Name"
              placeholder="Enter Audience name"
              variant="outlinedDash"
              fullWidth
              helperText={
                nameHasError ? (
                  <Typography variant="label" color="error">
                    {`* Maximum ${NAME_MAX_CHARS} characters`}
                  </Typography>
                ) : null
              }
              error={nameHasError}
            />
          </Col>
          {(selectedSegmentInfo.length ?? 0) > 0 && (
            <Col xs={6}>
              <Alert severity="secondary">
                {differentCreativeGroupsSelected ? (
                  'Total cost can’t be calculated as added segments are from different creative types.'
                ) : (
                  <>
                    Total cost for all the added pre-bid segments will be{' '}
                    {
                      <Typography weight="demi">{`$${segmentsGroupedByParentPathKey
                        .reduce((acc, group) => acc + Number(group[0].cpm), 0)
                        .toFixed(2)} (CPM)`}</Typography>
                    }
                    .
                  </>
                )}
              </Alert>
            </Col>
          )}
        </Row>
        <Row sx={{ height: 100, overflowY: 'hidden' }}>
          <Col
            xs={12}
            sx={{
              height: 100,
              overflowY: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                height: 'auto',
                maxHeight: 100,
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 4,
                border: 1,
                p: 16,
              }}
            >
              <Box sx={{ mb: 12 }}>
                {/* @ts-ignore */}
                <Button
                  // eslint-disable-next-line
                  {...bindTrigger(popupState)}
                  variant="outlined"
                  startIcon={<Add />}
                  size="medium"
                  selected={popupState.isOpen}
                >
                  Add Segment
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                  height: 100,
                }}
              >
                {/* @ts-ignore */}
                <SelectPrebidSegment
                  onChange={(data) => setSelectedSegmentInfo(Object.values(data))}
                  ref={apiRef}
                  size="medium"
                  slotProps={{
                    InputBaseProps: {
                      placeholder: 'Search for segments',
                      size: 'medium',
                      startAdornment: <Search />,
                    },
                  }}
                  // eslint-disable-next-line
                  {...bindPopover(popupState)}
                  warningText="Only one creative type (Display or Video) is supported per campaign; So, avoid grouping respective pre-bid segments in one audience."
                />
                {selectedSegmentInfo.length === 0 && (
                  <Typography sx={{ textColor: 'neutral-500' }}>
                    Added segments will be visible here
                  </Typography>
                )}

                {segmentsGroupedByParentPathKey.map((group, groupIdx) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 8,
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                      <Box sx={{ p: 8 }}>
                        <Avatar
                          size={32}
                          variant="square"
                          src={group[0].providerLogoUrl as string}
                          alt={group[0].providerName}
                          imgProps={{
                            style: { objectFit: 'cover' },
                          }}
                        />
                      </Box>
                      <Typography weight="bold" style={{ fontWeight: 600 }}>
                        {group[0].parentPathKey}
                      </Typography>
                      <Chip
                        label={<Typography weight="normal">{`${group[0].cpm} (CPM)`}</Typography>}
                        color="secondary"
                        icon={<CurrencyDollar />}
                        size="small"
                      />
                      {shouldShowWarning(group) && (
                        <Tooltip
                          title="Only one creative type (Display or Video) is supported per campaign; So, avoid grouping respective pre-bid segments in one audience."
                          placement="top"
                          arrow
                        >
                          <SemanticWarning color="warning" fontSize={24} />
                        </Tooltip>
                      )}
                      <Tooltip
                        title="This signifies the total cost of the segments within this specific category"
                        placement="top"
                        arrow
                      >
                        <InfoCircle fontSize={20} sx={{ textColor: 'neutral-400' }} />
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {group.map((item) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'between',
                            width: 100,
                            pl: 40,
                          }}
                        >
                          <TypoTooltip>{item.label}</TypoTooltip>
                          <IconButton onClick={() => apiRef.current?.removeSelection(item.value)}>
                            <Delete fontSize={24} sx={{ textColor: 'neutral-400' }} />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'between' }}>
        <Link
          /* @ts-ignore */
          target="_blank"
          href="https://help.iqm.com/en/articles/8698600-fraud-viewability-and-brand-safety-pre-bid-targeting"
        >
          <SvgIcon fontSize={20} sx={{ mr: 8 }}>
            <ExternalLink />
          </SvgIcon>
          <Typography> Learn more about pre-bid segments</Typography>
        </Link>
        <Box sx={{ display: 'flex', gap: 16 }}>
          <Button variant="contained" color="secondary" onClick={resetAndClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={isLoading}
            disabled={shouldDisableBtn}
            onClick={() =>
              onCompletion({
                audienceName,
                ids: selectedSegmentInfo.map((item) => Number(item.preservedValue)),
              })
            }
          >
            {isEdit ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  openSnackbar: snackbarActions.openSnackbar,
  updateSorting: tableActions.updateSorting,
};

export const PreBidAudienceDialog = connect(null, mapAction)(PreBidAudienceDialogComponent);
