import * as React from 'react';
import {
  Button,
  Box,
  TextField,
  Tooltip,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@applift/factor';
import { Add } from '@applift/icons';
import { SegmentSelect, NormalizedSegmentData, SegmentSelectApiRefType } from '@applift/platform';

import { TDialog } from 'store/app/reducer';
import { SegmentedAudiencePartner } from 'models/SegmentedAudience';

import { SegmentGroup } from '../../../components/SegmentGroup';
import { TSegmentGroup } from '../../types';
import { SummaryInfo } from '../SummaryInfo';

interface Props {
  audienceName: string;
  setAudienceName: React.Dispatch<React.SetStateAction<string>>;
  segmentGroups: TSegmentGroup[];
  setSegmentGroups: React.Dispatch<React.SetStateAction<TSegmentGroup[]>>;
  initialData?: { audienceName: string; segmentGroups: TSegmentGroup[] };
  segmentSelectRef: React.MutableRefObject<SegmentSelectApiRefType | undefined>;
  selectedPartner?: SegmentedAudiencePartner | null;
  dialog?: TDialog;
  isSegmentGroupSelectionDirty: boolean;
}

const MAX_SEGMENT_GROUPS = 5;
const MAX_ROW_SELECTION = 100;

export const CreateOrEditForm = (props: Props) => {
  const {
    initialData,
    audienceName,
    setAudienceName,
    segmentGroups,
    setSegmentGroups,
    segmentSelectRef,
    selectedPartner,
    dialog,
    isSegmentGroupSelectionDirty,
  } = props;

  const [selectedGroup, setSelectedGroup] = React.useState<number>(0);

  React.useEffect(() => {
    if (segmentGroups.length && segmentGroups[0]?.relation) {
      setSegmentGroups((sg) => [{ ...sg[0], relation: null }, ...sg.slice(1)]);
    }

    segmentSelectRef.current?.reinitializeRowSelection(
      segmentGroups[selectedGroup]?.segments || {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, segmentGroups.length, initialData]);

  const onSelectChange = (data: Record<string, NormalizedSegmentData>) => {
    setSegmentGroups((v) =>
      v
        .slice(0, selectedGroup)
        .concat([{ segments: data, relation: v[selectedGroup]?.relation }])
        .concat(v.slice(selectedGroup + 1)),
    );
  };

  const onAddSegmentGroup = () => {
    setSegmentGroups([...segmentGroups, { segments: {}, relation: 'OR' }]);
    setSelectedGroup(segmentGroups.length);
  };

  const onRemoveSegmentGroup = (index: number) => {
    const newGroups = segmentGroups.slice(0, index).concat(segmentGroups.slice(index + 1));

    if (!newGroups.length) {
      newGroups[0] = { segments: {}, relation: null };
    }

    setSegmentGroups(newGroups);

    if (index >= segmentGroups.length - 1) {
      const newSelectedIndex = Math.max(0, index - 1);

      setSelectedGroup(newSelectedIndex);
      if (newSelectedIndex === selectedGroup && selectedGroup === 0) {
        segmentSelectRef.current?.reinitializeRowSelection({});
      }
    }
  };

  const rowSelectionCount = React.useMemo(
    () => Object.keys(segmentGroups[selectedGroup]?.segments || {}).length,
    [segmentGroups, selectedGroup],
  );

  const getOptionDisabled = (option: NormalizedSegmentData) =>
    (!!selectedPartner?.segmentPartnerId &&
      selectedPartner.segmentPartnerId !== option.partnerId) ||
    option.type === 'noResultsLeaf' ||
    (option.type === 'segment' &&
      rowSelectionCount >= MAX_ROW_SELECTION &&
      !segmentGroups[selectedGroup]?.segments?.[option.value]) ||
    (option.type === 'segment' &&
      !!segmentGroups.find((sg, index) => index !== selectedGroup && sg.segments[option.value]));

  return (
    <Box
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      style={{ maxHeight: '100%' }}
    >
      <Box sx={{ pb: 16, width: 100, display: 'flex', justifyContent: 'between' }}>
        <TextField
          variant="outlinedDash"
          value={audienceName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAudienceName(e.target.value)}
          placeholder="Enter Audience Name"
          label="Audience Name"
          error={audienceName.length > 255}
          helperText={audienceName.length > 255 ? 'Maximum 255 characters' : null}
          sx={{ mr: 16 }}
        />
        <SummaryInfo
          dialog={dialog}
          selectedPartner={selectedPartner || null}
          segmentGroups={segmentGroups}
          isAudienceReady={
            dialog?.type === 'edit' &&
            dialog?.status?.toLowerCase() === 'ready' &&
            !isSegmentGroupSelectionDirty
          }
        />
      </Box>
      <Box
        sx={{ width: 100, height: 100, overflowY: 'auto' }}
        style={{ minHeight: 0, maxHeight: '100%' }}
      >
        <Box
          sx={{
            pb: 16,
            position: 'sticky',
            top: 0,
            width: 100,
            bgColor: 'neutral-0',
            zIndex: 'sticky',
          }}
        >
          <SegmentSelect
            getOptionDisabled={getOptionDisabled}
            inputStartAdornment={
              <Typography weight="demi" noWrap gutterBottom={false} sx={{ ml: 4 }}>
                Group {selectedGroup + 1}:
              </Typography>
            }
            onChange={onSelectChange}
            ref={segmentSelectRef}
            maxSelectCount={MAX_ROW_SELECTION}
            selectedPartnerId={selectedPartner?.segmentPartnerId}
            slotProps={{
              PaperProps: {
                style: { height: '360px', maxHeight: '360px' },
                sx: { width: 100, maxWidth: 100 },
              },
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', width: 100, gap: 12 }}>
          {segmentGroups.map((group, index) => (
            <Box
              sx={{
                width: 100,
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              {!!group.relation && (
                <ToggleButtonGroup
                  variant="outlined"
                  color="primary"
                  exclusive
                  value={group.relation}
                  onChange={(_e: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
                    if (value) {
                      setSegmentGroups((sg) =>
                        sg
                          .slice(0, index)
                          .concat([{ ...sg[index], relation: value as any }])
                          .concat(sg.slice(index + 1)),
                      );
                    }
                  }}
                >
                  <ToggleButton selected={group.relation === 'OR'} value="OR">
                    OR
                  </ToggleButton>
                  <ToggleButton selected={group.relation === 'AND'} value="AND">
                    AND
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              <SegmentGroup
                // eslint-disable-next-line
                key={`${index}-${Object.entries(group.segments)?.[0]?.[0] || 'empty'}`}
                segments={group.segments}
                title={`Group ${index + 1}`}
                isSelected={selectedGroup === index}
                relation="OR"
                onRemoveSegmentGroup={
                  selectedGroup === index &&
                  (selectedGroup !== 0 || Object.entries(group.segments).length)
                    ? () => onRemoveSegmentGroup(index)
                    : undefined
                }
                onRemoveSegment={
                  selectedGroup === index
                    ? (segmentId: string) => {
                        segmentSelectRef.current?.removeSelection(segmentId);
                      }
                    : undefined
                }
                onSelectSegmentGroup={
                  selectedGroup !== index ? () => setSelectedGroup(index) : undefined
                }
              />
            </Box>
          ))}
          {Object.keys(segmentGroups[0]?.segments || {})?.length ? (
            <Tooltip
              arrow
              title={
                segmentGroups.length >= MAX_SEGMENT_GROUPS
                  ? `Maximum limit of ${MAX_SEGMENT_GROUPS} segment group in one segmented audience is reached`
                  : null
              }
            >
              <Box style={{ width: 'fit-content' }} sx={{ pb: 16 }}>
                <Button
                  startIcon={<Add />}
                  color="secondary"
                  variant="outlined"
                  disabled={segmentGroups.length >= MAX_SEGMENT_GROUPS || !segmentGroups.length}
                  onClick={onAddSegmentGroup}
                >
                  Add Segment Group
                </Button>
              </Box>
            </Tooltip>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
