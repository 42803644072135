import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withDataDogCallbackLogging } from 'iqm-framework';

import { AppState } from 'store';
import { AudienceTypeId, audienceIDMap } from 'models/Table';
import { SetSearchOpen, tableActions, UpdateSearch } from 'store/table/actions';
import { Search } from '../../Search';

interface Props extends SetSearchOpen, UpdateSearch {
  searchText: string;
  searchOpen: boolean;
  audienceTypeId: AudienceTypeId;
}

const AudiencesSearchComponent = (props: Props) => {
  const { searchText, updateSearch, setSearchOpen, searchOpen, audienceTypeId } = props;

  const LoggedSearch = useMemo(() => {
    return withDataDogCallbackLogging(
      `Search ${audienceIDMap[+audienceTypeId]?.label || 'Audience'}`,
      'setValue',
      1000,
    )(Search);
  }, [audienceTypeId]);

  if (LoggedSearch) {
    return (
      <LoggedSearch
        isOpen={searchOpen}
        setSearchOpen={setSearchOpen}
        value={searchText}
        setValue={updateSearch}
      />
    );
  }

  return (
    <Search
      isOpen={searchOpen}
      setSearchOpen={setSearchOpen}
      value={searchText}
      setValue={updateSearch}
    />
  );
};

const mapState = (state: AppState) => ({
  searchText: state.table.searchText,
  searchOpen: state.table.searchOpen,
  audienceTypeId: state.table.audienceTypeIds,
});

const mapActions = {
  updateSearch: tableActions.updateSearch,
  setSearchOpen: tableActions.setSearchOpen,
};

export const AudiencesSearch = connect(mapState, mapActions)(AudiencesSearchComponent);
