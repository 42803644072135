import React from 'react';
import { SvgIconProps } from '@applift/factor';
import {
  IoCurrency,
  IoImpression,
  StatusDeleted,
  StatusExpired,
  StatusReady,
} from '@applift/icons';

export const BUDGET_TYPE_ID = {
  DOLLAR_BASED: 1,
  IMPRESSIONS_BASED: 2,
};

export const BudgetTypeIconMapper = {
  [BUDGET_TYPE_ID.DOLLAR_BASED]: 'InsertionOrderBudgetCurrency',
  [BUDGET_TYPE_ID.IMPRESSIONS_BASED]: 'InsertionOrderBudgetImpression',
};

export const BudgetTypeIconMapperApplift = {
  [BUDGET_TYPE_ID.DOLLAR_BASED]: IoCurrency,
  [BUDGET_TYPE_ID.IMPRESSIONS_BASED]: IoImpression,
};

export const IO_STATUS_ID = {
  ACTIVE: 1,
  EXPIRED: 2,
  DELETED: 3,
};

export const IOStatusMapper = {
  [IO_STATUS_ID.ACTIVE]: { label: 'Active', icon: 'StatusReadyColor' },
  [IO_STATUS_ID.EXPIRED]: { label: 'Expired', icon: 'StatusExpiredColor' },
  [IO_STATUS_ID.DELETED]: { label: 'Deleted', icon: 'StatusDeletedColor' },
};

const StatusColorMapper = {
  [IO_STATUS_ID.ACTIVE]: 'info-400',
  [IO_STATUS_ID.EXPIRED]: 'neutral-500',
  [IO_STATUS_ID.DELETED]: 'danger-400',
};

const getStatusIcon = (IconComponent: any, statusId: number) => {
  const textColor = StatusColorMapper[statusId];

  return (props: Partial<SvgIconProps>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IconComponent {...props} sx={{ textColor, ...(props.sx || {}) }} />
  );
};

export const IOStatusMapperApplift = {
  [IO_STATUS_ID.ACTIVE]: { label: 'Active', icon: getStatusIcon(StatusReady, IO_STATUS_ID.ACTIVE) },
  [IO_STATUS_ID.EXPIRED]: {
    label: 'Expired',
    icon: getStatusIcon(StatusExpired, IO_STATUS_ID.EXPIRED),
  },
  [IO_STATUS_ID.DELETED]: {
    label: 'Deleted',
    icon: getStatusIcon(StatusDeleted, IO_STATUS_ID.DELETED),
  },
};
