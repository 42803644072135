import React from 'react';

import { TDialog } from 'store/app/reducer';
import { MatchedAudienceDialog } from '../components/MatchedAudienceDialog';

interface Props {
  dialog: TDialog;
  handleClose: () => void;
}

const EditMatchedAudience = (props: Props) => {
  const { dialog, handleClose } = props;

  return (
    <MatchedAudienceDialog dialog={dialog} handleClose={handleClose} title="Matched Audience" />
  );
};

export default EditMatchedAudience;
