import React from 'react';
import { Box, CircularProgress, Typography } from '@applift/factor';
import * as ChartGeo from 'chartjs-chart-geo';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import {
  GetSegmentAudienceBreakdownWidgetDataResponse,
  GetSegmentAudienceBreakdownWidgetResponse,
} from 'api/SegmentedAudience';
import { ReachFormatter as NumberFormatter } from 'utils/format';
import { NoData, Timeout, ErrorComponent } from './GraphsErrorState';
import { externalGraphTooltip } from './component/Tooltip';

export interface GeographyMapChartType {
  isPlaceholder?: boolean;
  geoTypeData?: GetSegmentAudienceBreakdownWidgetResponse['data'];
  isLoading?: boolean;
  errorType?: 'Timeout' | 'Error' | '';
  refetchAudienceBreakdownInfo: () => void;
}

ChartJS.register(
  ...registerables,
  ChartGeo.ChoroplethController,
  ChartGeo.ProjectionScale,
  ChartGeo.ColorScale,
  ChartGeo.GeoFeature,
);

interface IMapJson {
  [key: string]: {
    url: string;
    objectsKey: string;
    propertiesKey: string;
  };
}

const WORLD_JSON: IMapJson = {
  united_states: {
    url: 'https://unpkg.com/us-atlas/states-10m.json',
    objectsKey: 'states',
    propertiesKey: 'name',
  },
};

const MAP_JSON = { ...WORLD_JSON };

export default MAP_JSON;

export const GeographyMap = (props: GeographyMapChartType) => {
  const { isPlaceholder, geoTypeData, refetchAudienceBreakdownInfo, errorType, isLoading } = props;
  const backgroundColor = React.useMemo(
    () =>
      isPlaceholder
        ? ['#999999', '#CCCCCC', '#E6E6E6']
        : ['#D2E0FE', '#B1CAFE', '#78A1FC', '#4F84F7'],
    [isPlaceholder],
  );

  const chartRef = React.useRef();

  const [data, setData] = React.useState<any>([]);
  const chosenKey = 'united_states';

  React.useEffect(() => {
    fetch(MAP_JSON[chosenKey].url)
      .then((response) => response.json())
      .then((value) => {
        setData(
          // @ts-ignore
          ChartGeo.topojson.feature(value, value.objects[MAP_JSON[chosenKey].objectsKey]).features,
        );
      });
  }, []);

  const featuresWithAudienceValue = React.useCallback(
    (
      features: any[],
      audienceData: GetSegmentAudienceBreakdownWidgetDataResponse[] | undefined,
    ) => {
      const audienceMap = new Map(audienceData?.map((item) => [item.state, item]));

      // @ts-ignore
      chartRef.current?.update();

      if (isPlaceholder) {
        return features.map((feature) => ({
          feature,
          value: 0,
        }));
      }

      return features.map((feature) => {
        const matchingAudience = audienceMap.get(feature.properties.name);
        return {
          feature,
          value: +(matchingAudience?.['audienceValue(%)'] ?? 0),
        };
      });
    },
    [isPlaceholder],
  );

  const modifyArrayWithPreviousSums = (arr: any[]) => {
    if (arr.length <= 1) {
      return arr;
    }

    return arr.reduce((acc, current, index) => {
      acc[index] = (acc[index - 1] || 0) + current;
      return acc;
    }, Array(arr.length).fill(0));
  };

  const divideIntoFourParts = React.useCallback((number: number) => {
    if (number <= 0 || typeof number !== 'number') {
      return 0;
    }
    const parts = [];
    let remaining = number;

    for (let i = 0; i < 4; i++) {
      const part = Math.floor(remaining / (4 - i));
      parts.push(part);
      remaining -= part;
    }

    if (remaining > 0) {
      parts[parts.length - 1] += remaining;
    }

    return modifyArrayWithPreviousSums(parts);
  }, []);

  const legendsArr = React.useMemo(() => divideIntoFourParts(100) || [], [divideIntoFourParts]);

  const renderLegendNumber = React.useCallback(
    (idx) => {
      if (idx === 0) {
        return <Typography variant="label">0</Typography>;
      }
      if (idx === 1) {
        return <Typography variant="label">{NumberFormatter.format(legendsArr[1])}</Typography>;
      }
      if (idx === 3) {
        return <Typography variant="label">{NumberFormatter.format(legendsArr[3])}</Typography>;
      }
      return null;
    },
    [legendsArr],
  );

  const renderChart = React.useCallback(() => {
    if (isLoading && !isPlaceholder) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <CircularProgress thickness={3} />
          <Typography sx={{ mt: 16 }} variant="p">
            Loading...
          </Typography>
        </Box>
      );
    }

    if (errorType === 'Timeout') {
      return <Timeout onRefresh={refetchAudienceBreakdownInfo} />;
    }

    if (errorType === 'Error') {
      return <ErrorComponent />;
    }

    if (!geoTypeData?.length && !isPlaceholder) {
      return <NoData />;
    }

    return (
      <Chart
        ref={chartRef}
        key={data}
        type="choropleth"
        data={{
          labels: data.map((d: any) => d.properties[MAP_JSON[chosenKey].propertiesKey]),
          datasets: [
            {
              label: 'Countries',
              data: featuresWithAudienceValue(data, geoTypeData),
              type: 'choropleth',
            },
          ],
        }}
        options={{
          scales: {
            projection: {
              axis: 'x',
              projection: 'albersUsa',
            },
            color: {
              axis: 'x',
              min: 0,
              max: 100,
              display: false,
              interpolate: (v) => {
                if (v <= 0.25) {
                  return backgroundColor[0];
                }
                if (v <= 0.5) {
                  return backgroundColor[1];
                }
                if (v <= 0.75) {
                  return backgroundColor[2];
                }
                if (v <= 1) {
                  return backgroundColor[3];
                }
                return '';
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: (e) => {
                  // @ts-ignore
                  return e[0].raw?.feature?.properties.name;
                },
                label: (e) => {
                  // @ts-ignore
                  return `Reach: ${NumberFormatter.format((e.raw?.value as number) || 0)}%`;
                },
              },
              enabled: false,
              external: (ctx) => externalGraphTooltip(ctx),
              backgroundColor: 'white',
              bodyColor: 'black',
              titleColor: 'black',
              borderColor: 'black',
              borderWidth: 0.2,
            },
          },
        }}
      />
    );
  }, [
    backgroundColor,
    data,
    errorType,
    featuresWithAudienceValue,
    geoTypeData,
    isLoading,
    isPlaceholder,
    refetchAudienceBreakdownInfo,
  ]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 100,
          position: 'relative',
        }}
        style={{ aspectRatio: '1/1', pointerEvents: isPlaceholder ? 'none' : 'auto' }}
      >
        {renderChart()}
      </Box>

      {!isPlaceholder && !isLoading && !errorType && geoTypeData?.length ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'evenly',
            width: 75,
            flexDirection: 'column',
            mx: 'auto',
          }}
        >
          <Box
            sx={{ width: 100, display: 'flex', alignSelf: 'stretch', flexDirection: 'row' }}
            style={{ height: '6px' }}
          >
            {legendsArr?.map((val: number, idx: number) => {
              return (
                <Box
                  sx={{ alignSelf: 'stretch', width: 100 }}
                  style={{ backgroundColor: backgroundColor[idx] }}
                />
              );
            })}
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'between', width: 100, alignItems: 'center' }}
          >
            <Box style={{ flex: 1 }}>{renderLegendNumber(0)}</Box>
            <Box style={{ flex: 1 }} sx={{ textAlign: 'center' }}>
              {renderLegendNumber(1)}
            </Box>
            <Box style={{ flex: 1 }} sx={{ textAlign: 'right' }}>
              {renderLegendNumber(3)}
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};
