import { AxiosResponse } from 'axios';
import { datadogRum } from 'iqm-framework';

import { WithResponse } from 'models/Response';
import {
  CreateSegmentedAudienceResponse,
  SegmentedAudiencePartner,
} from 'models/SegmentedAudience';
import { TSegmentGroup } from 'components/Audiences/SegmentedAudience/CreateOrEditSegmentedAudience/types';

import { SUCCESS, FAILED, service } from '../../constants';
import { TActionParams } from '../../types';
import { getApiErrorMsg } from '../../utils';

const actions = {
  createSegmentedAudienceGroup: {
    id: 'createSegmentedAudienceGroup',
    text: 'Create Segmented Audience Group',
  },
};

interface TCreateSegmentedAudienceGroups extends TActionParams {
  segmentGroups: TSegmentGroup[];
  response: AxiosResponse<WithResponse<CreateSegmentedAudienceResponse>>;
  selectedPartner: SegmentedAudiencePartner | null;
  dialogType?: string;
}

export const createSegmentedAudienceGroups = (params: TCreateSegmentedAudienceGroups) => {
  const { segmentGroups, response, success, selectedPartner, dialogType } = params;
  const vendorName = selectedPartner?.segmentPartnerName || null;
  const vendorID = selectedPartner?.segmentPartnerId;

  if (segmentGroups.length) {
    segmentGroups.forEach((group) => {
      const segments = Object.values(group.segments || {});

      if (segments.length) {
        datadogRum.addAction(
          `${actions.createSegmentedAudienceGroup.text} ${success ? SUCCESS : FAILED}`,
          {
            action: `${service}/${actions.createSegmentedAudienceGroup.id}`,
            success,
            errorMsg: !success ? getApiErrorMsg(response) : undefined,
            latency: response.data?.latency,
            statusCode: response.status,
            info: {
              vendorName,
              vendorID,
              segmentCount: segments.length,
              dialogType,
            },
          },
        );
      }
    });
  }
};
