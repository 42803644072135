import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import ViewLookalikeAudience from 'components/Audiences/LookalikeAudience/ViewLookalikeAudience';
import ViewMatchedAudience from 'components/Audiences/MatchedAudience/ViewMatchedAudience';
import ViewSegmentedAudience from 'components/Audiences/SegmentedAudience/ViewSegmentedAudience';
import ViewSegmentedAudienceTemp from 'components/Audiences/SegmentedAudience/ViewSegmentedAudienceTemp';
import ViewRetargetedAudience from 'components/Audiences/RetargetedAudience/ViewRetargetedAudience';
import ViewContextualAudience from 'components/Audiences/ContextualAudience/ViewContextualAudience';
import ViewGeofarmedAudience from 'components/Audiences/GeofarmedAudience/ViewGeofarmedAudience';
import ViewCampaignAudience from 'components/Audiences/CampaignAudience/ViewCampaignAudience';
import {
  CAMPAIGN_AUDIENCE_TYPE_ID,
  CONTEXTUAL_AUDIENCE_TYPE_ID,
  GEOFARMED_AUDIENCE_TYPE_ID,
  LOOKALIKE_AUDIENCE_TYPE_ID,
  MATCHED_AUDIENCE_TYPE_ID,
  RETARGETED_AUDIENCE_TYPE_ID,
  SEGMENTED_AUDIENCE_TYPE_ID,
} from 'constants/audiences';

interface ViewAudiencePageProps {
  iframe?: boolean;
}

const ViewAudiencePage = ({ iframe = false }: ViewAudiencePageProps) => {
  const url = window.location.href.split('/');
  const payload = url[url.length - 1];
  const { audienceTypeId, audienceId, isIncluded } = JSON.parse(atob(payload as string));

  const renderAudienceInfo = useCallback(() => {
    let audienceViewComponent: JSX.Element = <></>;
    switch (audienceTypeId) {
      case MATCHED_AUDIENCE_TYPE_ID:
        audienceViewComponent = (
          <ViewMatchedAudience
            dialog={{ audienceId }}
            dialogVisibility={false}
            isIncluded={isIncluded}
          />
        );
        break;
      case SEGMENTED_AUDIENCE_TYPE_ID:
        audienceViewComponent = iframe ? (
          <ViewSegmentedAudienceTemp dialog={{ audienceId }} dialogVisibility={false} />
        ) : (
          <ViewSegmentedAudience dialog={{ audienceId }} dialogVisibility={false} />
        );
        break;
      case RETARGETED_AUDIENCE_TYPE_ID:
        audienceViewComponent = (
          <ViewRetargetedAudience
            dialog={{ audienceId }}
            dialogVisibility={false}
            isIncluded={isIncluded}
          />
        );
        break;
      case GEOFARMED_AUDIENCE_TYPE_ID:
        audienceViewComponent = (
          <ViewGeofarmedAudience
            dialog={{ audienceId }}
            dialogVisibility={false}
            isIncluded={isIncluded}
          />
        );
        break;
      case CONTEXTUAL_AUDIENCE_TYPE_ID:
        audienceViewComponent = (
          <ViewContextualAudience
            dialog={{ audienceId }}
            dialogVisibility={false}
            isIncluded={isIncluded}
          />
        );
        break;
      case LOOKALIKE_AUDIENCE_TYPE_ID:
        audienceViewComponent = (
          <ViewLookalikeAudience
            dialog={{ audienceId }}
            dialogVisibility={false}
            isIncluded={isIncluded}
          />
        );
        break;
      case CAMPAIGN_AUDIENCE_TYPE_ID:
        audienceViewComponent = (
          <ViewCampaignAudience
            dialog={{ audienceId }}
            dialogVisibility={false}
            isIncluded={isIncluded}
          />
        );
        break;
      default:
        break;
    }
    return audienceViewComponent;
  }, [audienceTypeId, audienceId, isIncluded, iframe]);

  return <>{renderAudienceInfo()}</>;
};

const mapActions = {};

export const ViewAudience = connect(null, mapActions)(ViewAudiencePage);
