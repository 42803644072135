import * as React from 'react';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  IconButton,
  sx,
  Chip,
  Tooltip,
  Button,
  ButtonBase,
} from '@applift/factor';
import { Segment, Delete, InfoCircle } from '@applift/icons';
import { SearchSegmentOption, NormalizedSegmentData } from '@applift/platform';

interface Props {
  segments: Record<string, NormalizedSegmentData>;
  title: string;
  relation: 'AND' | 'OR';
  onRemoveSegment?: (segmentId: string) => void;
  onRemoveSegmentGroup?: () => void;
  isSelected?: boolean;
  isEditable?: boolean;
  onSelectSegmentGroup?: () => void;
}

export const SegmentGroup = (props: Props) => {
  const {
    segments,
    title,
    relation,
    onRemoveSegment,
    onRemoveSegmentGroup,
    isSelected,
    isEditable = true,
    onSelectSegmentGroup,
  } = props;

  const segmentArr = Object.entries(segments || {});

  return (
    <Tooltip
      placement="top"
      arrow
      title={!isSelected && isEditable ? 'Click to edit the segment group' : null}
    >
      <Paper
        Component={isEditable ? ButtonBase : 'div'}
        onClick={!isSelected && isEditable ? onSelectSegmentGroup : undefined}
        variant="outlined"
        style={isSelected || !isEditable ? { cursor: 'default' } : {}}
        sx={{ ...(isSelected && isEditable ? { borderColor: 'primary-600', boxShadow: 200 } : {}) }}
      >
        <Box sx={{ pt: 16, width: 100 }}>
          <Box sx={{ width: 100, display: 'flex', alignItems: 'center', px: 16, pb: 8, gap: 16 }}>
            <Typography weight="demi" sx={{ fontSize: 16 }}>
              {title} {segmentArr.length ? `(${segmentArr.length})` : ''}
            </Typography>
            <Chip
              size="small"
              color="secondary"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography gutterBottom={false} sx={{ fontSize: 12, mr: 4 }}>
                    {relation}
                  </Typography>
                  <Tooltip
                    placement="top"
                    arrow
                    title={`The segments within one group have ${relation} relationship between them`}
                  >
                    <InfoCircle
                      fontSize={16}
                      sx={{ textColor: 'neutral-500', position: 'relative' }}
                      style={{ top: -1 }}
                    />
                  </Tooltip>
                </Box>
              }
            />
            {isEditable ? (
              <Button
                variant="text"
                color="secondary"
                startIcon={<Delete />}
                onClick={() => onRemoveSegmentGroup?.()}
                sx={{
                  ml: 'auto',
                  visibility: !!onRemoveSegmentGroup && isSelected ? 'visible' : 'hidden',
                }}
              >
                Remove Group
              </Button>
            ) : null}
          </Box>
          {segmentArr.length ? (
            <List>
              {segmentArr.map((item: [string, NormalizedSegmentData]) => (
                <ListItem
                  key={item[0]}
                  classes={{
                    root: sx({ borderBottom: 1 }),
                    content: sx({ alignItems: 'center' }),
                  }}
                >
                  <Box sx={{ width: 100, pr: 8 }} style={{ minWidth: 0 }}>
                    <SearchSegmentOption data={item[1]} />
                  </Box>
                  {!!onRemoveSegment && (
                    <IconButton
                      onClick={() => onRemoveSegment(item[0])}
                      color="secondary"
                      size="medium"
                      style={{ height: 'fit-content', minWidth: 'fit-content' }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mb: 24,
                mt: 32,
              }}
            >
              <Segment fontSize={40} sx={{ textColor: 'neutral-400', mb: 8 }} />
              <Typography>Selected segments will appear here</Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Tooltip>
  );
};
