import React from 'react';
import { AudienceTypeId } from './Table';

export const ROUTE_NAMES = [
  '/',
  '/matched',
  '/segmented',
  '/retargeted',
  '/geofarmed',
  '/contextual',
  '/lookalike',
  '/campaign',
  '/pre-bid',
  '/matched/:id',
  '/segmented/:id',
  '/retargeted/:id',
  '/contextual/:id',
  '/geofarmed/:id',
  '/lookalike/:id',
  '/campaign/:id',
  '/pre-bid/:id',
  '*',
] as const;
export type RouteName = typeof ROUTE_NAMES[number];

export type Route = {
  path: RouteName;
  component: React.ComponentType<any>;
  title?: string;
  icon?: string;
  value?: AudienceTypeId;
  exact?: boolean;
  private?: boolean;
  description?: string;
};
