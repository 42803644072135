import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@applift/factor';
import { CampaignAdvanced, CampaignPG } from '@applift/icons';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaigns';
import React from 'react';

interface Props {
  selectedCampaignType: number;
  onChange: (value: number) => void;
  defaultValue: number;
  disabled: boolean;
}

export const CampaignTypeRadioGroup = (props: Props) => {
  const { selectedCampaignType, onChange, defaultValue, disabled } = props;

  return (
    <FormControl required fullWidth key="campaignType">
      <FormLabel disabled={disabled}>
        <Typography variant="label" component="span" sx={{ textColor: 'neutral-600' }}>
          Campaign Type
        </Typography>
      </FormLabel>
      <RadioGroup
        title="CampaignType"
        defaultValue={defaultValue}
        value={selectedCampaignType}
        onChange={(_e: React.ChangeEvent<HTMLInputElement>, value: string) => {
          onChange(Number.parseInt(value, 10));
        }}
        sx={{ display: 'flex', flexDirection: 'row', gap: 8, flexWrap: 'nowrap' }}
      >
        <FormControlLabel
          disabled={disabled}
          value={CAMPAIGN_TYPE_BY_NAME.advanced}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CampaignAdvanced fontSize={20} sx={{ mr: 4, textColor: 'primary-600' }} />{' '}
              <Typography>Advanced</Typography>
            </Box>
          }
          control={<Radio />}
        />
        <FormControlLabel
          disabled={disabled}
          value={CAMPAIGN_TYPE_BY_NAME.pg}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CampaignPG fontSize={20} sx={{ mr: 4, textColor: 'primary-600' }} />{' '}
              <Typography>PG</Typography>
            </Box>
          }
          control={<Radio />}
        />
      </RadioGroup>
    </FormControl>
  );
};
