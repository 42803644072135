import React from 'react';
import { SVG_HOST_URL } from 'factor';

import styles from './styles.module.scss';

const EMPTY_LIST_ILLUSTRATION = `${SVG_HOST_URL}/misc/file-searching.svg`;

interface Props {
  className?: string;
  text?: string;
}

const EmptyListIllustration = (props: Props) => {
  const { className, text } = props;

  return (
    <div className={`${styles.emptyList} ${className}`}>
      <img
        src={EMPTY_LIST_ILLUSTRATION}
        alt="empty list illustration"
        className={styles.emptyListIllustration}
      />
      <div className={styles.emptyListText}>{text}</div>
    </div>
  );
};

export default EmptyListIllustration;
