import { Option } from './Option';

export interface TableRow {
  total?: boolean;
  id?: string | number;
  audienceName?: string;
  ecpm: string;
  matchRate: string;
  whiteList: string;
  blackList: string;
  rowClassName?: string;
  selected?: boolean;
}

export interface Table {
  title: string;
  unsortedData?: TableRow;
  data: TableRow[];
}

export interface StickedOption<T = any> extends Option<T> {
  alwaysEnabled?: boolean;
  isGroupTitle?: boolean;
}

export interface TableSortingParams {
  field: string;
  direction: 'asc' | 'desc';
}

export interface TableAudienceItem {
  id: number;
  audienceName: string;
  audienceType: string;
  audienceTypeId: number;
  included: number;
  excluded: number;
  status: string;
  matchRate: number;
  createdOn: number;
  tagTime: number;
  tagDuration: string;
  tagUniqueCount: number;
  isAudienceNewPlatform?: 0 | 1;
  tagId?: string;
  dataCost?: number;
  segmentedAudience?: string[];
  startDate: number;
  endDate: number;
  uniques: number;
  excludedCampaignList: number[];
  includedCampaignList: number[];
  rejectionReason: string;
  fileTotalCount?: number;
}

export interface Skeleton {
  columns: number;
  rows: number;
  classes?: string[];
}

export interface DeleteAudienceRequestData {
  audienceIds: string;
}

export interface UpdateAudienceNameResponse {
  id: number;
  message: string;
}

interface Record {
  statusId: number;
  id: number;
}

export interface InsightsData {
  [key: number]: Record;
}

export const audienceIDMap: { [key: number]: { icon: string; label: string } } = {
  1: {
    icon: 'AudienceMatched',
    label: 'Matched Audience',
  },
  2: {
    icon: 'AudienceSegmented',
    label: 'Segmented Audience',
  },
  3: {
    icon: 'AudienceRetargeted',
    label: 'Retargeted Audience',
  },
  4: {
    icon: 'AudienceGeo',
    label: 'Geofarmed Audience',
  },
  5: {
    icon: 'AudienceContextual',
    label: 'Contextual Audience',
  },
  6: {
    icon: 'AudienceLookalike',
    label: 'Lookalike Audience',
  },
  7: {
    icon: 'AudienceCampaign',
    label: 'Campaign Audience',
  },
  8: {
    icon: 'AudiencePreBid',
    label: 'Pre-bid Audience',
  },
};

export type AudienceTypeId = '' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
