import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import {
  ColumnMapping,
  Column,
  DataPartners,
  EditableMatchedAudience,
  DataFormats,
} from '../../models/MatchedAudience';
import { matchedAudienceConstants } from './constants';

export interface MatchedAudienceState {
  columnsMapping: ColumnMapping[];
  columnsMasterlist: Column[];
  audienceName: string;
  submitInProgress: boolean;
  editableAudience: EditableMatchedAudience | null;
  isSuccessfullyCreated: boolean | null;
  dataPartners: DataPartners[];
  dataFormats: DataFormats[];
}

const defaultMatchedAudienceState: MatchedAudienceState = {
  columnsMapping: [],
  columnsMasterlist: [],
  audienceName: '',
  submitInProgress: false,
  editableAudience: null,
  isSuccessfullyCreated: null,
  dataPartners: [],
  dataFormats: [],
};

function saveColumnsMasterlist(
  state: MatchedAudienceState,
  action: Action<Column[]>,
): MatchedAudienceState {
  return {
    ...state,
    columnsMasterlist: action.payload,
  };
}

function saveColumnsMapping(
  state: MatchedAudienceState,
  action: Action<ColumnMapping[]>,
): MatchedAudienceState {
  return {
    ...state,
    columnsMapping: action.payload,
  };
}

function saveAudienceName(
  state: MatchedAudienceState,
  action: Action<string>,
): MatchedAudienceState {
  return {
    ...state,
    audienceName: action.payload,
  };
}

function reset(state: MatchedAudienceState): MatchedAudienceState {
  return {
    ...defaultMatchedAudienceState,
    columnsMasterlist: state.columnsMasterlist,
  };
}

function startCreating(state: MatchedAudienceState): MatchedAudienceState {
  return {
    ...state,
    submitInProgress: true,
  };
}

function endCreating(state: MatchedAudienceState): MatchedAudienceState {
  return {
    ...state,
    submitInProgress: false,
  };
}

function setSuccessfullyCreated(
  state: MatchedAudienceState,
  action: Action<boolean | null>,
): MatchedAudienceState {
  return {
    ...state,
    isSuccessfullyCreated: action.payload,
  };
}

function setEditableAudience(
  state: MatchedAudienceState,
  action: Action<EditableMatchedAudience>,
): MatchedAudienceState {
  return {
    ...state,
    editableAudience: action.payload,
  };
}

function setDataPartners(
  state: MatchedAudienceState,
  action: Action<DataPartners[]>,
): MatchedAudienceState {
  return {
    ...state,
    dataPartners: action.payload,
  };
}

function setDataFormats(
  state: MatchedAudienceState,
  action: Action<DataFormats[]>,
): MatchedAudienceState {
  return {
    ...state,
    dataFormats: action.payload,
  };
}

const reducer = reducerFromMap<MatchedAudienceState>(defaultMatchedAudienceState, {
  [matchedAudienceConstants.SAVE_COLUMNS_LIST]: saveColumnsMasterlist,
  [matchedAudienceConstants.SAVE_COLUMNS_MAPPING]: saveColumnsMapping,
  [matchedAudienceConstants.SET_AUDIENCE_NAME]: saveAudienceName,
  [matchedAudienceConstants.SET_EDITABLE_AUDIENCE]: setEditableAudience,
  [matchedAudienceConstants.START_CREATING]: startCreating,
  [matchedAudienceConstants.END_CREATING]: endCreating,
  [matchedAudienceConstants.RESET]: reset,
  [matchedAudienceConstants.SET_DATA_FORMATS]: setDataFormats,
  [matchedAudienceConstants.SET_DATA_PARTNERS]: setDataPartners,
  [matchedAudienceConstants.SUCCESSFULLY_CREATED]: setSuccessfullyCreated,
});

export const matchedAudience = (
  state: MatchedAudienceState = defaultMatchedAudienceState,
  action: Action<any>,
) => reducer(state, action);
