import * as React from 'react';
import { Box, Avatar, Typography, Divider } from '@applift/factor';

interface SummaryItem {
  label?: string;
  value?: React.ReactNode;
  logoUrl?: string | null;
  icon?: React.ReactNode;
}

interface Props {
  summaryItems: SummaryItem[];
}

export const SummaryInfoRow = (props: Props) => {
  const { summaryItems } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, flexWrap: 'wrap' }}>
      {summaryItems.map((item, index) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', gap: 4 }}
          style={{ minWidth: 'fit-content' }}
        >
          {item.icon}
          <Typography sx={{ textColor: 'neutral-500' }}>{item.label} </Typography>
          {!!item?.logoUrl && (
            <Avatar
              imgProps={{
                style: { objectFit: 'contain' },
                sx: { bgColor: 'neutral-0' },
              }}
              variant="square"
              size="small"
              src={item?.logoUrl}
              alt={item?.label}
            />
          )}
          <Typography>{item.value}</Typography>
          {index !== summaryItems.length - 1 && (
            <Divider
              variant="middle"
              orientation="vertical"
              color="secondary"
              style={{
                minHeight: 4,
                height: 4,
                minWidth: 4,
                width: 4,
                maxWidth: 4,
              }}
              sx={{ borderRadius: 'circle', bgColor: 'neutral-400', mx: 8 }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};
