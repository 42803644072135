import { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { getInstance } from './Instance';
import { CreateRetargetedAudienceData, RetargetedAudience } from '../models/RetargetedAudience';
import { WithResponse } from '../models/Response';

interface CreateResponse {
  message: string;
  // eslint-disable-next-line camelcase
  tag_script: string;
}

export const createRetargetedAudience = async (
  data: CreateRetargetedAudienceData,
): Promise<AxiosResponse<WithResponse<CreateResponse>>> => {
  try {
    const response: AxiosResponse<WithResponse<CreateResponse>> = await getInstance().post(
      '/v3/audience/retarget/tag',
      data,
    );
    return response;
  } catch (e) {
    return Promise.reject(get(e, 'response', e));
  }
};

export const updateRetargetedAudience = async (
  data: CreateRetargetedAudienceData,
  audienceId: number,
): Promise<WithResponse<CreateResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<CreateResponse>> = await getInstance().put(
      `/v3/audience/retarget/${audienceId}`,
      data,
    );
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const sendEmail = async (data: {
  recipients: string;
  pixelCode: string;
}): Promise<boolean> => {
  try {
    await getInstance().post('/v2/audience/email/notify', data);
    return true;
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const getRetargetedAudience = async (
  id: number,
): Promise<WithResponse<RetargetedAudience>> => {
  try {
    const response: AxiosResponse<WithResponse<RetargetedAudience>> = await getInstance().get(
      `/v3/audience/retarget/${id}`,
    );
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};
