import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import {
  CitiesInfo,
  CongressionalDistrictsInfo,
  CountiesInfo,
  CountryInfo,
  CreateLookalikeAudienceData,
  CreateLookalikeAudienceResponse,
  EditLookalikeAudienceResponse,
  GetLookalikeAudienceResponse,
  SenatesInfo,
  StateHousesInfo,
  StatesInfo,
} from 'models/LookalikeAudience';
import { WithResponse } from 'models/Response';
import { getInstance } from './Instance';

export const createLookalikeAudience = async (
  createAudienceData: CreateLookalikeAudienceData,
): Promise<AxiosResponse<WithResponse<CreateLookalikeAudienceResponse>>> => {
  try {
    const response: AxiosResponse<WithResponse<
      CreateLookalikeAudienceResponse
    >> = await getInstance().post(`/v3/audience/lookalike/add`, createAudienceData);
    return response;
  } catch (error) {
    return Promise.reject(get(error, 'response', error));
  }
};

export const updateLookalikeAudienceName = async (
  audienceName: string,
  audienceId: number,
): Promise<EditLookalikeAudienceResponse> => {
  try {
    const response: AxiosResponse<EditLookalikeAudienceResponse> = await getInstance().patch(
      `/v2/audience/updateName/${audienceId}`,
      {
        audienceName,
        audienceTypeId: 6,
      },
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getLookalikeAudienceInfo = async (
  audienceId: number,
): Promise<WithResponse<GetLookalikeAudienceResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<
      GetLookalikeAudienceResponse
    >> = await getInstance().get(`/v3/audience/lookalike/${audienceId}`);
    return response.data;
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getCountryInfo = async (
  countryIds: string,
  pageNo: number,
  noOfEntries: number,
): Promise<WithResponse<CountryInfo[]>> => {
  try {
    const response: AxiosResponse<WithResponse<CountryInfo[]>> = await getInstance().get(
      `/v2/master/segment/country?pageNo=${pageNo || -1}&noOfEntries=${noOfEntries ||
        ''}&sortBy&order&searchField&segmentIds=${countryIds}`,
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getStatesInfo = async (
  stateIds: string,
  pageNo: number,
  noOfEntries: number,
): Promise<WithResponse<StatesInfo[]>> => {
  try {
    const response: AxiosResponse<WithResponse<StatesInfo[]>> = await getInstance().get(
      `/v2/master/segment/states?pageNo=${pageNo || -1}&noOfEntries=${noOfEntries ||
        ''}&sortBy&order&searchField&segmentIds=${stateIds}`,
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getCitiesInfo = async (
  cityIds: string,
  pageNo: number,
  noOfEntries: number,
): Promise<WithResponse<CitiesInfo[]>> => {
  try {
    const response: AxiosResponse<WithResponse<CitiesInfo[]>> = await getInstance().get(
      `/v2/master/segment/city?campaignId=&pageNo=${pageNo || -1}&noOfEntries=${noOfEntries ||
        ''}&stateIds=&sortBy=&order=&segmentIds=${cityIds}&campaignId`,
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getCountiesInfo = async (
  countyIds: string,
  pageNo: number,
  noOfEntries: number,
): Promise<WithResponse<CountiesInfo[]>> => {
  try {
    const response: AxiosResponse<WithResponse<CountiesInfo[]>> = await getInstance().get(
      `/v2/master/segment/county?campaignId=&pageNo=${pageNo || -1}&noOfEntries=${noOfEntries ||
        ''}&stateIds=&sortBy=&order=&segmentIds=${countyIds}`,
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getSenatesInfo = async (
  senateIds: string,
  pageNo: number,
  noOfEntries: number,
): Promise<WithResponse<SenatesInfo[]>> => {
  try {
    const response: AxiosResponse<WithResponse<SenatesInfo[]>> = await getInstance().get(
      `/v2/master/segment/senate-district?pageNo=${pageNo || -1}&noOfEntries=${noOfEntries ||
        ''}&stateIds=&sortBy&order&segmentIds=${senateIds}`,
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getCongressionalDistrictsInfo = async (
  congressionalDistrictIds: string,
  pageNo: number,
  noOfEntries: number,
): Promise<WithResponse<CongressionalDistrictsInfo[]>> => {
  try {
    const response: AxiosResponse<WithResponse<
      CongressionalDistrictsInfo[]
    >> = await getInstance().get(
      `/v2/master/segment/congressional-district?pageNo=${pageNo || -1}&noOfEntries=${noOfEntries ||
        ''}&stateIds=&sortBy&order&segmentIds=${congressionalDistrictIds}`,
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getStateHousesInfo = async (
  stateHouseIds: string,
  pageNo: number,
  noOfEntries: number,
): Promise<WithResponse<StateHousesInfo[]>> => {
  try {
    const response: AxiosResponse<WithResponse<StateHousesInfo[]>> = await getInstance().get(
      `/v2/master/segment/house-district?pageNo=${pageNo || -1}&noOfEntries=${noOfEntries ||
        ''}&stateIds=&sortBy&order&segmentIds=${stateHouseIds}`,
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};
