import React from 'react';
import { Box, CircularProgress, Typography } from '@applift/factor';
import { useSegmentsByAudienceId } from 'hooks/useSegmentedAudience';
import styles from './styles.module.scss';

interface Props {
  audienceId: number;
  count: number;
}

export const SegmentsList = (props: Props) => {
  const { audienceId, count } = props;

  const segmentsListQuery = useSegmentsByAudienceId({
    audienceIds: [audienceId],
    segmentListSize: count,
  });

  if (segmentsListQuery.data?.data?.[0]) {
    const { totalSegments, segmentDetailsResponseVOList } = segmentsListQuery.data.data[0];

    return (
      <>
        <Box sx={{ mb: 2 }}>Segments </Box>
        <ul className={styles.segmentList}>
          {segmentDetailsResponseVOList.map((segment) => (
            <Typography component="li" className={styles.segmentList}>
              {' '}
              {segment.segmentName}
            </Typography>
          ))}
        </ul>
        <Box sx={{ ml: 12, mt: 2 }}>
          <Typography>{totalSegments > count ? `+ ${totalSegments - count} more` : ''}</Typography>
        </Box>
      </>
    );
  }

  if (segmentsListQuery.error) {
    return <Typography>Error loading segments list.</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={16} thickness={2} />
    </Box>
  );
};
