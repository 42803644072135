import { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { getInstance } from './Instance';
import {
  Column,
  DownloadFileAPIResponse,
  SubmitMatchedAudienceData,
  EditableMatchedAudience,
  UpdateMatchedAudienceData,
  IMatchedAudience,
  DataPartners,
  DataFormats,
} from '../models/MatchedAudience';
import { Response, WithResponse } from '../models/Response';

interface UploadResponse {
  message: string;
}
interface CreateResponse {
  success: boolean;
  data: {
    message: string;
  };
}

export const uploadMatchedAudience = async (
  details: SubmitMatchedAudienceData,
): Promise<UploadResponse> => {
  try {
    const {
      file,
      columns,
      audienceName,
      metadata,
      isSingleColumnEnabled,
      fileTotalCount,
      columnSettings,
    } = details;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('columns', JSON.stringify(columns));
    formData.append('metadata', JSON.stringify(metadata));
    formData.append('audienceName', audienceName);
    formData.append('isSingleColumnEnabled', JSON.stringify(isSingleColumnEnabled));
    formData.append('fileTotalCount', JSON.stringify(fileTotalCount));
    if (columnSettings && Object.values(columnSettings).length) {
      formData.append('columnSettings', JSON.stringify(columnSettings));
    }
    const response: AxiosResponse<Response<UploadResponse>> = await getInstance().post(
      '/v2/audience/matched/add',
      formData,
    );
    // @ts-ignore
    return get(response, 'data.responseObject.message', null);
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const updateMatchedAudience = async (
  details: UpdateMatchedAudienceData,
): Promise<UploadResponse> => {
  try {
    const { rawS3URL, columns, audienceName } = details;
    const response: AxiosResponse<Response<UploadResponse>> = await getInstance().patch(
      `/v2/audience/matched/update/${details.id}`,
      {
        audienceName,
        rawS3URL,
        columns: JSON.stringify(columns),
      },
    );
    // @ts-ignore
    return get(response, 'data.responseObject.message', null);
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const getColumns = async (): Promise<Column[]> => {
  try {
    const response: AxiosResponse<Response<Column[]>> = await getInstance().get(
      '/v3/audience/static/matched/column-list',
    );
    // @ts-ignore
    return get(response, 'data.data', '');
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const getDownloadFileURL = async (
  audienceId: number,
  advertiserId: number,
  dspId: number,
): Promise<string> => {
  try {
    const response: AxiosResponse<Response<DownloadFileAPIResponse>> = await getInstance().get(
      `/v2/audience/matched/${audienceId}/file/download`,
      {
        params: {
          advertiserId,
          dspId,
        },
      },
    );
    return get(response, 'data.responseObject.url', '');
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const getAudience = async (audienceId: number): Promise<EditableMatchedAudience> => {
  try {
    const response: AxiosResponse<Response<EditableMatchedAudience>> = await getInstance().get(
      `/v2/audience/matched/${audienceId}`,
    );
    const data = get(response, 'data.responseObject', null);
    // @ts-ignore
    const metadata = JSON.parse(data.metadata);
    // @ts-ignore
    const existingColumnMatching = JSON.parse(data.existingColumnMatching);
    let columnSettings;
    if (data && data.columnSettings) {
      columnSettings = JSON.parse(data.columnSettings.toString());
    }

    // @ts-ignore
    return {
      ...data,
      metadata,
      existingColumnMatching,
      columnSettings,
    };
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const getMatchedAudience = async (
  audienceId: number,
): Promise<IMatchedAudience & {
  axiosResponse: AxiosResponse<Response<EditableMatchedAudience>>;
}> => {
  try {
    const response: AxiosResponse<Response<EditableMatchedAudience>> = await getInstance().get(
      `/v2/audience/matched/${audienceId}`,
    );
    const data = get(response, 'data.responseObject', null);
    // @ts-ignore
    const metadata = JSON.parse(data.metadata);
    // @ts-ignore
    const existingColumnMatching = JSON.parse(data.existingColumnMatching);
    let columnSettings;
    if (data && data.columnSettings) {
      columnSettings = JSON.parse(data.columnSettings.toString());
    }

    return {
      ...data,
      metadata,
      // @ts-ignore
      existingColumnMatching,
      columnSettings,
      axiosResponse: response,
    };
  } catch (e) {
    const responseData = get(e, 'response.data', {});
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ ...responseData, axiosResponse: get(e, 'response') });
  }
};

export const duplicateMatchedAudience = async (
  audienceId: number,
  audienceName: string,
): Promise<CreateResponse> => {
  try {
    const response: AxiosResponse<Response<CreateResponse>> = await getInstance().post(
      `/v3/audience/matched/duplicate/${audienceId}`,
      {
        name: audienceName,
      },
    );
    // @ts-ignore
    return get(response.data, 'data.message', null);
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const editDownloadFileURL = async (audienceId: number): Promise<string> => {
  try {
    const response: AxiosResponse<Response<DownloadFileAPIResponse>> = await getInstance().get(
      `/v2/audience/matched/${audienceId}/file/download`,
    );
    return get(response, 'data.responseObject.url', '');
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const getDataPartners = async (): Promise<DataPartners[]> => {
  try {
    const response: AxiosResponse<WithResponse<DataPartners[]>> = await getInstance().get(
      '/v3/audience/static/matched/data-partner',
    );
    // @ts-ignore
    return get(response, 'data.data', '');
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const getDataFormats = async (): Promise<DataFormats[]> => {
  try {
    const response: AxiosResponse<WithResponse<DataFormats[]>> = await getInstance().get(
      '/v3/audience/static/matched/data-format',
    );
    // @ts-ignore
    return get(response, 'data.data', '');
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};
