import { searchAudience } from './actions/allAudience';
import { uploadMatchedAudienceFile, viewMatchedAudience } from './actions/matchedAudience';
import { createSegmentedAudienceGroups } from './actions/segmentedAudience';
import { createLookalikeAudience } from './actions/lookalikeAudience';
import { createGeofarmedAudience } from './actions/geofarmedAudience';
import {
  createContextualAudience,
  uploadContextualAudienceFile,
} from './actions/contextualAudience';
import { createRetargetedAudience } from './actions/retargetedAudience';
import { createCampaignAudience } from './actions/campaignAudience';

export const RUMLogger = {
  searchAudience,
  uploadMatchedAudienceFile,
  viewMatchedAudience,
  createSegmentedAudienceGroups,
  createLookalikeAudience,
  createGeofarmedAudience,
  createContextualAudience,
  uploadContextualAudienceFile,
  createRetargetedAudience,
  createCampaignAudience,
};
