import { Chart } from 'chart.js';

export const externalGraphTooltip = (context: { chart: any; tooltip: any }) => {
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip');

  const labelColor = context.tooltip.labelColors[0].backgroundColor;

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<div></div>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  //   @ts-ignore
  if (tooltipModel.opacity === 0) {
    // @ts-ignore
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem: { lines: any }) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let style = `font-size: 14px`;
    style += `; font-weight: 600`;
    style += `; display: flex`;
    style += `; align-items: start`;
    style += `; flex-direction: column`;

    let innerHtml = `<div style="${style}"><div style="display: flex; align-items: center"><div style="width: 8px; height: 8px; background: ${labelColor}; border-radius: 120px ;display: block; margin-right: 12px"></div>`;

    titleLines.forEach((title: any) => {
      innerHtml += `<div>${title}</div>`;
    });
    innerHtml += '</div><div></div';

    bodyLines.forEach((body: any, i: string | number) => {
      const colors = tooltipModel.labelColors[i];
      let style = `border-color:${colors.borderColor}`;
      style += '; border-width: 2px';
      style += '; display: block';
      style += '; font-size: 14px';
      style += '; margin-top: 4px';
      style += '; margin-left: 20px';
      style += '; font-weight: 400';
      const span = `<span style="${style}">${body}</span>`;
      innerHtml += `<tr><td>${span}</td></tr>`;
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('div');
    // @ts-ignore
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  // @ts-ignore
  const bodyFont = Chart.helpers?.toFont(tooltipModel.options.bodyFont);

  // Display, position, and set styles for font

  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.zIndex = '9999';
  tooltipEl.style.backgroundColor = '#FFFFFF';
  tooltipEl.style.boxShadow = '#FFFFFF';
  tooltipEl.style.padding = '8px';
  tooltipEl.style.borderRadius = '4px';
  tooltipEl.style.boxShadow = '0px 4px 8px 0px #0000000D';
  tooltipEl.style.left = `${position.left + tooltipModel.caretX}px`;
  tooltipEl.style.top = `${position.top + tooltipModel.caretY}px`;
  tooltipEl.style.font = bodyFont?.string;
  tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
  tooltipEl.style.pointerEvents = 'none';
};
