import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import get from 'lodash/get';

import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import { CreateCampaignAudienceData, UpdateCampaignAudienceData } from 'models/CampaignAudience';
import { ICampaignInformationWithOption } from 'components/Audiences/TableSelectedActions/AddToCampaignButton';
import { RUMLogger } from 'services/RUMLogger';
import { getApiErrorMsg } from 'services/RUMLogger/utils';
import { EMPTY_SELECTED_AUDIENCE_STATUS } from 'constants/audiences';
import { setRowAnimation } from 'styles/tableRowAnimation';
import { createAction } from '../../utils/actions';
import { API } from '../../api';
import { campaignAudienceConstants } from './constants';
import { AppState } from '..';
import { tableConstants } from '../table/constants';
import { tableActions } from '../table/actions';
import { snackbarActions } from '../snackbar/actions';

export interface CreateCampaignAudience {
  createCampaignAudience: (
    data: CreateCampaignAudienceData,
    selectedCampaigns: ICampaignInformationWithOption[],
  ) => void;
}

export interface UpdateCampaignAudience {
  updateCampaignAudience: (data: UpdateCampaignAudienceData) => void;
}

export const campaignAudienceActions = {
  createCampaignAudience: (
    createAudienceData: CreateCampaignAudienceData,
    selectedCampaigns: ICampaignInformationWithOption[],
  ) => {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(campaignAudienceConstants.START_CREATING));
      try {
        const response = await API.CampaignAudience.setCampaignAudience(createAudienceData);
        RUMLogger.createCampaignAudience({
          createAudienceData,
          selectedCampaigns,
          statusCode: response.status,
          latency: response.data.latency,
          success: true,
        });
        const { data } = response.data;
        const message = data?.message;

        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(
          createAction(
            tableConstants.SET_AUDIENCES_SELECTED_STATUSES,
            EMPTY_SELECTED_AUDIENCE_STATUS,
          ),
        );
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
        setRowAnimation();
      } catch (e) {
        RUMLogger.createCampaignAudience({
          createAudienceData,
          selectedCampaigns,
          statusCode: get(e, 'status'),
          errorMsg: getApiErrorMsg(e as any),
          success: false,
          latency: get(e, 'data.latency'),
        });
        dispatch(
          snackbarActions.openSnackbar({
            message: get(e, 'data.errorObjects[0].error'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(campaignAudienceConstants.RESET));
        dispatch(createAction<void>(campaignAudienceConstants.END_CREATING));
      }
    };
  },
  updateCampaignAudience: (updateAudienceData: UpdateCampaignAudienceData) => {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(campaignAudienceConstants.START_UPDATING));
      try {
        const { message } = await API.CampaignAudience.updateAudience(updateAudienceData);

        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
      } catch (e) {
        dispatch(
          snackbarActions.openSnackbar({
            message: (e as Array<{ errorMsg: string }>)[0].errorMsg,
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(campaignAudienceConstants.RESET));
        dispatch(createAction<void>(campaignAudienceConstants.END_UPDATING));
      }
    };
  },
  reset() {
    return createAction<void>(campaignAudienceConstants.RESET);
  },
};
