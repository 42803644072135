import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AllowlistBlocklistTooltip } from 'factor';
import { Box, Tooltip, Typography } from '@applift/factor';
import {
  TableBaseRoot,
  TableSortedOptions,
  ORIGIN_URL,
  TableComponent,
  AuthService,
} from 'iqm-framework';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import get from 'lodash/get';
import { AxiosResponse } from 'axios';

import {
  StickedOption,
  TableSortingParams,
  AudienceTypeId,
  TableAudienceItem,
  InsightsData,
} from 'models/Table';
import { AppState } from 'store';
import {
  tableActions,
  ChangeSortingColumns,
  SetSelectedAudiences,
  SetAudiencesByType,
  SetAudiencesStatuses,
  SetLoadingStatus,
  SetTableData,
  SetTableTotalItems,
  UpdateAudienceName,
  UpdateSorting,
} from 'store/table/actions';
import { matchedAudienceActions } from 'store/matchedAudience/actions';
import { getSortingColumnOptions } from 'utils/table';
import { AudienceStatus, AudienceType } from 'api/Table';
import { ALL_AUDIENCE_TYPE_IDS, MATCHED_AUDIENCE_TYPE_ID } from 'constants/audiences';
import { getAuthHeaders } from 'utils/headers';
import { User } from 'models/User';
import { applicationActions, OpenDialog } from 'store/app/actions';
import { RUMLogger } from 'services/RUMLogger';

import { getBodyMapper } from './getBodyMapper';
import { GenerateProgress } from '../../ProgressLoader';
import { OpenSnackbar, snackbarActions } from '../../../store/snackbar/actions';

import styles from './styles.module.scss';
// eslint-disable-next-line import/no-mutable-exports
export let tableComponentInstanceRef: any;

interface Props
  extends UpdateAudienceName,
    UpdateSorting,
    SetTableTotalItems,
    SetLoadingStatus,
    SetTableData,
    OpenSnackbar,
    ChangeSortingColumns,
    SetSelectedAudiences,
    SetAudiencesByType,
    SetAudiencesStatuses,
    OpenDialog {
  sorting: TableSortingParams;
  searchText: string;
  user: User | null;
  selectedSortingColumns: { [key in AudienceTypeId]: StickedOption[] };
  setInsightsData: (data: InsightsData) => void;

  matchedAudienceSubmitInProgress: boolean;
  retargetedAudienceSubmitInProgress: boolean;
  lookalikeAudienceSubmitInProgress: boolean;
  campaignAudienceSubmitInProgress: boolean;
  segmentedAudienceSubmitInProgress: boolean;
  geofarmedAudienceSubmitInProgress: boolean;
  contextualAudienceSubmitInProgress: boolean;
  audienceTypeIds: AudienceTypeId;
  audiencesByType: AudienceType[];
  statusList: AudienceStatus[];
  selectedAudienceStatus: { [key in AudienceTypeId]: string };
  loading: boolean;
  isSidebarOpened: boolean;
  controls?: (tableComponentInstanceRef: any) => React.ReactNode;
}

const AudiencesTableComponent = (props: Props) => {
  const {
    updateAudienceName,
    updateSorting,
    setTableTotalItems,
    setLoadingStatus,
    setTableData,
    openSnackbar,
    changeSortingColumns,

    sorting,
    searchText,
    user,
    selectedSortingColumns,
    setSelectedAudiences,
    matchedAudienceSubmitInProgress,
    retargetedAudienceSubmitInProgress,
    lookalikeAudienceSubmitInProgress,
    campaignAudienceSubmitInProgress,
    segmentedAudienceSubmitInProgress,
    geofarmedAudienceSubmitInProgress,
    contextualAudienceSubmitInProgress,
    audienceTypeIds,
    setAudiencesByType,
    setAudiencesStatuses,
    selectedAudienceStatus,
    isSidebarOpened,
    controls,
    openDialog,

    setInsightsData,
  } = props;

  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isColumnsChanged, setColumnsChanged] = useState<boolean>(false);
  const prevLoggedSearchedParams = useRef<any>();
  // eslint-disable-next-line
  const RUMLogSearch = useCallback(debounce(RUMLogger.searchAudience, 1000), []);

  const tableHeaderMapper = useMemo(
    () => ({
      id: {
        sortingKey: 'id',
        label: (
          <Tooltip
            title={<Typography variant="label">Audience ID</Typography>}
            placement="top"
            arrow
            className={styles.tooltip}
          >
            <Box>ID</Box>
          </Tooltip>
        ),
        className: 'w-60-60',
      },
      audienceName: {
        sortingKey: 'audienceName',
        label: (
          <Tooltip
            title={<Typography variant="label">Audience Name</Typography>}
            placement="top"
            arrow
            className={styles.tooltip}
          >
            <Box>Audience Name</Box>
          </Tooltip>
        ),
        className: 'w-150-300',
      },
      created: {
        sortingKey: 'createdOn',
        label: (
          <Tooltip
            title={<Typography variant="label">Created on</Typography>}
            placement="top"
            arrow
            className={styles.tooltip}
          >
            <Box>Created</Box>
          </Tooltip>
        ),
        className: 'w-60-60',
      },
      records: {
        label: (
          <Tooltip
            title={<Typography variant="label">Total number of records uploaded</Typography>}
            placement="top"
            arrow
            className={styles.tooltip}
          >
            <Box>Records</Box>
          </Tooltip>
        ),
        className: 'w-60-60',
      },
      matchRate: {
        sortingKey: 'matchRate',
        label: (
          <Tooltip
            title={<Typography variant="label">Match Rate</Typography>}
            placement="top"
            arrow
            className={styles.tooltip}
          >
            <Box>Match rate</Box>
          </Tooltip>
        ),
        className: 'w-60-60',
      },
      cost: {
        sortingKey: 'cost',
        label: (
          <Tooltip
            title={<Typography variant="label">Cost (CPM)</Typography>}
            placement="top"
            arrow
            className={styles.tooltip}
          >
            <Box>Cost (CPM)</Box>
          </Tooltip>
        ),
        className: 'w-60-60',
      },
      uniques: {
        label: (
          <Tooltip
            title={<Typography variant="label">Reach</Typography>}
            placement="top"
            arrow
            className={styles.tooltip}
          >
            <Box>Reach</Box>
          </Tooltip>
        ),
        className: 'w-65-65',
      },
      period: {
        className: 'w-150-350',
        label: (
          <Tooltip
            title={<Typography variant="label">Data Collection Period</Typography>}
            placement="top"
            arrow
            className={styles.tooltip}
          >
            <Box>Data Collection Period</Box>
          </Tooltip>
        ),
      },
      incExc: {
        className: 'w-135-135',
        label: (
          <AllowlistBlocklistTooltip
            labels={['Campaigns targeted for this audience', 'Campaigns blocked for this audience']}
            tooltipProps={{
              disableAnimation: true,
              delayTime: 0,
              portal: true,
              auto: true,
              adjustPortalPosition: true,
            }}
          />
        ),
      },
      status: {
        className: 'w-80-80',
        label: (
          <Tooltip
            title={<Typography variant="label">Status</Typography>}
            placement="left"
            arrow
            className={styles.tooltip}
          >
            <div>Status</div>
          </Tooltip>
        ),
      },
      insights: {
        className: 'w-250-350',
        label: (
          <Tooltip
            title={<Typography variant="label">Generate Insights</Typography>}
            arrow
            className={styles.tooltip}
          >
            <div>Insights</div>
          </Tooltip>
        ),
      },
    }),
    [],
  );

  useEffect(() => {
    setAudiencesByType();
    setAudiencesStatuses();
  }, [setAudiencesByType, setAudiencesStatuses]);

  const params = useMemo(
    () => ({
      searchField: searchText,
      // Direct using of 'dataCost' in columns select option
      // causes appearing of unneeded 'Spending' group for that option.
      // Such behavior is set in iqm-framework.
      sortBy: sorting.field === 'cost' ? 'dataCost' : sorting.field,
      sortType: sorting.direction,
      // if user is not allowed to see segmented audiences, then audienceTypeIds
      // should be without segmented id value
      audienceTypeIds: audienceTypeIds === '' ? ALL_AUDIENCE_TYPE_IDS : audienceTypeIds,
      status: selectedAudienceStatus[audienceTypeIds]?.toLowerCase(),
    }),
    [searchText, sorting, audienceTypeIds, selectedAudienceStatus],
  );

  const dataRequest = useMemo(
    () => ({
      url: `${ORIGIN_URL}/api/v2/audience/detailed/list`,
      headers: getAuthHeaders(),
      method: 'get',
      params,
    }),
    [params],
  );

  const transformRequestParams = useCallback((query: any) => {
    const { pgno: pageNo, no_of_entries: noOfEntries } = query;
    ['pgno', 'no_of_entries', 'sort_by', 'sort_type', 'draw'].forEach((key) =>
      Reflect.deleteProperty(query, key),
    );
    return {
      ...query,
      pageNo,
      noOfEntries,
    };
  }, []);

  const transformCSVDownloadRequest = useCallback(
    () => ({
      ...dataRequest,
      data: {
        ...params,
        noOfEntries: 50,
        dataPath: 'responseObject.data',
      },
      name: `${user?.firstName || ''} ${user?.lastName || ''}`,
      headers: {
        'Base-Url': ORIGIN_URL,
      },
      fileName: 'Audiences',
    }),
    [dataRequest, params, user],
  );

  const excludeList = ['id', 'audienceName'];

  const additionalGroupMap = {
    Audience: Object.keys(tableHeaderMapper).filter((key: string) => !excludeList.includes(key)),
  };

  const sortingColumnOptions = getSortingColumnOptions(audienceTypeIds);
  const transformColumnsOrder = () => sortingColumnOptions.map((v) => v.value);

  tableComponentInstanceRef = useRef(null);

  const getInnerRef = useCallback((ref: any) => {
    tableComponentInstanceRef.current = ref;
  }, []);

  const toggleOpenProp = (isOpened: boolean) => {
    if (isColumnsChanged) {
      setColumnsChanged(false);
      if (
        selectedSortingColumns[audienceTypeIds].length === 2 ||
        selectedSortingColumns[audienceTypeIds].length === sortingColumnOptions.length
      ) {
        setIsOpen(false);
      }
    } else {
      setIsOpen(isOpened);
    }
  };

  const handleColumnsChange = (value: StickedOption[]) => {
    changeSortingColumns({
      ...selectedSortingColumns,
      [audienceTypeIds]: value,
    });
    setColumnsChanged(true);
  };

  const handleTableAudienceSelection = (value: TableAudienceItem[]) => {
    setSelectedAudiences(value);
  };

  const confirmName = useCallback(
    (audienceTypeId: number, id: number, newValue: string) => {
      updateAudienceName(newValue, audienceTypeId, id);
    },
    [updateAudienceName],
  );

  const bodyMapping = useMemo(
    () =>
      getBodyMapper({
        user,
        audienceTypeIds,
        isSidebarOpened,
        confirmName,
        history,
        openDialog,
        openSnackbar,
      }),
    [user, audienceTypeIds, isSidebarOpened, confirmName, history, openDialog, openSnackbar],
  );

  const onChangeSort = ({ sorting: newSorting }: any) => {
    if (sorting.field !== newSorting.field || sorting.direction !== newSorting.direction) {
      updateSorting({
        field: newSorting.field,
        direction: newSorting.direction,
      });
      tableComponentInstanceRef?.current.clearSelected();
    }
  };

  const handleDataChanged = useCallback(
    (data: TableAudienceItem[]) => {
      setTableTotalItems(tableComponentInstanceRef.current.state.totalItems);
      setLoadingStatus(false);
      setTableData(data);
    },
    [setTableTotalItems, setLoadingStatus, setTableData],
  );

  const skeleton = useMemo(
    () => ({
      rows: 10,
      columns: selectedSortingColumns[audienceTypeIds].length,
    }),
    [selectedSortingColumns, audienceTypeIds],
  );

  const logSearch = (
    requestParams: any,
    originalResponse: AxiosResponse<any>,
    success: boolean,
    latency: number,
  ) => {
    if (
      requestParams.pageNo === 1 &&
      prevLoggedSearchedParams.current?.searchField !== requestParams.searchField &&
      prevLoggedSearchedParams.current?.audienceTypeIds === requestParams.audienceTypeIds
    ) {
      RUMLogSearch({
        success,
        axiosResponse: originalResponse,
        searchTerm: requestParams.searchField,
        latency,
      });
    }
    prevLoggedSearchedParams.current = requestParams;
  };

  const handleDataLoaded = (data: any, originalResponse: AxiosResponse<any>, latency: number) => {
    logSearch(get(originalResponse, 'config.params', {}), originalResponse, true, latency);
    setLoadingStatus(false);
  };

  const fetchDataErrorHandler = async (err: any, latency: number) => {
    const requestConfig = get(err, 'response.config.params') || get(err, 'config.params') || {};
    logSearch(requestConfig, get(err, 'response', {}), false, latency);

    if (err) {
      if (err?.message?.includes('401')) {
        openSnackbar({
          message: 'Oops! Your session is expired. You may need to login again',
          type: 'error',
        });
        await new Promise((resolve) => setTimeout(resolve, 3000));
        AuthService.logout();
      } else {
        openSnackbar({
          message: 'Something went wrong. Please try again over a few minutes.',
          type: 'error',
        });
      }
    }
  };

  const transformData = (data: { id: number; audienceTypeId: number }[], response: any) => {
    setInsightsData(response.responseObject.insightDetails);
    return data.map((item) => ({
      ...item,
      typeIdAndId: `${item.id}-${item.audienceTypeId}`,
    }));
  };

  const submitInProgress = useMemo(() => {
    return (
      matchedAudienceSubmitInProgress ||
      retargetedAudienceSubmitInProgress ||
      lookalikeAudienceSubmitInProgress ||
      campaignAudienceSubmitInProgress ||
      segmentedAudienceSubmitInProgress ||
      geofarmedAudienceSubmitInProgress ||
      contextualAudienceSubmitInProgress
    );
  }, [
    matchedAudienceSubmitInProgress,
    retargetedAudienceSubmitInProgress,
    lookalikeAudienceSubmitInProgress,
    campaignAudienceSubmitInProgress,
    segmentedAudienceSubmitInProgress,
    geofarmedAudienceSubmitInProgress,
    contextualAudienceSubmitInProgress,
  ]);

  if (submitInProgress) {
    return (
      <div className={styles.progressContainer}>
        <GenerateProgress />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <TableBaseRoot
        allColumns={sortingColumnOptions}
        selectedColumns={selectedSortingColumns[audienceTypeIds]}
        dataRequest={dataRequest}
        transformCSVDownloadRequest={transformCSVDownloadRequest}
        onColumnsChanged={handleColumnsChange}
      >
        <div className={styles.controls}>
          {controls && controls(tableComponentInstanceRef)}
          <TableSortedOptions
            selectedColumns={selectedSortingColumns[audienceTypeIds]}
            transformColumnsOrder={transformColumnsOrder}
            additionalGroupMap={additionalGroupMap}
            selectProps={{
              showControlLabel: false,
              isOpen,
              toggleOpenProp,
            }}
          />
        </div>
        <TableComponent
          key={audienceTypeIds}
          className={styles.table}
          onSelect={handleTableAudienceSelection}
          dataPath="responseObject.data"
          countPath="responseObject.filteredRecords"
          headerMapping={tableHeaderMapper}
          bodyMapping={bodyMapping}
          offsetTop={60}
          entityName="audiences"
          tableParams={{
            windowFreeResizeEvent: true,
            onChange: onChangeSort,
            preventNavigationByScroll: true,
            tableMaxHeight: 'calc(100vh - 15rem)',
            lastColumnFrozen: Number(audienceTypeIds) === MATCHED_AUDIENCE_TYPE_ID,
          }}
          innerRef={getInnerRef}
          onDataChanged={handleDataChanged}
          skeleton={skeleton}
          idField="typeIdAndId"
          checkboxInHeader
          transformRequestParams={transformRequestParams}
          emptyTableLabel="No Audiences matching selected criteria."
          onFetchDataError={fetchDataErrorHandler}
          defaultSorting={sorting}
          onLoading={() => setLoadingStatus(true)}
          onDataLoaded={handleDataLoaded}
          transformData={transformData}
        />
      </TableBaseRoot>
    </div>
  );
};

const mapState = (state: AppState) => ({
  sorting: state.table.sorting,
  searchText: state.table.searchText,
  user: state.auth.user,
  selectedSortingColumns: state.table.selectedSortingColumns,
  matchedAudienceSubmitInProgress: state.matchedAudience.submitInProgress,
  retargetedAudienceSubmitInProgress: state.retargetedAudience.submitInProgress,
  lookalikeAudienceSubmitInProgress: state.lookalikeAudience.submitInProgress,
  campaignAudienceSubmitInProgress: state.campaignAudience.submitInProgress,
  segmentedAudienceSubmitInProgress: state.segmentedAudience.submitInProgress,
  geofarmedAudienceSubmitInProgress: state.geofarmedAudience.submitInProgress,
  contextualAudienceSubmitInProgress: state.contextualAudience.submitInProgress,
  audienceTypeIds: state.table.audienceTypeIds,
  audiencesByType: state.table.audiencesByType,
  statusList: state.table.statusList,
  selectedAudienceStatus: state.table.selectedAudienceStatus,
  loading: state.table.loading,
  tableTotalItems: state.table.tableTotalItems,
  isSidebarOpened: state.app.isSidebarOpened,
});

const mapAction = {
  updateAudienceName: tableActions.updateAudienceName,
  setAudienceName: matchedAudienceActions.setAudienceName,
  updateSorting: tableActions.updateSorting,
  setTableTotalItems: tableActions.setTableTotalItems,
  setLoadingStatus: tableActions.setLoadingStatus,
  setTableData: tableActions.setTableData,
  openSnackbar: snackbarActions.openSnackbar,
  changeSortingColumns: tableActions.changeSortingColumns,
  setSelectedAudiences: tableActions.setSelectedAudiences,
  setAudiencesByType: tableActions.setAudiencesByType,
  setAudiencesStatuses: tableActions.setAudiencesStatuses,
  openDialog: applicationActions.openDialog,
  setInsightsData: tableActions.setInsightsData,
};

export const AudiencesTable = connect(mapState, mapAction)(AudiencesTableComponent);
