import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import { Event } from './Event';
import './styles.scss';
import styles from './WeekScheduler.module.scss';

export interface Scheduling {
  [key: string]: Array<Array<string>>;
}

type Props = {
  value: Scheduling;
  setScheduling: (data: Scheduling) => void;
  isReadOnly: boolean;
};

type State = {
  isInititated: boolean;
};

export class WeekScheduler extends React.Component<Props, State> {
  calendarRef = React.createRef<FullCalendar>();

  daysMap = [6, 0, 1, 2, 3, 4, 5];

  constructor(props: Props) {
    super(props);
    this.state = {
      isInititated: false,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.addEvents();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props;
    const { isInititated } = this.state;
    if (!isInititated && value && !isEqual(value, prevProps.value)) {
      this.api.removeAllEvents();
      this.addEvents();
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ isInititated: true });
    }
    this.api.updateSize();
  }

  get api() {
    return (this.calendarRef.current as FullCalendar).getApi();
  }

  addEvents = () => {
    const { value } = this.props;
    const values = Object.entries(value);
    const currentWeek = moment().week();
    for (let i = 0; i < values.length; i += 1) {
      const momentDay = this.daysMap.indexOf(Number(values[i][0]));
      const timesArray = values[i][1];
      for (let j = 0; j < timesArray.length; j += 1) {
        const event = timesArray[j];
        const startDate = moment()
          .day(Number(momentDay))
          .week(currentWeek)
          .set(this.prepareTimeToSet(event[0]));
        const endDate = moment()
          .day(Number(momentDay))
          .week(currentWeek)
          .set(this.prepareTimeToSet(event[1]));
        this.handleSelect({ start: startDate.toDate(), end: endDate.toDate() });
      }
    }
  };

  prepareTimeToSet = (timeStr: string) => {
    const times = timeStr.split(':');
    return {
      hour: Number(times[0]),
      minute: Number(times[1]),
      second: Number(times[2]),
    };
  };

  prepareAndUpdateScheduling = () => {
    const { setScheduling } = this.props;
    const events = this.api.getEvents().map(({ start, end }) => ({ start, end }));
    const scheduling: Scheduling = events.reduce((acc: Scheduling, event) => {
      if (event.start && event.end) {
        const start = moment(event.start);
        const end = moment(event.end);
        const startDay = this.daysMap[start.day()];
        const endDay = this.daysMap[end.day()];
        if (startDay === endDay) {
          acc[startDay] = acc[startDay] || [];
          acc[startDay].push([start.format('HH:mm:ss'), end.format('HH:mm:ss')]);
        } else {
          const newStart = start;
          let newStartDay = this.daysMap[newStart.day()];
          while (newStartDay !== endDay) {
            acc[newStartDay] = acc[newStartDay] || [];
            acc[newStartDay].push([
              newStart.format('HH:mm:ss'),
              newStart.endOf('day').format('HH:mm:ss'),
            ]);
            newStart.startOf('day').add(1, 'day');
            newStartDay = this.daysMap[newStart.day()];
          }
          acc[newStartDay] = acc[newStartDay] || [];
          acc[newStartDay].push([newStart.format('HH:mm:ss'), end.format('HH:mm:ss')]);
        }
      }
      return acc;
    }, {});
    setScheduling(scheduling);
  };

  handleSelect = ({ start, end }: { start: Date; end: Date }) => {
    let modifiedEndDate;
    const mStartDate = moment(start).endOf('day');
    const mEndDate = moment(end).add(-1, 'milliseconds');

    if (mStartDate.isSame(mEndDate)) {
      modifiedEndDate = mStartDate.toDate();
    }

    this.api.addEvent({
      id: uniqueId('event-'),
      title: '',
      start,
      end: modifiedEndDate || end,
    });

    this.prepareAndUpdateScheduling();
  };

  handleDrop = ({ event }: { event: any }) => {
    const allEvents = this.api.getEvents();

    const intersections = allEvents.filter((e: any) => {
      return (
        (e.start <= event.start && e.end >= event.start) ||
        (e.start <= event.end && e.end >= event.end) ||
        (e.start <= event.start && e.end >= event.end) ||
        (e.start >= event.start && e.end <= event.end)
      );
    });

    if (intersections.length) {
      const newStart = Math.min.apply(
        null,
        intersections.map((c: any) => c.start),
      );
      const newEnd = Math.max.apply(
        null,
        intersections.map((c: any) => c.end),
      );

      this.handleSelect({ start: new Date(newStart), end: new Date(newEnd) });

      intersections.forEach((e) => e.remove());
      event.remove();
      this.prepareAndUpdateScheduling();
    }
  };

  handleEventRender = (info: any) => {
    const { isReadOnly } = this.props;
    ReactDOM.render(
      <Event info={info} onRemove={this.handleRemoveEvent} isReadOnly={isReadOnly} />,
      info.el,
    );
  };

  handleRemoveEvent = (id: string) => () => {
    const event = this.api.getEventById(id);

    if (event) {
      event.remove();
      this.prepareAndUpdateScheduling();
    }
  };

  render() {
    const { isReadOnly } = this.props;
    const additionalEvents = isReadOnly
      ? {}
      : {
          select: this.handleSelect,
          eventDrop: this.handleDrop,
        };
    return (
      <div data-qa="361" className={styles.fullCalendar}>
        <FullCalendar
          ref={this.calendarRef}
          plugins={[timeGridPlugin, interactionPlugin]}
          defaultView="timeGridWeek"
          selectable
          header={false}
          allDaySlot={false}
          selectOverlap={false}
          columnHeaderFormat={{
            weekday: 'long',
          }}
          height="auto"
          slotDuration="01:00:00"
          editable={!isReadOnly}
          eventRender={this.handleEventRender}
          /* eslint-disable react/jsx-props-no-spreading */
          {...additionalEvents}
        />
      </div>
    );
  }
}
