import React from 'react';
import { Button } from 'factor';

import styles from './styles.module.scss';

interface Props {
  audienceCardsInfo: any[];
}

export const AllAudienceBlankPage = (props: Props) => {
  const { audienceCardsInfo } = props;

  return (
    <div>
      <header className={styles.header}>
        A Perfect Data Secure Platform for your Audience Management
      </header>
      <div className={styles.cardWrapper}>
        {audienceCardsInfo.map((card) => (
          <div className={`${styles.normalCard}`} key={card.audienceTypeId}>
            <div className={styles.image}>
              <img src={card?.imagePath} alt="" />
            </div>
            <div className={styles.title}>{card.title}</div>
            <div className={styles.info}>{card.infoText}</div>
            <div className={styles.btnContainer}>
              <Button
                variant="tertiary2"
                size="small"
                className={`mr-2 ${styles.createBtn}`}
                iconName="Plus"
                onClick={card.handler}
              >
                {`Create ${card.title}`}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
