import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  enqueueSnackbar,
  LoadingButton,
  isDeepEqual,
  CircularProgress,
} from '@applift/factor';
import { InfoCircle } from '@applift/icons';
import { SegmentSelectApiRefType } from '@applift/platform';
import { AxiosResponse } from 'axios';

import { RUMLogger } from 'services/RUMLogger';
import { WithResponse } from 'models/Response';
import {
  CreateSegmentedAudienceResponse,
  SegmentedAudiencePartner,
} from 'models/SegmentedAudience';
import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import {
  useCreateSegmentedAudience,
  useEditSegmentedAudience,
  useSegmentPartnerProviderList,
  useSegmentedAudienceInfo,
} from 'hooks/useSegmentedAudience';
import { createAction } from 'utils/actions';
import { tableConstants } from 'store/table/constants';
import { segmentedAudienceConstants } from 'store/segmentedAudience/constants';
import { EMPTY_SELECTED_AUDIENCE_STATUS } from 'constants/audiences';
import { setRowAnimation } from 'styles/tableRowAnimation';
import { AppState } from 'store';
import { tableActions } from 'store/table/actions';
import { TDialog } from 'store/app/reducer';
import { applicationActions } from 'store/app/actions';
import { REMEMBER_SAVE_SEGMENTED_CONFIRMATION, getLocal } from 'services/localStorage';

import { equationToSegmentGroups, extractAudiencePartner, getSegmentEquation } from '../helpers';
import { TSegmentGroup } from './types';
import { CreateOrEditForm } from './components/CreateOrEditForm';
import { ConfirmSaveReadyAudienceDialog } from './components/ConfirmSaveReadyAudienceDialog';
import { DialogLayout } from '../components/DialogLayout/DialogLayout';

interface Props {
  handleClose: () => void;
  dialog: TDialog;
  updateStoreOnSuccess: (mutationType: 'edit' | 'create') => void;
  startCreating: () => void;
  endCreating: () => void;
}

export const CreateOrEditSegmentedAudienceComponent = (props: Props) => {
  const {
    handleClose: handleCloseProp,
    dialog,
    updateStoreOnSuccess,
    startCreating,
    endCreating,
  } = props;

  const [audienceName, setAudienceName] = React.useState<string>('');
  const [segmentGroups, setSegmentGroups] = React.useState<TSegmentGroup[]>([
    { segments: {}, relation: null },
  ]);
  const [isEditReady, setIsEditReady] = React.useState(false);
  const [isSaveConfirmDialogOpen, setIsSaveConfirmDialogOpen] = React.useState(false);
  const initialData = React.useRef<{ audienceName: string; segmentGroups: TSegmentGroup[] }>();
  const segmentSelectRef = React.useRef<SegmentSelectApiRefType>();
  const history = useHistory();

  const isEdit = dialog?.type?.toLowerCase() === 'edit' && !!dialog?.audienceId;
  const isDuplicate = dialog?.type?.toLowerCase() === 'duplicate' && !!dialog?.audienceId;

  const handleClose = () => {
    if (isEdit) {
      history.goBack();
    }
    handleCloseProp();
  };

  const shouldFetchExistingAudience = (isEdit || isDuplicate) && !!dialog?.audienceId;

  const existingAudienceData = useSegmentedAudienceInfo({
    enabled: shouldFetchExistingAudience,
    audienceId: dialog?.audienceId!,
  });

  const partnerProviderList = useSegmentPartnerProviderList({
    enabled: !shouldFetchExistingAudience,
  });

  React.useEffect(() => {
    if (
      !initialData.current &&
      existingAudienceData.data &&
      (isEdit || isDuplicate) &&
      !isEditReady
    ) {
      const { data: existingAudience } = existingAudienceData;

      const existingGroups = equationToSegmentGroups(existingAudience);

      const existingAudienceName = isDuplicate
        ? `Copy of ${existingAudience.name}`
        : existingAudience.name;

      initialData.current = {
        segmentGroups: cloneDeep(existingGroups),
        audienceName: existingAudienceName,
      };
      setSegmentGroups(existingGroups);
      setAudienceName(existingAudienceName);
      setIsEditReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAudienceData.data, isEdit, isDuplicate]);

  const selectedPartner = React.useMemo(() => {
    if (existingAudienceData.data) {
      return extractAudiencePartner(existingAudienceData.data);
    }

    const index = segmentGroups.findIndex((sg) => Object.keys(sg.segments || {})?.length);
    const partnerId = Object.values(segmentGroups[index]?.segments || {})?.[0]?.partnerId;

    if (partnerId && partnerProviderList.data?.data?.partnerProviderList) {
      const partner = partnerProviderList.data.data.partnerProviderList?.find(
        (value) => value.partnerId === partnerId,
      );

      if (partner) {
        return {
          segmentPartnerId: partner.partnerId,
          segmentPartnerName: partner.partnerName,
          segmentPartnerLogo: partner.partnerLogoUrl,
        } as SegmentedAudiencePartner;
      }
    }

    return null;
  }, [segmentGroups, partnerProviderList.data, existingAudienceData.data]);

  const onMutationSuccess = (mutationType: 'edit' | 'create') => {
    if (tableComponentInstanceRef?.current) {
      tableComponentInstanceRef.current.clearSelected();
      tableComponentInstanceRef.current.getNewData();
    }

    updateStoreOnSuccess(mutationType);
    endCreating();
  };

  const createMutation = useCreateSegmentedAudience({
    onSuccess: (response: AxiosResponse<WithResponse<CreateSegmentedAudienceResponse>>) => {
      enqueueSnackbar('Audience created successfully', { variant: 'success' });

      RUMLogger.createSegmentedAudienceGroups({
        success: true,
        response,
        segmentGroups,
        selectedPartner: selectedPartner || null,
        dialogType: dialog?.type?.toLowerCase(),
      });

      onMutationSuccess('create');
    },
    onSettled: () => endCreating(),
    onMutate: () => {
      startCreating();
      handleClose();
    },
    onError: (error) => {
      RUMLogger.createSegmentedAudienceGroups({
        // @ts-ignore
        response: error,
        success: false,
        segmentGroups,
        selectedPartner: selectedPartner || null,
        dialogType: dialog?.type?.toLowerCase(),
      });
    },
  });

  const editMutation = useEditSegmentedAudience({
    onSuccess: () => {
      enqueueSnackbar('Your changes have been saved successfully', { variant: 'success' });
      onMutationSuccess('edit');
    },
    onSettled: () => endCreating(),
    onMutate: () => {
      startCreating();
      handleClose();
    },
  });

  const { isLoading: isMutationLoading } = isEdit ? editMutation : createMutation;

  const isSubmitButtonDisabled = React.useMemo(
    () =>
      !selectedPartner ||
      audienceName.length > 255 ||
      !audienceName.length ||
      !segmentGroups.every((group) => !!Object.values(group.segments || {}).length) ||
      (isEdit && isDeepEqual(initialData.current, { audienceName, segmentGroups })),
    [segmentGroups, audienceName, selectedPartner, isEdit],
  );

  const handleEditSave = () => {
    if (!selectedPartner) {
      return;
    }

    editMutation.mutate({
      equation: getSegmentEquation(segmentGroups),
      name: audienceName,
      groupId: dialog.audienceId as number,
    });
  };

  const isSegmentGroupSelectionDirty = React.useMemo(
    () => !isDeepEqual(initialData.current?.segmentGroups, segmentGroups),
    [segmentGroups],
  );

  if (isSaveConfirmDialogOpen) {
    return (
      <ConfirmSaveReadyAudienceDialog
        handleClose={() => setIsSaveConfirmDialogOpen(false)}
        handleSave={handleEditSave}
        audienceName={audienceName}
      />
    );
  }

  let dialogTitleText = 'Create';
  if (isEdit) {
    dialogTitleText = 'Edit';
  }
  if (isDuplicate) {
    dialogTitleText = 'Duplicate';
  }

  let dialogButtonText = 'Create';
  if (isEdit) {
    dialogButtonText = 'Save';
  }
  if (isDuplicate) {
    dialogButtonText = 'Duplicate';
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      transitionDuration={dialog?.otherInfo?.disableTransitionDuration ? 0 : undefined}
    >
      <DialogTitle onClose={handleClose}>{dialogTitleText} Segmented Audience</DialogTitle>
      <DialogContent
        dividers
        style={{
          minHeight: '500px',
          height: '650px',
          maxHeight: '100%',
        }}
        sx={{ py: 0, width: 100, pr: 0 }}
      >
        {(!isEdit && !isDuplicate) || (isEditReady && initialData.current) ? (
          <DialogLayout
            dialog={dialog}
            showGraphsPlaceholder={
              dialog?.status?.toLowerCase() !== 'ready' ||
              isSegmentGroupSelectionDirty ||
              isDuplicate
            }
          >
            <CreateOrEditForm
              audienceName={audienceName}
              setAudienceName={setAudienceName}
              segmentGroups={segmentGroups}
              setSegmentGroups={setSegmentGroups}
              initialData={initialData.current}
              segmentSelectRef={segmentSelectRef}
              selectedPartner={selectedPartner}
              dialog={dialog}
              isSegmentGroupSelectionDirty={isSegmentGroupSelectionDirty}
            />
          </DialogLayout>
        ) : (
          <Box
            sx={{
              mt: 32,
              display: 'flex',
              height: 100,
              width: 100,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={92} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {dialog?.status?.toLowerCase() !== 'ready' && (
          <Box
            sx={{
              mr: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400' }} />
            <Typography gutterBottom={false} sx={{ textColor: 'neutral-500' }}>
              Actual total cost and reach will be determined once the audience is ready.
            </Typography>
          </Box>
        )}

        {dialog?.status?.toLowerCase() === 'ready' && (
          <Box
            sx={{
              mr: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400' }} />
            <Typography gutterBottom={false} sx={{ textColor: 'neutral-500' }}>
              The audience will be processed again if there is any addition or removal of segments
              or segment groups.
            </Typography>
          </Box>
        )}

        <Button
          disabled={isMutationLoading}
          onClick={handleClose}
          color="secondary"
          variant="contained"
        >
          Cancel
        </Button>

        <LoadingButton
          onClick={() => {
            if (!selectedPartner) {
              return;
            }

            if (
              isEdit &&
              dialog?.status?.toLowerCase() === 'ready' &&
              !getLocal(REMEMBER_SAVE_SEGMENTED_CONFIRMATION) &&
              isSegmentGroupSelectionDirty
            ) {
              setIsSaveConfirmDialogOpen(true);
            } else if (isEdit) {
              handleEditSave();
            } else {
              createMutation.mutate({
                equation: getSegmentEquation(segmentGroups),
                name: audienceName,
              });
            }
          }}
          color="primary"
          variant="contained"
          disabled={isSubmitButtonDisabled}
          loading={isMutationLoading}
          {...{ 'data-dd-action-name': `${dialogTitleText} Segmented Audience` }}
        >
          {dialogButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const mapAction = (dispatch: Dispatch) => ({
  startCreating: () => {
    dispatch(createAction<void>(segmentedAudienceConstants.START_CREATING));
  },
  endCreating: () => {
    dispatch(createAction<void>(segmentedAudienceConstants.END_CREATING));
  },
  updateStoreOnSuccess: (mutationType: 'edit' | 'create') => {
    dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
    dispatch(
      createAction(tableConstants.SET_AUDIENCES_SELECTED_STATUSES, EMPTY_SELECTED_AUDIENCE_STATUS),
    );

    if (mutationType === 'edit') {
      (dispatch as ThunkDispatch<AppState, void, AnyAction>)(applicationActions.resetSidebar());
    }

    if (mutationType === 'create') {
      // update audiences count by type
      (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
      setRowAnimation();
    }
  },
});

export const CreateOrEditSegmentedAudience = connect(
  null,
  mapAction,
)(CreateOrEditSegmentedAudienceComponent);
