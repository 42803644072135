import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { contextualAudienceConstants } from './constants';

export interface ContextualAudienceState {
  audienceName: string;
  submitInProgress: boolean;
}

const defaultContextualAudienceState: ContextualAudienceState = {
  audienceName: '',
  submitInProgress: false,
};

const startCreating = (state: ContextualAudienceState): ContextualAudienceState => {
  return {
    ...state,
    submitInProgress: true,
  };
};

const endCreating = (state: ContextualAudienceState): ContextualAudienceState => {
  return {
    ...state,
    submitInProgress: false,
  };
};

const startUpdating = (state: ContextualAudienceState): ContextualAudienceState => {
  return {
    ...state,
    submitInProgress: true,
  };
};

const endUpdating = (state: ContextualAudienceState): ContextualAudienceState => {
  return {
    ...state,
    submitInProgress: false,
  };
};

const reset = (): ContextualAudienceState => {
  return {
    ...defaultContextualAudienceState,
  };
};

const reducer = reducerFromMap<ContextualAudienceState>(defaultContextualAudienceState, {
  [contextualAudienceConstants.START_CREATING]: startCreating,
  [contextualAudienceConstants.END_CREATING]: endCreating,
  [contextualAudienceConstants.START_UPDATING]: startUpdating,
  [contextualAudienceConstants.END_UPDATING]: endUpdating,
  [contextualAudienceConstants.RESET]: reset,
});

export const contextualAudience = (
  state: ContextualAudienceState = defaultContextualAudienceState,
  action: Action<any>,
) => reducer(state, action);
