import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import { CreateCampaignAudienceData, UpdateCampaignAudienceData } from 'models/CampaignAudience';
import { Response, WithResponse } from 'models/Response';
import { getInstance } from './Instance';

export interface ICampaign {
  clicks: number;
  conversionCount: number;
  id: number;
  impression: number;
  name: string;
  status: string;
  uniqueCount?: number;
  advertiserId?: number;
  dspId?: number;
  owId?: number;
  uowId?: number;
  creativeType?: string;
  length: void;
}

export interface ICampaignGroups {
  id: number;
  name: string;
  rootParentId: string;
  campaignCount: number;
  campaigns: ICampaign[];
}

export interface IGetCampaignsParams {
  includeChild?: number;
  status?: string;
  startDate?: number;
  endDate?: number;
}

export const getCampaignsList = async (
  params: IGetCampaignsParams,
): Promise<WithResponse<ICampaignGroups[]>> => {
  try {
    const response: AxiosResponse<WithResponse<ICampaignGroups[]>> = await getInstance().post(
      'v3/cmp/campaigngroup/campaign/list',
      {
        ...params,
        status: 'draft,pending,deleted,rejected,running,expired,paused',
        includeChild: 1,
      },
    );
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const setCampaignAudience = async (data: CreateCampaignAudienceData) => {
  try {
    const response: AxiosResponse<WithResponse<{
      message: string;
    }>> = await getInstance().post(`v3/audience/campaign/add`, data);
    return response;
  } catch (e) {
    return Promise.reject(get(e, 'response', e));
  }
};

export interface IAudienceDetails {
  id: number;
  name: string;
  status: string;
  type: number;
  includedCount: number;
  excludedCount: number;
  createdOn: number;
  organizationName: string;
  owId: number;
  createdByUowId: number;
  createdByUser: string;
  uniques: number;
  campaignDetail: CampaignDetail;
  startDate: number;
  endDate: number;
  editAccess: boolean;
}

export interface CampaignDetail {
  impression: { [key: string]: string };
  clicks: { [key: string]: string };
  conversion: { [key: string]: string };
}

export const getAudienceDetails = async (
  audienceId: string,
): Promise<WithResponse<IAudienceDetails>> => {
  try {
    const response: AxiosResponse<WithResponse<IAudienceDetails>> = await getInstance().get(
      `v3/audience/campaign/${audienceId}`,
    );
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export interface IUpdateCampaignAudienceData {
  audienceName: string;
  audienceId: string;
  audienceTypeId: number;
}

export interface IUpdateCampaignAudienceResponse {
  id: number;
  message: string;
}

export const updateAudience = async (
  updateAudienceData: UpdateCampaignAudienceData,
): Promise<IUpdateCampaignAudienceResponse> => {
  try {
    const { audienceId, audienceName, audienceTypeId } = updateAudienceData;
    const response: AxiosResponse<Response<
      IUpdateCampaignAudienceResponse
    >> = await getInstance().patch(`v2/audience/updateName/${audienceId}`, {
      audienceName,
      audienceTypeId,
    });
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};
