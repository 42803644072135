import { API } from 'api';
import { ContactList, RequestError } from 'api/Hubspot';
import { Dialog, LoadingBar, Select } from 'factor';
import { getOwId, ORIGIN_URL } from 'iqm-framework';
import { ICloudProviderProps } from 'models/Cloud';
import React, { ComponentProps, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store';
import ContactListsTable from '../ContactListsTable';
import HubspotLogo from './img/HubSpot_Logo.svg';
import styles from './styles.module.scss';

const popupCenter = (url: string, title: string, w: number, h: number) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  /* eslint-disable */
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;
  /* eslint-enable */

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `,
  );

  newWindow?.focus();

  return newWindow;
};

const authorize = async (owId: number, apiToken: string) => {
  const popup = popupCenter(
    `${process.env.REACT_APP_HUBSPOT_API_URL}?hostname=${ORIGIN_URL.substring(
      8,
    )}&owid=${owId}&token=${apiToken}`,
    'hubspot',
    926,
    532,
  );
  if (!popup) return false;

  const result = await (async () => {
    const controller = new AbortController();
    const { signal } = controller;
    try {
      return await new Promise<boolean>((resolve) => {
        window.addEventListener(
          'message',
          (e: MessageEvent<unknown>) => {
            if (
              typeof e.data === 'object' &&
              e.data &&
              'type' in e.data &&
              'authorized' in e.data &&
              e.data.type === 'hubspot' &&
              e.data.authorized === true
            ) {
              resolve(e.data.authorized);
              popup?.close();
            }
          },
          { signal },
        );

        const interval = setInterval(() => {
          if (popup.closed) {
            resolve(false);
          }
        }, 100);
        signal.addEventListener('abort', () => clearInterval(interval));
      });
    } finally {
      controller.abort();
    }
  })();

  return (
    result || (await API.Hubspot.isAuthorized(ORIGIN_URL.substring(8), owId, apiToken)).authorized
  );
};

class ProcessError extends Error {}

const HubspotBtn = ({ select, onClick }: ComponentProps<'button'> & { select?: boolean }) => (
  <button type="button" onClick={onClick} className={styles.button}>
    <img src={HubspotLogo} alt="HubSpot Logo" style={{ cursor: `pointer`, height: 20 }} />
    {select && 'Hubspot'}
  </button>
);

interface Props extends ReturnType<typeof mapState>, ICloudProviderProps {}

export const HubspotComponent = ({ select, setUnsupportedFiles, user }: Props) => {
  const owId = getOwId();
  const apiToken = user?.apiToken || '';

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const controllerRef = useRef<AbortController>();

  const [contactLists, setContactLists] = useState<
    (ContactList & { name: string; value: number; isSelected: boolean })[]
  >();
  const [selectedContactLists, setSelectedContactLists] = useState<
    NonNullable<typeof contactLists>
  >([]);

  const handleOpen = async () => {
    controllerRef.current = new AbortController();
    setOpen(true);
    setError(undefined);
    setLoading(true);
    setContactLists(undefined);
    setSelectedContactLists([]);
    try {
      const isAuthorizedRes = await API.Hubspot.isAuthorized(
        ORIGIN_URL.substring(8),
        owId,
        apiToken,
        controllerRef.current.signal,
      );

      if (!isAuthorizedRes.authorized && !(await authorize(owId, apiToken))) {
        throw new ProcessError('Not authorized');
      }

      const contactListsRes = await API.Hubspot.getContactLists(
        ORIGIN_URL.substring(8),
        owId,
        apiToken,
        controllerRef.current.signal,
      );

      if (!contactListsRes.success || !contactListsRes.data) {
        throw new ProcessError('Could not fetch contact lists');
      }

      setContactLists(
        contactListsRes.data.map((list) => ({
          label: list.name,
          value: list.id,
          isSelected: true,
          ...list,
        })),
      );
    } catch (e) {
      if (e instanceof DOMException && e.name === 'AbortError') return;
      if (e instanceof ProcessError) {
        setError(e.message);
        return;
      }
      if (e instanceof RequestError && e.response.status === 401) {
        setError('Issue authenticating with Hubspot');
        return;
      }
      setOpen(false);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const getSelectionDetails = () =>
    selectedContactLists.length && (
      <span className={styles.dropDownLabel}>
        {selectedContactLists[0].label}
        {selectedContactLists.length > 1 && (
          <>
            {selectedContactLists.length > 1 && ' & '}
            <span className={styles.additionalValueCount}>
              {`${selectedContactLists.length - 1} more`}
            </span>
          </>
        )}
      </span>
    );

  const handleCancel = () => {
    controllerRef.current?.abort();
    setOpen(false);
  };

  const handleDownlaod = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const res = await API.Hubspot.getContacts(
        ORIGIN_URL.substring(8),
        owId,
        apiToken,
        selectedContactLists.map((list) => list.id).join(','),
        controllerRef.current?.signal,
      );

      const file = new File([await res.blob()], 'Hubspot.csv', {
        type: 'text/csv',
      });

      if (!file.size) {
        setError('That list contains no contacts');
        return;
      }

      setUnsupportedFiles([file]);

      setOpen(false);
    } catch (e) {
      if (e instanceof DOMException && e.name === 'AbortError') return;
      setError('Could not fetch contacts');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HubspotBtn select={select} onClick={handleOpen} />
      <Dialog
        dialogTitle="Select a List from Hubspot"
        open={open}
        onCrossButtonClick={handleCancel}
        actionButtons={[
          {
            variant: 'secondary',
            title: 'Cancel',
            handler: handleCancel,
          },
          {
            title: 'Download',
            handler: handleDownlaod,
            disabled: selectedContactLists.length === 0,
            showLoadingIndicator: loading && contactLists,
          },
        ]}
        crossButton
        headerFooterBorders
        className={styles.dialog}
        footerLeft={<span className={styles.error}>{error}</span>}
      >
        {loading && (
          <div className={styles.loadingContainer}>
            {!contactLists && <p>Fetching Contact Lists...</p>}
            {contactLists && <p>Fetching Contacts...</p>}
            <LoadingBar />
          </div>
        )}
        {!loading && contactLists && (
          <div className={styles.container}>
            <Select
              onChange={(value: any) => setSelectedContactLists(value ? [value] : [])}
              value={selectedContactLists}
              placeholder="Select Contact Lists"
              multiPlaceholder={getSelectionDetails}
              options={contactLists}
              label="Contact Lists"
              allSelectable
              isSearchable
              isSearchClearable
              searchByValue
              isClearable
              underline
              showLabelAlways
              tumbler={false}
            />
            <ContactListsTable selectedContactLists={selectedContactLists} />
          </div>
        )}
      </Dialog>
    </>
  );
};

const mapState = (state: AppState) => ({
  user: state.auth.user,
});

export const Hubspot = connect(mapState)(HubspotComponent);
