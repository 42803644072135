import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
} from '@applift/factor';

import { InsertionOrderBasic } from 'models/InsertionOrder';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaigns';

import { SelectInsertionOrder } from './SelectInsertionOrder';
import { SelectedInsertionOrderTable } from './SelectedInsertionOrderTable';
import { CampaignTypeRadioGroup } from './CampaignTypeRadioGroup';

const DEFAULT_CAMPAIGN_TYPE = CAMPAIGN_TYPE_BY_NAME.advanced;

interface Props {
  onClose: () => void;
  onConfirm: (params: { ioId: number; campaignName: string; campaignType: number }) => void;
}

export const CreateCampaignDialog = (props: Props) => {
  const { onClose, onConfirm } = props;
  const [
    selectedInsertionOrder,
    setSelectedInsertionOrder,
  ] = React.useState<InsertionOrderBasic | null>(null);
  const [campaignName, setCampaignName] = React.useState('');
  const [campaignType, setCampaignType] = React.useState<number>(DEFAULT_CAMPAIGN_TYPE);

  const isCreateDisabled = !selectedInsertionOrder?.ioId || !campaignName?.trim() || !campaignType;

  const handleTableRemove = React.useCallback(() => {
    setSelectedInsertionOrder(null);
  }, []);

  const handleConfirm = () => {
    if (isCreateDisabled) {
      return;
    }

    onConfirm({
      ioId: selectedInsertionOrder.ioId,
      campaignName: campaignName.trim(),
      campaignType,
    });
  };

  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle onClose={onClose}>Create Campaign</DialogTitle>
      <DialogContent dividers>
        <Typography component="p" sx={{ mb: 24 }}>
          Enter the campaign details which you would like to create using the selected audience(s)
        </Typography>

        <Box sx={{ width: 100, maxWidth: 100, display: 'flex', alignItems: 'center', gap: 24 }}>
          <Box style={{ flexBasis: 280, minWidth: 0, flexGrow: 1 }}>
            <SelectInsertionOrder
              selectedInsertionOrder={selectedInsertionOrder}
              setSelectedInsertionOrder={setSelectedInsertionOrder}
            />
          </Box>
          <Box style={{ flexBasis: 280, flexGrow: 1 }}>
            <TextField
              label="Campaign Name"
              placeholder="Enter Campaign Name"
              value={campaignName}
              onChange={(e) => {
                setCampaignName(e.target.value?.trim() ? e.target.value || '' : '');
              }}
              required
              variant="outlinedDash"
              fullWidth
            />
          </Box>
          <Box style={{ flexBasis: 220, flexGrow: 0 }}>
            <CampaignTypeRadioGroup
              defaultValue={DEFAULT_CAMPAIGN_TYPE}
              selectedCampaignType={campaignType}
              onChange={setCampaignType}
              disabled
            />
          </Box>
        </Box>

        {!!selectedInsertionOrder && (
          <Box sx={{ mt: 24, width: 100, maxWidth: 100 }}>
            <SelectedInsertionOrderTable
              selectedInsertionOrder={selectedInsertionOrder}
              onRemove={handleTableRemove}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isCreateDisabled}
          onClick={!isCreateDisabled ? handleConfirm : undefined}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
