import * as React from 'react';
import { Box, Typography } from '@applift/factor';
import { ClipboardClose } from '@applift/icons';

export const ErrorComponent = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Box>
        <ClipboardClose sx={{ textColor: 'neutral-500', mb: 12 }} fontSize={40} />
      </Box>
      <Typography variant="p">
        Oops, something went wrong. Please try again after some time.
      </Typography>
    </Box>
  );
};
