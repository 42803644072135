import React from 'react';
import {
  SelectAdvanceComponents,
  TextField,
  TruncateMiddle,
  useDebounceValue,
} from '@applift/factor';
import { Row, Updater, RowSelectionState } from '@tanstack/react-table';

import { InsertionOrderBasic } from 'models/InsertionOrder';
import { useInsertionOrderList } from 'hooks/useInsertionOrder';
import { IO_STATUS_ID } from 'constants/insertionOrder';

import { Option } from './Option';

interface Props {
  setSelectedInsertionOrder: (io: InsertionOrderBasic) => void;
  selectedInsertionOrder: InsertionOrderBasic | null;
}

interface RenderOptionParam {
  row: Row<InsertionOrderBasic>;
  isSelected: boolean | 'all' | 'some';
  disableRowExpansion: boolean;
  disableCheckbox?: boolean | undefined;
  isDisabled?: boolean | undefined;
}

export const ListItemRenderer = SelectAdvanceComponents.DefaultListOptionItemWrapper<
  InsertionOrderBasic
>({
  disableCheckbox: true,
  selectionStyle: 'none',
  arrowPosition: 'right',
});

export const SelectInsertionOrder = (props: Props) => {
  const { setSelectedInsertionOrder, selectedInsertionOrder } = props;
  const [search, setSearch] = React.useState('');

  const debouncedSearch = useDebounceValue(search, 500);

  const ioListFullQuery = useInsertionOrderList({
    sortBy: '-ioId',
    ioStatusIdsList: [IO_STATUS_ID.ACTIVE, IO_STATUS_ID.EXPIRED],
  });

  const ioListSearchQuery = useInsertionOrderList(
    {
      sortBy: '-ioId',
      ioStatusIdsList: [IO_STATUS_ID.ACTIVE, IO_STATUS_ID.EXPIRED],
      searchField: debouncedSearch,
    },
    { enabled: !!debouncedSearch?.length },
  );

  const ioListQuery = debouncedSearch?.length ? ioListSearchQuery : ioListFullQuery;

  const optionsList = React.useMemo(
    () => ioListQuery.data?.pages?.map((page) => page.ioBasicDetailsList).flat() || [],
    [ioListQuery.data],
  );

  const getOverlay = (): 'error' | 'noRows' | 'noResult' | undefined => {
    if (ioListQuery.error) {
      return 'error';
    }
    if (ioListQuery.data?.pages?.length && !ioListQuery.data.pages[0]?.ioBasicDetailsList?.length) {
      if (debouncedSearch?.length) {
        return 'noResult';
      }
      return 'noRows';
    }

    return undefined;
  };

  const rowSelectionState: RowSelectionState = selectedInsertionOrder
    ? { [selectedInsertionOrder.ioId]: true }
    : {};

  const onChange = (updater: Updater<RowSelectionState>) => {
    const selectedRows = typeof updater === 'function' ? updater(rowSelectionState) : updater;

    const selectedIoId = Number.parseInt(Object.keys(selectedRows || {})[0], 10);
    const selectedItem = optionsList.find((opt) => opt.ioId === selectedIoId);

    if (selectedItem) {
      setSelectedInsertionOrder(selectedItem);
    }
  };

  return (
    <TextField
      select="advanceSelect"
      value={rowSelectionState}
      onChange={onChange}
      label="Insertion Order"
      required
      placeholder="Select Insertion Order"
      fullWidth
      variant="outlinedDash"
      SelectProps={{
        onClose: () => {
          setSearch('');
        },
        renderValue: () => (
          <TruncateMiddle title={selectedInsertionOrder?.ioName || ''} arrow>
            {selectedInsertionOrder?.ioName || ''}
          </TruncateMiddle>
        ),
        slotProps: {
          InputBaseProps: {
            value: search,
            onChange: (e) => {
              setSearch(e.target.value);
            },
            placeholder: 'Search by ID, Name',
            autoComplete: 'off',
          },
        },
        overlay: getOverlay(),
        multiple: false,
        data: optionsList,
        renderOption: (option: RenderOptionParam) => {
          const { ioId, ioName, ioBudgetTypeId, ioStatusId } = option.row.original;
          return (
            <Option id={ioId} name={ioName} budgetTypeId={ioBudgetTypeId} statusId={ioStatusId} />
          );
        },
        getOptionLabel: (option: InsertionOrderBasic) => `${option.ioName}`,
        getRowId: (row: InsertionOrderBasic) => `${row.ioId}`,
        rowCount: ioListQuery.data?.pages?.[0]?.filteredRecords || 5,
        loading: ioListQuery.isLoading,
        renderListItem: ListItemRenderer,
        onFetchRows:
          !ioListQuery.isFetchingNextPage && !ioListQuery.error
            ? () => {
                setTimeout(() => {
                  ioListQuery.fetchNextPage();
                }, 1);
              }
            : undefined,
      }}
    />
  );
};
