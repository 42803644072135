export const nFormatter = (numArg: number, digits: number) => {
  const num = numArg < 0 ? -numArg : numArg;
  const isNumArgNegative = numArg < 0;
  if (Math.abs(Number(num)) >= 1.0e6) {
    return isNumArgNegative
      ? `-$${(Math.abs(Number(num)) / 1.0e6).toFixed(digits)}M`
      : `$${(Math.abs(Number(num)) / 1.0e6).toFixed(digits)}M`;
  }
  if (Math.abs(Number(num)) >= 1.0e3) {
    return isNumArgNegative
      ? `-$${(Math.abs(Number(num)) / 1.0e3).toFixed(digits)}K`
      : `$${(Math.abs(Number(num)) / 1.0e3).toFixed(digits)}K`;
  }
  return `$${Math.abs(Number(num))}`;
};
