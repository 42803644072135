import { User } from '../../models/User';
import { createAction } from '../../utils/actions';
import { authConstants } from './constants';

export const authActions = {
  authorized(user: User) {
    return createAction<User>(authConstants.LOGIN_SUCCESS, user);
  },

  logout() {
    return createAction(authConstants.LOGOUT);
  },
};

export interface Authorized {
  authorized: (user: User) => void;
}

export interface Logout {
  logout: () => void;
}
