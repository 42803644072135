import { datadogRum } from 'iqm-framework';
import { ParseResult } from 'papaparse';

import { getFileExtension } from 'utils/file';

import { SUCCESS, FAILED, service } from '../../constants';
import { TActionParams } from '../../types';
import { actionTypes } from '../actionTypes';

const actions = {
  uploadMatchedAudienceFile: {
    id: 'uploadMatchedAudienceFile',
    text: 'Upload Matched Audience File',
  },
  viewMatchedAudience: {
    id: 'viewMatchedAudience',
    text: 'View Matched Audience',
  },
};

interface TUploadMatchedParams extends TActionParams {
  parseResult?: ParseResult<Array<string>>;
  file?: File;
}

export const uploadMatchedAudienceFile = (params: TUploadMatchedParams) => {
  datadogRum.addAction(
    `${actions.uploadMatchedAudienceFile.text} ${params.success ? SUCCESS : FAILED}`,
    {
      success: params.success,
      errorMsg: params?.errorMsg,
      action: `${service}/${actions.uploadMatchedAudienceFile.id}`,
      actionType: `${service}/${actionTypes.uploadAudienceFile.id}`,
      info: {
        numColumns: params.parseResult?.data?.[0]?.length,
        numRows: params.parseResult?.data?.length,
        fileSizeBytes: params.file?.size,
        fileMIME: params.file?.type,
        fileExtension: params.file ? getFileExtension(params.file) : null,
      },
    },
  );
};

interface TViewMatchedParams extends TActionParams {
  audienceStatus?: string;
}

export const viewMatchedAudience = (params: TViewMatchedParams) => {
  datadogRum.addAction(`${actions.viewMatchedAudience.text} ${params.success ? SUCCESS : FAILED}`, {
    success: params.success,
    errorMsg: params.errorMsg,
    statusCode: params.statusCode,
    latency: params.latency,
    action: `${service}/${actions.viewMatchedAudience.id}`,
    actionType: `${service}/${actionTypes.viewAudience.id}`,
    info: {
      audienceStatus: params.audienceStatus,
    },
  });
};
