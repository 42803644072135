import React from 'react';
import capitalize from 'lodash/capitalize';
import { Icon, WithMiddleEllipses } from 'factor';
import { Tooltip } from '@applift/factor';
import { CampaignSimple } from 'models/CampaignAudience';
import { STATUS_MAPPER } from 'components/CampaignNameWithIcons';
import { creativeIconMap } from 'components/consts';
import styles from './styles.module.scss';

interface Props {
  campaign: Partial<CampaignSimple>;
  className?: string;
}

export const Option = (props: Props) => {
  const { className, campaign } = props;
  const { creativeTypeId, id, name, status = '' } = campaign;

  // @ts-ignore
  const creativeIconName = creativeIconMap[`${creativeTypeId}`];
  const statusId = STATUS_MAPPER[(status || '').toLowerCase()];

  return (
    <div className={`${styles.wrapper} ${className || ''}`}>
      <div className={styles.creativeType}>
        {!!creativeIconName && <Icon name={creativeIconName} />}
      </div>
      <div className={styles.col}>
        <Tooltip title={name || ''} placement="top-start" arrow>
          <div className={`${styles.row}`}>
            <WithMiddleEllipses text={name || ''} className={styles.bold} />
          </div>
        </Tooltip>
        <div className={styles.row}>
          <div className={styles.infoItem}>
            <span className={`${styles.infoLabel} ${styles.labelText}`}>ID</span>
            <span className={styles.infoValue}>{id}</span>
          </div>

          <div className={styles.separator} />

          <div className={styles.infoItem}>
            {!!statusId && <Icon name={statusId} className={styles.labelIcon} />}
            <span className={styles.infoValue}>
              {(status || '').toLowerCase() === 'draft_deleted' ? 'Deleted' : capitalize(status)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
