import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Pill, Button, Tooltip, Icon } from 'factor';
import { enqueueSnackbar } from '@applift/factor';
import { StatusReady } from '@applift/icons';

import { INSIGHT_STATUSES } from 'components/consts';
import { useCreateAudienceInsight, useInsightsStatus } from 'hooks/useInsights';
import { AppState, store } from 'store';
import { TableAudienceItem, AudienceTypeId, InsightsData } from 'models/Table';
import { AudienceType } from 'api/Table';
import { routes } from 'routes';
import { applicationActions, OpenDialog, ResetSidebar, ToggleSidebarMenu } from 'store/app/actions';
import { matchedAudienceActions, GetMatchedAudience } from 'store/matchedAudience/actions';
import {
  CAMPAIGN_AUDIENCE_TYPE_ID,
  CONTEXTUAL_AUDIENCE_TYPE_ID,
  LOOKALIKE_AUDIENCE_TYPE_ID,
  GEOFARMED_AUDIENCE_TYPE_ID,
  MATCHED_AUDIENCE_TYPE_ID,
  RETARGETED_AUDIENCE_TYPE_ID,
  SEGMENTED_AUDIENCE_TYPE_ID,
  AUDIENCE_STATUSES,
  INSIGHTS_MIN_UNIQUES,
  PRE_BID_AUDIENCE_TYPE_ID,
} from 'constants/audiences';
import { lookalikeAudienceActions, GetLookalikeAudience } from 'store/lookalikeAudience/actions';
import { DeleteAudienceButton } from './DeleteAudienceButton/DeleteAudienceButton';

import styles from './index.module.scss';

interface Props
  extends ResetSidebar,
    GetMatchedAudience,
    GetLookalikeAudience,
    ToggleSidebarMenu,
    OpenDialog {
  className?: string;
  selectedAudiences: TableAudienceItem[];
  isSidebarOpened: boolean;
  tableComponentInstance: any;
  openDialog: any;
  selectedAudienceStatus: { [key in AudienceTypeId]: string };
  tableTotalItems: number;
  audiencesByType: AudienceType[];
  audienceTypeIds: string;
  insightsData: InsightsData;
}

const TableSelectedActionsComponent = memo((props: Props) => {
  const {
    className = '',
    selectedAudiences,
    resetSidebar,
    getMatchedAudience,
    getLookalikeAudience,
    isSidebarOpened,
    toggleSidebar,
    tableComponentInstance,
    openDialog,
    selectedAudienceStatus,
    tableTotalItems,
    audiencesByType,
    audienceTypeIds,
    insightsData,
  } = props;
  const [shouldShowEdit, setShouldShowEdit] = useState<boolean>(false);
  const [showDuplicateButton, setShouldShowDuplicate] = useState<boolean>(false);
  const [showIntegrateTagBtn, setShowIntegrateTagBtn] = useState<boolean>(false);
  const [hideDeleteBtn, setHideDeleteBtn] = useState<boolean>(false);
  const [disableInsightBtn, setDisableInsightBtn] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();

  const getAudienceIds = (insights: InsightsData, statusId: number) =>
    Object.keys(insights)
      .filter((key) => insights[key as any].statusId === statusId)
      .map((id) => Number(id));
  const insightStatuses = useInsightsStatus();
  const readyInsightAudienceIds = getAudienceIds(
    insightsData || {},
    insightStatuses.data?.data?.insightsStatusData?.find((status) => status.name === 'ready')?.id ||
      INSIGHT_STATUSES.READY,
  );
  const failedInsightAudienceIds = getAudienceIds(
    insightsData || {},
    insightStatuses.data?.data?.insightsStatusData?.find((status) => status.name === 'failed')
      ?.id || INSIGHT_STATUSES.FAILED,
  );

  const checkIfAudienceAttachedToCampaign = useCallback((audience: TableAudienceItem) => {
    return !(audience.included === 0 && audience.excluded === 0);
  }, []);

  const onCreateSuccess = (response: any) => {
    const insightsData = response?.data?.insightsData;
    const invalidSourceIds = response?.data?.invalidSourceIds;
    if (insightsData?.length) {
      enqueueSnackbar(
        `Selected Audience${insightsData?.length > 1 ? 's' : ''} insights generated successfully.`,
        {
          variant: 'success',
        },
      );
    }
    if (invalidSourceIds?.length) {
      enqueueSnackbar(
        `Insights for ${invalidSourceIds.length} selected audience(s) could not be generated.`,
        {
          variant: 'error',
        },
      );
    }
    tableComponentInstance?.current.clearSelected();
    tableComponentInstance?.current.getNewData();
    openDialog({});
  };

  const onCreateFailed = () => {
    tableComponentInstance?.current.clearSelected();
    tableComponentInstance?.current.getNewData();
    openDialog({});
  };

  const createAudienceInsightMutation = useCreateAudienceInsight(onCreateSuccess, onCreateFailed);

  useEffect(() => {
    const showEditRetargeted =
      selectedAudiences.length === 1 &&
      selectedAudiences[0].audienceTypeId === RETARGETED_AUDIENCE_TYPE_ID;
    const showEditMatched =
      selectedAudiences.length === 1 &&
      selectedAudiences[0].audienceTypeId === MATCHED_AUDIENCE_TYPE_ID &&
      selectedAudiences[0].isAudienceNewPlatform === 1 &&
      !['failed'].includes(selectedAudiences[0].status.toLowerCase()) &&
      !selectedAudiences[0].included &&
      !selectedAudiences[0].excluded;
    const showEditSegmented =
      selectedAudiences.length === 1 &&
      selectedAudiences[0].audienceTypeId === SEGMENTED_AUDIENCE_TYPE_ID;
    const showEditCampaign =
      selectedAudiences.length === 1 &&
      selectedAudiences[0].audienceTypeId === 7 &&
      ['processing', 'approved', 'pending', 'ready', 'pending for approval'].includes(
        selectedAudiences[0].status.toLowerCase(),
      );
    const showEditContextual =
      selectedAudiences.length === 1 &&
      selectedAudiences[0].audienceTypeId === CONTEXTUAL_AUDIENCE_TYPE_ID;
    const showEditLookalike =
      selectedAudiences.length === 1 &&
      selectedAudiences[0].audienceTypeId === LOOKALIKE_AUDIENCE_TYPE_ID &&
      !['failed'].includes(selectedAudiences[0].status.toLowerCase());
    const showEditGeofarmed =
      selectedAudiences.length === 1 &&
      selectedAudiences[0].audienceTypeId === GEOFARMED_AUDIENCE_TYPE_ID &&
      !['failed'].includes(selectedAudiences[0].status.toLowerCase());
    const showEditPreBid =
      selectedAudiences.length === 1 &&
      selectedAudiences[0].audienceTypeId === PRE_BID_AUDIENCE_TYPE_ID;
    const isAllPreBidSelected =
      selectedAudiences.length > 0 &&
      selectedAudiences.every((audience) => audience.audienceTypeId === PRE_BID_AUDIENCE_TYPE_ID);
    const hideDeleteButton = isAllPreBidSelected
      ? false
      : selectedAudiences.length > 0 &&
        selectedAudiences.filter(
          (Audience) =>
            Audience.status.toLowerCase() === 'ready' &&
            checkIfAudienceAttachedToCampaign(Audience),
        ).length > 0;
    const disableInsightBtn = !selectedAudiences.every(
      (audience) =>
        audience.status === AUDIENCE_STATUSES.READY && audience.uniques > INSIGHTS_MIN_UNIQUES,
    );

    setDisableInsightBtn(disableInsightBtn);
    setShouldShowEdit(
      showEditRetargeted ||
        showEditMatched ||
        showEditSegmented ||
        showEditContextual ||
        showEditCampaign ||
        showEditGeofarmed ||
        showEditLookalike ||
        showEditPreBid,
    );
    setShouldShowDuplicate(
      selectedAudiences.length === 1 &&
        audienceTypeIds !== '3' &&
        selectedAudiences[0].audienceTypeId !== 3,
    );
    setHideDeleteBtn(hideDeleteButton);
    setShowIntegrateTagBtn(
      selectedAudiences.length === 1 && selectedAudiences[0].audienceTypeId === 3,
    );
  }, [checkIfAudienceAttachedToCampaign, selectedAudiences, audienceTypeIds]);

  const clearSelected = () => tableComponentInstance.current.clearSelected();

  const editOrDuplicateAudience = (type: 'edit' | 'duplicate') => {
    const audience = selectedAudiences[0];
    const audienceId = selectedAudiences[0].id;
    const audienceStatus = selectedAudiences[0].status;

    if (type !== 'duplicate') {
      resetSidebar();
    }
    switch (audience.audienceTypeId) {
      case MATCHED_AUDIENCE_TYPE_ID:
        getMatchedAudience(audienceId);
        if (type !== 'duplicate') {
          history.push(`/matched/${audienceId}`);
        }
        break;
      case SEGMENTED_AUDIENCE_TYPE_ID:
        if (type !== 'duplicate') {
          history.push({
            pathname: `/segmented/${audienceId}`,
            state: {
              included: audience.included,
              excluded: audience.excluded,
              audName: audience.audienceName,
            },
          });
        }
        break;
      case LOOKALIKE_AUDIENCE_TYPE_ID:
        if (type === 'edit') {
          history.push(`/lookalike/${audienceId}`);
        }
        getLookalikeAudience(audienceId, false).catch(() => {
          openDialog({});
        });
        break;
      case GEOFARMED_AUDIENCE_TYPE_ID:
        if (type === 'edit') {
          history.push(`/geofarmed/${audienceId}`);
        }
        break;
      case CONTEXTUAL_AUDIENCE_TYPE_ID:
        if (type === 'edit') {
          history.push(`/contextual/${audienceId}`);
        }
        break;
      case RETARGETED_AUDIENCE_TYPE_ID:
        history.push({
          pathname: `/retargeted/${type !== 'duplicate' ? audienceId : ''}`,
          state: {
            audienceData: {
              time: audience.tagTime,
              audienceName: audience.audienceName,
              tagId: audience.tagId,
              tagDuration: audience.tagDuration,
              startDate: audience.startDate,
              endDate: audience.endDate,
              status: audience.status,
            },
          },
        });
        break;
      case CAMPAIGN_AUDIENCE_TYPE_ID:
        history.push({
          pathname: `/campaign/${type !== 'duplicate' ? audienceId : ''}`,
          state: {
            audienceData: {
              audienceName: audience.audienceName,
              startDate: audience.startDate,
              endDate: audience.endDate,
            },
          },
        });
        break;
      case PRE_BID_AUDIENCE_TYPE_ID:
        if (type === 'edit') {
          history.push(`/pre-bid/${audienceId}`);
        }
        break;
      default:
        break;
    }
    if (!isSidebarOpened && type !== 'duplicate') {
      toggleSidebar();
    }
    openDialog({
      audienceId,
      audienceTypeId: audience.audienceTypeId,
      type,
      status: audienceStatus,
    });
  };

  const currentRoute = useMemo(() => {
    if (location.pathname === '/') {
      return routes.find(({ path }) => path === location.pathname);
    }

    return routes.find(({ path }) => {
      const pathLength = location.pathname.length;
      const routeLength = path.length;
      if (path !== '/' && pathLength > routeLength) {
        return location.pathname.substring(0, routeLength) === path;
      }
      return path === location.pathname;
    });
  }, [location.pathname]);

  const currentAudienceCount = useMemo(() => {
    const totalCount = audiencesByType.reduce((acc: number, prev) => {
      return acc + +prev.audienceCount;
    }, 0);
    if (currentRoute) {
      let audienceCount = audiencesByType.find(
        (audience) => audience.audienceTypeId === Number(currentRoute.value),
      )?.audienceCount;

      if (currentRoute.value === '') {
        audienceCount = totalCount;
      }
      return audienceCount || 0;
    }
    return null;
  }, [currentRoute, audiencesByType]);

  const getAudienceCount = () => {
    const isAudienceFilteredByStatus = Object.values(selectedAudienceStatus).some(
      (status) => status !== '',
    );
    return isAudienceFilteredByStatus ? tableTotalItems : currentAudienceCount;
  };

  const integrateTag = () => {
    store.dispatch(applicationActions.resetSidebar());
    history.push({
      state: {
        audienceData: {
          time: selectedAudiences[0].tagTime,
          audienceName: selectedAudiences[0].audienceName,
          tagId: selectedAudiences[0].tagId,
          tagDuration: selectedAudiences[0].tagDuration,
          startDate: selectedAudiences[0].startDate,
          endDate: selectedAudiences[0].endDate,
        },
        showTags: true,
      },
    });
    openDialog({
      audienceId: selectedAudiences[0].id,
      audienceTypeId: selectedAudiences[0].audienceTypeId,
      type: 'tag',
    });
    if (!isSidebarOpened) {
      store.dispatch(applicationActions.toggleSidebar());
    }
  };

  if (!selectedAudiences.length) {
    return <></>;
  }

  const insightsBtn = (
    <Button
      className="mr-2"
      size="small"
      variant="tertiary2"
      onClick={() => {
        createAudienceInsightMutation.mutate(selectedAudiences.map((aud) => aud.id).toString());
        openDialog({
          type: 'insights',
          otherInfo: {
            action: 'generate',
            generateData: selectedAudiences.map((data) => ({
              id: data.id,
              audienceName: data.audienceName,
              matchRate: data.matchRate,
              uniques: data.uniques,
            })),
          },
        });
      }}
      disabled={disableInsightBtn}
    >
      {`Generate ${selectedAudiences.length > 1 ? selectedAudiences.length : ''} Insights`}
    </Button>
  );

  const renderInsightBtn = () => {
    const selectedAudiencesHaveReadyInsight = selectedAudiences
      .map((aud) => aud.id)
      .some((item) => readyInsightAudienceIds.includes(item));
    const selectedAudiencesHaveFailedInsight = selectedAudiences
      .map((aud) => aud.id)
      .some((item) => failedInsightAudienceIds.includes(item));
    if (
      Number(audienceTypeIds) !== MATCHED_AUDIENCE_TYPE_ID ||
      selectedAudiencesHaveReadyInsight ||
      selectedAudiencesHaveFailedInsight
    ) {
      return null;
    }
    const isInsightsAppAccessible = JSON.parse(sessionStorage.getItem('allowedApps') || '')?.find(
      (item: { appName: string }) => item.appName === 'Insights',
    );
    return disableInsightBtn ? (
      <Tooltip
        label={
          !isInsightsAppAccessible ? (
            <span className={styles.insightTooltipContent}>
              <Icon name="Insights" />
              <span>
                Insight app access is required to generate audience insights. Contact your admin for
                more information
              </span>
            </span>
          ) : (
            <span>
              Insights can only be generated for{' '}
              <StatusReady
                className={styles.tooltipIcon}
                fontSize={14}
                sx={{
                  display: 'inline-flex',
                  textColor: 'info-400',
                }}
              />{' '}
              Ready audiences with more than 10k uniques
            </span>
          )
        }
        labelMaxWidth={300}
      >
        {insightsBtn}
      </Tooltip>
    ) : (
      insightsBtn
    );
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <Pill
        removable
        className="m-0 mr-2"
        removeClicked={clearSelected}
        label={`${selectedAudiences.length} out of ${getAudienceCount()} selected `}
      />
      {shouldShowEdit && (
        <Button
          variant="tertiary2"
          size="small"
          className="mr-2"
          onClick={() => editOrDuplicateAudience('edit')}
        >
          Edit Audience
        </Button>
      )}
      {showIntegrateTagBtn && (
        <Button variant="tertiary2" size="small" className="mr-2" onClick={integrateTag}>
          Integrate Tag
        </Button>
      )}
      {showDuplicateButton && (
        <Button
          variant="tertiary2"
          size="small"
          className="mr-2"
          onClick={() => editOrDuplicateAudience('duplicate')}
        >
          Duplicate Audience
        </Button>
      )}
      {renderInsightBtn()}
      {!hideDeleteBtn && (
        <DeleteAudienceButton className="mr-2" tableComponentInstanceRef={tableComponentInstance} />
      )}
    </div>
  );
});

const mapState = (state: AppState) => ({
  selectedAudiences: state.table.selectedAudiences,
  isSidebarOpened: state.app.isSidebarOpened,
  selectedAudienceStatus: state.table.selectedAudienceStatus,
  tableTotalItems: state.table.tableTotalItems,
  audiencesByType: state.table.audiencesByType,
  audienceTypeIds: state.table.audienceTypeIds,
  insightsData: state.table.insightsData,
});

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  getMatchedAudience: matchedAudienceActions.getMatchedAudience,
  getLookalikeAudience: lookalikeAudienceActions.getLookalikeAudience,
  toggleSidebar: applicationActions.toggleSidebar,
  openDialog: applicationActions.openDialog,
};

export const TableSelectedActions = connect(mapState, mapAction)(TableSelectedActionsComponent);
