import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Button,
  Box,
  Tooltip,
  Chip,
} from '@applift/factor';
import { AlertTriangle, AudienceSegmented, CurrencyDollar, InfoCircle, Show } from '@applift/icons';
import moment from 'moment-timezone';
import { maxBy, upperFirst } from 'lodash';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from 'store';
import { TDialog } from 'store/app/reducer';
import { OpenDialog, applicationActions } from 'store/app/actions';
import { SEGMENTED_AGE_WARNING, SEGMENT_READY_24_TO_48_HOURS } from 'constants/tooltips';
import { ReachFormatter } from 'utils/format';
import { useSegmentedAudienceInfo } from 'hooks/useSegmentedAudience';
import { getAudienceStatusIcon } from 'helpers/audience';
import { User } from 'models/User';

import { CostFormatter, equationToSegmentGroups } from '../helpers';
import { SegmentGroup } from '../components/SegmentGroup';
import { SummaryInfoRow } from '../components/SummaryInfoRow';
import { DialogLayout } from '../components/DialogLayout/DialogLayout';

interface Props extends OpenDialog {
  dialog: TDialog;
  handleClose?: () => void;
  dialogVisibility?: boolean;
  user: User | null;
}

const ViewSegmentedAudienceComponent = (props: Props) => {
  const { dialog, handleClose, dialogVisibility = true, user, openDialog } = props;
  const history = useHistory();

  const audienceDetails = useSegmentedAudienceInfo({
    audienceId: dialog.audienceId!,
    enabled: !!dialog.audienceId,
  });

  const segmentGroups = React.useMemo(() => {
    if (audienceDetails.data?.segmentPartnerId) {
      return equationToSegmentGroups(audienceDetails.data).map((sg) => ({
        ...sg,
        segments: Object.entries(sg.segments).reduce((acc, curr) => {
          const withOmittedFields = { ...curr[1] };
          withOmittedFields.description = null;
          withOmittedFields.lastUpdatedDate = null;
          return { ...acc, [curr[0]]: withOmittedFields };
        }, {}),
      }));
    }

    return null;
  }, [audienceDetails.data]);

  const summaryCost = React.useMemo(() => {
    if (audienceDetails.data?.status?.toLowerCase() === 'ready') {
      if (!audienceDetails?.data?.dataCost) {
        return '-';
      }
      return CostFormatter.format(audienceDetails.data.dataCost);
    }

    const value = maxBy(audienceDetails.data?.segmentsList || [], (v) => v.cpm || 0)?.cpm;

    if (!value) {
      return '-';
    }
    return `~${CostFormatter.format(value)}`;
  }, [audienceDetails.data]);

  const summaryReach = React.useMemo(() => {
    if (audienceDetails.data?.status?.toLowerCase() === 'ready') {
      if (!audienceDetails?.data?.uniques) {
        return '-';
      }
      return ReachFormatter.format(Number.parseInt(audienceDetails.data.uniques, 10));
    }

    const value = audienceDetails.data?.segmentsList?.reduce((total, segment) => {
      return total + (segment.totalReach || 0);
    }, 0);

    if (!value && audienceDetails.data?.segmentsList?.length) {
      return '-';
    }
    if (!value) {
      return '~250M';
    }
    return `~${ReachFormatter.format(value)}`;
  }, [audienceDetails.data]);

  const statusIcon = getAudienceStatusIcon(audienceDetails.data?.status || '', { fontSize: 16 });

  const summaryInfo = [
    ...(audienceDetails.data?.createdOn
      ? [
          {
            label: 'Created On:',
            value: moment
              .unix(audienceDetails.data?.createdOn)
              .tz(user?.timezoneName || moment.tz.guess())
              .format('MM/DD/YYYY'),
          },
        ]
      : []),
    {
      label: 'Partner:',
      value: audienceDetails?.data?.segmentPartnerName || 'Not Selected',
      logoUrl: audienceDetails?.data?.segmentPartnerLogo,
    },
    {
      label: 'Reach:',
      value: summaryReach,
      icon: <Show sx={{ textColor: 'neutral-400' }} fontSize={16} />,
    },
    {
      label: 'Cost (eCPM):',
      value: summaryCost,
      icon: <CurrencyDollar sx={{ textColor: 'neutral-400' }} fontSize={16} />,
    },
    {
      value: (
        <Box
          sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap', gap: 4 }}
          style={{ minWidth: 'fit-content' }}
        >
          {statusIcon}
          <Typography>{upperFirst(audienceDetails.data?.status)}</Typography>
          {audienceDetails.data?.status?.toLowerCase() === 'processing' ? (
            <Tooltip arrow title={SEGMENT_READY_24_TO_48_HOURS}>
              <InfoCircle sx={{ textColor: 'neutral-400' }} fontSize={24} />
            </Tooltip>
          ) : null}
        </Box>
      ),
    },
  ];

  const { createdOn, name } = audienceDetails.data || {};

  const content = (
    <Box
      style={{
        minHeight: '500px',
        height: dialogVisibility ? '650px' : '560px',
        maxHeight: '100%',
      }}
      sx={{ py: 0 }}
    >
      {!segmentGroups || !audienceDetails.data ? (
        <Box
          sx={{
            height: 100,
            width: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={92} />
        </Box>
      ) : (
        <DialogLayout
          dialog={{ ...dialog, status: audienceDetails.data?.status?.toLowerCase() }}
          showGraphsPlaceholder={audienceDetails.data?.status?.toLowerCase() !== 'ready'}
        >
          <Box
            sx={{
              width: 100,
              height: 100,
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
            style={{ maxHeight: '100%' }}
          >
            <Box sx={{ position: 'sticky', width: 100 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 8, mb: 8 }}>
                <AudienceSegmented
                  sx={{ textColor: 'primary-600' }}
                  fontSize={24}
                  style={{ minWidth: 24 }}
                />
                <Typography component="h3" weight="demi" sx={{ fontSize: 16 }}>
                  {name}
                </Typography>
                {createdOn && moment.unix(createdOn).isBefore(moment().add(-1, 'year')) ? (
                  <Tooltip arrow title={SEGMENTED_AGE_WARNING}>
                    <AlertTriangle fontSize={24} sx={{ textColor: 'warning-400' }} />
                  </Tooltip>
                ) : null}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 8, mb: 16 }}>
                <SummaryInfoRow summaryItems={summaryInfo} />
              </Box>
            </Box>
            <Box
              sx={{
                width: 100,
                height: 100,
                overflowY: 'auto',
              }}
              style={{ minHeight: 0, maxHeight: '100%' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 100,
                  gap: 12,
                  height: 100,
                }}
                style={{ minHeight: 0, maxHeight: '100%' }}
              >
                {segmentGroups.map((group, index) => (
                  <>
                    {group.relation ? (
                      <Chip
                        size="small"
                        color="secondary"
                        label={group.relation}
                        style={{ width: 'fit-content' }}
                      />
                    ) : null}
                    <SegmentGroup
                      isEditable={false}
                      segments={group.segments}
                      title={`Group ${index + 1}`}
                      relation="OR"
                    />
                    {index === segmentGroups.length - 1 && <Box sx={{ pt: 16 }} />}
                  </>
                ))}
              </Box>
            </Box>
          </Box>
        </DialogLayout>
      )}
    </Box>
  );

  return dialogVisibility ? (
    <Dialog maxWidth="xl" fullWidth open={dialogVisibility}>
      <DialogTitle onClose={handleClose}>Audience Details</DialogTitle>
      <DialogContent
        style={{
          minHeight: 500,
          height: '650px',
          maxHeight: '100%',
        }}
        sx={{ py: 0, pr: 0 }}
        dividers
      >
        {content}
      </DialogContent>
      <DialogActions>
        {audienceDetails.data?.status?.toLowerCase() === 'processing' && (
          <Box
            sx={{
              mr: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <InfoCircle fontSize={24} sx={{ textColor: 'neutral-400' }} />
            <Typography gutterBottom={false} sx={{ textColor: 'neutral-500' }}>
              Actual total cost and reach will be determined once the audience is ready.
            </Typography>
          </Box>
        )}

        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            history.push(`/segmented/${dialog.audienceId}`);
            openDialog({
              ...dialog,
              status: audienceDetails.data?.status?.toLowerCase() || dialog.status,
              type: 'edit',
              otherInfo: { disableTransitionDuration: true },
            });
          }}
        >
          Edit
        </Button>
        <Button color="primary" variant="contained" onClick={handleClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    content
  );
};

const mapState = (state: AppState) => ({
  user: state.auth.user,
});

const mapActions = {
  openDialog: applicationActions.openDialog,
};

const ViewSegmentedAudience = connect(mapState, mapActions)(ViewSegmentedAudienceComponent);

export default ViewSegmentedAudience;
