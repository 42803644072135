import React from 'react';
import { Tooltip } from 'factor';

export const withTooltip = (
  content: JSX.Element | string,
  tooltip?: JSX.Element | string,
  className = '',
) => {
  return (
    <Tooltip label={tooltip || content}>
      <span className={className}>{content}</span>
    </Tooltip>
  );
};
