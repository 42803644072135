import React from 'react';
import { Icon } from 'factor';

import styles from './styles.module.scss';

export interface IProps {
  children: JSX.Element;
  creativeType: string;
  status: string;
}

export const STATUS_MAPPER: { [key: string]: string } = {
  expired: 'StatusExpiredColor',
  paused: 'StatusPausedColor',
  running: 'StatusRunningColor',
  draft: 'StatusDraftColor',
  pending: 'StatusPendingColor',
  deleted: 'StatusDeletedColor',
  rejected: 'StatusRejectedColor',
};

export const CREATIVE_MAPPER = {
  IMAGE: 'CreativeImage',
  VIDEO: 'CreativeVideo',
  HTML: 'CreativeHtml',
  AUDIO: 'CreativeAudio',
  TICKER_BANNER: 'CreativeTicker',
  GOTV: 'CreativeGotv',
  NATIVE: 'CreativeNative',
};

const CampaignNameWithIcons = (props: IProps) => {
  const { children, creativeType, status } = props;

  interface IStatusMapper {
    expired: string;
    paused: string;
    running: string;
    draft: string;
    pending: string;
  }
  interface ICreativeMapper {
    IMAGE: string;
    VIDEO: string;
    HTML: string;
    AUDIO: string;
    TICKER_BANNER: string;
    GOTV: string;
  }

  const iconMaker = () => (
    <>
      <div className={styles.campaignIconWrapper}>
        <Icon name={CREATIVE_MAPPER[creativeType as keyof ICreativeMapper] || ''} />
        <span className={styles.campaignStatus}>
          <Icon name={STATUS_MAPPER[status as keyof IStatusMapper] || ''} />
        </span>
      </div>
    </>
  );

  return (
    <div className="d-flex justify-content-between align-items-center">
      {creativeType && status && iconMaker()}
      {children}
    </div>
  );
};

export default CampaignNameWithIcons;
