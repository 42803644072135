import React from 'react';

import { TDialog } from 'store/app/reducer';
import CampaignAudienceDialog from '../components/CampaignAudienceDialog';

interface Props {
  dialog: TDialog;
  handleClose?: (() => void) | null;
  dialogVisibility?: boolean;
  isIncluded?: boolean;
}

const ViewCampaignAudience = (props: Props) => {
  const { dialog, handleClose = null, dialogVisibility = true, isIncluded } = props;

  return (
    <CampaignAudienceDialog
      isView
      dialog={dialog}
      handleClose={handleClose}
      title="Campaign Audience"
      dialogVisibility={dialogVisibility}
      isIncluded={isIncluded}
    />
  );
};

export default ViewCampaignAudience;
