import { getOwId } from 'iqm-framework';

import { User } from '../models/User';
import { localStorageService } from '../services/localStorage';

const { apiToken }: User = localStorageService.getBaseInfo() || { apiToken: '' };

export const getAuthHeaders = (): Record<string, string> => ({
  Authorization: `Bearer ${apiToken}`,
  'X-IAA-OW-ID': getOwId(),
});
