import React from 'react';
import { DatesPicker } from 'factor';
import { Moment } from 'moment';

import styles from './styles.module.scss';

export const ONE_DAY_SECONDS = 1000 * 3600 * 24;

interface Props {
  startDate: Date;
  endDate: Date | null;
  onStartDateChange: (date: Moment) => void;
  onEndDateChange: (date: Moment) => void;
  disabled?: boolean;
}

export const DatePicker = (props: Props) => {
  const { startDate, endDate, onStartDateChange, onEndDateChange, disabled } = props;

  return (
    <div className="row">
      <div className="col-6">
        <DatesPicker
          underline
          dateRangePickerClassName={styles.picker}
          updateDateRange={onStartDateChange}
          singleDateMode
          dateFormat="MM/DD/YYYY"
          singleDate={startDate}
          datePickerProps={{
            numberOfCalendars: 1,
            minimumDate: new Date(),
          }}
          label="Start Date"
          disabled={disabled}
        />
      </div>
      <div className="col-6 d-flex">
        <DatesPicker
          underline
          dateRangePickerClassName={styles.picker}
          updateDateRange={onEndDateChange}
          singleDateMode
          dateFormat="MM/DD/YYYY"
          singleDate={endDate}
          datePickerProps={{
            numberOfCalendars: 1,
            minimumDate: new Date(startDate.valueOf() + ONE_DAY_SECONDS),
          }}
          label="End Date"
          disabled={disabled}
        />
      </div>
    </div>
  );
};
