import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import get from 'lodash/get';
import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import { RUMLogger } from 'services/RUMLogger';
import { getApiErrorMsg } from 'services/RUMLogger/utils';
import { setRowAnimation } from 'styles/tableRowAnimation';
import { createAction } from '../../utils/actions';
import { API } from '../../api';
import { retargetedAudienceConstants } from './constants';
import {
  CreateRetargetedAudienceData,
  EmailInstructionRequestData,
} from '../../models/RetargetedAudience';
import { AppState } from '..';
import { tableConstants } from '../table/constants';
import { tableActions } from '../table/actions';
import { snackbarActions } from '../snackbar/actions';

export interface CreateRetargetedAudience {
  createRetargetedAudience: (data: CreateRetargetedAudienceData) => void;
}

export interface UpdateRetargetedAudience {
  updateRetargetedAudience: (data: CreateRetargetedAudienceData, audienceId: number) => void;
}

export interface SendEmailInstruction {
  sendEmailInstruction: (data: EmailInstructionRequestData) => void;
}

export interface UpdateTagScript {
  updateTagScript: (tagScript: string) => void;
}

export const retargetedAudienceActions = {
  createRetargetedAudience(data: CreateRetargetedAudienceData) {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(retargetedAudienceConstants.START_CREATING));
      try {
        const response = await API.RetargetedAudience.createRetargetedAudience(data);
        RUMLogger.createRetargetedAudience({
          createAudienceData: data,
          statusCode: get(response, 'status'),
          latency: get(response, 'data.latency'),
          success: true,
        });
        const res = response.data;
        // eslint-disable-next-line camelcase
        const responseTagScript = res.data?.tag_script;
        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));

        if (responseTagScript) {
          dispatch(
            snackbarActions.openSnackbar({
              message: res.data?.message,
              type: 'success',
            }),
          );
          dispatch(
            createAction<{ tagScript: string; audienceName: string }>(
              retargetedAudienceConstants.SAVE_AUDIENCE_DATA,
              {
                tagScript: responseTagScript,
                audienceName: data.tag_name,
              },
            ),
          );

          // update audiences count by type
          (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
          setRowAnimation();
        }
      } catch (e) {
        RUMLogger.createRetargetedAudience({
          createAudienceData: data,
          statusCode: get(e, 'status'),
          latency: get(e, 'data.latency'),
          success: false,
          // @ts-ignore
          errorMsg: getApiErrorMsg(e),
        });
        // eslint-disable-next-line no-console
        console.error(e);
        dispatch(
          snackbarActions.openSnackbar({
            message: get(e, 'data.errorObjects[0].error'),
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(retargetedAudienceConstants.END_CREATING));
      }
    };
  },
  updateRetargetedAudience(data: CreateRetargetedAudienceData, audienceId: number) {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(retargetedAudienceConstants.START_CREATING));
      try {
        const res = await API.RetargetedAudience.updateRetargetedAudience(data, audienceId);
        // eslint-disable-next-line camelcase
        const responseTagScript = res.data?.tag_script;
        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }

        if (responseTagScript) {
          dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
          dispatch(
            snackbarActions.openSnackbar({
              message: res.data?.message,
              type: 'success',
            }),
          );
          dispatch(
            createAction<{ tagScript: string; audienceName: string }>(
              retargetedAudienceConstants.SAVE_AUDIENCE_DATA,
              {
                tagScript: responseTagScript,
                audienceName: data.tag_name,
              },
            ),
          );
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        dispatch(
          snackbarActions.openSnackbar({
            message: 'Updating Retargeted Audience failed',
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(retargetedAudienceConstants.END_CREATING));
      }
    };
  },
  sendEmailInstruction(data: EmailInstructionRequestData) {
    return async (dispatch: Dispatch) => {
      try {
        await API.RetargetedAudience.sendEmail(data);
        dispatch(
          snackbarActions.openSnackbar({
            message: 'Pixel Code emailed successfully',
            type: 'success',
          }),
        );
      } catch (e) {
        dispatch(
          snackbarActions.openSnackbar({
            message: 'Sending Email instruction failed',
            type: 'error',
          }),
        );
      }
    };
  },
  reset() {
    return createAction<void>(retargetedAudienceConstants.RESET);
  },
  updateTagScript(tagScript: string) {
    return createAction<string>(retargetedAudienceConstants.UPDATE_TAG_SCRIPT, tagScript);
  },
};
