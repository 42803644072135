import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Dialog, TextField, Select, CollapsibleBlock, Icon, Button } from 'factor';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import Postmate, { ParentAPI } from 'postmate';
import { TimezoneProvider, TimezonePicker, EpochDatePicker } from 'iqm-framework';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { AppState } from 'store';
import { applicationActions, ResetSidebar } from 'store/app/actions';
import {
  CreateGeofarmedAudience as ICreateGeofarmedAudience,
  geofarmedAudienceActions,
} from 'store/geofarmedAudience/actions';
import { transformGeofarmedAudienceCreateData } from 'store/geofarmedAudience/helper';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { tableActions, UpdateSorting } from 'store/table/actions';
import { TDialog } from 'store/app/reducer';
import { OptionId, Option } from 'models/Option';
import { API } from 'api';
import { ONE_DAY_SECONDS } from 'components/DatePicker2';
import StepIndicator from 'components/StepIndicator';
import { getCampaignUrl } from 'utils/helpers';
import { audienceNameValidationRule } from 'utils/validation';
import { SORTING_DIRECTION, SORTING_VALUE } from 'constants/audiences';
import { service as RUMService } from 'services/RUMLogger/constants';

import { useLocationDetails } from '../components/useLocationDetails';
import { WeekScheduler, Scheduling } from '../../../WeekScheduler';

import styles from './styles.module.scss';

interface Props extends OpenSnackbar, ICreateGeofarmedAudience, ResetSidebar, UpdateSorting {
  handleClose: () => void;
  dialog: TDialog;
  title: string;
  submitInProgress: boolean;
  isDuplicate: boolean;
}

const GeofarmedAudienceDialogComponent = (props: Props) => {
  const {
    handleClose,
    resetSidebar,
    updateSorting,
    dialog,
    title,
    createGeofarmedAudience,
    submitInProgress,
    isDuplicate = false,
  } = props;
  const [step, setStep] = useState<1 | 2>(1);
  const [mapData, setMapData] = useState<any>(null);
  const [isAdvancedBlockOpen, setAdvanceBlockOpen] = useState<boolean>(false);
  const step1Ref = useRef(null);
  const child1Ref = useRef<ParentAPI | null>(null);
  const [frequencyTypeOptions, setFrequencyTypeOptions] = useState<Option<number>[] | undefined>(
    [],
  );
  const [audienceName, setAudienceName] = useState<string>('');
  const [frequency, setFrequency] = useState<string>('1');
  const [frequencyType, setFrequencyType] = useState<Option<number>>({
    label: 'Total',
    value: 1,
  });
  const [timezone, setTimezone] = useState<OptionId<string>>({
    id: 29,
    label: 'US/Eastern',
    value: 'US/Eastern',
  });
  const [startDate, setStartDate] = useState<number>(moment().valueOf());
  const [endDate, setEndDate] = useState<number | null>(null);
  const [timeRanges, setTimeRanges] = useState<Scheduling>({});
  const [timezoneOptions, setTimezoneOptions] = useState<OptionId[]>([]);
  const TextFieldInputRef = useRef<HTMLInputElement | null>(null);
  const [isTimeRangeVisible, setIsTimeRangeVisible] = useState<Boolean>(false);
  const numberInputRef = useRef<HTMLInputElement | null>(null);
  const [endDateError, setEndDateError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const { location, audience } = useLocationDetails(dialog.audienceId, isDuplicate);

  const getInputRef = useCallback((ref: HTMLInputElement) => {
    TextFieldInputRef.current = ref;
  }, []);

  useEffect(() => {
    if (TextFieldInputRef.current) {
      TextFieldInputRef.current.focus();
    }
  }, [TextFieldInputRef, step]);

  useEffect(() => {
    if (location && audience) {
      new Postmate({
        container: step1Ref.current,
        url: getCampaignUrl('location'),
        name: 'location-block',
        classListArray: [styles.iframeSize],
      }).then((one) => {
        child1Ref.current = one;
        child1Ref.current.call(
          'initStore',
          JSON.stringify({ ...location, parentApp: 'audiences' }),
        );
        child1Ref.current.call('setIframeParent', RUMService);
      });
      setAudienceName(`copy of ${audience.name}`);
      setFrequency(`${audience.frequency}`);
      setStartDate(audience.startDate * 1000);
      if (audience.endDate) {
        setEndDate(audience.endDate * 1000);
      }
      if (audience && audience.timeRange) {
        setIsTimeRangeVisible(true);
        setTimeRanges(audience.timeRange);
      }
    }
    if (audience && timezoneOptions) {
      setTimezone(timezoneOptions.filter((one) => one.id === audience.timezone)[0]);
    }
    if (audience && frequencyTypeOptions) {
      setFrequencyType(
        frequencyTypeOptions.filter((one) => one.value === audience.frequencyType)[0],
      );
    }
  }, [
    location,
    audience,
    timezoneOptions,
    frequencyTypeOptions,
    setAudienceName,
    setFrequency,
    setFrequencyType,
    setStartDate,
    setEndDate,
    setTimezone,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await API.GeofarmedAudience.getFrequencyOptions();
        setFrequencyTypeOptions(result);
      } catch (e) {
        setFrequencyTypeOptions([]);
      }
    };
    fetchData();
  }, []);

  const onStartDateChange = (value: number) => {
    setStartDate(value);
  };

  const onEndDateChange = (value: number | null) => {
    setEndDate(value);
  };

  useEffect(() => {
    if (!isDuplicate) {
      const initHandShakeStep1 = async () => {
        child1Ref.current = await new Postmate({
          container: step1Ref.current,
          url: getCampaignUrl('location'),
          name: 'location-block',
          classListArray: [styles.iframeSize],
        }).then((one) => {
          one.call('initStore', JSON.stringify({ parentApp: 'audiences' }));
          one.call('setIframeParent', RUMService);
          return one;
        });
      };
      initHandShakeStep1();
      return () => {
        /* eslint-disable-next-line no-unused-expressions */
        child1Ref.current?.destroy?.();
      };
    }
    return () => {};
  }, [isDuplicate]);

  const resetAndClose = useCallback(() => {
    handleClose();
    resetSidebar();
  }, [handleClose, resetSidebar]);

  const handleNext = useCallback(async () => {
    const data = await child1Ref.current?.get?.('getData');
    /* eslint-disable-next-line no-extra-boolean-cast */
    if (!!!data?.hasError) {
      setMapData(data);
      setStep(2);
    }
  }, [setStep]);

  const handleBack = useCallback(() => {
    setStep(1);
  }, [setStep]);

  const isSubmitDisabled = useMemo(
    () =>
      !(audienceName?.trim?.() ?? '') ||
      Number.isNaN(Number.parseInt(frequency, 10)) ||
      Number.parseInt(frequency, 10) < 1 ||
      !frequencyType ||
      !startDate ||
      !timezone,

    [audienceName, frequency, frequencyType, startDate, timezone],
  );

  const handleSubmit = useCallback(async () => {
    if (startDate && !endDate && moment().diff(moment(startDate), 'days') > 60) {
      setStartDateError('Start Date can not be older than 60 days');
      return;
    }
    await createGeofarmedAudience(
      transformGeofarmedAudienceCreateData({
        audienceName,
        frequency,
        frequencyType,
        startDate,
        endDate,
        timezone,
        timeRange: timeRanges,
        locations: mapData,
      }),
      resetAndClose,
      mapData.filterStore,
    );
    updateSorting({
      field: SORTING_VALUE.CREATED_ON,
      direction: SORTING_DIRECTION.DESC,
    });
  }, [
    createGeofarmedAudience,
    updateSorting,
    audienceName,
    frequency,
    frequencyType,
    startDate,
    endDate,
    timezone,
    mapData,
    timeRanges,
    resetAndClose,
  ]);

  const actionButtons = useMemo(
    () =>
      step === 1
        ? [
            {
              variant: 'secondary',
              title: 'Cancel',
              handler: resetAndClose,
            },
            {
              title: 'Next',
              handler: handleNext,
            },
          ]
        : [
            {
              title: 'Previous',
              handler: handleBack,
              disabled: submitInProgress,
            },
            {
              title: 'Create Audience',
              handler: handleSubmit,
              disabled:
                audienceName.trim().length >= 255 ||
                submitInProgress ||
                isSubmitDisabled ||
                Number(frequency) > 10,
              ...{ 'data-dd-action-name': 'Create Geofarmed Audience' },
            },
          ],
    [
      resetAndClose,
      step,
      handleNext,
      handleBack,
      isSubmitDisabled,
      handleSubmit,
      submitInProgress,
      audienceName,
      frequency,
    ],
  );

  const preventDefault = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    /* eslint-disable no-unused-expressions */
    numberInputRef.current?.addEventListener('wheel', preventDefault, { passive: false });
    /* eslint-disable no-return-assign */
    return () => numberInputRef.current?.removeEventListener('wheel', preventDefault);
  }, [preventDefault]);

  useEffect(() => {
    if (startDate && endDate) {
      if (moment(startDate).isAfter(moment(endDate))) {
        setEndDate(null);
        setEndDateError('');
        return;
      }
      setStartDateError('');
      if (moment(endDate).diff(startDate, 'days') > 60) {
        setEndDateError('Maximum timespan allowed is 60 days. Please select shorter range');
      } else {
        setEndDateError('');
      }
    } else if (!endDate) {
      setEndDateError('');
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [startDate, endDate ?? 0, setStartDateError, setEndDateError]);

  return (
    <Dialog
      dialogTitle={title}
      open={!isEmpty(dialog)}
      crossButton
      headerFooterBorders
      className={styles.container}
      onCrossButtonClick={resetAndClose}
      actionButtons={actionButtons}
      footerLeft={<StepIndicator step={step} maxStep={2} />}
    >
      <div ref={step1Ref} className={step === 1 ? styles.mapBoxContainer : styles.hideStep} />
      <div className={`${step === 2 ? styles.audienceDetailContainer : styles.hideStep}`}>
        <div className={styles.audienceDetailWrapper}>
          <div className={styles.audienceLeftChild}>
            <CollapsibleBlock
              collapsible={false}
              header={{
                title: <span>Audience Info</span>,
              }}
            >
              <div className={styles.audienceInfo}>
                <TextField
                  onChange={setAudienceName}
                  value={audienceName}
                  placeholder="Enter Audience Name"
                  label="Audience Name"
                  variant="withoutTickbox"
                  inputRef={getInputRef}
                  validationRules={audienceNameValidationRule}
                />

                <TimezoneProvider timezone={timezone}>
                  <TimezonePicker
                    onLoaded={setTimezoneOptions}
                    onTimezoneChange={setTimezone}
                    showLabelAlways
                    variant="withoutTickbox"
                  />
                </TimezoneProvider>
                <TimezoneProvider timezone={timezone}>
                  <div className={styles.datePickerWrapper}>
                    <EpochDatePicker
                      dateRangePickerClassName={styles.picker}
                      dateFormat="MM/DD/YYYY hh:mm A"
                      datePickerProps={{
                        numberOfCalendars: 1,
                        insidePreselectors: true,
                      }}
                      preselected={[
                        {
                          key: 'today',
                          title: 'Today',
                          date: (
                            tz: {},
                            convert: (date: Moment) => Moment,
                            getCurrentDay: (tz: {}) => Moment,
                          ) => convert(getCurrentDay(tz).add(15, 'minutes')),
                        },
                      ]}
                      label="Start Date"
                      withTimePicker
                      singleDateMode
                      singleDate={startDate}
                      calendarMinimumDate={moment()
                        .subtract(1, 'year')
                        .startOf('day')
                        .valueOf()}
                      onDateChanged={onStartDateChange}
                      insidePreselectors
                      showLabelAlways
                    />
                    <span className={styles.error}>{startDateError ?? ' '}</span>
                  </div>
                  <div className={styles.datePickerWrapper}>
                    <EpochDatePicker
                      dateRangePickerClassName={styles.picker}
                      dateFormat="MM/DD/YYYY hh:mm A"
                      datePickerProps={{
                        numberOfCalendars: 1,
                      }}
                      singleDatePlaceholder="(Optional)"
                      label="End Date"
                      withTimePicker
                      singleDateMode
                      singleDate={endDate}
                      calendarMinimumDate={moment(startDate + ONE_DAY_SECONDS)}
                      onDateChanged={onEndDateChange}
                      isClearable
                      showLabelAlways
                    />
                    <span className={styles.error}>{endDateError ?? ' '}</span>
                  </div>
                </TimezoneProvider>
              </div>
            </CollapsibleBlock>
            <CollapsibleBlock
              collapsible
              header={{
                title: <span>Advanced</span>,
              }}
              isCollapseOpen={isAdvancedBlockOpen}
              id="createadvanced"
              onToggle={(collapseStatus: boolean) => setAdvanceBlockOpen(collapseStatus)}
            >
              <div className={styles.advance}>
                <TextField
                  type="number"
                  onChange={setFrequency}
                  value={frequency}
                  placeholder="Enter Frequency"
                  label="Frequency"
                  variant="withoutTickbox"
                  inputAttributes={{
                    min: 1,
                    max: 10,
                  }}
                  className={styles.frequency}
                  validationRules={[
                    {
                      func: (value: string) => {
                        return Number(value) <= 10;
                      },
                      error: () => '* maximum allowed frequency is 10',
                    },
                  ]}
                  inputRef={(iRef: HTMLInputElement) => (numberInputRef.current = iRef)}
                />
                <Select
                  onChange={setFrequencyType}
                  value={frequencyType}
                  placeholder="Frequency Type"
                  options={frequencyTypeOptions}
                  label="Frequency Type"
                  underline
                  showLabelAlways
                  fitContainer
                  portal
                />
              </div>
            </CollapsibleBlock>
          </div>
          <div className={styles.audienceRightChild}>
            <CollapsibleBlock
              collapsible={false}
              header={{
                title: <span>Customize Time Range</span>,
              }}
            >
              {!isTimeRangeVisible ? (
                <>
                  <div className="d-flex flex-column justify-content-center align-items-center emptyContainer">
                    <Icon name="AudienceEmptyTimeline" className={styles.emptyTimelineIcon} />
                    <div className={styles.emptyText}>
                      Customize to capture audiences who visit the selected places daily for a
                      specific time range for your selected dates
                    </div>
                    <Button variant="bold-link" onClick={() => setIsTimeRangeVisible(true)}>
                      Customize
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Button
                    variant="bold-link"
                    onClick={() => {
                      setIsTimeRangeVisible(false);
                      setTimeRanges({});
                    }}
                    className={styles.removeButton}
                  >
                    Remove
                  </Button>
                  <div className={styles.instructionText}>
                    Select the time slots in which you want the audience to be captured who visits
                    the selected places
                  </div>
                  <WeekScheduler
                    setScheduling={setTimeRanges}
                    value={timeRanges}
                    isReadOnly={false}
                  />
                </>
              )}
            </CollapsibleBlock>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  submitInProgress: state.contextualAudience.submitInProgress,
});

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  createGeofarmedAudience: geofarmedAudienceActions.createGeofarmedAudience,
  openSnackbar: snackbarActions.openSnackbar,
  updateSorting: tableActions.updateSorting,
};

const GeofarmedAudienceDialog = connect(mapState, mapAction)(GeofarmedAudienceDialogComponent);

export default GeofarmedAudienceDialog;
