import { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { getInstance } from './Instance';
import { Response, WithResponse } from '../models/Response';

export type AudienceResponse = {
  id: number;
  audienceName: string;
  audienceType: string;
  audienceTypeId: number;
  matchRate: number;
  included: number;
  excluded: number;
  status: string;
  createdOn: number;
  tagTime: number;
  tagUniqueCount: number;
  isAudienceNewPlatform: number;
  dataCost: number;
  startDate: number;
  endDate: number;
  organizationName: string;
  uniques: number;
  runningCampaignCount: number;
  pendingCampaignCount: number;
  pausedCampaignCount: number;
  expiredCampaignCount: number;
  isEditAccess: boolean;
  isApprovalAccess: boolean;
};

export const getAudienceList = async (
  audienceTypeIds: string = '1,2,3,4,5,6,7,8',
  audienceIds: string,
  pageNo: number = -1,
  shouldPassOwIdsAll: boolean,
): Promise<WithResponse<AudienceResponse[]>> => {
  try {
    const response: AxiosResponse<Response<AudienceResponse[]>> = await getInstance().get(
      `v2/audience/detailed/list?searchField=&sortBy=createdOn&sortType=desc&audienceTypeIds=${audienceTypeIds}&pageNo=${pageNo}&noOfEntries=&audienceIds=${audienceIds}${
        shouldPassOwIdsAll ? '&owIds=all' : ''
      }`,
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};
