import React from 'react';
import { NumberFormatter } from 'factor';
import moment from 'moment';

import { TableAudienceItem } from 'models/Table';

import styles from './styles.module.scss';

const getGeofarmedBodyMapper = () => ({
  period: {
    key: (data: TableAudienceItem) => {
      const hasEndDate = Boolean(data.endDate);
      const dateData = `${!hasEndDate ? 'From ' : ''}
          ${moment(new Date(data.startDate * 1000)).format('MM/DD/yyyy')}
          ${hasEndDate ? ' - ' : ''}
          ${hasEndDate ? moment(new Date(data.endDate * 1000)).format('MM/DD/yyyy') : ''}`;
      return (
        <div className="d-flex align-items-center">
          <div>{dateData}</div>
        </div>
      );
    },
  },
  uniques: {
    key: (data: TableAudienceItem) => {
      return <NumberFormatter>{data.uniques}</NumberFormatter>;
    },
    className: `_right ${styles.boldtext}`,
  },
});

export default getGeofarmedBodyMapper;
