import React from 'react';
import { Action } from '../../models/Action';
import { reducerFromMap } from '../../utils/actions';
import { snackbarConstants } from './constants';

export type ToastType = 'warning' | 'info' | 'success' | 'error';

export interface ToastMessage {
  id: string;
  message: React.ReactNode;
  type: ToastType;
  action?: React.ReactNode;
  iconName?: string;
}

export interface ToastMessageActionParam extends Omit<ToastMessage, 'message'> {
  message: React.ReactNode | Error;
}

export interface SnackbarState {
  messages: ToastMessage[];
  authorizedState?: boolean;
}

export const defaultSnackbarState: SnackbarState = {
  messages: [],
};

const openSnackbar = (state: SnackbarState, action: Action<ToastMessage>): SnackbarState => {
  return {
    ...state,
    messages: [...state.messages, action.payload],
  };
};

const closeSnackbar = (state: SnackbarState): SnackbarState => {
  return {
    ...state,
    messages: [],
  };
};

const updateSnackbars = (state: SnackbarState, action: Action<ToastMessage[]>) => {
  return {
    ...state,
    messages: action.payload,
  };
};

const reducer = reducerFromMap<SnackbarState>(defaultSnackbarState, {
  [snackbarConstants.OPEN_SNACKBAR]: openSnackbar,
  [snackbarConstants.CLOSE_SNACKBAR]: closeSnackbar,
  [snackbarConstants.UPDATE_SNACKBARS]: updateSnackbars,
});

export const snackbar = (state: SnackbarState = defaultSnackbarState, action: Action<any>) =>
  reducer(state, action);
