import React, { memo } from 'react';
import { Icon } from 'factor';

export const GenerateProgress = memo(() => {
  return (
    <div className="generate-progress">
      <div className="generate-progress__image">
        <Icon name="GenerateProgress" />
      </div>
      <div className="generate-progress__bar _animated">
        <div className="generate-progress__bar-stripped" />
      </div>
      <div className="generate-progress__value">
        Saving audience. Please wait
        <span className="generate-progress__value-dotted" />
      </div>
    </div>
  );
});
