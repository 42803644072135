import axios, { AxiosResponse } from 'axios';
import get from 'lodash/get';

export interface ICloudFile {
  name: string;
  url: string;
  type: string;
  token?: string;
}

export const downloadFileAsync = async (file: ICloudFile): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axios.get(
      file.url,
      file.token
        ? {
            headers: {
              Authorization: `Bearer ${file.token}`,
            },
          }
        : undefined,
    );
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};
