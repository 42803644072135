import { InitData } from 'models/User';
import { localStorageService } from 'services/localStorage';

export const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';

export const isPreprod = process.env.REACT_APP_ENVIRONMENT === 'preprod';

export const HEADER_TOKEN_NAME = 'x-iaa-api-token';
export const BASE_URL = '/';

export const WORKSPACE_SUFFIX = localStorageService.getLocal<InitData>('initData')
  ?.workspaceURLSuffix;
