export const actionTypes = {
  searchAudience: {
    id: 'searchAudience',
    text: 'Search Audiences',
  },
  uploadAudienceFile: {
    id: 'uploadAudienceFile',
    text: 'Upload Audience File',
  },
  createAudience: {
    id: 'createAudience',
    text: 'Create Audience',
  },
  viewAudience: {
    id: 'viewAudience',
    text: 'View Audience',
  },
};
