import React from 'react';
import { NumberFormatter } from 'factor';
import get from 'lodash/get';

import { TableAudienceItem } from 'models/Table';
import { withTooltip } from 'utils/withTooltip';
import { API } from 'api';
import { OpenSnackbar } from 'store/snackbar/actions';
import { OpenDialog } from 'store/app/actions';
import { InsightsCell } from './InsightsCell';
import { User } from '../../../../models/User';

import styles from './styles.module.scss';

interface Props extends OpenSnackbar, OpenDialog {
  user: User | null;
}

const getMatchedBodyMapper = ({ user, openSnackbar, openDialog }: Props) => ({
  records: {
    key: (data: TableAudienceItem) => {
      return data.fileTotalCount;
    },
    className: `_right`,
  },
  matchRate: {
    key: (data: TableAudienceItem) => {
      const downloadFile = (item: TableAudienceItem) => async () => {
        if (user) {
          try {
            const responseURL = await API.MatchedAudience.getDownloadFileURL(
              item.id,
              user.userId,
              user.dspId,
            );
            window.location.href = responseURL;
          } catch (e) {
            openSnackbar({
              message: get(e, 'responseObject.errorMsg', ''),
              type: 'error',
            });
          }
        }
      };

      return (
        <>
          <span className={styles.matchRate}>
            {data.status === 'Processing' ? '0 %' : `${data.matchRate} %`}
          </span>
          <button
            type="button"
            className={`${styles.link} p1 color-p2`}
            onClick={downloadFile(data)}
          >
            {withTooltip('CSV', 'Download Audience file')}
          </button>
        </>
      );
    },
    className: `_right ${styles.tdMatchRate}`,
  },
  uniques: {
    key: (data: TableAudienceItem) => {
      return <NumberFormatter>{data.uniques}</NumberFormatter>;
    },
    className: `_right ${styles.boldtext}`,
  },
  insights: {
    key: (data: TableAudienceItem) => {
      return <InsightsCell openDialog={openDialog} data={data} />;
    },
    className: `left ${styles}`,
  },
});

export default getMatchedBodyMapper;
