import { AxiosResponse, AxiosError } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';

import { PreBidSegmentAudienceInfo } from 'models/PreBidAudience';
import { getInstance } from './Instance';
import { getPreBidAudienceInfoQueryKey } from './queryKey';
import { WithResponse } from '../models/Response';

export const fetchPreBidAudienceInfo = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getPreBidAudienceInfoQueryKey['keys']>>) => {
  const { audienceId } = queryKey[0]!;
  try {
    const response: AxiosResponse<WithResponse<
      PreBidSegmentAudienceInfo
    >> = await getInstance().get(`/v3/audience/prebid/${audienceId}`);
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data);
  }
};

interface CreatePreBidAudience {
  prebidAudienceName: string;
  prebidAudienceSegmentIdList: number[];
}

export const createPreBidAudience = async (payload: CreatePreBidAudience): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await getInstance().post(
      `/v3/audience/prebid/add`,
      payload,
    );
    return response?.data?.data;
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data);
  }
};

export const editPreBidAudience = async (
  payload: CreatePreBidAudience & { audienceId: number },
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await getInstance().put(
      `/v3/audience/prebid/${payload.audienceId}`,
      {
        prebidAudienceName: payload.prebidAudienceName,
        prebidAudienceSegmentIdList: payload.prebidAudienceSegmentIdList,
      },
    );
    return response?.data?.data;
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data);
  }
};
