import { datadogRum } from 'iqm-framework';

import { CreateRetargetedAudienceData } from 'models/RetargetedAudience';

import { SUCCESS, FAILED, service } from '../../constants';
import { TActionParams } from '../../types';
import { actionTypes } from '../actionTypes';

const actions = {
  createRetargetedAudience: {
    id: 'createRetargetedAudience',
    text: 'Create Retargeted Audience',
  },
};

interface TCreateRetargetedAudienceParams extends TActionParams {
  createAudienceData: CreateRetargetedAudienceData;
}

export const createRetargetedAudience = (params: TCreateRetargetedAudienceParams) => {
  const {
    start_date: startDate,
    end_date: endDate,
    tag_duration: tagDuration,
    tag_time: tagTime,
  } = params.createAudienceData;

  let daysCount;
  if (tagDuration === 'month' && tagTime) {
    daysCount = tagTime * 30;
  } else if (startDate && endDate) {
    daysCount = Math.ceil((endDate - startDate) / 1000 / 24 / 60 / 60);
  }

  datadogRum.addAction(
    `${actions.createRetargetedAudience.text} ${params.success ? SUCCESS : FAILED}`,
    {
      action: `${service}/${actions.createRetargetedAudience.id}`,
      actionType: `${service}/${actionTypes.createAudience.id}`,
      success: params.success,
      errorMsg: params.errorMsg,
      latency: params.latency,
      statusCode: params.statusCode,
      info: {
        ...params.createAudienceData,
        daysCount,
      },
    },
  );
};
