import { AxiosError, AxiosResponse } from 'axios';
import { enqueueSnackbar } from '@applift/factor';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createAudience,
  getSegmentAudienceBreakdown,
  getSegmentPartnerProviderList,
  getSegmentedAudienceInfo,
  updateSegmentedAudience,
} from 'api/SegmentedAudience';
import { WithResponse } from 'models/Response';
import {
  CreateAudienceData,
  CreateSegmentedAudienceResponse,
  EditAudienceData,
} from 'models/SegmentedAudience';
import {
  getSegmentedAudienceBreakdownQueryKey,
  getSegmentedAudienceInfoQueryKey,
  getSegmentsByAudienceIdKey,
} from 'api/queryKey';
import { API } from 'api';

const onError = (e: AxiosError<WithResponse<any>>) => {
  enqueueSnackbar(
    (e?.response?.data?.errorObjects?.[0]?.error as string) || 'Something went wrong!',
    {
      variant: 'error',
    },
  );
};

interface UseCreateSegmentedAudienceParams {
  onSuccess?: (
    data: AxiosResponse<WithResponse<CreateSegmentedAudienceResponse>>,
    variables: CreateAudienceData,
  ) => void;
  onSettled?: () => void;
  onError?: (error: any) => void;
  onMutate?: () => void;
}

export const useCreateSegmentedAudience = (options?: UseCreateSegmentedAudienceParams) => {
  const { onSuccess, onSettled, onMutate } = options || {};

  return useMutation({
    mutationKey: ['createSegmentedAudience'],
    mutationFn: createAudience,
    onError: (error: unknown) => {
      onError(error as any);

      if (options?.onError) {
        options.onError(error);
      }
    },
    onSuccess,
    onSettled,
    onMutate,
  });
};

interface UseEditSegmentedAudienceParams {
  onSuccess?: (data: AxiosResponse<WithResponse<any>>, variables: EditAudienceData) => void;
  onSettled?: () => void;
  onError?: () => void;
  onMutate?: () => void;
}

export const useEditSegmentedAudience = (options: UseEditSegmentedAudienceParams) => {
  const { onSuccess, onSettled, onMutate } = options;
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateSegmentedAudience'],
    mutationFn: updateSegmentedAudience,
    onError: (error) => {
      onError(error as any);

      if (options.onError) {
        options.onError();
      }
    },
    onSuccess: (data: AxiosResponse<WithResponse<any>>, variables: EditAudienceData) => {
      queryClient.removeQueries({
        predicate: (query: any) =>
          query?.queryKey?.[0]?.scope === 'getSegmentedAudienceInfo' &&
          query?.queryKey?.[0]?.audienceId === variables.groupId,
      });
      if (onSuccess) {
        onSuccess(data, variables);
      }
    },
    onSettled,
    onMutate,
  });
};

interface UseSegmentedAudienceInfoParams {
  audienceId: number;
  enabled?: boolean;
}

export const useSegmentedAudienceInfo = ({
  audienceId,
  enabled,
}: UseSegmentedAudienceInfoParams) => {
  return useQuery({
    queryKey: getSegmentedAudienceInfoQueryKey.keys({
      scope: 'getSegmentedAudienceInfo',
      audienceId,
    }),
    queryFn: getSegmentedAudienceInfo,
    onError,
    enabled,
  });
};

interface UseSegmentPartnerProviderListOptions {
  enabled?: boolean;
}

export const useSegmentPartnerProviderList = (options?: UseSegmentPartnerProviderListOptions) => {
  const { enabled } = options || {};

  return useQuery({
    queryKey: ['getSegmentPartnerProviderList'],
    queryFn: getSegmentPartnerProviderList,
    enabled,
    onError,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

export const useSegmentAudienceBreakdown = (
  audienceId: any,
  options?: UseSegmentPartnerProviderListOptions,
) => {
  const { enabled } = options || {};

  return useQuery({
    queryKey: getSegmentedAudienceBreakdownQueryKey.keys({
      scope: 'getSegmentedAudienceBreakdownQueryKey',
      audienceId,
    }),
    queryFn: getSegmentAudienceBreakdown,
    enabled,
    onError,
  });
};

interface UseSegmentsByAudienceIdParams {
  audienceIds: number[];
  segmentListSize?: number;
}

interface UseSegmentsByAudienceIdOptions {
  enabled: boolean;
}

export const useSegmentsByAudienceId = (
  params: UseSegmentsByAudienceIdParams,
  options?: UseSegmentsByAudienceIdOptions,
) => {
  const { audienceIds, segmentListSize } = params;
  const { enabled } = options || {};

  return useQuery({
    queryKey: getSegmentsByAudienceIdKey.keys({
      scope: 'getSegmentsByAudienceId',
      audienceIds,
      segmentListSize,
    }),
    queryFn: API.SegmentedAudience.getSegmentsByAudienceId,
    enabled,
  });
};
