import React, { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import { TextField, Checkbox, Icon, ButtonCircle } from 'factor';
import { Box, Tooltip } from '@applift/factor';
import { useHistory } from 'react-router-dom';
import Papa, { ParseResult } from 'papaparse';
import uniqueId from 'lodash/uniqueId';
import startCase from 'lodash/startCase';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { History } from 'history';

import { Option, OptionData } from 'models/Option';
import { AppState } from 'store';
import { formatBytes } from 'utils/file';
import { MATCHED_AUD_SYSTEM_FIELD_KEYS as FIELD_KEYS } from 'components/consts';
import {
  Column,
  ColumnMapping,
  EXCEL_FORMATS,
  CSV_FORMAT,
  ColumnsToSend,
  MatchedAudienceMetadata,
  EditableMatchedAudience,
  UpdateMatchedAudienceData,
  CreateOrDuplicateMatchedAudience,
  DataPartners,
  DataFormats,
  MappedColumns,
} from 'models/MatchedAudience';
import { tableActions, UpdateSorting } from 'store/table/actions';
import {
  matchedAudienceActions,
  SetDataFormats,
  SetDataPartners,
} from 'store/matchedAudience/actions';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { detectHashType } from 'utils/helpers';
import { EMAIL_REGEX } from 'constants/RegExp';
import { RUMLogger } from 'services/RUMLogger';
import { removeNullColumns } from 'utils/csv';
import { MatchedAudienceDialogContext } from '../../context';
import { SelectedAudiencesTable } from './SelectedAudienceTable';

import styles from './styles.module.scss';

interface Props extends OpenSnackbar, UpdateSorting, SetDataPartners, SetDataFormats {
  uploadedFile: File | null;
  audienceName: string;
  columnsMasterlist: Column[];
  columnsMapping: ColumnMapping[];
  submitInProgress: boolean;
  isEdit: boolean;
  editableAudience: EditableMatchedAudience | null;
  isDuplicateAudience: boolean;
  removeFile: () => void;
  saveColumnsMapping: (mapping: ColumnMapping[]) => void;
  setAudienceName: (name: string) => void;
  createOrDuplicateMatchedAudience: (audience: CreateOrDuplicateMatchedAudience) => void;
  updateMatchedAudience: (details: UpdateMatchedAudienceData, history: History) => void;
  updateAudienceName: (name: string, AudienceTypeId: number, AudienceId: number) => void;
  handleChange: (name: string) => void;
  dataPartners: DataPartners[];
  dataFormats: DataFormats[];
}

const MatchedAudienceSetupComponent = (props: Props) => {
  const {
    removeFile,
    columnsMapping,
    uploadedFile,
    audienceName,
    submitInProgress,
    saveColumnsMapping,
    setAudienceName,
    columnsMasterlist,
    createOrDuplicateMatchedAudience,
    editableAudience,
    isEdit,
    updateMatchedAudience,
    openSnackbar,
    updateAudienceName,
    isDuplicateAudience,
    handleChange,
    updateSorting,
    setDataFormats,
    setDataPartners,
    dataFormats,
    dataPartners,
  } = props;

  const { setCreate, handleClose } = useContext(MatchedAudienceDialogContext);

  const history = useHistory();
  const [isCheckboxSelected, modifyCheckboxSelection] = useState(false);
  const [fileColumns, setFileColumns] = useState<OptionData<string[]>[]>([]);
  const [fileColumnsWithData, setFileColumnsWithData] = useState<{ [key: string]: string[] }>({});
  const [columns, setColumns] = useState<MappedColumns[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [fileData, setFileData] = useState<ParseResult<Array<string>> | null>(null);
  const [disableAddColumn, setDisableAddColumn] = useState<boolean>(false);
  const audienceNameUpdated = useRef<boolean>(false);
  const [isAudienceEdited, setIsAudienceEdited] = useState<boolean>(false);
  const TextFieldInputRef = useRef<HTMLInputElement | null>(null);
  const prevUploadedFile = useRef<File | null>(null);

  const getInputRef = useCallback((ref: HTMLInputElement) => {
    TextFieldInputRef.current = ref;
  }, []);

  const combinations = isCheckboxSelected
    ? ['Street, City, Zip, State']
    : [
        'First Name, Last Name, Zip, State',
        'Last Name, Street Address, Zip, State',
        'Street Address, Zip, State',
      ];

  const combinationOrders = useMemo(
    () =>
      isCheckboxSelected
        ? // array of combination arrays that are required to create audience
          [
            [FIELD_KEYS.VOTER_ID],
            [FIELD_KEYS.PHONE],
            [FIELD_KEYS.EMAIL],
            [FIELD_KEYS.FULL_ADDRESS],
            [FIELD_KEYS.NPI_ID],
          ]
        : [
            [FIELD_KEYS.FIRST_NAME, FIELD_KEYS.LAST_NAME, FIELD_KEYS.ZIP, FIELD_KEYS.STATE],
            [FIELD_KEYS.LAST_NAME, FIELD_KEYS.ZIP, FIELD_KEYS.STATE, FIELD_KEYS.STREET_ADDRESS],
            [FIELD_KEYS.ZIP, FIELD_KEYS.STATE, FIELD_KEYS.STREET_ADDRESS],
          ],
    [isCheckboxSelected],
  );

  const isCombinationAchieved = useCallback(() => {
    let isCombinationMatched = false;
    const selectedItemsOrder = columnsMapping.map((item) => item.columnKey);
    combinationOrders.forEach((combinationArray) => {
      if (combinationArray.every((elem) => selectedItemsOrder.indexOf(elem) > -1)) {
        isCombinationMatched = true;
      }
    });
    return isCombinationMatched;
  }, [columnsMapping, combinationOrders]);

  useEffect(() => {
    if (isCheckboxSelected && !dataFormats.length && !dataPartners.length) {
      setDataPartners();
      setDataFormats();
    }
  }, [isCheckboxSelected, dataPartners, dataFormats, setDataFormats, setDataPartners]);

  useEffect(() => {
    if (TextFieldInputRef.current && !isEdit) {
      TextFieldInputRef.current.focus();
    }
  }, [TextFieldInputRef, isEdit]);

  const getFilesize = useCallback(() => {
    if (uploadedFile) {
      return formatBytes(uploadedFile.size);
    }

    if (editableAudience?.metadata) {
      return formatBytes(editableAudience.metadata.fileSize);
    }

    return '';
  }, [uploadedFile, editableAudience]);

  const parseCSVAndSave = useCallback((csv: File | string, original?: File) => {
    Papa.parse(csv, {
      preview: 5000002,
      skipEmptyLines: true,
      complete: (results: ParseResult<Array<string>>) => {
        RUMLogger.uploadMatchedAudienceFile({
          file: original || (csv as File),
          success: true,
          parseResult: results,
        });
        removeNullColumns(results);
        setFileData(results);
      },
    });
  }, []);

  const getDisabledTooltip = (disabledItem: string, showTooltip: boolean) => {
    if (!disabledItem || !showTooltip) {
      return '';
    }
    return `When performing a matching process
    using ${
      disabledItem === FIELD_KEYS.VOTER_ID ? 'NPI ID, Voter ID' : 'Voter ID, NPI ID'
    } is not an acceptable form of identification.`;
  };

  useEffect(() => {
    if (columnsMasterlist.length) {
      const selectedRowOrders = columnsMapping.map((item) => item.columnKey);
      const mandatoryFieldsOrder = combinationOrders.flat(1);
      let optionToDisable = '';

      if (
        selectedRowOrders.some((item) => item === FIELD_KEYS.NPI_ID || item === FIELD_KEYS.VOTER_ID)
      ) {
        optionToDisable = selectedRowOrders.includes(FIELD_KEYS.NPI_ID)
          ? FIELD_KEYS.VOTER_ID
          : FIELD_KEYS.NPI_ID;
      }

      const mappedColumns = columnsMasterlist
        .filter(
          (col: Column) =>
            !selectedRowOrders.includes(col.key) &&
            (isCombinationAchieved() || mandatoryFieldsOrder.includes(col.key)),
        )
        .map((col: Column) => ({
          ...col,
          reactLabel: (
            <Tooltip
              title={getDisabledTooltip(optionToDisable, optionToDisable === col.key)}
              arrow
              placement="top"
              sx={{ width: 100 }}
            >
              <Box>{col.label}</Box>
            </Tooltip>
          ),
          value: col.key,
          isDisable: col.key === optionToDisable,
        }));
      setColumns(mappedColumns);
    }
  }, [
    columnsMasterlist,
    columnsMapping,
    isCheckboxSelected,
    combinationOrders,
    isCombinationAchieved,
  ]);

  useEffect(() => {
    if ((fileData || editableAudience) && columnsMapping.length) {
      const fileColumnsToModify = fileData
        ? fileData.data[0]?.map((one, index) => ({
            column: one,
            data: [
              fileData?.data?.[1]?.[index] ?? '',
              fileData?.data?.[2]?.[index] ?? '',
              fileData?.data?.[3]?.[index] ?? '',
            ].filter((record) => !!record),
          }))
        : Object.entries(editableAudience?.metadata?.columnValues ?? {}).map((one) => ({
            column: one[0],
            data: one[1],
          }));

      setFileColumnsWithData(
        fileColumnsToModify.reduce<{ [key: string]: string[] }>((prev, current) => {
          return { ...prev, [current.column]: current.data };
        }, {}),
      );

      const newFileColumns = (fileColumnsToModify || ([] as any))
        .filter((one: any) => {
          const found = columnsMapping.find(
            (colMap: ColumnMapping) => colMap.selectedOption?.value === one.column,
          );
          return !found;
        })
        .map((one: any) => ({ label: one.column, value: one.column, data: one.data }));
      setFileColumns(newFileColumns);
    }
  }, [columnsMapping, fileData, editableAudience]);

  useEffect(() => {
    if (editableAudience && columnsMasterlist.length) {
      if (!audienceNameUpdated.current) {
        setAudienceName(editableAudience.audienceName);
      }
      setFileColumns(
        Object.entries(editableAudience.metadata?.columnValues ?? {}).map((one) => ({
          label: one[0],
          value: one[0],
          data: one[1],
        })),
      );

      if (editableAudience.isSingleColumnEnabled) {
        modifyCheckboxSelection(true);
      }

      const getAdditionalColumnSettingValue = (systemColumnKey: string) =>
        editableAudience.columnSettings
          ? Object.entries(editableAudience.columnSettings).find(
              ([colKey]: [string, string]) => colKey === systemColumnKey,
            )?.[1]
          : null;
      const colMapping = Object.entries(editableAudience.existingColumnMatching).map(
        ([fileCol, sysCol]: [string, string]) => ({
          columnLabel: columnsMasterlist.find((col) => col.key === sysCol)?.label,
          columnKey: sysCol,
          uniqueId: uniqueId(),
          selectedOption: {
            label: fileCol,
            value: fileCol,
            data: editableAudience?.metadata?.columnValues?.[fileCol] ?? '',
          },
          ...(editableAudience.columnSettings &&
          dataFormats &&
          dataPartners &&
          Object.keys(editableAudience.columnSettings).includes(sysCol)
            ? {
                selectedAdditionalSetting: {
                  label: ([FIELD_KEYS.VOTER_ID, FIELD_KEYS.NPI_ID].includes(sysCol)
                    ? dataPartners
                    : dataFormats
                  ).find((item) => item.keyName === getAdditionalColumnSettingValue(sysCol))?.label,
                  keyName: getAdditionalColumnSettingValue(sysCol),
                  value: getAdditionalColumnSettingValue(sysCol),
                },
              }
            : {}),
        }),
      );
      saveColumnsMapping(colMapping.filter(Boolean) as ColumnMapping[]);
    }
  }, [
    isDuplicateAudience,
    editableAudience,
    setAudienceName,
    saveColumnsMapping,
    columnsMasterlist,
    dataPartners,
    dataFormats,
  ]);

  // logic to store dispatch selected value
  const onSelectFileColumn = (selectedColLabel: string) => (selected: OptionData<string[]>) => {
    const currentMapping: ColumnMapping[] = columnsMapping.map((colMapping: ColumnMapping) => {
      if (colMapping.columnLabel === selectedColLabel && selectedColLabel) {
        const getAdditionalSettingInfo = (hash: string, type: string) => {
          const dataFormat = dataFormats.find((item) =>
            (type === FIELD_KEYS.PHONE ? /^[^a-zA-Z]*$/ : EMAIL_REGEX).test(hash)
              ? item.keyName === 'Raw'
              : item.keyName === detectHashType(hash),
          ) as DataPartners;
          return {
            ...dataFormat,
            label: dataFormat?.label,
            value: dataFormat?.keyName,
          };
        };
        return {
          ...colMapping,
          selectedOption: selected,
          ...([FIELD_KEYS.EMAIL, FIELD_KEYS.PHONE].includes(colMapping.columnKey) &&
          selected?.data?.[0]
            ? {
                selectedAdditionalSetting: getAdditionalSettingInfo(
                  selected.data[0],
                  colMapping.columnLabel,
                ),
              }
            : {}),
        };
      }
      return colMapping;
    });
    saveColumnsMapping(currentMapping);
  };

  const onSelectAdditionalSetting = (col: ColumnMapping) => (selectedAdditionalSetting: any) => {
    const currentMapping: ColumnMapping[] = columnsMapping.map((colMapping: ColumnMapping) => {
      if (colMapping.columnLabel === col.columnLabel) {
        return {
          ...colMapping,
          selectedAdditionalSetting,
        };
      }
      return colMapping;
    });
    saveColumnsMapping(currentMapping);
  };

  const onSelectSystemColumn = (col: ColumnMapping) => (selected: Option) => {
    const currentMapping: ColumnMapping[] = columnsMapping.map((colMapping: ColumnMapping) => {
      const mapping = { ...colMapping };
      if (mapping.uniqueId === col.uniqueId) {
        mapping.columnLabel = selected.label;
        mapping.columnKey = selected.value;
      }
      return mapping;
    });
    saveColumnsMapping(currentMapping);
  };

  const toggleCheckboxSelection = useCallback(
    (value?: boolean) => {
      const emptySelection = {
        columnLabel: '',
        columnKey: '',
      };
      const newValue = value !== undefined ? value : !isCheckboxSelected;
      if (newValue || value === undefined) {
        saveColumnsMapping(
          Array(newValue ? 1 : 3)
            .fill(emptySelection)
            .map((item) => ({ ...item, uniqueId: uniqueId() })),
        );
      } else {
        saveColumnsMapping([{ ...emptySelection, uniqueId: uniqueId() }]);
      }
      setHasError(false);
      modifyCheckboxSelection(newValue);
    },
    [isCheckboxSelected, saveColumnsMapping],
  );

  const handleCheckboxChange = useCallback(() => {
    return !(isEdit || isDuplicateAudience) && toggleCheckboxSelection();
  }, [toggleCheckboxSelection, isEdit, isDuplicateAudience]);

  const resetSetup = useCallback(() => {
    removeFile();
    setAudienceName('');
    toggleCheckboxSelection(false);
  }, [removeFile, setAudienceName, toggleCheckboxSelection]);

  useEffect(() => {
    if (uploadedFile && fileData && fileData.data?.length && fileData.data.length - 1 >= 5000000) {
      openSnackbar({
        message: 'File should not have more than 5M records',
        type: 'error',
      });
      RUMLogger.uploadMatchedAudienceFile({
        success: false,
        file: uploadedFile,
        errorMsg: 'More records than 5M',
      });
      resetSetup();
    }
  }, [fileData, openSnackbar, resetSetup, uploadedFile]);

  useEffect(() => {
    if (!uploadedFile || prevUploadedFile.current === uploadedFile) {
      return;
    }

    if (EXCEL_FORMATS.includes(uploadedFile.type)) {
      import('xlsx')
        .then(({ default: xlsx }) => {
          const reader = new FileReader();
          reader.onload = (evt) => {
            const bstr = evt?.target?.result;
            const wb = xlsx.read(bstr, { type: 'binary', sheetRows: 5000002 });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = xlsx.utils.sheet_to_csv(ws);
            parseCSVAndSave(data, uploadedFile);
          };

          reader.readAsBinaryString(uploadedFile);
        })
        .catch(() => {
          resetSetup();
          RUMLogger.uploadMatchedAudienceFile({
            file: uploadedFile,
            success: false,
            errorMsg: 'Matched Audience xlsx file parse failed',
          });
          openSnackbar({
            message: 'There was an error. Please check your network connectivity and try again.',
            type: 'error',
          });
        });
    } else if (uploadedFile.type === CSV_FORMAT) {
      parseCSVAndSave(uploadedFile);
    }

    if (!audienceName) {
      setAudienceName(
        startCase(uploadedFile.name.substring(0, uploadedFile.name.lastIndexOf('.'))),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSetup, parseCSVAndSave, openSnackbar, setAudienceName, uploadedFile]);

  const addColumn = () => {
    const mappings = [
      ...columnsMapping,
      {
        columnLabel: '',
        columnKey: '',
        uniqueId: uniqueId(),
      },
    ];
    // timeout needed for the Select to auto open
    setTimeout(() => {
      saveColumnsMapping(mappings);
    });
  };

  const removeColumn = (col: ColumnMapping) => () => {
    const mappings = columnsMapping.reduce((acc: ColumnMapping[], colMap: ColumnMapping) => {
      if (colMap.uniqueId !== col.uniqueId) {
        acc.push(colMap);
      }
      return acc;
    }, []);
    saveColumnsMapping(mappings);
  };

  const checkIfCombinationIsValid = useCallback(() => {
    const getItemDetail = (key: string) => columnsMapping.find((item) => item.columnKey === key);
    const addressValid = getItemDetail(FIELD_KEYS.STREET_ADDRESS)?.selectedOption;
    const zipValid = getItemDetail(FIELD_KEYS.ZIP)?.selectedOption;
    const firstNameValid = getItemDetail(FIELD_KEYS.FIRST_NAME)?.selectedOption;
    const lastNameValid = getItemDetail(FIELD_KEYS.LAST_NAME)?.selectedOption;
    const stateValid = getItemDetail(FIELD_KEYS.STATE)?.selectedOption;
    return (
      stateValid &&
      zipValid &&
      ((firstNameValid && lastNameValid) || addressValid || (lastNameValid && addressValid))
    );
  }, [columnsMapping]);

  const handleOnSubmit = useCallback(() => {
    if (!editableAudience && !(isCheckboxSelected || checkIfCombinationIsValid())) {
      setHasError(true);
      return;
    }
    if (isEdit && !isAudienceEdited) {
      history.goBack();
      handleClose();
      return;
    }
    const additionalSettingFieldKeys = [
      FIELD_KEYS.VOTER_ID,
      FIELD_KEYS.PHONE,
      FIELD_KEYS.EMAIL,
      FIELD_KEYS.NPI_ID,
    ];
    const columnsToSend: ColumnsToSend = columnsMapping.reduce(
      (acc: ColumnsToSend, colMap: ColumnMapping) => {
        if (
          colMap.selectedOption &&
          (isCheckboxSelected && additionalSettingFieldKeys.includes(colMap.columnKey)
            ? colMap.selectedAdditionalSetting
            : true)
        ) {
          acc[colMap.selectedOption.value] = colMap.columnKey;
        }
        return acc;
      },
      {},
    );

    const columnSettingsToSend: ColumnsToSend = columnsMapping.reduce(
      (acc: ColumnsToSend, colMap: ColumnMapping) => {
        if (colMap.selectedAdditionalSetting) {
          acc[colMap.columnKey] = colMap.selectedAdditionalSetting.value;
        }
        return acc;
      },
      {},
    );

    if (isEdit && editableAudience && !isDuplicateAudience) {
      handleClose();
      const details = {
        audienceName,
        columns: columnsToSend,
        id: editableAudience.id,
        rawS3URL: editableAudience.rawS3URL,
      };

      if (isEqual(columnsToSend, editableAudience.existingColumnMatching)) {
        Reflect.deleteProperty(details, 'columns');
      }

      updateAudienceName(audienceName, 1, editableAudience.id);
      updateMatchedAudience(details, history);
      history.goBack();
    } else if (uploadedFile && fileData) {
      const metadata: MatchedAudienceMetadata = {
        columns: fileData.data[0],
        fileName: uploadedFile.name,
        fileSize: uploadedFile.size,
        columnValues: fileColumnsWithData,
        rows: fileData.data.length - 1, // -1 for excluding header row
      };

      const details = {
        file: uploadedFile,
        columns: columnsToSend,
        audienceName,
        metadata,
        columnValues: fileColumnsWithData,
        isSingleColumnEnabled: isCheckboxSelected,
        fileTotalCount: fileData.data.length - 1,
        columnSettings: columnSettingsToSend,
      };
      createOrDuplicateMatchedAudience({
        isDuplicateAudience: false,
        details,
        resetSetup,
        closeDialog: handleClose,
        updateSorting,
      });
    } else if (isDuplicateAudience) {
      createOrDuplicateMatchedAudience({
        isDuplicateAudience: true,
        audienceName,
        audienceId: editableAudience?.id,
        resetSetup,
        closeDialog: handleClose,
        updateSorting,
      });
    }
  }, [
    handleClose,
    checkIfCombinationIsValid,
    audienceName,
    columnsMapping,
    editableAudience,
    fileData,
    resetSetup,
    history,
    isEdit,
    createOrDuplicateMatchedAudience,
    updateMatchedAudience,
    uploadedFile,
    updateAudienceName,
    isDuplicateAudience,
    isAudienceEdited,
    fileColumnsWithData,
    updateSorting,
    isCheckboxSelected,
  ]);

  const isCreationDisabled = useMemo(() => {
    if (submitInProgress) return true;

    if (isCheckboxSelected) {
      const isFullAddressSelectedWithValue = columnsMapping.find(
        (column) => column.columnKey === FIELD_KEYS.FULL_ADDRESS,
      )?.selectedOption;
      const isOneAdditionalSettingRowValid = columnsMapping.some(
        (column) => column.selectedAdditionalSetting && column.selectedOption,
      );
      return !(isFullAddressSelectedWithValue || isOneAdditionalSettingRowValid);
    }

    return !(columnsMapping.filter((colMap: ColumnMapping) => colMap.selectedOption).length >= 3);
  }, [submitInProgress, columnsMapping, isCheckboxSelected]);

  const handleTextChange = (change: string) => {
    setIsAudienceEdited(true);
    handleChange(change);
  };

  useEffect(() => {
    if (isDuplicateAudience && !audienceNameUpdated.current && audienceName) {
      setAudienceName(`Copy of ${audienceName}`);
      audienceNameUpdated.current = true;
    }
  });

  useEffect(() => setDisableAddColumn(columnsMapping.length >= columnsMasterlist.length), [
    columnsMapping,
    columnsMasterlist,
  ]);

  useEffect(() => {
    prevUploadedFile.current = uploadedFile;
  }, [uploadedFile]);

  useEffect(() => {
    setCreate({
      onClick: handleOnSubmit,
      disabled: !(
        !isCreationDisabled &&
        audienceName.trim().length < 255 &&
        audienceName.trim().length &&
        (isAudienceEdited || !isEdit)
      ),
      resetSetup,
    });
  }, [
    setCreate,
    handleOnSubmit,
    isCreationDisabled,
    resetSetup,
    audienceName,
    isAudienceEdited,
    isEdit,
  ]);

  const getTotalRows = () => {
    if (editableAudience) {
      return editableAudience.metadata?.rows;
    }
    return fileData?.data.length ? fileData.data.length - 1 : 0;
  };

  return (
    <div className={styles.container}>
      <div className="row mb-3">
        <div className="col-6">
          {audienceName.length >= 255 ? (
            <>
              <TextField
                className={styles.audienceName}
                variant="withoutTickbox"
                label="Audience Name"
                value={audienceName}
                onChange={handleTextChange}
                inputRef={getInputRef}
              />
              <span className={styles.errorMsg}>* Maximum 255 characters</span>
            </>
          ) : (
            <TextField
              variant="withoutTickbox"
              label="Audience Name"
              value={audienceName}
              onChange={handleTextChange}
              inputRef={getInputRef}
            />
          )}
        </div>
        <div className="col-6">
          <div className={styles.file}>
            <header className={styles.fileHeader}>
              <h4 className={styles.fileTitle}>
                {uploadedFile?.name || editableAudience?.metadata?.fileName}
              </h4>
              {uploadedFile && (
                <ButtonCircle
                  iconName="Delete"
                  onClick={() => {
                    removeFile();
                    if (
                      audienceName ===
                      startCase(uploadedFile.name.substring(0, uploadedFile.name.lastIndexOf('.')))
                    ) {
                      setAudienceName('');
                    }
                    toggleCheckboxSelection(false);
                  }}
                  className={styles.fileRemove}
                />
              )}
            </header>
            <ul className={styles.fileParams}>
              <li className={styles.fileParam}>
                <Icon className={styles.doneIcon} name="Done" />
                {getFilesize()}
              </li>
              <li className={styles.fileParam}>
                {fileData?.data[0].length || editableAudience?.metadata?.columns.length} columns
              </li>
              <li className={styles.fileParam}>{getTotalRows()} rows</li>
            </ul>
          </div>
        </div>
      </div>
      <h4 className="p1-demi mb-2">Match Columns</h4>
      <div className="d-flex align-items-center mb-3">
        <Checkbox
          name="voterID"
          checked={isCheckboxSelected}
          onChange={handleCheckboxChange}
          disabled={isEdit || isDuplicateAudience}
        />
        <span className={`p1 ml-1 ${styles.checkBoxText}`} onClick={handleCheckboxChange}>
          I have a VoterID/ NPI ID/ Phone/ Email/ Full Address in file
        </span>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={styles.infoDiv}>
            <div className={styles.infoText}>
              {isCheckboxSelected
                ? 'The full address requires the following format for better matching'
                : 'One of the following system fields combinations is required for matching:'}
            </div>
            <div className={styles.combinationsWrapper}>
              {combinations.map((combination) => (
                <div key={combination} className={styles.combinationItem}>
                  <span>{combination}</span>
                </div>
              ))}
            </div>
            <div
              className={styles.redirectLink}
              onClick={() =>
                window.open(
                  'https://help.iqm.com/en/articles/5651480-selecting-a-matched-audience',
                  '_blank',
                )
              }
            >
              <Icon name="OpenLink" className="mr-1" />
              <div>Know More</div>
            </div>
          </div>
        </div>
      </div>
      {hasError && (
        <div className="row">
          <div className="col-12 mt-2 mb-1">
            <div className={styles.errorMessage}>
              <Icon name="ErrorTriangle" className="mr-1" />
              <div>One of the system fields combinations is required for audience matching</div>
            </div>
          </div>
        </div>
      )}
      <SelectedAudiencesTable
        isCheckboxSelected={isCheckboxSelected}
        onSelectFileColumn={onSelectFileColumn}
        onSelectSystemColumn={onSelectSystemColumn}
        onSelectAdditionalSetting={onSelectAdditionalSetting}
        addColumn={addColumn}
        fileColumns={fileColumns}
        columns={columns}
        isEdit={isEdit}
        isDuplicateAudience={isDuplicateAudience}
        disableAddColumn={disableAddColumn}
        removeColumn={removeColumn}
        dataPartners={dataPartners}
        dataFormats={dataFormats}
        columnsMapping={columnsMapping}
        columnsMasterlist={columnsMasterlist}
      />
    </div>
  );
};

const mapState = ({ matchedAudience }: AppState) => ({
  columnsMasterlist: matchedAudience.columnsMasterlist,
  columnsMapping: matchedAudience.columnsMapping,
  audienceName: matchedAudience.audienceName,
  submitInProgress: matchedAudience.submitInProgress,
  editableAudience: matchedAudience.editableAudience,
  dataPartners: matchedAudience.dataPartners,
  dataFormats: matchedAudience.dataFormats,
});

const mapAction = {
  saveColumnsMapping: matchedAudienceActions.saveColumnsMapping,
  setAudienceName: matchedAudienceActions.setAudienceName,
  createOrDuplicateMatchedAudience: matchedAudienceActions.createOrDuplicateMatchedAudience,
  updateMatchedAudience: matchedAudienceActions.updateMatchedAudience,
  openSnackbar: snackbarActions.openSnackbar,
  updateAudienceName: tableActions.updateAudienceName,
  updateSorting: tableActions.updateSorting,
  setDataPartners: matchedAudienceActions.setDataPartners,
  setDataFormats: matchedAudienceActions.setDataFormats,
};

export const MatchedAudienceSetup = connect(mapState, mapAction)(MatchedAudienceSetupComponent);
