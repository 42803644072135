import * as React from 'react';
import { CurrencyDollar, Database, Show } from '@applift/icons';
import { maxBy, upperFirst } from 'lodash';

import { getAudienceStatusIcon } from 'helpers/audience';
import { TDialog } from 'store/app/reducer';
import { ReachFormatter } from 'utils/format';
import { useSegmentedAudienceInfo } from 'hooks/useSegmentedAudience';
import { SegmentedAudiencePartner } from 'models/SegmentedAudience';

import { TSegmentGroup } from '../../types';
import { CostFormatter, estimateReach } from '../../../helpers';
import { SummaryInfoRow } from '../../../components/SummaryInfoRow';

interface Props {
  segmentGroups: TSegmentGroup[];
  selectedPartner: SegmentedAudiencePartner | null;
  dialog?: TDialog;
  isAudienceReady?: boolean;
}

export const SummaryInfo = (props: Props) => {
  const { segmentGroups, selectedPartner, dialog, isAudienceReady } = props;

  const existingAudienceData = useSegmentedAudienceInfo({
    enabled: dialog?.status?.toLowerCase() === 'ready' && !!dialog?.audienceId,
    audienceId: dialog?.audienceId!,
  });

  const estimatedReach = React.useMemo(() => {
    if (isAudienceReady) {
      if (!existingAudienceData.data?.uniques) {
        return '-';
      }
      return ReachFormatter.format(Number.parseInt(existingAudienceData.data.uniques, 10));
    }

    const value = estimateReach(segmentGroups);

    if (value === null && segmentGroups.some((sg) => Object.keys(sg.segments).length)) {
      return '-';
    }
    if (value === null) {
      return '~250M';
    }
    return `~${ReachFormatter.format(value)}`;
  }, [segmentGroups, isAudienceReady, existingAudienceData.data]);

  const estimatedCost = React.useMemo(() => {
    if (isAudienceReady) {
      if (!existingAudienceData.data?.dataCost) {
        return '-';
      }
      return CostFormatter.format(existingAudienceData.data.dataCost);
    }

    const maxCpm = maxBy(
      segmentGroups.map((sg) => maxBy(Object.values(sg.segments), (segment) => segment.cpm)),
      (maxWithinGroup) => maxWithinGroup?.cpm || 0,
    )?.cpm;

    if (!maxCpm) {
      return '-';
    }
    return `~${CostFormatter.format(maxCpm)}`;
  }, [segmentGroups, isAudienceReady, existingAudienceData.data]);

  const statusIcon = getAudienceStatusIcon(dialog?.status?.toLowerCase() || '', { fontSize: 16 });

  const summaryInfo = [
    ...(dialog?.type === 'edit' && dialog?.status
      ? [
          {
            label: '',
            value: upperFirst(dialog.status),
            icon: statusIcon,
          },
        ]
      : []),
    {
      label: 'Partner:',
      value: selectedPartner?.segmentPartnerName || 'Not Selected',
      logoUrl: selectedPartner?.segmentPartnerLogo,
      icon: <Database sx={{ textColor: 'neutral-400' }} fontSize={16} />,
    },
    {
      label: 'Reach:',
      value: estimatedReach,
      icon: <Show sx={{ textColor: 'neutral-400' }} fontSize={16} />,
    },
    {
      label: 'Cost (eCPM):',
      value: estimatedCost,
      icon: <CurrencyDollar sx={{ textColor: 'neutral-400' }} fontSize={16} />,
    },
  ];

  return <SummaryInfoRow summaryItems={summaryInfo} />;
};
