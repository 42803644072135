import * as React from 'react';
import { DownloadAudienceInsightsDialog } from '@applift/insight-app';
import { connect } from 'react-redux';
import { Dialog, enqueueSnackbar, Typography } from '@applift/factor';

import { useDownloadInsights } from 'hooks/useInsights';
import { downloadFile } from 'utils/file';
import { TableAudienceItem } from 'models/Table';

interface DownloadInsightProps {
  closeDialog: () => void;
  audienceData: TableAudienceItem;
}

export const DownloadInsightComponent = (props: DownloadInsightProps) => {
  const { closeDialog, audienceData } = props;
  const [selectedImage, setSelectedImage] = React.useState<File>();
  const [selectedLogoMethod, setSelectedLogoMethod] = React.useState<
    'default' | 'custom' | undefined
  >(undefined);

  const onSuccessGetDownloadUrl = (response: any) => {
    const url = response.data.insightsReportUrl;
    const fileName = `${audienceData.audienceName}_insights.pdf`;
    downloadFile(url, fileName);
    enqueueSnackbar(
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        Insights are being downloaded as PDF.
      </Typography>,
      {
        variant: 'info',
      },
    );
    closeDialog();
  };

  const onErrorGetDownloadUrl = (error: any) => {
    enqueueSnackbar(
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        {error?.errorObjects?.[0]?.error ?? 'Something went wrong. Please try after some time.'}
      </Typography>,
      {
        variant: 'error',
      },
    );
    closeDialog();
  };

  const getDownloadUrlMutation = useDownloadInsights(
    onSuccessGetDownloadUrl,
    onErrorGetDownloadUrl,
  );

  const onDownloadPDF = React.useCallback(() => {
    const downloadForm = new FormData();

    downloadForm.append('audienceId', `${audienceData.id}`);
    downloadForm.append('fileType', 'pdf');
    if (selectedLogoMethod === 'custom') {
      // @ts-ignore
      downloadForm.append('customLogoFile', selectedImage);
    }
    getDownloadUrlMutation.mutate(downloadForm);
  }, [getDownloadUrlMutation, selectedImage, selectedLogoMethod, audienceData]);

  return (
    <Dialog open fullWidth>
      <DownloadAudienceInsightsDialog
        onClose={closeDialog}
        // @ts-ignore
        data={{ name: `${audienceData.audienceName}` }}
        actionBtnDisabled={
          selectedLogoMethod === 'custom' ? !selectedImage : selectedLogoMethod !== 'default'
        }
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        selectedLogoMethod={selectedLogoMethod}
        setSelectedLogoMethod={setSelectedLogoMethod}
        action={onDownloadPDF}
        isActionLoading={getDownloadUrlMutation.isLoading}
      />
    </Dialog>
  );
};

export const DownloadInsight = connect(null, null)(DownloadInsightComponent);
