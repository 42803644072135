import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Select, Icon } from 'factor';

import { AudienceTypeId } from 'models/Table';
import { AppState } from 'store';
import { SetSelectedAudiencesStatuses, tableActions } from 'store/table/actions';
import { AudienceStatus } from 'api/Table';
import { Option } from 'models/Option';
import { AUDIENCE_STATUSES, PRE_BID_AUDIENCE_TYPE_ID } from 'constants/audiences';
import { tableStatusIconsMap } from '../../consts';
import { tableComponentInstanceRef } from '../AudiencesTable';

import styles from './styles.module.scss';

interface Props extends SetSelectedAudiencesStatuses {
  audienceTypeIds: AudienceTypeId;
  statusList: AudienceStatus[];
  selectedAudienceStatus: { [key in AudienceTypeId]: string };
}

const AudiencesStatusComponent = (props: Props) => {
  const {
    audienceTypeIds,
    statusList,
    setSelectedAudiencesStatuses,
    selectedAudienceStatus,
  } = props;

  const statusOptions = useMemo(() => {
    if (statusList) {
      return [{ status: 'All', id: '', displayName: 'All' }, ...statusList].map((item) => ({
        label: item.displayName,
        value: item.id,
        reactLabel: (
          <span className={styles.statusOption}>
            <i className={styles.statusIcon}>
              <Icon name={tableStatusIconsMap[item.status]} className="_size-16" />
            </i>
            <span className={styles.statusLabel}>{item.displayName}</span>
          </span>
        ),
      }));
    }

    return [];
  }, [statusList]);

  const getLabelSelection = (label: string) => {
    let labelSelection = label;
    if (labelSelection === AUDIENCE_STATUSES.PENDING_FOR_APPROVAL) {
      labelSelection = AUDIENCE_STATUSES.PENDING;
    } else if (labelSelection === AUDIENCE_STATUSES.ALL) {
      labelSelection = '';
    }
    return labelSelection;
  };

  const selectedStatusOption = useMemo(() => {
    const option = statusOptions.find(
      (statusOption) =>
        getLabelSelection(statusOption.label) === selectedAudienceStatus[audienceTypeIds],
    );
    return option ? { label: option.label, value: option.value } : null;
  }, [statusOptions, selectedAudienceStatus, audienceTypeIds]);

  const onStatusChange = (option: Option<number>) => {
    tableComponentInstanceRef?.current.clearSelected();
    setSelectedAudiencesStatuses({
      ...selectedAudienceStatus,
      [audienceTypeIds]: getLabelSelection(option.label),
    });
  };

  return audienceTypeIds !== PRE_BID_AUDIENCE_TYPE_ID.toString() ? (
    <Select
      className={styles.statusSelect}
      options={statusOptions}
      placeholder="Status"
      value={selectedStatusOption}
      onChange={onStatusChange}
    />
  ) : null;
};

const mapState = (state: AppState) => ({
  audienceTypeIds: state.table.audienceTypeIds,
  statusList: state.table.statusList,
  selectedAudienceStatus: state.table.selectedAudienceStatus,
});

const mapAction = {
  setSelectedAudiencesStatuses: tableActions.setSelectedAudiencesStatuses,
};

export const AudiencesStatus = connect(mapState, mapAction)(AudiencesStatusComponent);
