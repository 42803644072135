import React from 'react';

import { TDialog } from 'store/app/reducer';
import LookalikeAudienceDialog from '../components/LookalikeAudienceDialog';

interface Props {
  dialog: TDialog;
  handleClose: () => void;
}

const EditLookalikeAudience = (props: Props) => {
  const { dialog, handleClose } = props;

  return (
    <LookalikeAudienceDialog dialog={dialog} handleClose={handleClose} title="Lookalike Audience" />
  );
};

export default EditLookalikeAudience;
