import React from 'react';
import { Snackbar } from 'factor';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { toastActions } from '../../store/toast/actions';

interface Props {
  children: JSX.Element;
  toastOpened: boolean;
  toastMessage: string | JSX.Element;
  closeToast: () => void;
}

const AppWrapperComponent = (props: Props) => {
  const { children, toastOpened, closeToast, toastMessage } = props;
  return (
    <>
      <div className="main">{children}</div>
      <Snackbar open={toastOpened} onClose={closeToast} message={toastMessage} />
    </>
  );
};

const mapState = (state: AppState) => {
  return {
    toastOpened: state.toast.isOpen,
    toastMessage: state.toast.message,
  };
};

const mapActions = {
  closeToast: toastActions.close,
};

export const AppWrapper = connect(mapState, mapActions)(AppWrapperComponent);
