import { AxiosResponse } from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';

import { InsertionOrderBasic } from 'models/InsertionOrder';
import { WithResponse } from 'models/Response';

import { getInstance } from './Instance';
import { getInsertionOrderListKey } from './queryKey';

export interface InsertionOrderListResponse {
  totalRecords: number;
  filteredRecords: number;
  ioBasicDetailsList: InsertionOrderBasic[];
}

export const getInsertionOrderList = async (
  queryContext: QueryFunctionContext<ReturnType<typeof getInsertionOrderListKey.keys>>,
): Promise<InsertionOrderListResponse> => {
  const { pageParam, queryKey } = queryContext || {};
  const { ioStatusIdsList, searchField, sortBy, noOfEntries } = queryKey?.[0] || {};

  try {
    const response: AxiosResponse<WithResponse<
      InsertionOrderListResponse
    >> = await getInstance().post('/v3/cmp/io/basic/list', {
      ioStatusIdsList,
      searchField,
      sortBy,
      noOfEntries,
      pageNo: pageParam || 1,
    });
    if (response.data.data) {
      return response.data.data;
    }
    throw response;
  } catch (err) {
    return Promise.reject((err as any)?.response || err);
  }
};
