import { createAction } from '../../utils/actions';
import { toastConstants } from './constants';

export const toastActions = {
  open: (message: string | JSX.Element) => {
    return createAction(toastConstants.TOAST_OPEN, message);
  },
  close: () => {
    return createAction(toastConstants.TOAST_CLOSE);
  },
};

export interface Open {
  open: (message: string | JSX.Element) => void;
}
