import React, { useMemo } from 'react';
import { Table } from 'factor';

import { ContactList } from 'api/Hubspot';

import styles from './styles.module.scss';

type Props<T> = {
  selectedContactLists: T[];
};

const ContactListsTable = <T extends ContactList>(props: Props<T>) => {
  const { selectedContactLists } = props;

  const tableConfig = useMemo(() => {
    const hubspotColumns = [
      { label: 'Dynamic', sortingKey: 'dynamic', className: 'w-60-60' },
      { label: 'Created', sortingKey: 'created', className: 'w-60-60' },
      { label: 'Updated', sortingKey: 'updated', className: 'w-60-60' },
    ];
    const hubspotBody = [
      {
        key: (item: ContactList) => (item.dynamic ? 'dynamic' : 'static'),
        className: `w-120-120 _right`,
      },
      {
        key: (item: ContactList) => new Date(item.created).toLocaleDateString(),
        className: `w-120-120 _right`,
      },
      {
        key: (item: ContactList) => new Date(item.updated).toLocaleDateString(),
        className: `w-120-120 _right`,
      },
    ];
    const tableConfig = {
      header: [
        { label: 'ID', sortingKey: 'id', className: 'w-80-80' },
        { label: 'List Name', sortingKey: 'name', className: 'w-200-400' },
        { label: 'Size', sortingKey: 'size', className: 'w-120-120 _right' },
      ],
      body: [
        {
          key: (item: ContactList) => item.id,
          className: 'w-80-80',
        },
        {
          key: (item: ContactList) => item.name,
          className: 'w-200-400',
        },
        {
          key: (item: ContactList) => item.size,
          className: 'w-120-120 _right',
        },
      ],
      data: [...selectedContactLists],
    };
    if (selectedContactLists.some((contactList) => contactList.created)) {
      tableConfig.header.concat(hubspotColumns);
      tableConfig.body.concat(hubspotBody);
    }
    return tableConfig;
  }, [selectedContactLists]);

  return (
    <Table
      className={styles.table}
      data={tableConfig.data}
      header={tableConfig.header}
      body={tableConfig.body}
      rowKeyExtractor={(item: ContactList) => item.id}
      countPath="responseObject.filteredRecords"
      tableMaxHeight="100"
    />
  );
};

export default ContactListsTable;
