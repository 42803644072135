import React from 'react';
import { TimezoneProvider, EpochDateRangePicker } from 'iqm-framework';
import moment from 'moment';
import { Tooltip, Icon } from 'factor';
import styles from './styles.module.scss';
import {
  getCalendarMax,
  getCalendarMin,
  getDatesOnMinMaxUpdate,
  getDefaultDateRange,
} from '../../../../../utils/datePicker';

interface Props {
  startDate: number;
  endDate: number;
  isDateRangeValid: boolean;
  setIsDateRangeValid: (isValid: boolean) => void;
  setStartDate: (epoch: number) => void;
  setEndDate: (epoch: number) => void;
}

const PRESELECTED: any = [
  EpochDateRangePicker.PRESELECT_KEYS.LAST_WEEK,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_MONTH,
  EpochDateRangePicker.PRESELECT_KEYS.LAST_FIFTEEN_DAYS,
];

const USER_TIMEZONE_DETAILS = {
  label: moment.tz.guess(),
  value: moment.tz.guess(),
};

export const DateRangePicker = (props: Props) => {
  const {
    startDate,
    endDate,
    isDateRangeValid,
    setIsDateRangeValid,
    setStartDate,
    setEndDate,
  } = props;

  const onDateRangeChange = (e: { startDate: number; endDate: number }) => {
    const startEpoch = e.startDate;
    const endEpoch = e.endDate;
    const epochDiff = (endEpoch - startEpoch) / (1000 * 60 * 60 * 24);
    setIsDateRangeValid(epochDiff <= 30);
    setStartDate(startEpoch);
    setEndDate(endEpoch);
  };

  return (
    <TimezoneProvider timezone={USER_TIMEZONE_DETAILS}>
      <div
        className={`${styles.dateRangePickerWrapper} ${
          !isDateRangeValid ? styles.invalidDateRange : null
        }`}
      >
        <EpochDateRangePicker
          dateRange={{ startDate, endDate }}
          onDateRangeChange={onDateRangeChange}
          label={
            <div className="d-flex align-items-center">
              Data Collection Period
              <Tooltip
                label="Data collection is only available for the past 6 months, with a maximum selection period of 30 days"
                position="left"
                className="ml-1"
                auto={false}
              >
                <Icon className={styles.statInfoTooltipIcon} name="Info" />
              </Tooltip>
            </div>
          }
          defaultDateRange={getDefaultDateRange()}
          dateFormat="MM/DD/YYYY"
          className={styles.dateRangePicker}
          datePickerProps={{
            numberOfCalendars: 2,
            singleDateRange: true,
            maximumDate: getCalendarMax(),
            minimumDate: getCalendarMin(),
          }}
          preselected={PRESELECTED}
          insidePreselectors
          removeSafariSelectionIssue
          dataDogApplyButtonLabel="Apply Date Range"
          getDatesOnMinMaxUpdate={getDatesOnMinMaxUpdate}
        />
      </div>
      {!isDateRangeValid ? (
        <div className={styles.invalidDateRangeMsg}>
          Maximum allowed selection period is 30 days
        </div>
      ) : null}
    </TimezoneProvider>
  );
};
