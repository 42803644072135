import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import { Dialog, Icon, NumberFormatter, ProgressCircle, Tooltip, Pill } from 'factor';
import moment from 'moment-timezone';

import { TDialog } from 'store/app/reducer';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { getRetargetedAudience } from 'api/RetargetedAudience';
import { RetargetedAudience } from 'models/RetargetedAudience';
import PixelScriptCode from 'components/PixelScriptCode';
import { COMMON_DIALOG_MODAL_PROPS } from 'constants/audiences';
import { SOMETHING_WENT_WRONG_MSG } from 'constants/errors';
import { tableStatusIconsMap } from 'components/consts';

import styles from './styles.module.scss';

const getUTCDate = (date: number) => {
  return moment
    .unix(date)
    .tz('UTC')
    .format('MM/DD/yyyy');
};

interface Props extends OpenSnackbar {
  dialog: TDialog;
  handleClose?: () => void;
  dialogVisibility?: boolean;
  isIncluded?: boolean;
}

const ViewRetargetedAudience = (props: Props) => {
  const { dialog, handleClose, dialogVisibility = true, isIncluded, openSnackbar } = props;

  const [audience, setAudience] = useState<RetargetedAudience | undefined>();

  useEffect(() => {
    if (dialog?.audienceId) {
      getRetargetedAudience(dialog.audienceId)
        .then((res) => setAudience(res.data))
        .catch((err) => {
          openSnackbar({
            message: get(err, 'errorObjects[0].error', SOMETHING_WENT_WRONG_MSG),
            type: 'error',
          });
          if (handleClose) {
            handleClose();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  const actionButtons = useMemo(
    () => [
      {
        title: 'Done',
        handler: handleClose,
      },
    ],
    [handleClose],
  );

  const renderContent = audience ? (
    <div className={styles.content}>
      <div className={styles.titleContainer}>
        <div className={styles.icon}>
          <Icon name="AudienceRetargeted" />
        </div>
        {audience.name.length > 60 ? (
          <Tooltip className={styles.toolTip} label={audience.name} position="bottom" auto={false}>
            <div className={`${styles.name} ${styles.restrictName}`}>{audience.name}</div>
          </Tooltip>
        ) : (
          <div className={styles.name}>{audience.name}</div>
        )}
        {isBoolean(isIncluded) && (
          <Pill
            className={isIncluded ? styles.successPill : styles.dangerPill}
            label={isIncluded ? 'Allowed' : 'Blocked'}
          />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Created on:</div>
          <div className={styles.infoText}>{getUTCDate(audience.createdOn)}</div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Data Collection Period: </div>
          {audience.duration === 'DATE_RANGE' ? (
            <div className={styles.infoText}>{`${moment(audience.startDate).format(
              'MM/DD/yyyy',
            )}-${moment(audience.endDate).format('MM/DD/yyyy')}`}</div>
          ) : (
            <div className={styles.infoText}>{`${audience.time} months`}</div>
          )}
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Reach</div>
          <div className={styles.infoText}>
            <NumberFormatter>{audience.uniques}</NumberFormatter>
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <Icon name={tableStatusIconsMap[audience.status]} className="_size-16" />
          <div className={styles.infoStatus}>{audience.status}</div>
        </div>
      </div>
      <PixelScriptCode
        className={styles.pixelScriptCode}
        tagScript={`<script src='https://pxl.iqm.com/c/${audience.tagId}' async></script>`}
        isRenderedViaIFrame={!dialogVisibility}
      />
    </div>
  ) : (
    <div className={dialogVisibility ? styles.loadingWithDialog : styles.loadingWithoutDialog}>
      <ProgressCircle />
    </div>
  );

  return dialogVisibility ? (
    <Dialog
      dialogTitle="Audience Details"
      open={dialog}
      crossButton
      headerFooterBorders
      modalProps={COMMON_DIALOG_MODAL_PROPS}
      className={styles.container}
      onCrossButtonClick={handleClose}
      actionButtons={actionButtons}
    >
      {renderContent}
    </Dialog>
  ) : (
    <>{renderContent}</>
  );
};

const mapAction = {
  openSnackbar: snackbarActions.openSnackbar,
};

export default connect(null, mapAction)(ViewRetargetedAudience);
