import React, { useMemo, useCallback } from 'react';
import { Dialog } from 'factor';

import styles from './styles.module.scss';

interface Props {
  open: boolean;
  handleSubmit?: any;
  handleClose: () => void;
  editName?: string;
}

export const SaveConfirmationDialog = (props: Props) => {
  const { open, handleSubmit, handleClose, editName } = props;

  const handleSave = useCallback((): void => {
    handleSubmit();
    handleClose();
  }, [handleSubmit, handleClose]);

  const actionButtons = useMemo(
    () => [
      {
        title: 'Discard',
        variant: 'secondary',
        handler: handleClose,
      },
      {
        title: 'Save',
        handler: handleSave,
      },
    ],
    [handleClose, handleSave],
  );

  return (
    <Dialog
      dialogTitle="Save Audience"
      className={styles.container}
      open={open}
      actionButtons={actionButtons}
    >
      <p className={styles.confirmationText}>
        Are you sure you want to save these changes for the audience{' '}
        <span className={styles.name}>{editName}</span> ?
      </p>
      <p className={`title-card-subtitle ${styles.confirmationNote}`}>
        Note: This action will affect the running campaign’s performance
      </p>
    </Dialog>
  );
};
