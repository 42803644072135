import React, { useMemo, useRef } from 'react';
import { Select, Table, Button, ButtonCircle, Icon, Tooltip } from 'factor';
import {
  Column,
  ColumnMapping,
  DataFormats,
  DataPartners,
  MappedColumns,
} from 'models/MatchedAudience';
import { OptionData, Option } from 'models/Option';
import { MATCHED_AUD_SYSTEM_FIELD_KEYS as FIELD_KEYS } from 'components/consts';

import styles from './styles.module.scss';

interface Props {
  isCheckboxSelected: boolean;
  onSelectFileColumn: (col: string) => (selected: OptionData<string[]>) => void;
  onSelectSystemColumn: (col: ColumnMapping) => (selected: Option) => void;
  onSelectAdditionalSetting: (col: ColumnMapping) => (selected: OptionData<string[]>) => void;
  addColumn: () => void;
  fileColumns: OptionData<string[]>[];
  columns: MappedColumns[];
  isEdit: boolean;
  isDuplicateAudience: boolean;
  disableAddColumn: boolean;
  removeColumn: (col: ColumnMapping) => () => void;
  dataPartners: DataPartners[];
  dataFormats: DataFormats[];
  columnsMapping: ColumnMapping[];
  columnsMasterlist: Column[];
}

export const SelectedAudiencesTable = (props: Props) => {
  const {
    isCheckboxSelected,
    onSelectAdditionalSetting,
    onSelectFileColumn,
    onSelectSystemColumn,
    fileColumns,
    columns,
    addColumn,
    isEdit,
    isDuplicateAudience,
    disableAddColumn,
    removeColumn,
    dataPartners,
    dataFormats,
    columnsMapping,
    columnsMasterlist,
  } = props;

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const toggleOpenProp = (isOpen: boolean) => {
    if (isOpen) {
      setTimeout(() => {
        const openedDropDownHeight =
          Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) -
          document
            .querySelectorAll('[class*="select-simple _opened"]')?.[0]
            ?.getBoundingClientRect().bottom -
          60;
        // eslint-disable-next-line
        document
          .querySelector(':root')
          // @ts-ignore
          ?.style?.setProperty('--scrollableContainerHeight', `${openedDropDownHeight / 16}rem`);
      }, 0);
    }
  };
  const getAdditionalSettingPartnerOptions = React.useCallback(
    (dataItems: DataPartners[], sysFieldName: string) => {
      const fieldId = columnsMasterlist.find((col) => col.key === sysFieldName)?.id;
      const filteredDataPartners = dataItems.filter((item) => item.audienceColumnId === fieldId);
      return filteredDataPartners.map((item) => ({
        ...item,
        label: item.label,
        value: item.keyName,
      }));
    },
    [columnsMasterlist],
  );

  const getAdditionalSettingFormatOptions = (dataItems: DataFormats[]) =>
    dataItems.map((item) => ({ ...item, label: item.label, value: item.keyName }));

  const tableConfig = useMemo(() => {
    let data: ColumnMapping[] = [...columnsMapping];
    if (!isEdit && !isDuplicateAudience && !disableAddColumn) {
      data.push({ isEmpty: true, uniqueId: '99999' } as any);
    }
    data = data.map((one, index) => ({
      ...one,
      index,
    }));
    return {
      header: [
        {
          label: 'System Fields',
          className: isEdit ? '_editSystemFields' : '_createSystemFields',
        },
        {
          label: 'Uploaded File Columns',
          className: isEdit
            ? `_editUploadedFileColumns${isCheckboxSelected ? 'CheckBox' : ''}`
            : `_createUploadedFileColumns${isCheckboxSelected ? 'CheckBox' : ''}`,
        },
        ...(isCheckboxSelected
          ? [
              {
                label: 'Additional File Column Settings',
                className: isEdit ? '_editAdditionalFileColumns' : '_createAdditionalFileColumns',
              },
            ]
          : []),
        {
          label: 'Sample Data',
          className: isEdit
            ? `_editOnSampleData${isCheckboxSelected ? 'CheckBox' : ''}`
            : `_createOnSampleData${isCheckboxSelected ? 'CheckBox' : ''}`,
        },
      ],
      body: [
        {
          key: (col: any) => {
            if (col.isEmpty) {
              return (
                <Button
                  className={styles.AddButton}
                  variant="bold-link"
                  onClick={() => {
                    if (buttonRef.current) {
                      buttonRef.current.blur();
                    }
                    addColumn();
                  }}
                  innerRef={(ref: HTMLButtonElement) => {
                    buttonRef.current = ref;
                  }}
                >
                  <Icon name="Plus" className={styles.plusIcon} />
                  Add Field
                </Button>
              );
            }
            if (isDuplicateAudience || isEdit) {
              return <>{col.columnLabel}</>;
            }
            return (
              <Select
                className={styles.systemDropdown}
                options={columns}
                portalStyles={{ zIndex: 100 }}
                toggleOpenProp={toggleOpenProp}
                placeholder="Select field"
                value={{ label: col.columnLabel, value: col.columnKey }}
                scrollableClassName={styles.scrollableContainer}
                onChange={onSelectSystemColumn(col)}
                isSearchable
                isReadOnly={isEdit || isDuplicateAudience}
                portal
              />
            );
          },
          className: isEdit ? '_editSystemFields' : '_createSystemFields',
        },
        {
          key: (col: any) => {
            if (col.isEmpty) {
              return null;
            }
            if (isDuplicateAudience || isEdit) {
              return <>{col?.selectedOption?.label}</>;
            }
            return (
              <Select
                className={styles.matchDropdown}
                value={col.selectedOption}
                options={fileColumns}
                toggleOpenProp={toggleOpenProp}
                scrollableClassName={styles.scrollableContainer}
                onChange={onSelectFileColumn(col.columnLabel)}
                isSearchable
                placeholder="Select match"
                isSearchClearable
                isReadOnly={isEdit || isDuplicateAudience}
                portal
                customSelectRenderValue={({ value }: any) => {
                  if (value) {
                    const maxLength = 98;
                    const str = value.label;
                    if (str.length > maxLength) {
                      const half = (maxLength - 3) / 2;
                      const strArray = str.split('');
                      return `${strArray.slice(0, half).join('')}...${strArray
                        .slice(str.length - half)
                        .join('')}`;
                    }
                    return str;
                  }
                  return '';
                }}
              />
            );
          },
          className: isEdit
            ? `_editUploadedFileColumns${isCheckboxSelected ? 'CheckBox' : ''}`
            : `_createUploadedFileColumns${isCheckboxSelected ? 'CheckBox' : ''}`,
        },
        ...(isCheckboxSelected
          ? [
              {
                key: (col: any) => {
                  if (col.isEmpty) {
                    return null;
                  }
                  if (isDuplicateAudience || isEdit) {
                    return <>{col?.selectedAdditionalSetting?.label || <>—</>}</>;
                  }
                  const additionalSettingRequiredRowIds = [
                    FIELD_KEYS.VOTER_ID,
                    FIELD_KEYS.EMAIL,
                    FIELD_KEYS.PHONE,
                    FIELD_KEYS.NPI_ID,
                  ];
                  if (col.columnKey && !additionalSettingRequiredRowIds.includes(col.columnKey)) {
                    return <div className="ml-2">—</div>;
                  }
                  return additionalSettingRequiredRowIds.includes(col.columnKey) ? (
                    <Select
                      className={styles.systemDropdown}
                      toggleOpenProp={toggleOpenProp}
                      options={
                        [FIELD_KEYS.VOTER_ID, FIELD_KEYS.NPI_ID].includes(col.columnKey)
                          ? getAdditionalSettingPartnerOptions(dataPartners, col.columnKey)
                          : getAdditionalSettingFormatOptions(dataFormats)
                      }
                      placeholder={
                        [FIELD_KEYS.VOTER_ID, FIELD_KEYS.NPI_ID].includes(col.columnKey)
                          ? 'Select Data Partner'
                          : 'Select Data Format'
                      }
                      value={col?.selectedAdditionalSetting ?? {}}
                      scrollableClassName={styles.scrollableContainer}
                      onChange={onSelectAdditionalSetting(col)}
                      isSearchable
                      isReadOnly={isEdit || isDuplicateAudience}
                      portal
                    />
                  ) : null;
                },

                className: isEdit ? '_editAdditionalFileColumns' : '_createAdditionalFileColumns',
              },
            ]
          : []),
        {
          key: (col: any) =>
            col.isEmpty ? null : (
              <div
                className={
                  isCheckboxSelected
                    ? styles.sampleDataWrapperCheckboxSelected
                    : styles.sampleDataWrapper
                }
              >
                <span className={styles.sampleData}>
                  {Array.isArray(col?.selectedOption?.data) ? (
                    <Tooltip
                      label={col.selectedOption.data.slice(0, 3).join(', ')}
                      portal
                      labelMaxWidth={400}
                    >
                      <div className={styles.tooltipText}>
                        {col.selectedOption.data.slice(0, 3).join(', ')}
                      </div>
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </span>
                {!isEdit && !isDuplicateAudience && data.length > (isCheckboxSelected ? 2 : 4) ? (
                  <ButtonCircle
                    iconName="Delete"
                    onClick={removeColumn(col)}
                    className={styles.fileRemove}
                  />
                ) : null}
              </div>
            ),

          className: isEdit
            ? `_editOnSampleData${isCheckboxSelected ? 'CheckBox' : ''}`
            : `_createOnSampleData${isCheckboxSelected ? 'CheckBox' : ''}`,
        },
      ],
      data,
    };
  }, [
    isCheckboxSelected,
    onSelectAdditionalSetting,
    isEdit,
    isDuplicateAudience,
    disableAddColumn,
    addColumn,
    columns,
    fileColumns,
    onSelectFileColumn,
    onSelectSystemColumn,
    removeColumn,
    dataPartners,
    dataFormats,
    columnsMapping,
    getAdditionalSettingPartnerOptions,
  ]);

  return (
    <>
      <Table
        className={styles.table}
        data={tableConfig.data}
        header={tableConfig.header}
        body={tableConfig.body}
        rowKeyExtractor={(data: any) => data.uniqueId}
        sorting={{
          field: 'index',
          direction: 'asc',
        }}
        tbodyRowHeight={40}
      />
    </>
  );
};
