import React from 'react';
import { connect } from 'react-redux';
import { Snackbar as FactorSnackbar } from 'factor';

import { AppState } from 'store';
import { snackbarActions, UpdateSnackbars } from 'store/snackbar/actions';
import { ToastMessage } from 'store/snackbar/reducers';

import styles from './styles.module.scss';

interface Props extends UpdateSnackbars {
  messages: ToastMessage[];
}

const SnackbarComponent = (props: Props) => {
  const { updateSnackbars, messages } = props;

  return (
    <FactorSnackbar
      containerClassName={styles.snackbarContainer}
      updateToasts={updateSnackbars}
      messages={messages}
    />
  );
};

const mapState = (state: AppState) => {
  return {
    messages: state.snackbar.messages,
  };
};

const mapActions = {
  updateSnackbars: snackbarActions.updateSnackbars,
};

export const Snackbar = connect(mapState, mapActions)(SnackbarComponent);
