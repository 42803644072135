import {
  ExistingLookalikeAudience,
  GeoPoliticalInfo,
  GeoPoliticalInfoErrors,
  SourceAudienceInfo,
} from 'models/LookalikeAudience';
import { AudienceResponse } from 'api/CommonAPI';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { lookalikeAudienceConstants } from './constants';

export interface LookalikeAudienceState {
  audienceName: string;
  submitInProgress: boolean;
  audienceInfo: ExistingLookalikeAudience | null;
  sourceAudienceInfo: SourceAudienceInfo | null;
  geoPoliticalInfo: GeoPoliticalInfo | null;
  geoPoliticalInfoErrors: GeoPoliticalInfoErrors | null;
}

const defaultLookalikeAudienceState: LookalikeAudienceState = {
  audienceName: '',
  submitInProgress: false,
  audienceInfo: null,
  sourceAudienceInfo: null,
  geoPoliticalInfo: null,
  geoPoliticalInfoErrors: null,
};

const startCreating = (state: LookalikeAudienceState): LookalikeAudienceState => {
  return {
    ...state,
    submitInProgress: true,
  };
};

const endCreating = (state: LookalikeAudienceState): LookalikeAudienceState => {
  return {
    ...state,
    submitInProgress: false,
  };
};

const setSourceAudienceInfo = (
  state: LookalikeAudienceState,
  action: Action<AudienceResponse[]>,
): LookalikeAudienceState => {
  return {
    ...state,
    sourceAudienceInfo: action.payload,
  };
};

const setAudienceInfo = (
  state: LookalikeAudienceState,
  action: Action<ExistingLookalikeAudience>,
): LookalikeAudienceState => {
  return {
    ...state,
    audienceInfo: action.payload,
  };
};

const setGeoPoliticalInfo = (
  state: LookalikeAudienceState,
  action: Action<GeoPoliticalInfo>,
): LookalikeAudienceState => {
  return {
    ...state,
    geoPoliticalInfo: action.payload,
  };
};

const setGeoPoliticalInfoErrors = (
  state: LookalikeAudienceState,
  action: Action<GeoPoliticalInfoErrors>,
): LookalikeAudienceState => {
  return {
    ...state,
    geoPoliticalInfoErrors: action.payload,
  };
};

const setAudienceName = (
  state: LookalikeAudienceState,
  action: Action<string>,
): LookalikeAudienceState => {
  return {
    ...state,
    audienceName: action.payload,
  };
};

const reset = (): LookalikeAudienceState => {
  return {
    ...defaultLookalikeAudienceState,
  };
};

const reducer = reducerFromMap<LookalikeAudienceState>(defaultLookalikeAudienceState, {
  [lookalikeAudienceConstants.START_CREATING]: startCreating,
  [lookalikeAudienceConstants.END_CREATING]: endCreating,
  [lookalikeAudienceConstants.SET_AUDIENCE_NAME]: setAudienceName,
  [lookalikeAudienceConstants.SET_GEO_POLITICAL_INFO]: setGeoPoliticalInfo,
  [lookalikeAudienceConstants.SET_GEO_POLITICAL_INFO_ERRORS]: setGeoPoliticalInfoErrors,
  [lookalikeAudienceConstants.SET_SOURCE_AUDIENCE_INFO]: setSourceAudienceInfo,
  [lookalikeAudienceConstants.SET_AUDIENCE_INFO]: setAudienceInfo,
  [lookalikeAudienceConstants.RESET]: reset,
});

export const lookalikeAudience = (
  state: LookalikeAudienceState = defaultLookalikeAudienceState,
  action: Action<any>,
) => reducer(state, action);
