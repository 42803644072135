import { NormalizedSegmentData } from '@applift/platform';

import {
  ExistingSegmentedAudience,
  SegmentedAudiencePartner,
  SegmentsList,
} from 'models/SegmentedAudience';
import { TSegmentGroup } from './CreateOrEditSegmentedAudience/types';

export const CostFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const extractAudiencePartner = (
  existingAudience: ExistingSegmentedAudience,
): SegmentedAudiencePartner => ({
  segmentPartnerId: existingAudience.segmentPartnerId,
  segmentPartnerName: existingAudience.segmentPartnerName,
  segmentPartnerLogo: existingAudience.segmentPartnerLogo,
});

export const equationToSegmentGroups = (
  existingAudience: ExistingSegmentedAudience,
): TSegmentGroup[] => {
  const REGEXP = /\(([^)]+)\)/g;
  const equation = existingAudience.equation.substring(1, existingAudience.equation.length - 1);
  const matches = equation.match(REGEXP);
  const relations = equation.replace(REGEXP, '').split('');
  const existingGroups: TSegmentGroup[] = [];

  if (Array.isArray(matches)) {
    for (let i = 0; i < matches.length; i += 1) {
      const groupEquation = matches[i];
      const groupsFromEquation = groupEquation
        .substring(1, groupEquation.length - 1)
        .split(/[|&]+/);
      const groupItems: NormalizedSegmentData[] = [];

      for (let j = 0; j < groupsFromEquation.length; j += 1) {
        const id = Number.parseInt(groupsFromEquation[j], 10);
        const match = existingAudience.segmentsList.find((sg: SegmentsList) => sg.segmentId === id);

        if (match) {
          groupItems.push({
            value: `segment-${match.segmentId}`,
            preservedValue: `${match.segmentId}`,
            label: match.taxonomyValue || '',
            description: match.description,
            parentPathKey: match.pathKey,
            id: match.segmentId,
            name: match.taxonomyValue || '',
            logoUrl: null,
            partnerLogoUrl: existingAudience.segmentPartnerLogo || null,
            type: 'segment',
            isLeaf: true,
            subRows: [],
            isPartnerPublic: null,
            partnerId: existingAudience.segmentPartnerId,
            totalReach: match.totalReach || null,
            cpm: match.cpm,
            isReachDataAvailable: match.dataAvailable || null,
            lastUpdatedDate: match.lastUpdatedDate
              ? Date.parse(match.lastUpdatedDate).valueOf()
              : null,
            customPartner: false,
            reachMetadata: match?.reachMetadata,
          });
        }
      }

      const segments = groupItems.reduce(
        (acc: Record<string, NormalizedSegmentData>, curr: NormalizedSegmentData) => {
          return { ...acc, [curr.value]: curr };
        },
        {} as Record<string, NormalizedSegmentData>,
      );

      if (relations?.length && relations[i - 1]) {
        existingGroups.push({
          segments,
          relation: relations[i - 1] === '&' ? 'AND' : 'OR',
        });
      } else {
        existingGroups.push({
          segments,
          relation: null,
        });
      }
    }
  }

  return existingGroups;
};

export const getSegmentEquation = (groups: TSegmentGroup[]): string => {
  const equation = groups.reduce((acc: string, group: TSegmentGroup, groupIndex: number) => {
    let res = acc;
    let groupEquation = '';
    const groupEntries = Object.entries(group?.segments || {}).filter((s) => !!s);

    if (groupEntries.length) {
      for (let i = 0; i < groupEntries.length; i += 1) {
        const segment = groupEntries[i];

        const relationBefore = groupEntries[i - 1] ? '|' : '';
        groupEquation += `${relationBefore}${segment[1].id}`;
      }

      if (groupEquation.length) {
        if (groups[groupIndex - 1]) {
          res += groups[groupIndex].relation === 'AND' ? '&' : '|';
        }

        res += `(${groupEquation})`;
      }
    }

    if (groupIndex === groups.length - 1) {
      res += ')';
    }
    return res;
  }, '(');

  return equation;
};

const recursivelyApplyANDRelations = (
  maxReachPerGroup: { reach: number | null; relation: TSegmentGroup['relation'] }[],
): { reach: number | null; relation: TSegmentGroup['relation'] }[] => {
  if (maxReachPerGroup.length <= 1) {
    return maxReachPerGroup;
  }

  if (maxReachPerGroup[1]?.relation === 'AND') {
    let reachResult: number | null = Math.min(
      maxReachPerGroup[0].reach || 0,
      maxReachPerGroup[1].reach || 0,
    );
    if (maxReachPerGroup[0].reach === null) {
      reachResult = maxReachPerGroup[1].reach;
    }
    if (maxReachPerGroup[1].reach === null) {
      reachResult = maxReachPerGroup[0].reach;
    }

    return recursivelyApplyANDRelations([
      {
        ...maxReachPerGroup[0],
        reach: reachResult,
      },
      ...maxReachPerGroup.slice(2),
    ]);
  }
  return [maxReachPerGroup[0], ...recursivelyApplyANDRelations(maxReachPerGroup.slice(1))];
};

export const estimateReach = (segmentGroups: TSegmentGroup[]) => {
  const maxReachPerGroup = segmentGroups
    .filter((sg) => Object.keys(sg || {}).length)
    .map((sg) => ({
      reach: Object.values(sg.segments || {}).length
        ? Math.max(...Object.values(sg.segments || {}).map((segment) => segment.totalReach || 0))
        : null,
      relation: sg.relation,
    }));

  const withAndRelationsApplied = recursivelyApplyANDRelations(maxReachPerGroup);
  const withNullANDTermsRemoved = withAndRelationsApplied.filter((term) => term?.reach !== null);

  if (!withNullANDTermsRemoved.length) {
    return null;
  }

  return withNullANDTermsRemoved.reduce((acc, curr) => Math.max(acc, curr.reach!), 0);
};
