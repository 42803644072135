import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppState } from 'store';
import { AudienceTypeId, TableAudienceItem } from 'models/Table';
import { applicationActions, OpenDialog } from 'store/app/actions';
import { TDialog } from 'store/app/reducer';
import { AudienceType } from 'api/Table';
import { routes } from 'routes';
import { CreateCampaignButton } from 'components/CreateCampaignButton';
import { AddToCampaignButton } from '../TableSelectedActions/AddToCampaignButton';

import styles from './styles.module.scss';

interface Props extends OpenDialog {
  tableTotalItems: number;
  loading: boolean;
  dialog: TDialog;
  audiencesByType: AudienceType[];
  selectedAudienceStatus: { [key in AudienceTypeId]: string };
  selectedAudiences: TableAudienceItem[];
}

const AudiencesHeaderComponent = (props: Props) => {
  const {
    tableTotalItems = 0,
    loading,
    openDialog,
    audiencesByType,
    selectedAudienceStatus,
    selectedAudiences,
  } = props;

  const location = useLocation();

  const [showAddToCampaign, setShowAddToCampaign] = useState<boolean>(false);

  useEffect(() => {
    const showAddToCampaignButton = !!(
      selectedAudiences.length > 0 &&
      !(
        selectedAudiences.filter((Audience) =>
          ['failed', 'rejected'].includes(Audience.status.toLowerCase()),
        ).length > 0
      )
    );
    setShowAddToCampaign(showAddToCampaignButton);
  }, [selectedAudiences]);

  const currentRoute = useMemo(() => {
    if (location.pathname.length === 1) {
      return routes.find(({ path }) => path === location.pathname);
    }

    return routes.find(({ path }) => {
      const pathLength = location.pathname.length;
      const routeLength = path.length;
      if (path !== '/' && pathLength > routeLength) {
        return location.pathname.substring(0, routeLength) === path;
      }
      return path === location.pathname;
    });
  }, [location.pathname]);

  const currentAudienceCount = useMemo(() => {
    const totalCount = audiencesByType.reduce((acc: number, prev) => {
      return acc + +prev.audienceCount;
    }, 0);
    let audienceCount = audiencesByType.find(
      (audience) => audience.audienceTypeId === Number(currentRoute?.value),
    )?.audienceCount;
    if (currentRoute?.value === '') {
      audienceCount = totalCount;
    }
    return audienceCount || 0;
  }, [currentRoute, audiencesByType]);

  const getAudienceCount = () => {
    const isAudienceFilteredByStatus = Object.values(selectedAudienceStatus).some(
      (status) => status !== '',
    );
    return isAudienceFilteredByStatus ? tableTotalItems : currentAudienceCount;
  };

  const currentTitle = currentRoute?.title;

  const closeCreateAudiencePopup = useCallback(() => {
    openDialog({});
  }, [openDialog]);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data === 'sessionExpired') {
        closeCreateAudiencePopup();
        window.location.href = '/#/login';
      }
    });
  }, [closeCreateAudiencePopup]);

  return (
    <header className={styles.header}>
      <h4 className="p3-demi">
        {currentTitle} {!loading && `(${getAudienceCount()})`}
      </h4>
      <div style={{ flexGrow: 1 }} />
      {showAddToCampaign && <AddToCampaignButton className="btn-square ml-auto _md" />}
      {!!selectedAudiences.length && (
        <CreateCampaignButton className={`btn-square _md ${styles.createCampaignBtn}`} />
      )}
    </header>
  );
};

const mapState = (state: AppState) => ({
  audiencesByType: state.table.audiencesByType,
  dialog: state.app.dialog,
  tableTotalItems: state.table.tableTotalItems,
  loading: state.table.loading,
  selectedAudienceStatus: state.table.selectedAudienceStatus,
  selectedAudiences: state.table.selectedAudiences,
});

const mapAction = {
  openDialog: applicationActions.openDialog,
};

export const AudiencesHeader = connect(mapState, mapAction)(AudiencesHeaderComponent);
