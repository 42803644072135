import React from 'react';

interface MatchedAudienceDialogContextInterface {
  setCreate: ({
    onClick,
    disabled,
    resetSetup,
  }: {
    onClick: () => void;
    disabled: boolean;
    resetSetup: () => void;
  }) => void;
  handleClose: () => void;
}

export const MatchedAudienceDialogContext = React.createContext<
  MatchedAudienceDialogContextInterface
>({
  setCreate: () => {},
  handleClose: () => {},
});
