import React from 'react';
import { TDialog } from 'store/app/reducer';
import GeofarmedAudienceDialog from '../CreateGeofarmedAudience/createGeofarmedAudience';

interface Props {
  dialog: TDialog;
  handleClose: () => void;
}

const DuplicateGeofarmedAudience = (props: Props) => {
  const { dialog, handleClose } = props;

  return (
    <GeofarmedAudienceDialog
      dialog={dialog}
      handleClose={handleClose}
      title="Geofarmed Audience"
      isDuplicate
    />
  );
};

export default DuplicateGeofarmedAudience;
