import React, { useCallback, useEffect, useState } from 'react';
import { ButtonCircle, TextField } from 'factor';
import debounce from 'lodash/debounce';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  setValue: (value: string) => void;
  value: string | undefined;
  className?: string;
  name?: string;
  placeholder?: string;
  setSearchOpen?: (isOpen: boolean) => void;
  variant?: string;
}

export const Search = (props: Props) => {
  const {
    className = '',
    name,
    placeholder,
    isOpen,
    setSearchOpen,
    value,
    setValue,
    variant,
  } = props;

  const [inputValue, setInputValue] = useState<string>(value || '');
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  // eslint-disable-next-line
  const setSearchField = useCallback(
    debounce((newValue: string) => {
      if (newValue !== value) {
        setValue(newValue);
      }
    }, 300),
    [value, setValue],
  );

  useEffect(() => {
    setSearchField(inputValue);
  }, [inputValue, setSearchField]);

  useEffect(() => {
    if (!isOpen) {
      setInputValue('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && inputRef) {
      inputRef.focus();
    }
  }, [isOpen, inputRef]);

  const toggleSearchOpen = useCallback(() => {
    if (setSearchOpen) {
      setSearchOpen(!isOpen);
    }
  }, [setSearchOpen, isOpen]);

  const onResetClick = () => {
    if (setSearchOpen) {
      toggleSearchOpen();
    }
    setInputValue('');
  };

  const getInputRef = useCallback((ref: HTMLInputElement) => {
    setInputRef(ref);
  }, []);

  return (
    <div className={`${styles.container} ${className}`}>
      {!isOpen ? (
        <ButtonCircle
          className={styles.btn}
          iconName="Search"
          iconClass={styles.icon}
          onClick={toggleSearchOpen}
        />
      ) : (
        <TextField
          className={styles.textField}
          inputRef={getInputRef}
          name={name || 'value'}
          placeholder={placeholder || 'Search by Name or ID'}
          value={inputValue}
          onChange={setInputValue}
          iconName="Search"
          variant={variant || 'withoutTickbox'}
          onResetClick={onResetClick}
          hasAlwaysResetButton
        />
      )}
    </div>
  );
};
