import {
  StickedOption,
  TableSortingParams,
  TableAudienceItem,
  AudienceTypeId,
  InsightsData,
} from '../../models/Table';
import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { tableConstants } from './constants';
import { getSortingColumnOptions } from '../../utils/table';
import { AudienceStatus, AudienceType } from '../../api/Table';

export interface TableState {
  selectedSortingColumns: { [key in AudienceTypeId]: StickedOption[] };
  searchText: string;
  searchOpen: boolean;
  selectedAudiences: TableAudienceItem[];
  sorting: TableSortingParams;
  deleteInProgress: boolean;
  tableData: TableAudienceItem[];
  audienceTypeIds: AudienceTypeId;
  audiencesByType: AudienceType[];
  statusList: AudienceStatus[];
  selectedAudienceStatus: { [key in AudienceTypeId]: string };
  loading: boolean;
  tableTotalItems: number;
  insightsData: InsightsData;
}

export const defaultTableState: TableState = {
  selectedSortingColumns: {
    '': getSortingColumnOptions(''),
    '1': getSortingColumnOptions('1'),
    '2': getSortingColumnOptions('2'),
    '3': getSortingColumnOptions('3'),
    '4': getSortingColumnOptions('4'),
    '5': getSortingColumnOptions('5'),
    '6': getSortingColumnOptions('6'),
    '7': getSortingColumnOptions('7'),
    '8': getSortingColumnOptions('8'),
    '9': getSortingColumnOptions('9'),
  },
  searchText: '',
  searchOpen: false,
  selectedAudiences: [],
  sorting: {
    field: 'createdOn',
    direction: 'desc',
  },
  deleteInProgress: false,
  tableData: [],
  audienceTypeIds: '',
  audiencesByType: [],
  statusList: [],
  selectedAudienceStatus: {
    '': '',
    '1': '',
    '2': '',
    '3': '',
    '4': '',
    '5': '',
    '6': '',
    '7': '',
    '8': '',
    '9': '',
  },
  loading: true,
  tableTotalItems: 0,
  insightsData: {},
};

function changeSortingColumns(
  state: TableState,
  action: Action<{ [key in AudienceTypeId]: StickedOption[] }>,
): TableState {
  return {
    ...state,
    selectedSortingColumns: action.payload,
  };
}

function updateSearchText(state: TableState, action: Action<string>): TableState {
  return {
    ...state,
    searchText: action.payload,
  };
}

function setSearchOpen(state: TableState, action: Action<boolean>): TableState {
  return {
    ...state,
    searchOpen: action.payload,
  };
}

function setSelectedAudiences(state: TableState, action: Action<any[]>): TableState {
  return {
    ...state,
    selectedAudiences: [...action.payload],
  };
}

function updateSorting(state: TableState, action: Action<TableSortingParams>): TableState {
  return {
    ...state,
    sorting: action.payload,
  };
}

function updateDeleteProgress(state: TableState, action: Action<boolean>): TableState {
  return {
    ...state,
    deleteInProgress: action.payload,
  };
}

function setTableData(state: TableState, action: Action<TableAudienceItem[]>): TableState {
  return {
    ...state,
    tableData: action.payload,
  };
}

function setTableTotalItems(state: TableState, action: Action<number>): TableState {
  return {
    ...state,
    tableTotalItems: action.payload,
  };
}

function updateAudienceName(
  state: TableState,
  action: Action<{ audienceId: number; audienceName: string }>,
): TableState {
  const { audienceId, audienceName } = action.payload;
  const { tableData } = state;

  const modifiedData = tableData.map((aud: TableAudienceItem) => {
    if (aud.id === audienceId) {
      return {
        ...aud,
        audienceName,
      };
    }
    return aud;
  });

  return {
    ...state,
    tableData: modifiedData,
  };
}

function setAudienceTypeIds(state: TableState, action: Action<AudienceTypeId>): TableState {
  return {
    ...state,
    audienceTypeIds: action.payload,
  };
}

function setAudiencesByType(state: TableState, action: Action<AudienceType[]>): TableState {
  return {
    ...state,
    audiencesByType: action.payload,
  };
}

function setAudiencesStatuses(state: TableState, action: Action<AudienceStatus[]>): TableState {
  return {
    ...state,
    statusList: action.payload,
  };
}

function setInsightsData(state: TableState, action: Action<InsightsData>): TableState {
  return {
    ...state,
    insightsData: { ...state.insightsData, ...action.payload },
  };
}

function setSelectedAudiencesStatuses(
  state: TableState,
  action: Action<{ [key in AudienceTypeId]: string }>,
): TableState {
  return {
    ...state,
    selectedAudienceStatus: action.payload,
  };
}

function setLoadingStatus(state: TableState, action: Action<boolean>) {
  return {
    ...state,
    loading: action.payload,
  };
}

const reducer = reducerFromMap<TableState>(defaultTableState, {
  [tableConstants.CHANGE_SORTING_COLUMNS]: changeSortingColumns,
  [tableConstants.SET_TABLE_DATA]: setTableData,
  [tableConstants.SET_TABLE_TOTAL_ITEMS]: setTableTotalItems,
  [tableConstants.SET_SELECTED_AUDIENCES]: setSelectedAudiences,
  [tableConstants.UPDATE_AUDIENCE_NAME]: updateAudienceName,
  [tableConstants.UPDATE_SEARCHTEXT]: updateSearchText,
  [tableConstants.SET_SEARCH_OPEN]: setSearchOpen,
  [tableConstants.UPDATE_SORTING]: updateSorting,
  [tableConstants.UPDATE_DELETE_PROGRESS]: updateDeleteProgress,
  [tableConstants.SET_AUDIENCE_TYPE_IDS]: setAudienceTypeIds,
  [tableConstants.SET_AUDIENCES_BY_TYPE]: setAudiencesByType,
  [tableConstants.SET_AUDIENCES_STATUSES]: setAudiencesStatuses,
  [tableConstants.SET_AUDIENCES_SELECTED_STATUSES]: setSelectedAudiencesStatuses,
  [tableConstants.SET_LOADING_STATUS]: setLoadingStatus,
  [tableConstants.SET_INSIGHTS_DATA]: setInsightsData,
});

export const table = (state: TableState = defaultTableState, action: Action<any>) =>
  reducer(state, action);
