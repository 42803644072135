import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PrebidNormalizedSegmentData } from '@applift/platform';

import { usePreBidAudienceInfo, useEditPreBidAudience } from 'hooks/usePreBidAudience';
import { TDialog } from 'store/app/reducer';
import { tableActions, UpdateSorting } from 'store/table/actions';
import { preBidAudienceActions } from 'store/preBidAudience/actions';
import {
  SORTING_VALUE,
  SORTING_DIRECTION,
  ALL_CATEGORY_CREATIVE_TYPE_IDS,
} from 'constants/audiences';
import { PreBidAudienceDialog } from '../components/PreBidAudienceDialog/index';

interface Props extends UpdateSorting {
  handleClose: () => void;
  dialog: TDialog;
  onSuccessfulAudienceEdit: (message: string) => void;
}

const EditPreBidAudienceComponent = (props: Props) => {
  const { dialog, handleClose, updateSorting, onSuccessfulAudienceEdit } = props;
  const { audienceId } = dialog;

  const [initialAudienceInfo, setInitialAudienceInfo] = React.useState<{
    selectedData: Record<string, PrebidNormalizedSegmentData>;
    audienceName: string;
  }>();

  const history = useHistory();

  const onSuccessfulEdit = (data: { message: string }) => {
    handleClose();
    onSuccessfulAudienceEdit(data.message);
    history.goBack();
    updateSorting({
      field: SORTING_VALUE.CREATED_ON,
      direction: SORTING_DIRECTION.DESC,
    });
  };

  const editPreBidAudience = useEditPreBidAudience({ onSuccess: onSuccessfulEdit });

  const audienceInfoQuery = usePreBidAudienceInfo(audienceId as number);

  React.useEffect(() => {
    if (audienceInfoQuery.data?.data) {
      const data = audienceInfoQuery.data.data.prebidSegmentData.map((one) => {
        return {
          value: `${one.prebidAudienceSegmentId}`,
          preservedValue: `${one.prebidAudienceSegmentId}`,
          isProvider: false,
          label: one.prebidAudienceSegmentName,
          description: one.description,
          parentPathKey: one.parentPathKey,
          cpm: one.cpm,
          creativeTypeIdList: one.creativeTypeIdList ?? [],
          creativeTypeIdSerialized: (one.creativeTypeIdList ?? []).sort().join(','),
          isLeaf: true,
          subRows: [],
          providerId: one.providerId,
          providerName: one.providerName,
          providerLogoUrl: one.providerLogoUrl,
          isAllCreativeType:
            (one.creativeTypeIdList ?? []).sort().join(',') === ALL_CATEGORY_CREATIVE_TYPE_IDS,
        } as PrebidNormalizedSegmentData;
      });
      const newSelectionObject: Record<string, PrebidNormalizedSegmentData> = {};
      data.forEach((item) => {
        newSelectionObject[item.preservedValue] = item;
      });
      setInitialAudienceInfo({
        selectedData: newSelectionObject,
        audienceName: audienceInfoQuery.data?.data?.name as string,
      });
    }
  }, [audienceInfoQuery.data]);

  return (
    <PreBidAudienceDialog
      dialog={dialog}
      initialAudienceInfo={initialAudienceInfo}
      handleClose={handleClose}
      title="Pre-bid Audience"
      isLoading={editPreBidAudience.isLoading}
      onCompletion={({ audienceName, ids }) => {
        editPreBidAudience.mutate({
          prebidAudienceName: audienceName,
          prebidAudienceSegmentIdList: ids,
          audienceId: audienceId as number,
        });
      }}
    />
  );
};

const mapAction = {
  updateSorting: tableActions.updateSorting,
  onSuccessfulAudienceEdit: preBidAudienceActions.onPreBidSuccessfulEdit,
};

export const EditPreBidAudience = connect(null, mapAction)(EditPreBidAudienceComponent);
