import * as React from 'react';
import moment from 'moment';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Col,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Row,
  SvgIcon,
  Tooltip,
  Typography,
  TypoTooltip,
} from '@applift/factor';
import {
  AudiencePreBid,
  CurrencyDollar,
  ExternalLink,
  InfoCircle,
  SemanticWarning,
} from '@applift/icons';

import { PrebidSegmentData } from 'models/PreBidAudience';
import { usePreBidAudienceInfo } from 'hooks/usePreBidAudience';
import { TDialog } from 'store/app/reducer';
import { ALL_CATEGORY_CREATIVE_TYPE_IDS, PRE_BID_AUDIENCE_TYPE_ID } from 'constants/audiences';

import styles from './index.module.scss';

interface Props {
  handleClose: () => void;
  dialog: TDialog;
}

export const ViewPreBidAudience = (props: Props) => {
  const { handleClose, dialog } = props;

  const audienceInfoQuery = usePreBidAudienceInfo(dialog.audienceId as number);
  const audienceInfo = audienceInfoQuery.data?.data;

  const differentCreativeGroupsSelected = React.useMemo(() => {
    let leastLengthCreativeTypeIds = [11, 13, 14];
    audienceInfo?.prebidSegmentData?.forEach((obj) => {
      leastLengthCreativeTypeIds =
        (obj.creativeTypeIdList as number[]).length < leastLengthCreativeTypeIds.length
          ? (obj.creativeTypeIdList as number[])
          : leastLengthCreativeTypeIds;
    });
    return !audienceInfo?.prebidSegmentData.every((data) =>
      leastLengthCreativeTypeIds.every((item) => data.creativeTypeIdList?.includes(item)),
    );
  }, [audienceInfo?.prebidSegmentData]);

  const groupedData: { [key: string]: PrebidSegmentData[] } = {};
  audienceInfo?.prebidSegmentData.forEach((obj) => {
    if (!groupedData[`${obj.parentPathKey}${obj.providerId}`]) {
      groupedData[`${obj.parentPathKey}${obj.providerId}`] = [];
    }
    groupedData[`${obj.parentPathKey}${obj.providerId}`].push(obj);
  });
  const segmentsGroupedByParentPathKey = Object.values(groupedData);

  return (
    <Dialog
      open={dialog?.audienceTypeId === PRE_BID_AUDIENCE_TYPE_ID}
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { height: 100 } }}
    >
      <DialogTitle onClose={handleClose}>Pre-bid Audience</DialogTitle>
      <DialogContent dividers sx={{ height: 100, overflowY: 'hidden' }}>
        {audienceInfoQuery.isLoading || !audienceInfo ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              width: 100,
              height: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress size={100} />
          </Box>
        ) : (
          <Box sx={{ height: 100, overflowY: 'hidden' }}>
            <Row
              sx={{
                width: 100,
                mb: 12,
              }}
            >
              <Col
                xs={6}
                sx={{ height: 100, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 24, gap: 8 }}>
                  <AudiencePreBid fontSize={24} />
                  <TypoTooltip TypgraphyProps={{ sx: { textWeight: 'demi', fontSize: 18 } }}>
                    {audienceInfo.name}
                  </TypoTooltip>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 24 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }} className={styles.infoItem}>
                    <Typography variant="label" sx={{ fontSize: 16, mr: 4 }}>
                      Created on:
                    </Typography>
                    <Typography>
                      {moment.unix(audienceInfo.createdOn).format('MM/DD/yyyy')}
                    </Typography>
                  </Box>
                  {!differentCreativeGroupsSelected && audienceInfo.cpm && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
                      className={styles.infoItem}
                    >
                      <Typography
                        variant="label"
                        sx={{ fontSize: 16, mr: 4, textColor: 'neutral-600' }}
                      >
                        Cost (CPM):
                      </Typography>
                      <Typography>{`$${audienceInfo.cpm}`}</Typography>
                      <Tooltip
                        title="This represents the overall cost for all the segments you've selected across all categories."
                        placement="top"
                        arrow
                      >
                        <InfoCircle fontSize={20} sx={{ textColor: 'neutral-400', pb: 2 }} />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Col>
              {differentCreativeGroupsSelected && (
                <Col xs={6}>
                  <Alert severity="secondary">
                    Total cost couldn’t be calculated as added segments are from different creative
                    types.
                  </Alert>
                </Col>
              )}
            </Row>

            <Row sx={{ height: 100, overflowY: 'hidden' }}>
              <Col
                xs={12}
                sx={{
                  height: 75,
                  overflowY: 'hidden',
                  p: 16,
                }}
              >
                <Box
                  sx={{
                    overflowY: 'auto',
                    height: 'auto',
                    maxHeight: 100,
                    borderRadius: 4,
                    border: 1,
                  }}
                >
                  {segmentsGroupedByParentPathKey &&
                    segmentsGroupedByParentPathKey?.map((group, groupIdx) => (
                      <Box
                        sx={{
                          borderBottom:
                            groupIdx === segmentsGroupedByParentPathKey.length - 1 ? 0 : 1,
                          borderColor: 'neutral-200',
                          pt: 16,
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, mb: 4, px: 16 }}>
                          <Box sx={{ p: 8 }}>
                            <Avatar
                              size="small"
                              variant="square"
                              src={group[0].providerLogoUrl as string}
                              alt={group[0].providerName}
                              imgProps={{
                                style: { objectFit: 'cover' },
                              }}
                            />
                          </Box>
                          <Typography weight="bold" style={{ fontWeight: 600 }}>
                            {group[0].parentPathKey}
                          </Typography>
                          <Chip
                            label={
                              <Typography weight="normal">{`${Math.max(
                                ...group.map((segment: { cpm: number }) => segment.cpm ?? 0),
                              ).toFixed(2)} (CPM)`}</Typography>
                            }
                            color="secondary"
                            icon={<CurrencyDollar />}
                            size="small"
                          />
                          {differentCreativeGroupsSelected &&
                            group[0].creativeTypeIdList.sort().join(',') !==
                              ALL_CATEGORY_CREATIVE_TYPE_IDS && (
                              <Tooltip
                                title="Only one creative type (Display or Video) is supported per campaign; So, avoid grouping respective pre-bid segments in one audience."
                                placement="top"
                                arrow
                              >
                                <SemanticWarning color="warning" fontSize={24} />
                              </Tooltip>
                            )}
                          <Tooltip
                            title="This signifies the total cost for all the segments you've chosen within this specific category."
                            placement="top"
                            arrow
                          >
                            <InfoCircle fontSize={20} sx={{ textColor: 'neutral-400' }} />
                          </Tooltip>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {group.map((item: any) => (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'between',
                                width: 100,
                                mb: 16,
                                pl: 56,
                              }}
                            >
                              <TypoTooltip>{item.prebidAudienceSegmentName}</TypoTooltip>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Col>
            </Row>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'between' }}>
        <Link
          /* @ts-ignore */
          target="_blank"
          href="https://help.iqm.com/en/articles/8698600-fraud-viewability-and-brand-safety-pre-bid-targeting"
        >
          <SvgIcon fontSize={20} sx={{ mr: 8 }}>
            <ExternalLink />
          </SvgIcon>
          <Typography> Learn more about pre-bid segments</Typography>
        </Link>
        <Box>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Done
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
