import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'factor';
import { datadogRum } from 'iqm-framework';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import { applicationActions, ResetSidebar } from 'store/app/actions';
import { AppState } from 'store';
import { TDialog } from 'store/app/reducer';
import StepIndicator from 'components/StepIndicator';
import { CreateLookalikeAudienceData } from 'models/LookalikeAudience';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { tableActions, UpdateSorting } from 'store/table/actions';
import { CreateLookalikeAudience, lookalikeAudienceActions } from 'store/lookalikeAudience/actions';
import { SORTING_VALUE, SORTING_DIRECTION } from 'constants/audiences';
import GeoExpansionAndPoliticalInformation from '../GeoExpansionAndPoliticalInformation';
import LookalikeAudienceSelection from '../LookalikeAudienceSelection';

import styles from './styles.module.scss';

interface Props extends ResetSidebar, CreateLookalikeAudience, OpenSnackbar, UpdateSorting {
  handleClose: () => void;
  dialog: TDialog;
  title: string;
  submitInProgress: boolean;
  existingAudienceName?: string;
  updateLookalikeAudienceName: (audienceName: string, audienceId: number) => Promise<void>;
  resetLookalikeAudience: () => void;
}

type Options = {
  label: string;
  value: number;
  reactLabel: JSX.Element;
  matchRate: string;
  uniques: string;
  audienceName: string;
  created: string;
};

const LookalikeAudienceDialogComponent = (props: Props) => {
  const {
    handleClose,
    resetSidebar,
    dialog,
    title,
    createLookalikeAudience,
    submitInProgress,
    openSnackbar,
    existingAudienceName,
    updateLookalikeAudienceName,
    resetLookalikeAudience,
    updateSorting,
  } = props;

  const history = useHistory();
  const [step, setStep] = useState<1 | 2>(1);
  const [audienceName, setAudienceName] = useState<string>('');
  const [selectedAudiences, setSelectedAudiences] = useState<Options[]>([]);
  const [createAudienceInfo, setCreateAudienceInfo] = useState<
    Partial<CreateLookalikeAudienceData>
  >({});
  const [isAudienceNameValid, setNameValidity] = useState<boolean>(false);
  const sourceAudienceIds = useRef('');
  const isEdit = dialog.type === 'edit';
  const isDuplicate = dialog.type === 'duplicate';
  const { audienceId } = dialog;
  const isAudienceNameEdited = useRef(false);

  const resetAndClose = useCallback(() => {
    handleClose();
    resetSidebar();
    resetLookalikeAudience();
    if (isEdit) {
      history.goBack();
    }
  }, [handleClose, resetSidebar, history, isEdit, resetLookalikeAudience]);

  useEffect(() => {
    sourceAudienceIds.current = selectedAudiences?.length
      ? selectedAudiences.map((audience) => audience.value).join(',')
      : '';
  }, [selectedAudiences]);

  useEffect(() => {
    if (isEdit && existingAudienceName) {
      setAudienceName(existingAudienceName);
    }
    if (isDuplicate && existingAudienceName && !isAudienceNameEdited.current) {
      setAudienceName(`Copy of ${existingAudienceName}`);
      isAudienceNameEdited.current = true;
    }
  }, [isEdit, existingAudienceName, isDuplicate]);

  const actionButtons = useMemo(
    () =>
      step === 1
        ? [
            {
              variant: 'secondary',
              title: 'Cancel',
              handler: resetAndClose,
            },
            {
              title: 'Next',
              handler: () => setStep(2),
              disabled: !(isAudienceNameValid && selectedAudiences.length),
            },
          ]
        : [
            {
              variant: 'secondary',
              title: 'Cancel',
              handler: () => setStep(1),
            },
            {
              title: isEdit ? 'Save' : 'Create',
              handler: async () => {
                if (isEdit) {
                  if (isAudienceNameEdited.current) {
                    updateLookalikeAudienceName(audienceName, audienceId as number);
                  }
                  resetAndClose();
                } else if (createAudienceInfo) {
                  const {
                    countryIds,
                    stateIds,
                    senateDistrictIds,
                    congressionalDistrictIds,
                    houseDistrictIds,
                    cityIds,
                    countyIds,
                    audienceSize,
                  } = createAudienceInfo;
                  if (!audienceSize) {
                    openSnackbar({
                      message: 'Please select audience size numbers between 1 and 10 inclusive.',
                      type: 'error',
                    });
                    datadogRum.addAction('Creation of Lookalike Audience failed', {
                      message: 'Incorrect audience size number',
                    });
                    return;
                  }
                  await createLookalikeAudience({
                    audienceName,
                    sourceAudienceIds: sourceAudienceIds.current,
                    audienceSize: audienceSize as number,
                    countryIds: countryIds as string,
                    stateIds: stateIds as string,
                    ...(senateDistrictIds && { senateDistrictIds }),
                    ...(congressionalDistrictIds && { congressionalDistrictIds }),
                    ...(houseDistrictIds && { houseDistrictIds }),
                    ...(cityIds && { cityIds }),
                    ...(countyIds && { countyIds }),
                  });
                  updateSorting({
                    field: SORTING_VALUE.CREATED_ON,
                    direction: SORTING_DIRECTION.DESC,
                  });
                  resetAndClose();
                }
              },
              disabled: !(
                createAudienceInfo.countryIds &&
                createAudienceInfo.stateIds &&
                !submitInProgress &&
                (!isEdit || isAudienceNameEdited.current)
              ),
              ...(!isEdit ? { 'data-dd-action-name': 'Create Lookalike Audience' } : {}),
            },
          ],
    [
      resetAndClose,
      updateSorting,
      step,
      createAudienceInfo,
      audienceName,
      sourceAudienceIds,
      createLookalikeAudience,
      submitInProgress,
      isAudienceNameValid,
      selectedAudiences,
      openSnackbar,
      isEdit,
      audienceId,
      updateLookalikeAudienceName,
    ],
  );

  return (
    <Dialog
      dialogTitle={title}
      open={!isEmpty(dialog)}
      crossButton
      headerFooterBorders
      className={styles.container}
      onCrossButtonClick={resetAndClose}
      actionButtons={actionButtons}
      footerLeft={<StepIndicator step={step} maxStep={2} />}
    >
      {step === 1 ? (
        <LookalikeAudienceSelection
          setAudienceName={setAudienceName}
          audienceName={audienceName}
          setNameValidity={setNameValidity}
          selectedAudiences={selectedAudiences}
          setSelectedAudiences={setSelectedAudiences}
          isAudienceNameEdited={isAudienceNameEdited}
          isEdit={isEdit}
          isDuplicate={isDuplicate}
        />
      ) : (
        <GeoExpansionAndPoliticalInformation
          setCreateAudienceInfo={setCreateAudienceInfo}
          isEdit={isEdit}
          isDuplicate={isDuplicate}
        />
      )}
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  submitInProgress: state.lookalikeAudience.submitInProgress,
  existingAudienceName: state.lookalikeAudience.audienceName,
});

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  createLookalikeAudience: lookalikeAudienceActions.createLookalikeAudience,
  updateLookalikeAudienceName: lookalikeAudienceActions.updateLookalikeAudienceName,
  resetLookalikeAudience: lookalikeAudienceActions.reset,
  openSnackbar: snackbarActions.openSnackbar,
  updateSorting: tableActions.updateSorting,
};

const LookalikeAudienceDialog = connect(mapState, mapAction)(LookalikeAudienceDialogComponent);

export default LookalikeAudienceDialog;
