import { Dispatch, AnyAction } from 'redux';
import get from 'lodash/get';
import { ThunkDispatch } from 'redux-thunk';

import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import {
  CitiesInfo,
  CongressionalDistrictsInfo,
  CountiesInfo,
  CountryInfo,
  CreateLookalikeAudienceData,
  ExistingLookalikeAudience,
  GeoPoliticalInfo,
  GeoPoliticalInfoErrors,
  SenatesInfo,
  StateHousesInfo,
  StatesInfo,
} from 'models/LookalikeAudience';
import { AudienceResponse, getAudienceList } from 'api/CommonAPI';
import { applicationActions } from 'store/app/actions';
import { setRowAnimation } from 'styles/tableRowAnimation';
import { RUMLogger } from 'services/RUMLogger';
import { getApiErrorMsg } from 'services/RUMLogger/utils';
import { SOMETHING_WENT_WRONG_MSG } from 'constants/errors';
import { EMPTY_SELECTED_AUDIENCE_STATUS } from 'constants/audiences';

import { createAction } from '../../utils/actions';
import { API } from '../../api';
import { lookalikeAudienceConstants } from './constants';
import { AppState } from '..';
import { tableConstants } from '../table/constants';
import { tableActions } from '../table/actions';
import { snackbarActions } from '../snackbar/actions';

export interface CreateLookalikeAudience {
  createLookalikeAudience: (data: CreateLookalikeAudienceData) => void;
}

export interface GetLookalikeAudience {
  getLookalikeAudience: (
    audienceId: number,
    isRenderedByIFrame: boolean,
    rejectOnError?: boolean,
  ) => Promise<void>;
}

export interface UpdateLookalikeAudienceName {
  updateLookalikeAudienceName: (audienceName: string, audienceId: number) => Promise<void>;
}

export const lookalikeAudienceActions = {
  createLookalikeAudience(createAudienceData: CreateLookalikeAudienceData) {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(lookalikeAudienceConstants.START_CREATING));
      try {
        const response = await API.LookalikeAudience.createLookalikeAudience(createAudienceData);
        const message = response.data?.data?.message;

        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(
          createAction(
            tableConstants.SET_AUDIENCES_SELECTED_STATUSES,
            EMPTY_SELECTED_AUDIENCE_STATUS,
          ),
        );
        createAction<void>(lookalikeAudienceConstants.RESET);
        RUMLogger.createLookalikeAudience({
          success: true,
          latency: response.data?.latency,
          statusCode: response.status,
          createAudienceData,
        });
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
        setRowAnimation();
      } catch (e) {
        RUMLogger.createLookalikeAudience({
          success: false,
          // @ts-ignore
          latency: e?.data?.latency,
          // @ts-ignore
          statusCode: e?.status,
          // @ts-ignore
          errorMsg: getApiErrorMsg(e),
          createAudienceData,
        });

        const message = get(e, 'data.errorObjects[0].error', e);
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(lookalikeAudienceConstants.RESET));
        dispatch(createAction<void>(lookalikeAudienceConstants.END_CREATING));
      }
    };
  },
  updateLookalikeAudienceName(audienceName: string, audienceId: number) {
    return async (dispatch: Dispatch) => {
      try {
        const res = await API.LookalikeAudience.updateLookalikeAudienceName(
          audienceName,
          audienceId,
        );
        if (res && res.message) {
          dispatch(
            snackbarActions.openSnackbar({
              message: res.message,
              type: 'success',
            }),
          );
        }
        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }
        createAction<void>(lookalikeAudienceConstants.RESET);
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(applicationActions.resetSidebar());
      } catch (e) {
        dispatch(
          snackbarActions.openSnackbar({
            message: get(e, 'errorObjects[0].error', e),
            type: 'error',
          }),
        );
      }
    };
  },
  getLookalikeAudience(audienceId: number, isRenderedByIFrame: boolean, rejectOnError = true) {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(
          createAction<null>(lookalikeAudienceConstants.SET_GEO_POLITICAL_INFO_ERRORS, null),
        );
        const response = await API.LookalikeAudience.getLookalikeAudienceInfo(audienceId);
        if (response && response.data) {
          const audienceInfo: ExistingLookalikeAudience = response.data;
          dispatch(
            createAction<string>(lookalikeAudienceConstants.SET_AUDIENCE_NAME, audienceInfo.name),
          );

          dispatch(
            createAction<ExistingLookalikeAudience>(
              lookalikeAudienceConstants.SET_AUDIENCE_INFO,
              audienceInfo,
            ),
          );

          const sourceAudienceResponse = await getAudienceList(
            '1',
            audienceInfo.sourceAudienceIds,
            -1,
            isRenderedByIFrame,
          );
          if (sourceAudienceResponse && sourceAudienceResponse.data) {
            dispatch(
              createAction<AudienceResponse[]>(
                lookalikeAudienceConstants.SET_SOURCE_AUDIENCE_INFO,
                sourceAudienceResponse.data,
              ),
            );
          }

          const geoPoliticalInfo: Partial<GeoPoliticalInfo> = {};
          const getNumberOfEntries = (ids: string) => ids.split(',').length;
          const fieldsToMap = [
            {
              field: 'country',
              api: API.LookalikeAudience.getCountryInfo(
                audienceInfo.countryIds,
                1,
                getNumberOfEntries(audienceInfo.countryIds),
              ),
            },
            {
              field: 'states',
              api: API.LookalikeAudience.getStatesInfo(
                audienceInfo.stateIds,
                1,
                getNumberOfEntries(audienceInfo.stateIds),
              ),
            },
            ...(audienceInfo.cityIds
              ? [
                  {
                    field: 'cities',
                    api: API.LookalikeAudience.getCitiesInfo(
                      audienceInfo.cityIds,
                      1,
                      getNumberOfEntries(audienceInfo.cityIds),
                    ),
                  },
                ]
              : []),
            ...(audienceInfo.countyIds
              ? [
                  {
                    field: 'counties',
                    api: API.LookalikeAudience.getCountiesInfo(
                      audienceInfo.countyIds,
                      1,
                      getNumberOfEntries(audienceInfo.countyIds),
                    ),
                  },
                ]
              : []),
            ...(audienceInfo.houseDistrictIds
              ? [
                  {
                    field: 'stateHouses',
                    api: API.LookalikeAudience.getStateHousesInfo(
                      audienceInfo.houseDistrictIds,
                      1,
                      getNumberOfEntries(audienceInfo.houseDistrictIds),
                    ),
                  },
                ]
              : []),
            ...(audienceInfo.congressionalDistrictIds
              ? [
                  {
                    field: 'congressionalDistricts',
                    api: API.LookalikeAudience.getCongressionalDistrictsInfo(
                      audienceInfo.congressionalDistrictIds,
                      1,
                      getNumberOfEntries(audienceInfo.congressionalDistrictIds),
                    ),
                  },
                ]
              : []),
            ...(audienceInfo.senateDistrictIds
              ? [
                  {
                    field: 'senates',
                    api: API.LookalikeAudience.getSenatesInfo(
                      audienceInfo.senateDistrictIds,
                      1,
                      getNumberOfEntries(audienceInfo.senateDistrictIds),
                    ),
                  },
                ]
              : []),
          ];
          const geoPoliticalInfoResponses = await Promise.allSettled(
            fieldsToMap.map((field) => field.api),
          );
          const geoPoliticalInfoErrors: GeoPoliticalInfoErrors = {};
          geoPoliticalInfoResponses.forEach((result, idx) => {
            const { value: res, status, reason } = result as any;
            const { field } = fieldsToMap[idx];
            if (status === 'fulfilled') {
              switch (field) {
                case 'country':
                  geoPoliticalInfo.country = res?.data || ([] as CountryInfo[]);
                  break;
                case 'states':
                  geoPoliticalInfo.states = res?.data || ([] as StatesInfo[]);
                  break;
                case 'cities':
                  geoPoliticalInfo.cities = res?.data || ([] as CitiesInfo[]);
                  break;
                case 'counties':
                  geoPoliticalInfo.counties = res?.data || ([] as CountiesInfo[]);
                  break;
                case 'stateHouses':
                  geoPoliticalInfo.stateHouses = res?.data || ([] as StateHousesInfo[]);
                  break;
                case 'congressionalDistricts':
                  geoPoliticalInfo.congressionalDistricts =
                    res?.data || ([] as CongressionalDistrictsInfo[]);
                  break;
                case 'senates':
                  geoPoliticalInfo.senates = res?.data || ([] as SenatesInfo[]);
                  break;
                default:
                  break;
              }
              geoPoliticalInfoErrors[field as keyof GeoPoliticalInfoErrors] = false;
            } else {
              const message =
                get(reason, 'responseObject.errorMsg') ||
                get(reason, 'errorObjects[0].error') ||
                SOMETHING_WENT_WRONG_MSG;
              dispatch(
                snackbarActions.openSnackbar({
                  message,
                  type: 'error',
                }),
              );
              geoPoliticalInfoErrors[field as keyof GeoPoliticalInfoErrors] = true;

              if (rejectOnError) {
                dispatch(createAction<void>(lookalikeAudienceConstants.RESET));
                throw reason;
              }
            }
          });
          geoPoliticalInfo.audienceSize = audienceInfo.audienceSize;
          dispatch(
            createAction<GeoPoliticalInfoErrors>(
              lookalikeAudienceConstants.SET_GEO_POLITICAL_INFO_ERRORS,
              geoPoliticalInfoErrors,
            ),
          );
          dispatch(
            createAction<GeoPoliticalInfo>(
              lookalikeAudienceConstants.SET_GEO_POLITICAL_INFO,
              geoPoliticalInfo as GeoPoliticalInfo,
            ),
          );
        }
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    };
  },
  reset() {
    return createAction<void>(lookalikeAudienceConstants.RESET);
  },
};
