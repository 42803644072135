export const pluralize = (
  value: string,
  count: number,
  { single = '', multiple = '' } = {},
): string => {
  if (count <= 1) {
    return single || value;
  }
  return multiple || `${value}s`;
};
