import React from 'react';
import { Layout, LayoutProps, sx } from '@applift/factor';
import { TDialog } from 'store/app/reducer';
import { AudienceBreakdown } from '../AudienceBreakdown';

interface Props {
  dialog: TDialog;
  showGraphsPlaceholder: boolean;
  children: LayoutProps['children'];
}

export const DialogLayout = (props: Props) => {
  const { dialog, showGraphsPlaceholder, children } = props;

  return (
    <Layout
      defaultOpen
      defaultSidebarWidth={270}
      hideResizerHandle
      sidebarAnchor="right"
      sidebar={
        <AudienceBreakdown
          showPlaceholder={showGraphsPlaceholder}
          audienceId={dialog.audienceId}
          audienceStatus={dialog.status}
        />
      }
      classes={{
        root: sx({ maxHeight: 100, overflow: 'hidden' }),
        content: sx({ pt: 32, maxHeight: 100, pr: 24, overflow: 'hidden' }),
        sidebarContent: sx({ pt: 32, pl: 24, pr: 24, bgColor: 'neutral-0' }),
        toggleButton: sx({ marginTop: 32 }),
      }}
    >
      {children}
    </Layout>
  );
};
