import React from 'react';
import { TDialog } from 'store/app/reducer';
import RetargetedAudienceDialog from '../components/RetargetedAudienceDialog';

interface Props {
  dialog: TDialog;
  handleClose: () => void;
}

const CreateRetargetedAudience = (props: Props) => {
  const { dialog, handleClose } = props;

  return (
    <RetargetedAudienceDialog
      dialog={dialog}
      handleClose={handleClose}
      title="Retargeted Audience"
    />
  );
};

export default CreateRetargetedAudience;
