import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Layout as FactorLayout } from 'factor';
import { applicationActions } from '../../store/app/actions';
import { AppState } from '../../store';

interface Props {
  children: React.ReactNode;
  isSidebarOpened: boolean;
  toggleSidebar: () => void;
  sidebar: React.ReactNode;
}

class LayoutComponent extends PureComponent<Props> {
  render() {
    const { children, isSidebarOpened, toggleSidebar, sidebar } = this.props;

    return (
      <FactorLayout opened={isSidebarOpened} toggleSidebar={toggleSidebar} sidebar={sidebar}>
        {children}
      </FactorLayout>
    );
  }
}

const mapState = (state: AppState) => ({
  isSidebarOpened: state.app.isSidebarOpened,
});

const mapActions = {
  toggleSidebar: applicationActions.toggleSidebar,
};

export const Layout = connect(mapState, mapActions)(LayoutComponent);
