import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Checkbox, Radio, Tooltip } from 'factor';

import CampaignNameWithIcons from 'components/CampaignNameWithIcons';
import { ICampaign } from 'api/AddToCampaign';
import { Option } from 'models/Option';
import { nFormatter } from 'utils/formatCredit';

import styles from './styles.module.scss';

export interface ICampaignInformation {
  length: void;
  id: number;
  name: string;
  status: string;
  clicks: number;
  impression: number;
  conversionCount: number;
  uniqueCount: number;
}

export interface ICampaignAttributeSelection {
  impressions: number[];
  clicks: number[];
  conversions: number[];
}

interface ICampaignInformationWithOption extends ICampaign, Option<number> {
  isSelected: boolean;
}
type Props = {
  selectedCampaigns: ICampaignInformationWithOption[];
  setSelectedCampaigns: (d: React.SetStateAction<ICampaignInformationWithOption[]>) => void;
  cmpAttributeSelection: ICampaignAttributeSelection;
  setCmpAttributeSelection: (d: React.SetStateAction<ICampaignAttributeSelection>) => void;
  isView: boolean;
  isEdit: boolean;
};

const CampaignsTable = (props: Props) => {
  const {
    selectedCampaigns,
    setSelectedCampaigns,
    cmpAttributeSelection,
    setCmpAttributeSelection,
    isView,
    isEdit,
  } = props;

  const [tableConfig, setTableConfig] = useState<{
    header: unknown[];
    body: unknown[];
    data: unknown[];
  }>({
    header: [],
    body: [],
    data: [],
  });

  const isAllSelected = useMemo(() => selectedCampaigns.every((app) => app.isSelected), [
    selectedCampaigns,
  ]);

  const selectDeselectAll = useCallback(() => {
    const newValue = !isAllSelected;
    setSelectedCampaigns((oldCustomers) =>
      Array.isArray(oldCustomers)
        ? oldCustomers.map((customer) => ({ ...customer, isSelected: newValue }))
        : oldCustomers,
    );
  }, [isAllSelected, setSelectedCampaigns]);

  const handleCheckboxChange = useCallback(
    (e) => (id: number) => {
      const processedArr = selectedCampaigns.map((campaign) => {
        if (campaign.id === id) {
          return { ...campaign, isSelected: e };
        }
        return campaign;
      });
      setSelectedCampaigns([...processedArr]);
    },
    [selectedCampaigns, setSelectedCampaigns],
  );

  const rowClickHandler = (e: any, data: any) => {
    const { tagName } = e.target;
    const elType = e.target.getAttribute('type');

    if (tagName === 'INPUT' && elType === 'checkbox') {
      const processedCampaigns = selectedCampaigns.map((campaign) => {
        if (campaign.id === data.id) {
          return { ...campaign, isSelected: !campaign.isSelected };
        }
        return campaign;
      });
      setSelectedCampaigns(processedCampaigns);
    }
  };

  const loadTableData = useCallback(
    (campaigns: ICampaignInformationWithOption[]) => {
      if (campaigns.length) {
        setTableConfig({
          header: [
            {
              label: (
                <Checkbox
                  disabled={isView || isEdit}
                  onChange={() => selectDeselectAll()}
                  checked={isAllSelected}
                />
              ),
              className: 'w-60-60 _checkbox',
            },
            { label: 'ID', sortingKey: 'id', className: 'w-100-100' },
            { label: 'Campaign Name', sortingKey: 'campaignName', className: 'w-5-5' },
            { label: 'Impressions', sortingKey: 'impression', className: 'w-60-60' },
            { label: 'Clicks', sortingKey: 'clicks', className: 'w-60-60' },
            { label: 'Conversions', sortingKey: 'conversion', className: 'w-60-60' },
          ],
          body: [
            {
              key: (d: ICampaignInformationWithOption) => (
                <Checkbox
                  onChange={() => handleCheckboxChange(d.id)}
                  checked={d.isSelected}
                  name={`checkbox-id-${d.id}`}
                  disabled={isView || isEdit}
                />
              ),
              className: `_checkbox ${styles.checkboxColumn}`,
            },
            {
              key: (item: ICampaignInformationWithOption) => item.id,
              className: `${styles.idColumn}`,
            },
            {
              key: (item: ICampaignInformationWithOption) => (
                <span className={styles[item.status.toLowerCase()]}>
                  <CampaignNameWithIcons
                    creativeType={item.creativeType || ''}
                    status={item.status}
                  >
                    <Tooltip label={item.name} className={styles.toolTip} portal>
                      <span className={styles.name}>{item.name}</span>
                    </Tooltip>
                  </CampaignNameWithIcons>
                </span>
              ),
              className: styles.campaignName,
            },
            {
              key: (item: ICampaignInformationWithOption) => (
                <RadioWithVal
                  isView={isView}
                  isEdit={isEdit}
                  cmpAttributeSelection={cmpAttributeSelection}
                  setCmpAttributeSelection={setCmpAttributeSelection}
                  item={item}
                  attribute="IMPRESSIONS"
                >
                  <>
                    {nFormatter(item.impression, 2)
                      .toString()
                      .replace('$', '')}
                  </>
                </RadioWithVal>
              ),
              className: `w-120-120 ${styles.impression}`,
            },
            {
              key: (item: ICampaignInformationWithOption) => (
                <div className="d-flex justify-content-between align-items-center">
                  <RadioWithVal
                    isView={isView}
                    isEdit={isEdit}
                    cmpAttributeSelection={cmpAttributeSelection}
                    setCmpAttributeSelection={setCmpAttributeSelection}
                    item={item}
                    attribute="CLICKS"
                  >
                    <>
                      {nFormatter(item.clicks, 2)
                        .toString()
                        .replace('$', '')}
                    </>
                  </RadioWithVal>
                </div>
              ),
              className: `w-120-120 ${styles.clicks}`,
            },
            {
              key: (item: ICampaignInformationWithOption) => (
                <div className="d-flex justify-content-between align-items-center">
                  <RadioWithVal
                    isView={isView}
                    isEdit={isEdit}
                    cmpAttributeSelection={cmpAttributeSelection}
                    setCmpAttributeSelection={setCmpAttributeSelection}
                    item={item}
                    attribute="CONVERSIONS"
                  >
                    <>
                      {nFormatter(item.conversionCount, 2)
                        .toString()
                        .replace('$', '')}
                    </>
                  </RadioWithVal>
                </div>
              ),
              className: `w-120-120 ${styles.conversion}`,
            },
          ],
          data: [...campaigns],
        });
      }
    },
    [
      cmpAttributeSelection,
      handleCheckboxChange,
      isAllSelected,
      isEdit,
      isView,
      selectDeselectAll,
      setCmpAttributeSelection,
    ],
  );

  useEffect(() => {
    loadTableData(selectedCampaigns);
  }, [loadTableData, selectedCampaigns]);

  return (
    <Table
      className={styles.table}
      data={tableConfig.data}
      header={tableConfig.header}
      body={tableConfig.body}
      rowKeyExtractor={(item: ICampaignInformationWithOption) => item.id}
      rowClickHandler={rowClickHandler}
      countPath="responseObject.filteredRecords"
      tableMaxHeight="100"
    />
  );
};

export default CampaignsTable;

export interface IPropsRadio {
  children: JSX.Element;
  cmpAttributeSelection: ICampaignAttributeSelection;
  setCmpAttributeSelection: (d: React.SetStateAction<ICampaignAttributeSelection>) => void;
  item: ICampaignInformationWithOption;
  attribute: 'IMPRESSIONS' | 'CLICKS' | 'CONVERSIONS';
  isView: boolean;
  isEdit: boolean;
}

const RadioWithVal = (props: IPropsRadio) => {
  const {
    children,
    cmpAttributeSelection,
    setCmpAttributeSelection,
    item,
    attribute,
    isView,
    isEdit,
  } = props;

  const checkdVal = useMemo(() => {
    if (attribute === 'IMPRESSIONS') {
      return cmpAttributeSelection.impressions.includes(item.id);
    }
    if (attribute === 'CLICKS') {
      return cmpAttributeSelection.clicks.includes(item.id);
    }
    if (attribute === 'CONVERSIONS') {
      return cmpAttributeSelection.conversions.includes(item.id);
    }
    return false;
  }, [
    attribute,
    cmpAttributeSelection.clicks,
    cmpAttributeSelection.conversions,
    cmpAttributeSelection.impressions,
    item.id,
  ]);

  const removeIdFromEveryAttribute = useCallback(
    (id: number) => {
      setCmpAttributeSelection((currVal) => ({
        impressions: currVal.impressions.filter(
          (ele, idx) => idx !== currVal.impressions.findIndex((e) => e === id, 1),
        ),
        clicks: currVal.clicks.filter(
          (ele, idx) => idx !== currVal.clicks.findIndex((e) => e === id, 1),
        ),
        conversions: currVal.conversions.filter(
          (ele, idx) => idx !== currVal.conversions.findIndex((e) => e === id, 1),
        ),
      }));
    },
    [setCmpAttributeSelection],
  );

  const onChangeFunc = useCallback(() => {
    removeIdFromEveryAttribute(item.id);
    if (attribute === 'IMPRESSIONS') {
      return setCmpAttributeSelection((currVal) => ({
        ...currVal,
        impressions: [...currVal.impressions, item.id],
      }));
    }

    if (attribute === 'CLICKS') {
      return setCmpAttributeSelection((currVal) => ({
        ...currVal,
        clicks: [...currVal.clicks, item.id],
      }));
    }
    if (attribute === 'CONVERSIONS') {
      return setCmpAttributeSelection((currVal) => ({
        ...currVal,
        conversions: [...currVal.conversions, item.id],
      }));
    }
    return null;
  }, [attribute, item.id, removeIdFromEveryAttribute, setCmpAttributeSelection]);

  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <Radio
        name="Something here"
        disabled={isView || isEdit}
        checked={checkdVal}
        onChange={() => onChangeFunc()}
      />
      {children}
    </div>
  );
};
