import { Dispatch, AnyAction } from 'redux';
import get from 'lodash/get';
import { ThunkDispatch } from 'redux-thunk';

import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import {
  CreateContextualAudienceData,
  UpdateContextualAudienceData,
} from 'models/ContextualAudience';
import { RUMLogger } from 'services/RUMLogger';
import { getApiErrorMsg } from 'services/RUMLogger/utils';
import { setRowAnimation } from 'styles/tableRowAnimation';
import { EMPTY_SELECTED_AUDIENCE_STATUS } from 'constants/audiences';
import { createAction } from '../../utils/actions';
import { API } from '../../api';
import { contextualAudienceConstants } from './constants';
import { AppState } from '..';
import { tableConstants } from '../table/constants';
import { tableActions } from '../table/actions';
import { snackbarActions } from '../snackbar/actions';

export interface CreateContextualAudience {
  createContextualAudience: (
    data: CreateContextualAudienceData & { addedRecommendedKeywords: string[] },
  ) => void;
}

export interface UpdateContextualAudience {
  updateContextualAudience: (data: UpdateContextualAudienceData) => void;
}

export const contextualAudienceActions = {
  createContextualAudience: (
    createAudienceData: CreateContextualAudienceData & { addedRecommendedKeywords: string[] },
  ) => {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(contextualAudienceConstants.START_CREATING));
      try {
        const createAudienceParam = { ...createAudienceData };
        // @ts-ignore
        delete createAudienceParam.addedRecommendedKeywords;
        const response = await API.ContextualAudience.createAudience(createAudienceParam);
        RUMLogger.createContextualAudience({
          statusCode: response.status,
          latency: response.data.latency,
          success: true,
          createAudienceData,
        });
        const { data } = response.data;
        const message = data?.message;

        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
          setRowAnimation();
        }
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(
          createAction(
            tableConstants.SET_AUDIENCES_SELECTED_STATUSES,
            EMPTY_SELECTED_AUDIENCE_STATUS,
          ),
        );
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
      } catch (e) {
        RUMLogger.createContextualAudience({
          statusCode: get(e, 'status'),
          latency: get(e, 'data.latency'),
          success: false,
          // @ts-ignore
          errorMsg: getApiErrorMsg(e),
          createAudienceData,
        });
        dispatch(
          snackbarActions.openSnackbar({
            message: get(e, 'data.errorObjects[0].error', e),
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(contextualAudienceConstants.RESET));
        dispatch(createAction<void>(contextualAudienceConstants.END_CREATING));
      }
    };
  },
  updateContextualAudience: (updateAudienceData: UpdateContextualAudienceData) => {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(contextualAudienceConstants.START_UPDATING));
      try {
        const { message } = await API.ContextualAudience.updateAudience(updateAudienceData);

        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
      } catch (e) {
        dispatch(
          snackbarActions.openSnackbar({
            message: get(e, 'errorObjects[0].error', e),
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(contextualAudienceConstants.RESET));
        dispatch(createAction<void>(contextualAudienceConstants.END_UPDATING));
      }
    };
  },
  reset() {
    return createAction<void>(contextualAudienceConstants.RESET);
  },
};
