export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const getFileExtension = (file: File) => {
  return file.name?.includes('.') ? file.name.split('.').pop() : '';
};

export const downloadBlob = async (blob: any, fileName: any) => {
  const link = window.document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = async (fileUrl: any, fileName: any) => {
  try {
    const file = await fetch(fileUrl);
    const blob = await file.blob();
    downloadBlob(blob, fileName);
  } catch (e) {
    // eslint-disable-next-line
    console.error('error :', e);
  }
};
