import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, TextField, Select, ProgressCircle, CollapsibleBlock } from 'factor';
import moment, { Moment } from 'moment';
import Postmate, { ParentAPI } from 'postmate';
import { TimezoneProvider, TimezonePicker, EpochDatePicker } from 'iqm-framework';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { AppState } from 'store';
import { applicationActions, ResetSidebar } from 'store/app/actions';
import { UpdateGeofarmedAudience, geofarmedAudienceActions } from 'store/geofarmedAudience/actions';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { TDialog } from 'store/app/reducer';
import { OptionId, Option } from 'models/Option';
import { API } from 'api';
import StepIndicator from 'components/StepIndicator';
import { getCampaignUrl } from 'utils/helpers';
import { audienceNameValidationRule } from 'utils/validation';
import { useLocationDetails } from '../components/useLocationDetails';
import { WeekScheduler, Scheduling } from '../../../WeekScheduler';

import styles from './styles.module.scss';

interface Props extends OpenSnackbar, UpdateGeofarmedAudience, ResetSidebar {
  handleClose: () => void;
  dialog: TDialog;
  title: string;
  submitInProgress: boolean;
}

const GeofarmedAudienceDialogComponent = (props: Props) => {
  const {
    handleClose,
    resetSidebar,
    dialog,
    title,
    submitInProgress,
    updateGeofarmedAudience,
  } = props;
  const [step, setStep] = useState<1 | 2>(1);
  const step1Ref = useRef(null);
  const child1Ref = useRef<ParentAPI | null>(null);
  const [frequencyTypeOptions, setFrequencyTypeOptions] = useState<Option<number>[] | undefined>(
    [],
  );
  const [audienceName, setAudienceName] = useState<string>('');
  const [frequency, setFrequency] = useState<string>('1');
  const [frequencyType, setFrequencyType] = useState<Option<number>>({
    label: 'Total',
    value: 1,
  });
  const [timezone, setTimezone] = useState<OptionId<string>>({
    id: 29,
    label: 'US/Eastern',
    value: 'US/Eastern',
  });
  const [startDate, setStartDate] = useState<number>(moment().valueOf());
  const [endDate, setEndDate] = useState<number | null>(null);
  const [timeRanges, setTimeRanges] = useState<Scheduling>({});
  const [isAdvancedBlockOpen, setAdvanceBlockOpen] = useState<boolean>(false);
  const [timezoneOptions, setTimezoneOptions] = useState<OptionId[]>([]);
  const { location, audience } = useLocationDetails(dialog.audienceId);
  const history = useHistory();

  const onStartDateChange = (value: number) => {
    setStartDate(value);
  };

  const onEndDateChange = (value: number | null) => {
    setEndDate(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await API.GeofarmedAudience.getFrequencyOptions();
        setFrequencyTypeOptions(result);
      } catch (e) {
        setFrequencyTypeOptions([]);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (audience) {
      setAudienceName(audience.name);
      setFrequency(`${audience.frequency}`);
      if (audience && timezoneOptions.length > 0) {
        setTimezone(timezoneOptions.filter((one) => one.id === audience.timezone)[0]);
      }
      setStartDate(audience.startDate * 1000);
      if (audience.endDate) {
        setEndDate(audience.endDate * 1000);
      }
    }
    if (audience && frequencyTypeOptions) {
      setFrequencyType(
        frequencyTypeOptions.filter((one) => one.value === audience.frequencyType)[0],
      );
    }
    if (audience?.timeRange) {
      setTimeRanges(audience.timeRange);
    }
  }, [
    audience,
    timezoneOptions,
    frequencyTypeOptions,
    setAudienceName,
    setFrequency,
    setFrequencyType,
    setStartDate,
    setEndDate,
    setTimezone,
  ]);

  useEffect(() => {
    if (location) {
      const initHandShakeStep1 = async () => {
        child1Ref.current = await new Postmate({
          container: step1Ref.current,
          url: getCampaignUrl('maps-with-tabs'),
          name: 'location-block',
          classListArray: [styles.iframeSize],
        });
      };
      initHandShakeStep1().then(() => {
        /* eslint-disable-next-line no-unused-expressions */
        child1Ref.current?.call('initStore', JSON.stringify(location));
      });
      return () => {
        /* eslint-disable-next-line no-unused-expressions */
        child1Ref.current?.destroy?.();
      };
    }
    return () => {};
  }, [location]);

  const resetAndClose = useCallback(() => {
    handleClose();
    resetSidebar();
    history.goBack();
  }, [handleClose, resetSidebar, history]);

  const handleNext = useCallback(async () => {
    setStep(2);
  }, [setStep]);

  const handleBack = useCallback(() => {
    setStep(1);
  }, [setStep]);

  const isSubmitDisabled = useMemo(
    () => !(audienceName?.trim?.() ?? '') || audienceName === audience?.name,
    [audienceName, audience],
  );

  const handleSubmit = useCallback(async () => {
    await updateGeofarmedAudience(
      {
        audienceName,
        audienceId: audience?.id,
        audienceTypeId: audience?.type,
      },
      resetAndClose,
    );
  }, [audience, audienceName, resetAndClose, updateGeofarmedAudience]);

  const actionButtons = useMemo(
    () =>
      step === 1
        ? [
            {
              variant: 'secondary',
              title: 'Cancel',
              handler: resetAndClose,
            },
            {
              title: 'Next',
              handler: handleNext,
            },
          ]
        : [
            {
              title: 'Previous',
              handler: handleBack,
              disabled: submitInProgress,
            },
            {
              title: 'Save',
              handler: handleSubmit,
              disabled:
                isSubmitDisabled ||
                submitInProgress ||
                audienceName.trim().length >= 255 ||
                Number(frequency) > 10,
            },
          ],
    [
      resetAndClose,
      step,
      handleNext,
      handleBack,
      isSubmitDisabled,
      audienceName,
      handleSubmit,
      submitInProgress,
      frequency,
    ],
  );

  return (
    <Dialog
      dialogTitle={title}
      open={!isEmpty(dialog)}
      crossButton
      headerFooterBorders
      className={styles.container}
      onCrossButtonClick={resetAndClose}
      actionButtons={actionButtons}
      footerLeft={<StepIndicator step={step} maxStep={2} />}
    >
      {audience && location ? (
        <>
          <div ref={step1Ref} className={step === 1 ? styles.mapBoxContainer : styles.hideStep} />
          <div className={`${step === 2 ? styles.audienceDetailContainer : styles.hideStep}`}>
            <div className={styles.audienceDetailWrapper}>
              <div className={styles.audienceLeftChild}>
                <CollapsibleBlock
                  collapsible={false}
                  header={{
                    title: <span>Audience Info</span>,
                  }}
                >
                  <div className={styles.audienceInfo}>
                    <TextField
                      onChange={setAudienceName}
                      value={audienceName}
                      placeholder="Enter Audience Name"
                      label="Audience Name"
                      variant="withoutTickbox"
                      validationRules={audienceNameValidationRule}
                    />

                    <TimezoneProvider timezone={timezone}>
                      <TimezonePicker
                        onLoaded={setTimezoneOptions}
                        onTimezoneChange={setTimezone}
                        showLabelAlways
                        variant="withoutTickbox"
                        isReadOnly
                      />
                    </TimezoneProvider>
                    <TimezoneProvider timezone={timezone}>
                      <div className={styles.datePickerWrapper}>
                        <EpochDatePicker
                          dateRangePickerClassName={styles.picker}
                          dateFormat="MM/DD/YYYY hh:mm A"
                          datePickerProps={{
                            numberOfCalendars: 1,
                            insidePreselectors: true,
                          }}
                          preselected={[
                            {
                              key: 'today',
                              title: 'Today',
                              date: (
                                tz: {},
                                convert: (date: Moment) => Moment,
                                getCurrentDay: (tz: {}) => Moment,
                              ) => convert(getCurrentDay(tz).add(15, 'minutes')),
                            },
                          ]}
                          label="Start Date"
                          withTimePicker
                          singleDateMode
                          singleDate={startDate}
                          calendarMinimumDate={moment().valueOf()}
                          insidePreselectors
                          showLabelAlways
                          onDateChanged={onStartDateChange}
                          disabled
                        />
                      </div>
                      <div className={styles.datePickerWrapper}>
                        <EpochDatePicker
                          dateRangePickerClassName={styles.picker}
                          dateFormat="MM/DD/YYYY hh:mm A"
                          datePickerProps={{
                            numberOfCalendars: 1,
                          }}
                          singleDatePlaceholder="(Optional)"
                          label="End Date"
                          withTimePicker
                          singleDateMode
                          singleDate={endDate}
                          calendarMinimumDate={moment().valueOf()}
                          isClearable
                          showLabelAlways
                          onDateChanged={onEndDateChange}
                          disabled
                        />
                      </div>
                    </TimezoneProvider>
                  </div>
                </CollapsibleBlock>
                <CollapsibleBlock
                  collapsible
                  header={{
                    title: <span>Advanced</span>,
                  }}
                  isCollapseOpen={isAdvancedBlockOpen}
                  id="editadvanced"
                  onToggle={(collapseStatus: boolean) => setAdvanceBlockOpen(collapseStatus)}
                >
                  <div className={styles.advance}>
                    <TextField
                      type="number"
                      onChange={setFrequency}
                      value={frequency}
                      placeholder="Enter Frequency"
                      label="Frequency"
                      variant="withoutTickbox"
                      inputAttributes={{
                        min: 1,
                        max: 10,
                      }}
                      disabled
                      validationRules={[
                        {
                          func: (value: string) => {
                            return Number(value) <= 10;
                          },
                          error: () => '* maximum allowed frequency is 10',
                        },
                      ]}
                    />
                    <Select
                      onChange={(value: any) => setFrequencyType(value)}
                      value={frequencyType}
                      placeholder="Frequency Type"
                      options={frequencyTypeOptions}
                      label="Frequency Type"
                      underline
                      showLabelAlways
                      isReadOnly
                      fitContainer
                    />
                  </div>
                </CollapsibleBlock>
              </div>
              <div className={styles.audienceRightChild}>
                <CollapsibleBlock
                  collapsible={false}
                  header={{
                    title: <span>Customize Time Range</span>,
                  }}
                >
                  <WeekScheduler setScheduling={setTimeRanges} value={timeRanges} isReadOnly />
                </CollapsibleBlock>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.loading}>
          <ProgressCircle />
        </div>
      )}
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  submitInProgress: state.contextualAudience.submitInProgress,
});

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  openSnackbar: snackbarActions.openSnackbar,
  updateGeofarmedAudience: geofarmedAudienceActions.updateGeofarmedAudience,
};

const GeofarmedAudienceDialog = connect(mapState, mapAction)(GeofarmedAudienceDialogComponent);

export default GeofarmedAudienceDialog;
