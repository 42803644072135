import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { GeofarmedAudienceData, Locations } from 'models/GeofarmedAudience';
import { WithResponse } from 'models/Response';
import { ICountryOption } from 'models/Option';
import { API } from 'api';
import { FAILED_TO_LOAD_MSG } from 'constants/errors';

export const useLocationDetails = (audienceId: number | undefined, enabled: boolean = true) => {
  const [location, setLocation] = useState<Locations>();
  const [audience, setAudience] = useState<GeofarmedAudienceData | undefined>();
  const [audienceError, setAudienceError] = useState<WithResponse | null>(null);
  const [countryError, setCountryError] = useState<AxiosResponse | null>(null);

  useEffect(() => {
    if (audienceId && enabled) {
      API.GeofarmedAudience.getAudience(audienceId)
        .then((res) => setAudience(res.data))
        .catch((err) => setAudienceError(err));
    }
  }, [audienceId, enabled]);

  const getLocationObject = (
    audienceData: GeofarmedAudienceData,
    countries: ICountryOption[] | null,
  ) => {
    const countryId = audienceData.locations.country ?? audienceData.locations.countryId;
    const locationObj: Locations = {
      countryId: audienceData.locations.country ?? audienceData.locations.countryId,
      locationFileIds: audienceData.locations.locationFileIds,
      excluded: audienceData.locations.excluded,
      included: audienceData.locations.included,
      filterStore: {
        country: countries ? countries.filter((one) => one.value === countryId)[0] : null,
        locationDetails: audienceData.bulkLocationDetails,
      },
      errors: {
        countryError: !countries ? FAILED_TO_LOAD_MSG : null,
      },
    };
    return locationObj;
  };

  useEffect(() => {
    if (audience) {
      const countryId = audience.locations.country ?? audience.locations.countryId;
      API.GeofarmedAudience.getCountries({ segmentIds: `${countryId}` })
        .then((countries) => {
          setLocation(getLocationObject(audience, countries || []));
        })
        .catch((err) => {
          setCountryError(err);
          setLocation(getLocationObject(audience, null));
        });

      /*
        const countryId = audience.locations.country ?? audience.locations.countryId;
        const statesIds = [
          ...(audience?.locations?.included?.states ?? []),
          ...(audience?.locations?.excluded?.states ?? []),
        ];
        const stateHousesIds = [
          ...(audience?.locations?.included?.stateHouses ?? []),
          ...(audience?.locations?.excluded?.stateHouses ?? []),
        ];
        const stateSenatesIds = [
          ...(audience?.locations?.included?.stateSenates ?? []),
          ...(audience?.locations?.excluded?.stateSenates ?? []),
        ];
        const congressionalDistrictsIds = [
          ...(audience?.locations?.included?.congressionalDistricts ?? []),
          ...(audience?.locations?.excluded?.congressionalDistricts ?? []),
        ];
        const countiesIds = [
          ...(audience?.locations?.included?.counties ?? []),
          ...(audience?.locations?.excluded?.counties ?? []),
        ];
        const citiesIds = [
          ...(audience?.locations?.included?.cities ?? []),
          ...(audience?.locations?.excluded?.cities ?? []),
        ];

        Promise.all([
          countryId
            ? API.GeofarmedAudience.Countries({ segmentIds: `${countryId}` })
            : Promise.resolve([]),
          statesIds.length
            ? API.GeofarmedAudience.States({ segmentIds: `${statesIds.join(',')}` })
            : Promise.resolve([]),
          stateHousesIds.length
            ? API.GeofarmedAudience.Houses({ segmentIds: `${stateHousesIds.join(',')}` })
            : Promise.resolve([]),
          stateSenatesIds.length
            ? API.GeofarmedAudience.Senates({ segmentIds: `${stateSenatesIds.join(',')}` })
            : Promise.resolve([]),
          congressionalDistrictsIds.length
            ? API.GeofarmedAudience.congressionalDistricts({
                segmentIds: `${congressionalDistrictsIds.join(',')}`,
              })
            : Promise.resolve([]),
          countiesIds.length
            ? API.GeofarmedAudience.Counties({
                segmentIds: `${countiesIds.join(',')}`,
              })
            : Promise.resolve([]),
          citiesIds.length
            ? API.GeofarmedAudience.Cities({
                segmentIds: `${citiesIds.join(',')}`,
              })
            : Promise.resolve([]),
        ]).then((values) => {
          const [country, states, houses, senates, districts, counties, cities] = values;
          const locationObj: Locations = {
            countryId: audience.locations.country ?? audience.locations.countryId,
            locationFileIds: audience.locations.locationFileIds,
            excluded: audience.locations.excluded,
            included: audience.locations.included,
            filterStore: {
              country: country.filter((one) => one.value === countryId)[0],
              states: states.filter((one) => statesIds.indexOf(one.value) >= 0),
              houses: houses.filter((one) => stateHousesIds.indexOf(one.value) >= 0),
              senates: senates.filter((one) => stateSenatesIds.indexOf(one.value) >= 0),
              districts: districts.filter((one) => congressionalDistrictsIds.indexOf(one.value) >= 0),
              counties: counties.filter((one) => countiesIds.indexOf(one.value) >= 0),
              cities: cities.filter((one) => citiesIds.indexOf(one.value) >= 0),
              locationDetails: audience.bulkLocationDetails,
            },
          };
          setLocation(locationObj);
        });
      */
    }
  }, [audience]);

  return { audience, location, audienceError, countryError };
};
