import moment from 'moment';

export const getCalendarMin = () => {
  const activeTimezoneCurrentDate = moment.tz(moment().utc(), 'UTC').tz(moment.tz.guess());
  const activeTimezoneStartDate = activeTimezoneCurrentDate
    .clone()
    .add(-179, 'days')
    .startOf('day');
  const calendarMinimumDate = moment.tz(
    activeTimezoneStartDate.format('YYYY-MM-DD HH:mm:ss'),
    moment.tz.guess(),
  );

  return calendarMinimumDate;
};

export const getCalendarMax = () => {
  const activeTimezoneCurrentDate = moment.tz(moment().utc(), 'UTC').tz(moment.tz.guess());
  const activeTimezoneEndDate = activeTimezoneCurrentDate.clone().endOf('day');
  const calendarMaximumDate = moment.tz(
    activeTimezoneEndDate.format('YYYY-MM-DD HH:mm:ss'),
    moment.tz.guess(),
  );

  return calendarMaximumDate;
};

export const getDatesOnMinMaxUpdate = (
  startDate: number,
  endDate: number,
  minDate: Date,
  maxDate: Date,
) => {
  let newStart = startDate;
  let newEnd = endDate;
  if (endDate > maxDate.valueOf()) {
    newEnd = maxDate.valueOf();
    if (newStart > newEnd) {
      newStart = moment(newStart)
        .add(-1, 'day')
        .valueOf();
    }
  } else if (startDate < minDate.valueOf()) {
    newStart = minDate.valueOf();
    if (newStart > newEnd) {
      newEnd = moment(newEnd)
        .add(1, 'day')
        .valueOf();
    }
  }
  return { startDate: newStart, endDate: newEnd };
};

export const getDefaultDateRange = () => {
  return {
    getStartDate: (tz: string) => {
      const startDate = moment
        .tz(moment().utc(), 'UTC')
        .tz(tz)
        .add(-30, 'day')
        .startOf('day');
      return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
    },
    getEndDate: (tz: string) => {
      const startDate = moment
        .tz(moment().utc(), 'UTC')
        .tz(tz)
        .endOf('day');
      return moment.tz(startDate.format('YYYY-MM-DD HH:mm:ss'), moment.tz.guess());
    },
  };
};
