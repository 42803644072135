export const matchedAudienceConstants = {
  END_CREATING: 'END_CREATING_MATCHED',
  RESET: 'RESET_MATCHED',
  SAVE_COLUMNS_LIST: 'SAVE_COLUMNS_LIST',
  SAVE_COLUMNS_MAPPING: 'SAVE_COLUMNS_MAPPING',
  SET_AUDIENCE_NAME: 'SET_MATCHED_AUDIENCE_NAME',
  SET_EDITABLE_AUDIENCE: 'SET_EDITABLE_AUDIENCE',
  START_CREATING: 'START_CREATING_MATCHED',
  SET_DATA_PARTNERS: 'SET_DATA_PARTNERS',
  SET_DATA_FORMATS: 'SET_DATA_FORMATS',
  SUCCESSFULLY_CREATED: 'SUCCESSFULLY_CREATED_MATCHED',
};
