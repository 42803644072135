export function copy(text: string) {
  const input = document.createElement('textarea');
  input.innerHTML = text;
  document.body.appendChild(input);
  input.select();
  const result = document.execCommand('copy');
  document.body.removeChild(input);
  return result;
}

export function selectText(nodeId: string) {
  const node = document.getElementById(nodeId);

  if (window.getSelection && node) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn('Could not select text.');
  }
}
