import * as React from 'react';
import { ProcessingAudienceInsight, ProcessingAudienceInsightFooter } from '@applift/insight-app';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@applift/factor';
import { AudienceListType } from '@applift/insight-app/lib/models/AudienceInsight';

interface GenerateInsightProps {
  closeDialog: () => void;
  generateData: AudienceListType[];
}

export const GenerateInsight = (props: GenerateInsightProps) => {
  const { closeDialog, generateData } = props;

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle onClose={closeDialog}>Generate Audience Insights</DialogTitle>
      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
        <ProcessingAudienceInsight selectedAudience={generateData} />
      </DialogContent>
      <DialogActions>
        <ProcessingAudienceInsightFooter actionLabel="Done" action={closeDialog} />
      </DialogActions>
    </Dialog>
  );
};
