import axios, { AxiosResponse, Canceler } from 'axios';
import get from 'lodash/get';

import { getInstance } from './Instance';
import { Response } from '../models/Response';
import { DeleteAudienceRequestData, UpdateAudienceNameResponse } from '../models/Table';

interface DeleteAPIResponse {
  [key: string]: number[];
}

export interface AudienceType {
  audienceTypeId: number;
  audienceTypeName: string;
  audienceCount: number;
}

export interface AudienceStatus {
  id: number;
  status: string;
  displayName: string;
}

export const deleteAudiences = async (
  data: DeleteAudienceRequestData,
): Promise<DeleteAPIResponse> => {
  try {
    const response: AxiosResponse<Response<DeleteAPIResponse>> = await getInstance().put(
      '/v2/audience/delete',
      data,
    );
    const responseData: DeleteAPIResponse = get(response, 'data.responseObject', {});
    return responseData;
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const updateAudienceName = async (
  audienceName: string,
  audienceTypeId: number,
  audienceId: number,
): Promise<UpdateAudienceNameResponse> => {
  try {
    const response: AxiosResponse<Response<UpdateAudienceNameResponse>> = await getInstance().patch(
      `/v2/audience/updateName/${audienceId}`,
      {
        audienceName,
        audienceTypeId,
      },
    );
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

let canceler: Canceler;
export const getAudienceCountByType = async (
  audienceTypeIds: string,
  status: string,
  searchField?: string,
): Promise<AudienceType[] | null> => {
  if (canceler) {
    canceler('getAudienceCountByType is canceled by user');
  }
  try {
    const response: AxiosResponse<Response<AudienceType[]>> = await getInstance().get(
      '/v2/audience/typeWiseCount',
      {
        params: {
          audienceTypeIds,
          status,
          searchField,
        },
        cancelToken: new axios.CancelToken((c) => {
          canceler = c;
        }),
      },
    );
    return response.data.responseObject;
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.log('getAudienceCountByType error:', e);
    return null;
  }
};

let audiencesStatusesCanceller: Canceler;

export const getAudiencesStatuses = async (
  audienceTypeIds: string,
): Promise<AudienceStatus[] | null> => {
  if (audiencesStatusesCanceller) {
    audiencesStatusesCanceller('/v2/audience/status is cancelled by newer request');
  }
  try {
    const response: AxiosResponse<Response<AudienceStatus[]>> = await getInstance().get(
      '/v2/audience/status',
      {
        params: { audienceTypeIds: audienceTypeIds === '' ? '1,2,3,4,5,6,7,8' : audienceTypeIds },
        cancelToken: new axios.CancelToken((c) => {
          audiencesStatusesCanceller = c;
        }),
      },
    );
    return response.data.responseObject;
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.log('Error while getting /v2/audience/status:', e);
    return null;
  }
};
