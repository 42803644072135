import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Button } from 'factor';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button as NewButton,
  LoadingButton,
  Typography,
} from '@applift/factor';

import { AppState } from 'store';
import { tableActions, DeleteSelectedAudiences } from 'store/table/actions';
import { TableAudienceItem, InsightsData } from 'models/Table';
import { MATCHED_AUDIENCE_TYPE_ID, PRE_BID_AUDIENCE_TYPE_ID } from 'constants/audiences';

interface Props extends DeleteSelectedAudiences {
  className?: string;
  deleteInProgress: boolean;
  selectedAudiences: TableAudienceItem[];
  tableComponentInstanceRef: any;
  insightsData?: InsightsData;
}

const STORAGE_NAME = 'confirmDeleteAudience';

const DeleteAudienceButtonComponent = memo((props: Props) => {
  const {
    className = '',
    deleteSelectedAudiences,
    deleteInProgress,
    selectedAudiences,
    tableComponentInstanceRef,
    insightsData = {},
  } = props;
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const progress = useRef<boolean>();

  useEffect(() => {
    if (deleteInProgress) {
      progress.current = true;
    }

    if (!deleteInProgress && progress.current) {
      progress.current = false;
      setShowConfirmDelete(false);
    }
  }, [deleteInProgress]);

  const deleteSelected = () => {
    deleteSelectedAudiences(selectedAudiences, tableComponentInstanceRef.current);
  };

  const confirmDelete = () => {
    const noPopup = localStorage.getItem(STORAGE_NAME);
    if (noPopup === 'true') {
      deleteSelected();
    } else {
      setShowConfirmDelete(true);
    }
  };

  const closeDialog = () => {
    setShowConfirmDelete(false);
  };

  const insightAudienceIds = Object.keys(insightsData).map((id) => Number(id));

  const isAudienceInUse = useCallback(() => {
    return !!selectedAudiences.find((audience) => {
      return !(audience.included === 0 && audience.excluded === 0);
    });
  }, [selectedAudiences]);

  const isAudienceWithInsightSelected = React.useMemo(() => {
    return selectedAudiences.some((aud) => insightAudienceIds.includes(aud.id));
  }, [selectedAudiences, insightAudienceIds]);

  const getDialogContent = () => {
    return (
      <>
        <DialogTitle>Delete Audience?</DialogTitle>
        <DialogContent>
          {selectedAudiences.length > 1 ? (
            <>
              {isAudienceWithInsightSelected ? (
                <Typography>
                  Deleting{' '}
                  <Typography
                    component="span"
                    variant="span"
                    sx={{ textWeight: 'demi', display: 'inline-flex' }}
                  >
                    {selectedAudiences.length} Audiences
                  </Typography>{' '}
                  will delete the associated audience insights as well. Are you sure you want to
                  delete it?
                </Typography>
              ) : (
                <Typography>
                  Are you sure you want to delete{' '}
                  <Typography
                    component="span"
                    variant="span"
                    sx={{ textWeight: 'demi', display: 'inline-flex' }}
                  >
                    {selectedAudiences.length} Audiences
                  </Typography>{' '}
                  ?
                </Typography>
              )}
            </>
          ) : (
            <>
              {selectedAudiences[0].audienceTypeId === MATCHED_AUDIENCE_TYPE_ID &&
              insightAudienceIds.includes(selectedAudiences[0].id) ? (
                <>
                  <Typography lineHeight="multi-line">
                    Deleting{' '}
                    <Typography
                      component="span"
                      variant="span"
                      weight="demi"
                      lineHeight="multi-line"
                    >
                      {selectedAudiences[0].audienceName}
                    </Typography>{' '}
                    will delete the associated audience insights as well. Are you sure you want to
                    delete it?
                  </Typography>
                </>
              ) : (
                <Typography lineHeight="multi-line">
                  Are you sure you want to delete the selected audience{' '}
                  <Typography component="span" variant="span" weight="demi" lineHeight="multi-line">
                    {selectedAudiences[0].audienceName}
                  </Typography>{' '}
                  ?
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ paddingTop: 12 }}>
          <NewButton
            color="secondary"
            variant="contained"
            size="medium"
            disabled={deleteInProgress}
            onClick={closeDialog}
          >
            Cancel
          </NewButton>
          <LoadingButton
            size="medium"
            color="error"
            loading={deleteInProgress}
            sx={{ bgColor: 'danger-500', borderColor: 'danger-500' }}
            onClick={deleteSelected}
            disabled={deleteInProgress}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </>
    );
  };

  if (!selectedAudiences.length) {
    return null;
  }

  return (
    <>
      <Button
        variant="danger"
        size="small"
        className={className}
        onClick={confirmDelete}
        disabled={
          selectedAudiences.every((aud) => aud.audienceTypeId === PRE_BID_AUDIENCE_TYPE_ID)
            ? false
            : isAudienceInUse()
        }
      >
        Delete
      </Button>
      {createPortal(
        <Dialog open={showConfirmDelete} maxWidth="xs" fullWidth>
          {getDialogContent()}
        </Dialog>,
        document.body,
      )}
    </>
  );
});

const mapState = (state: AppState) => ({
  deleteInProgress: state.table.deleteInProgress,
  selectedAudiences: state.table.selectedAudiences,
  insightsData: state.table.insightsData,
});

const mapAction = {
  deleteSelectedAudiences: tableActions.deleteSelectedAudiences,
};

export const DeleteAudienceButton = connect(mapState, mapAction)(DeleteAudienceButtonComponent);
