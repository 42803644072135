import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import isBoolean from 'lodash/isBoolean';
import get from 'lodash/get';
import Axios from 'axios';
import { Tabs, Dialog, Icon, ProgressCircle, NumberFormatter, Tooltip, Pill } from 'factor';
import moment from 'moment';

import { TDialog } from 'store/app/reducer';
import { API } from 'api';
import { COMMON_DIALOG_MODAL_PROPS } from 'constants/audiences';
import { SOMETHING_WENT_WRONG_MSG } from 'constants/errors';
import { ContextualAudienceData } from 'models/ContextualAudience';
import { tableStatusIconsMap } from 'components/consts';
import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import EmptyListIllustration from '../components/EmptyListIllustration';

import styles from './styles.module.scss';

interface Props extends OpenSnackbar {
  dialog: TDialog;
  handleClose?: () => void;
  dialogVisibility?: boolean;
  isIncluded?: boolean;
}

type Tab = {
  title: string;
  value: number;
};

interface UrlObj {
  [key: string]: boolean;
}

const ViewContextualAudience = (props: Props) => {
  const { dialog, handleClose, dialogVisibility = true, isIncluded, openSnackbar } = props;

  const [audience, setAudience] = useState<ContextualAudienceData | undefined>();
  const [tab, setTab] = useState<number>(1);
  const [needTableRefersh, setNeedTableRefersh] = useState<boolean>(false);
  const [validatingUrl, setValidatingUrl] = useState<boolean>(false);
  const [addedUrlObjs, setAddedUrlObjs] = useState<UrlObj | null>(null);
  const validateUrlTokenRef = useRef<any>(null);

  useEffect(() => {
    if (dialog?.audienceId) {
      API.ContextualAudience.getAudience(dialog.audienceId)
        .then((res) => {
          setAudience(res.data);
          if (res?.data?.status !== dialog.status) {
            setNeedTableRefersh(true);
          }
        })
        .catch((err) => {
          openSnackbar({
            message: get(err, 'errorObjects[0].error', SOMETHING_WENT_WRONG_MSG),
            type: 'error',
          });
          if (handleClose) {
            handleClose();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  const getTabs = () => {
    const keywords = (audience && audience.keywords?.length && audience.keywords?.split(',')) || [];
    const urls = (audience && audience.urls?.length && audience.urls?.split(',')) || [];
    return [
      {
        title: `Keywords (${keywords.length})`,
        value: 1,
      },
      {
        title: `URLs (${urls.length})`,
        value: 2,
      },
    ];
  };

  const resetAndClose = useCallback(() => {
    if (handleClose) {
      handleClose();
    }
    if (needTableRefersh) {
      tableComponentInstanceRef.current.getNewData();
    }
  }, [handleClose, needTableRefersh]);

  const actionButtons = useMemo(
    () => [
      {
        title: 'Done',
        handler: resetAndClose,
      },
    ],
    [resetAndClose],
  );

  const checkHttp = (url: string) => {
    if (url.includes('https') || url.includes('http')) {
      return url;
    }
    return `https://${url}`;
  };

  const validatedUrl = useCallback(async (urlArr: string[]) => {
    setValidatingUrl(true);
    try {
      if (validateUrlTokenRef.current !== null) {
        validateUrlTokenRef.current.cancel();
      }
      const source = Axios.CancelToken.source();
      validateUrlTokenRef.current = source;
      const domainUrl = urlArr.map((url) => checkHttp(url));
      const result = await API.ContextualAudience.validateUrl(domainUrl, source);
      if (result.success && result.data) {
        setAddedUrlObjs(result.data);
      }
      validateUrlTokenRef.current = null;
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.log(`error: ${e}`);
    } finally {
      setValidatingUrl(false);
    }
  }, []);

  useEffect(() => {
    if (audience && !!audience.urls) {
      validatedUrl(audience.urls?.split(','));
    }
  }, [audience, validatedUrl]);

  const showProgrammaticAlert = (isValid: boolean | null) => {
    switch (isValid) {
      case false:
        return (
          <Tooltip
            label="The URL is outside of programmatic universe."
            className="ml-auto"
            position="left"
            auto={false}
            labelMaxWidth={170}
            portal
          >
            <Icon className={styles.urlItemWarningIcon} name="WarningTriangle" />
          </Tooltip>
        );
      case null:
        return (
          <Tooltip
            label="This URL could not be validated. It may not show the targeted ad."
            className="ml-auto"
            position="left"
            auto={false}
            labelMaxWidth={170}
            portal
          >
            <Icon className={styles.urlItemWarningIcon} name="AlertCircle" />
          </Tooltip>
        );
      default:
        return <></>;
    }
  };

  const isAllValidUrl = useMemo(
    () => addedUrlObjs && Object.values(addedUrlObjs).every((val) => !!val),
    [addedUrlObjs],
  );

  const warningIcon = (item: string) => {
    return validatingUrl ? (
      <ProgressCircle className="ml-auto" size={16} borderWidth={2} />
    ) : (
      addedUrlObjs && showProgrammaticAlert(addedUrlObjs[item])
    );
  };

  const renderTab = (type: 'keywords' | 'urls') => {
    let items = (audience && audience[type]?.length && audience[type]?.split(',')) || [];

    if (type === 'urls' && items.length > 0) {
      items = items.map((url) => checkHttp(url));
    }

    return (
      <div className={styles.tab}>
        {type === 'urls' && items.length > 0 && !isAllValidUrl && !validatingUrl && (
          <div className={styles.addedUrlsHeaderWarning}>
            <div className={styles.allAssinged}>
              <Icon name="WarningTriangle" />
              <div className={styles.text}>
                Audiences won't be captured from URL outside the programmatic universe
              </div>
            </div>
          </div>
        )}
        {items.length > 0 ? (
          <ul className={styles.list}>
            {items.map((item) => (
              <li className={styles.item}>
                <Icon
                  name={type === 'keywords' ? 'CampaignsKeyWords' : 'Link'}
                  className={styles.itemIcon}
                />
                {item}
                {type === 'urls' && warningIcon(item)}
              </li>
            ))}
          </ul>
        ) : (
          <EmptyListIllustration
            className={styles.emptyList}
            text={`No ${type === 'keywords' ? 'Keywords' : 'URLs'} added in this audience`}
          />
        )}
      </div>
    );
  };

  const renderContent = audience ? (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.audienceIcon}>
          <Icon name="AudienceContextual" />
        </div>
        {audience.name.length > 60 ? (
          <Tooltip label={audience.name} position="bottom" auto={false}>
            <div className={`${styles.name} ${styles.restrictName}`}>{audience.name}</div>
          </Tooltip>
        ) : (
          <div className={styles.name}>{audience.name}</div>
        )}
        {isBoolean(isIncluded) && (
          <Pill
            className={isIncluded ? styles.successPill : styles.dangerPill}
            label={isIncluded ? 'Allowed' : 'Blocked'}
          />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Created on:</div>
          <div className={styles.infoText}>
            {moment(audience.createdOn * 1000).format('MM/DD/yyyy')}
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Reach</div>
          <div className={styles.infoText}>
            <NumberFormatter>{audience.uniques}</NumberFormatter>
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <Icon name={tableStatusIconsMap[audience.status]} className={styles.statusIcon} />
          <div className={styles.infoStatus}>{audience.status}</div>
        </div>
      </div>
      <div className={styles.keywordsAndUrls}>
        <Tabs
          className={`${styles.tabs} ${isAllValidUrl || validatingUrl ? 'mb-0' : 'mb-4'}`}
          items={getTabs()}
          value={tab}
          onChange={({ value }: Tab) => setTab(value)}
          variant="normal"
        />
        {tab === 1 && renderTab('keywords')}
        {tab === 2 && renderTab('urls')}
      </div>
    </div>
  ) : (
    <div className={styles.loading}>
      <ProgressCircle />
    </div>
  );
  return dialogVisibility ? (
    <Dialog
      dialogTitle="Audience Details"
      open={dialog}
      crossButton
      headerFooterBorders
      className={styles.dialog}
      onCrossButtonClick={resetAndClose}
      modalProps={COMMON_DIALOG_MODAL_PROPS}
      actionButtons={actionButtons}
    >
      {renderContent}
    </Dialog>
  ) : (
    <>{renderContent}</>
  );
};

const mapAction = {
  openSnackbar: snackbarActions.openSnackbar,
};

export default connect(null, mapAction)(ViewContextualAudience);
