import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'factor';
import {
  Box,
  Button,
  CircularProgress,
  enqueueSnackbar,
  IconButton,
  LoadingButton,
  Menu,
  MenuItem,
  sx,
  Tooltip,
  Typography,
} from '@applift/factor';
import { CaretDown, EmailAdd, FilePdf, FileXlsx, StatusReady } from '@applift/icons';

import { InsightsData, TableAudienceItem } from 'models/Table';
import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import { INSIGHT_STATUSES } from 'components/consts';
import { OpenDialog } from 'store/app/actions';
import { AUDIENCE_STATUSES, INSIGHTS_MIN_UNIQUES } from 'constants/audiences';
import { AppState } from 'store';
import {
  useCreateAudienceInsight,
  useDownloadInsights,
  useInsightsStatus,
  useRegenerateAudienceInsight,
} from 'hooks/useInsights';
import { downloadFile } from 'utils/file';

import styles from './styles.module.scss';

interface Props extends OpenDialog, InsightsData {
  data: TableAudienceItem;
  insightsData?: InsightsData;
}

const InsightsCellComponent = ({ data, openDialog, insightsData = {} }: Props) => {
  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState<null | HTMLElement>(null);
  const [emailAnchorEl, setEmailAnchorEl] = React.useState<null | HTMLElement>(null);
  const openDownload = Boolean(downloadAnchorEl);
  const openEmail = Boolean(emailAnchorEl);
  const isInsightsAppAccessible = JSON.parse(sessionStorage.getItem('allowedApps') || '')?.find(
    (item: { appName: string }) => item.appName === 'Insights',
  );

  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setDownloadAnchorEl(event.currentTarget);
  const handleEmailClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setEmailAnchorEl(event.currentTarget);
  const handleDownloadClose = () => setDownloadAnchorEl(null);
  const handleEmailClose = () => setEmailAnchorEl(null);
  const getAudienceIds = (insights: InsightsData, statusId: number) =>
    Object.keys(insights)
      .filter((key) => insights[key as any].statusId === statusId)
      .map((id) => Number(id));
  const insightStatuses = useInsightsStatus();
  const readyInsightAudienceIds = getAudienceIds(
    insightsData || {},
    insightStatuses.data?.data?.insightsStatusData?.find(
      (status: { name: string }) => status.name === 'ready',
    )?.id || INSIGHT_STATUSES.READY,
  );
  const processingInsightAudienceIds = getAudienceIds(
    insightsData || {},
    insightStatuses.data?.data?.insightsStatusData?.find(
      (status: { name: string }) => status.name === 'processing',
    )?.id || INSIGHT_STATUSES.PROCESSING,
  );
  const failedInsightAudienceIds = getAudienceIds(
    insightsData || {},
    insightStatuses.data?.data?.insightsStatusData?.find(
      (status: { name: string }) => status.name === 'failed',
    )?.id || INSIGHT_STATUSES.FAILED,
  );

  const getInsightId = () => {
    return insightsData?.[data.id]?.id;
  };

  const onSuccessGetDownloadUrl = (response: any) => {
    const url = response.data.insightsReportUrl;
    const fileName = `${data.audienceName}_insights.xlsx`;
    downloadFile(url, fileName);
    enqueueSnackbar(
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        Insights are being downloaded as XLSX.
      </Typography>,
      {
        variant: 'info',
      },
    );
    openDialog({});
  };

  const onErrorGetDownloadUrl = (error?: any) => {
    enqueueSnackbar(
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        {error?.errorObjects?.[0]?.error ?? 'Something went wrong. Please try after some time.'}
      </Typography>,
      {
        variant: 'error',
      },
    );
    openDialog({});
  };

  const getDownloadUrlMutation = useDownloadInsights(
    onSuccessGetDownloadUrl,
    onErrorGetDownloadUrl,
  );

  const onDownloadXLSX = () => {
    const downloadForm = new FormData();
    downloadForm.append('audienceId', data.id.toString());
    downloadForm.append('fileType', 'xlsx');
    getDownloadUrlMutation.mutate(downloadForm);
  };

  const onRegenerateSuccess = (response: any) => {
    enqueueSnackbar(response?.data, {
      variant: 'success',
    });
    openDialog({});
    tableComponentInstanceRef?.current.clearSelected();
    tableComponentInstanceRef?.current.getNewData();
  };

  const onCreateSuccess = (response: any) => {
    const insightsData = response?.data?.insightsData;
    const invalidSourceIds = response?.data?.invalidSourceIds;
    if (insightsData?.length) {
      enqueueSnackbar(
        `Selected Audience${insightsData?.length > 1 ? 's' : ''} insights generated successfully.`,
        {
          variant: 'success',
        },
      );
    }
    if (invalidSourceIds?.length) {
      enqueueSnackbar(
        `Insights for ${invalidSourceIds.length} selected audience(s) could not be generated.`,
        {
          variant: 'error',
        },
      );
    }
    openDialog({});
    tableComponentInstanceRef?.current.clearSelected();
    tableComponentInstanceRef?.current.getNewData();
  };

  const onCreateOrRegenerateFailed = () => {
    enqueueSnackbar('Insights for selected audience could not be generated', {
      variant: 'error',
    });
    openDialog({});
    tableComponentInstanceRef?.current.clearSelected();
    tableComponentInstanceRef?.current.getNewData();
  };

  const createAudienceInsightMutation = useCreateAudienceInsight(
    onCreateSuccess,
    onCreateOrRegenerateFailed,
  );
  const reGenerateAudienceInsightMutation = useRegenerateAudienceInsight(
    onRegenerateSuccess,
    onCreateOrRegenerateFailed,
  );

  const getButton = (data: TableAudienceItem) => {
    const getGenerateBtnTooltip = (data: TableAudienceItem) => {
      if (!isInsightsAppAccessible) {
        return (
          <span className={styles.insightTooltipContent}>
            <Icon name="Insights" />
            <span>
              Insight app access is required to generate audience insights. Contact your admin for
              more information
            </span>
          </span>
        );
      }
      if (data.status === AUDIENCE_STATUSES.READY && data.uniques > INSIGHTS_MIN_UNIQUES) {
        return 'Generate Insights';
      }

      return data.status !== AUDIENCE_STATUSES.READY ? (
        <Typography component="span" variant="span" lineHeight="multi-line">
          Insights can only be generated for audiences with
          <StatusReady
            className={styles.tooltipIcon}
            fontSize={16}
            sx={{
              marginX: 2,
              display: 'inline-flex',
              textColor: 'info-400',
            }}
          />
          Ready status
        </Typography>
      ) : (
        'Insights can only be generated for audiences with more than 10k uniques'
      );
    };

    if (readyInsightAudienceIds.includes(data.id)) {
      return (
        <Tooltip
          title={
            isInsightsAppAccessible ? (
              'Download Insights as PDF or XLSX'
            ) : (
              <span className={styles.insightTooltipContent}>
                <Icon name="Insights" />
                <span>
                  Insight app access is required to download audience insights. Contact your admin
                  for more information
                </span>
              </span>
            )
          }
          placement="left"
          arrow
        >
          <Box>
            <Button
              endIcon={<CaretDown />}
              size="small"
              variant="outlined"
              disabled={!isInsightsAppAccessible}
              color="secondary"
              onClick={handleDownloadClick}
              sx={{ textColor: 'neutral-500', borderColor: 'neutral-400' }}
              disableRipple
            >
              <Typography component="span" variant="span">
                Download
              </Typography>
            </Button>
          </Box>
        </Tooltip>
      );
    }
    if (processingInsightAudienceIds.includes(data.id)) {
      return (
        <LoadingButton
          variant="outlined"
          color="secondary"
          size="small"
          className="mr-2"
          loadingPosition="start"
          loading
          startIcon={<CircularProgress size={10} thickness={1} />}
        >
          Generating
        </LoadingButton>
      );
    }
    if (failedInsightAudienceIds.includes(data.id)) {
      return (
        <Tooltip title={getGenerateBtnTooltip(data)} placement="left" arrow>
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              className="mr-2"
              disabled={
                data.status !== AUDIENCE_STATUSES.READY ||
                data.uniques < INSIGHTS_MIN_UNIQUES ||
                !isInsightsAppAccessible
              }
              onClick={() => {
                reGenerateAudienceInsightMutation.mutate(insightsData[data.id].id.toString());
                openDialog({
                  type: 'insights',
                  otherInfo: {
                    action: 'generate',
                    generateData: [
                      {
                        id: data.id,
                        audienceName: data.audienceName,
                        matchRate: data.matchRate,
                        uniques: data.uniques,
                      },
                    ],
                  },
                });
              }}
            >
              Generate again
            </Button>
          </Box>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={getGenerateBtnTooltip(data)} placement="left" arrow>
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            className="mr-2"
            disabled={
              data.status !== AUDIENCE_STATUSES.READY ||
              data.uniques < INSIGHTS_MIN_UNIQUES ||
              !isInsightsAppAccessible
            }
            onClick={() => {
              createAudienceInsightMutation.mutate(data.id.toString());
              openDialog({
                type: 'insights',
                otherInfo: {
                  action: 'generate',
                  generateData: [
                    {
                      id: data.id,
                      audienceName: data.audienceName,
                      matchRate: data.matchRate,
                      uniques: data.uniques,
                    },
                  ],
                },
              });
            }}
          >
            Generate
          </Button>
        </Box>
      </Tooltip>
    );
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {getButton(data)}
      {readyInsightAudienceIds.includes(data.id) && (
        <Tooltip
          title={
            isInsightsAppAccessible ? (
              'Email Insights as PDF or XLSX'
            ) : (
              <span className={styles.insightTooltipContent}>
                <Icon name="Insights" />
                <span>
                  Insight app access is required to mail audience insights. Contact your admin for
                  more information
                </span>
              </span>
            )
          }
          placement="top-end"
          arrow
        >
          <Box>
            <IconButton
              size="small"
              sx={{ p: 2 }}
              onClick={handleEmailClick}
              disabled={!isInsightsAppAccessible}
            >
              <EmailAdd fontSize={24} />
            </IconButton>
          </Box>
        </Tooltip>
      )}

      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={downloadAnchorEl}
        open={openDownload}
        onClose={handleDownloadClose}
        MenuListProps={{
          'aria-labelledby': 'DownloadMenuButton',
        }}
        classes={{
          list: sx({ border: 1, borderColor: 'primary-600', borderRadius: 4 }),
        }}
      >
        <MenuItem
          onClick={() => {
            openDialog({
              type: 'insights',
              otherInfo: {
                action: 'download',
                downloadType: 'pdf',
                insightId: getInsightId(),
                audienceData: data,
              },
            });
            handleDownloadClose();
          }}
          size="small"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilePdf fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            Download as PDF
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDownloadXLSX();
            handleDownloadClose();
          }}
          size="small"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FileXlsx fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            Download as XLSX
          </Box>
        </MenuItem>
      </Menu>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={emailAnchorEl}
        open={openEmail}
        onClose={handleEmailClose}
        MenuListProps={{
          'aria-labelledby': 'EmailMenuButton',
        }}
        classes={{
          list: sx({ border: 1, borderColor: 'primary-600', borderRadius: 4 }),
        }}
      >
        <MenuItem
          onClick={() => {
            openDialog({
              type: 'insights',
              otherInfo: { action: 'email', fileType: 'pdf', audienceData: data },
            });
            handleEmailClose();
          }}
          size="small"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FilePdf fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            Email as PDF
          </Box>
        </MenuItem>
        <MenuItem
          onClick={() => {
            openDialog({
              type: 'insights',
              otherInfo: { action: 'email', fileType: 'xlsx', audienceData: data },
            });
            handleEmailClose();
          }}
          size="small"
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FileXlsx fontSize={24} sx={{ mr: 4, textColor: 'neutral-400' }} />
            Email as XLSX
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const mapState = (state: AppState) => ({
  insightsData: state.table.insightsData,
});

export const InsightsCell = connect(mapState, null)(InsightsCellComponent);
