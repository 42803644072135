import { Dispatch, AnyAction } from 'redux';
import get from 'lodash/get';
import { ThunkDispatch } from 'redux-thunk';

import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import {
  CreateGeofarmedAudienceData,
  UpdateGeofarmedAudienceData,
  FilterStore,
} from 'models/GeofarmedAudience';
import { setRowAnimation } from 'styles/tableRowAnimation';
import { RUMLogger } from 'services/RUMLogger';
import { EMPTY_SELECTED_AUDIENCE_STATUS } from 'constants/audiences';

import { createAction } from '../../utils/actions';
import { API } from '../../api';
import { geofarmedAudienceConstants } from './constants';
import { AppState } from '..';
import { tableConstants } from '../table/constants';
import { tableActions } from '../table/actions';
import { snackbarActions } from '../snackbar/actions';

export interface CreateGeofarmedAudience {
  createGeofarmedAudience: (
    data: CreateGeofarmedAudienceData,
    resetAndClose: () => void,
    filterStore: FilterStore,
  ) => void;
}

export interface UpdateGeofarmedAudience {
  updateGeofarmedAudience: (data: UpdateGeofarmedAudienceData, resetAndClose: () => void) => void;
}

export const geofarmedAudienceActions = {
  createGeofarmedAudience: (
    createAudienceData: CreateGeofarmedAudienceData,
    resetAndClose: () => void,
    filterStore: FilterStore,
  ) => {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(geofarmedAudienceConstants.START_CREATING));
      try {
        const response = await API.GeofarmedAudience.createAudience(createAudienceData);
        const { data } = response;
        RUMLogger.createGeofarmedAudience({
          success: true,
          response,
          audienceData: createAudienceData,
          filterStore,
        });
        const message = data.data?.message;

        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(
          createAction(
            tableConstants.SET_AUDIENCES_SELECTED_STATUSES,
            EMPTY_SELECTED_AUDIENCE_STATUS,
          ),
        );
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
        setRowAnimation();
        resetAndClose();
      } catch (e) {
        const message = get(e, 'data.errorObjects[0].error');
        RUMLogger.createGeofarmedAudience({
          success: false,
          // @ts-ignore
          response: e,
          audienceData: createAudienceData,
          filterStore,
        });
        dispatch(
          snackbarActions.openSnackbar({
            message: message || e,
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(geofarmedAudienceConstants.END_CREATING));
      }
    };
  },
  updateGeofarmedAudience: (
    updateAudienceData: UpdateGeofarmedAudienceData,
    resetAndClose: () => void,
  ) => {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(geofarmedAudienceConstants.START_UPDATING));
      try {
        const { message } = await API.GeofarmedAudience.updateAudience(updateAudienceData);

        if (tableComponentInstanceRef?.current) {
          tableComponentInstanceRef.current.clearSelected();
          tableComponentInstanceRef.current.getNewData();
        }
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
        resetAndClose();
      } catch (e) {
        dispatch(
          snackbarActions.openSnackbar({
            message: get(e, 'errorObjects[0].error', e),
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(geofarmedAudienceConstants.END_UPDATING));
      }
    };
  },
};
