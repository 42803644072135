import axios, { AxiosResponse, Canceler } from 'axios';
import get from 'lodash/get';
import { QueryFunctionContext } from '@tanstack/react-query';
import { SegmentedPartner } from '@applift/platform';

import {
  IPartner,
  ISegment,
  CreateAudienceData,
  ExistingSegmentedAudience,
  EditAudienceData,
  SegmentsMap,
  CreateSegmentedAudienceResponse,
} from '../models/SegmentedAudience';
import { Response, WithResponse } from '../models/Response';
import { getInstance } from './Instance';
import {
  getSegmentedAudienceBreakdownQueryKey,
  getSegmentedAudienceInfoQueryKey,
  getSegmentsByAudienceIdKey,
} from './queryKey';

const AUDIENCE_BREAKDOWN_TIMEOUT = 30000;

export const fetchDataPartners = async (): Promise<IPartner[]> => {
  try {
    const response: AxiosResponse<Response<IPartner[]>> = await getInstance().get(
      '/v2/audience/partnerWithProvider/details',
    );
    return get(response, 'data.responseObject', []);
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

const cancelMap: { [key: string]: { searchText: string; canceler: Canceler } } = {};
export const fetchSegmentsForID = async (
  referenceId: string,
  searchText: string,
): Promise<ISegment[]> => {
  if (cancelMap[referenceId] && cancelMap[referenceId]?.searchText !== searchText) {
    cancelMap[referenceId].canceler('canceled by user');
  }

  try {
    const response: AxiosResponse<Response<ISegment[]>> = await getInstance().get(
      `/v2/audience/segment/details?referenceId=${referenceId}&searchField=${searchText}`,
      {
        cancelToken: new axios.CancelToken((canceler: Canceler) => {
          cancelMap[referenceId] = {
            searchText,
            canceler,
          };
        }),
      },
    );
    return get(response, 'data.responseObject', []);
  } catch (e) {
    return Promise.reject(get(e, 'response.data', {}));
  }
};

let searchCountCanceler: Canceler;
export const getSearchCount = async (
  providerIds: string,
  referenceId: string,
  searchField: string,
): Promise<{ [key: string]: number }> => {
  if (searchCountCanceler) {
    searchCountCanceler('canceled by user');
  }

  try {
    const response: AxiosResponse<Response<{ [key: string]: number }>> = await getInstance().post(
      `/v2/audience/searchCount`,
      {
        providerIds,
        referenceId,
        searchField,
      },
      {
        cancelToken: new axios.CancelToken((c) => {
          searchCountCanceler = c;
        }),
      },
    );
    return get(response, 'data.responseObject', {});
  } catch (e) {
    return Promise.reject(get(e, 'response.data', {}));
  }
};

let fetchSegmentCanceller: Canceler;
export const fetchSegmentsFromSearchText = async (
  providerIds: string,
  referenceId: string,
  searchField: string,
): Promise<{ [key: string]: SegmentsMap }> => {
  if (fetchSegmentCanceller) {
    fetchSegmentCanceller('Cancelled by user');
  }

  try {
    const response: AxiosResponse<Response<{
      [key: string]: SegmentsMap;
    }>> = await getInstance().post(
      `/v2/audience/segment/search/list`,
      {
        providerIds,
        referenceId,
        searchField,
      },
      {
        cancelToken: new axios.CancelToken((c) => {
          fetchSegmentCanceller = c;
        }),
      },
    );
    return get(response, 'data.responseObject', {});
  } catch (e) {
    return Promise.reject(get(e, 'response.data', {}));
  }
};

export const downloadSegmentsSearchResult = async (
  providerIds: string,
  referenceId: string,
  searchField: string,
): Promise<AxiosResponse<string>> => {
  try {
    const response: AxiosResponse<string> = await getInstance().post(
      `/v2/audience/segment/search/download`,
      { providerIds, referenceId, searchField },
    );
    return response;
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const createAudience = async (
  createAudienceData: CreateAudienceData,
): Promise<AxiosResponse<WithResponse<CreateSegmentedAudienceResponse>>> => {
  try {
    const response: AxiosResponse<WithResponse<
      CreateSegmentedAudienceResponse
    >> = await getInstance().post(`/v3/audience/segment/add`, createAudienceData);
    return response;
  } catch (e) {
    return Promise.reject(get(e, 'response', e));
  }
};

export const updateAudience = async (editAudienceData: EditAudienceData): Promise<string> => {
  try {
    const { groupId, ...updatedData } = editAudienceData;
    const response: AxiosResponse<{ message: string }> = await getInstance().put(
      `/v3/audience/segment/${groupId}`,
      updatedData,
    );

    return get(response, 'data.data.message', '');
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const updateSegmentedAudience = async (
  editAudienceData: EditAudienceData,
): Promise<AxiosResponse<WithResponse<{ message: string }>>> => {
  try {
    const { groupId, ...updatedData } = editAudienceData;
    const response: AxiosResponse<WithResponse<{ message: string }>> = await getInstance().put(
      `/v3/audience/segment/${groupId}`,
      updatedData,
    );

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAudience = async (
  audienceId: number,
): Promise<ExistingSegmentedAudience | null> => {
  try {
    const response: AxiosResponse<ExistingSegmentedAudience> = await getInstance().get(
      `/v3/audience/segment/${audienceId}`,
    );
    return get(response, 'data.data', null);
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e) || e);
  }
};

export const getSegmentedAudienceInfo = async (
  queryContext: QueryFunctionContext<ReturnType<typeof getSegmentedAudienceInfoQueryKey['keys']>>,
): Promise<ExistingSegmentedAudience | null> => {
  const { queryKey } = queryContext;
  const { audienceId } = queryKey?.[0] || {};

  try {
    const response: AxiosResponse<ExistingSegmentedAudience> = await getInstance().get(
      `/v3/audience/segment/${audienceId}`,
    );
    return get(response, 'data.data', null);
  } catch (e) {
    return Promise.reject(e);
  }
};

export type GetSegmentPartnerProviderListResponse = AxiosResponse<
  WithResponse<{
    partnerProviderList: SegmentedPartner[];
    totalRecords: number;
    filteredRecords: number;
  }>
>;

export const getSegmentPartnerProviderList = async () => {
  try {
    const response: GetSegmentPartnerProviderListResponse = await getInstance().get(
      '/v3/audience/segment/partner-provider/list',
    );
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export type GetSegmentAudienceBreakdownWidgetDataResponse = {
  srNo: number;
  deviceType?: string;
  gender?: string;
  age?: string;
  state: string;
  'audienceValue(Absolute)': string;
  'audienceValue(%)': number;
  'nationalAvg(%)': number;
  delta: number;
};

export type GetSegmentAudienceBreakdownWidgetResponse = {
  title: string;
  type: string;
  data: GetSegmentAudienceBreakdownWidgetDataResponse[];
};

export type GetSegmentAudienceBreakdownResponse = AxiosResponse<
  WithResponse<{
    audienceId: string;
    audienceName: string;
    audienceDate: string;
    verticalId: string;
    vertical: string;
    widgets: GetSegmentAudienceBreakdownWidgetResponse[];
  }>
>;

export const getSegmentAudienceBreakdown = async (
  queryContext: QueryFunctionContext<
    ReturnType<typeof getSegmentedAudienceBreakdownQueryKey['keys']>
  >,
) => {
  try {
    const { queryKey, signal } = queryContext;
    const { audienceId } = queryKey?.[0] || {};
    const { CancelToken } = axios;
    const source = CancelToken.source();
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled');
    });
    const response: GetSegmentAudienceBreakdownResponse = await getInstance().get(
      `/v3/audience/segment/audience-insights/${audienceId}`,
      {
        cancelToken: source.token,
        timeout: AUDIENCE_BREAKDOWN_TIMEOUT,
        timeoutErrorMessage: 'request timeout',
      },
    );
    return response.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

interface SegmentDetailsResponseVO {
  segmentId: number;
  segmentName: string;
}

interface AudienceSegmentData {
  audienceId: number;
  totalSegments: number;
  segmentDetailsResponseVOList: SegmentDetailsResponseVO[];
}

export const getSegmentsByAudienceId = async (
  queryContext: QueryFunctionContext<ReturnType<typeof getSegmentsByAudienceIdKey.keys>>,
) => {
  const { audienceIds, segmentListSize } = queryContext?.queryKey?.[0] || {};

  try {
    const response: AxiosResponse<WithResponse<AudienceSegmentData[]>> = await getInstance().get(
      `/v3/audience/segment/list-by-audience-ids`,
      {
        params: { audienceIds: audienceIds.join(','), segmentListSize },
      },
    );
    return response.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
