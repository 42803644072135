import React, { useState, useEffect } from 'react';

import { Chip } from 'factor';

import styles from './styles.module.scss';

interface Props {
  initialItems?: string[];
  onChange?: (values: string[]) => void;
  validateRegex?: RegExp;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

export const TagsInput = (props: Props) => {
  const { initialItems, onChange, validateRegex, placeholder, disabled, className = '' } = props;
  const [tags, setTags] = useState(initialItems || []);
  const [focused, setFocused] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');
  const tagInput = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (onChange) {
      onChange(tags);
    }
  }, [onChange, tags]);

  const removeTag = (i: number) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
  };

  const isValid = (val: string) => {
    if (validateRegex) {
      return validateRegex.test(val);
    }
    return true;
  };

  const focusTextField = () => {
    if (tagInput.current) {
      tagInput.current.focus();
    }
  };

  const inputKeyDown = (e: React.KeyboardEvent) => {
    const val = (e.target as HTMLInputElement).value;
    if (['Tab', 'Enter', ',', ';', ' '].includes(e.key) && val) {
      e.preventDefault();
      if (tags.find((tag: string) => tag.toLowerCase() === val.toLowerCase()) || !isValid(val)) {
        return;
      }
      setTags([...tags, val]);
      setTextFieldValue('');
      focusTextField();
    } else if (e.key === 'Backspace' && !val) {
      removeTag(tags.length - 1);
    }
  };

  const handleChange = (value: string) => {
    setTextFieldValue(value);
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!textFieldValue) {
      setFocused(false);
    }

    const val = (e.target as HTMLInputElement).value;
    if (tags.find((tag: string) => tag.toLowerCase() === val.toLowerCase()) || !isValid(val)) {
      return;
    }
    setTags([...tags, val]);
    setTextFieldValue('');
    setFocused(false);
  };

  return (
    <div
      className={['text-field__base', styles.tagsInput, className].join(' ')}
      onClick={focusTextField}
    >
      <label
        className={`text-field__label ${
          focused || tags.length || textFieldValue ? '_focused' : ''
        }`}
      >
        Email
      </label>
      <ul className={styles.tags} onClick={focusTextField}>
        {tags.map((tag: string, i: number) => (
          <li className={styles.tagItem} key={tag}>
            <Chip
              className={styles.tag}
              item={{ label: tag, value: i }}
              onRemove={(e: { value: number }) => removeTag(e.value)}
            />
          </li>
        ))}
        {!disabled && (
          <li className={styles.input}>
            <input
              placeholder={placeholder}
              onKeyDown={inputKeyDown}
              ref={tagInput}
              onChange={(e) => handleChange(e.target.value)}
              value={textFieldValue}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={disabled}
            />
          </li>
        )}
      </ul>
    </div>
  );
};
