import React from 'react';
import { Box, TruncateMiddle, Typography } from '@applift/factor';

import { BudgetTypeIconMapperApplift, IOStatusMapperApplift } from 'constants/insertionOrder';

interface Props {
  id: number;
  name: string;
  budgetTypeId: number;
  disablePadding?: boolean;
  statusId: number;
}

export const Option = (props: Props) => {
  const { id, name, budgetTypeId, disablePadding, statusId } = props;
  const BudgetIcon = BudgetTypeIconMapperApplift[budgetTypeId as any];
  const { label: statusLabel, icon: StatusIcon } = IOStatusMapperApplift[statusId] || {};

  return (
    <Box sx={{ width: 100, maxWidth: 100, display: 'flex', flexDirection: 'row' }}>
      {!!BudgetIcon && <BudgetIcon fontSize={24} sx={{ textColor: 'primary-600' }} />}
      <Box
        sx={{
          display: 'flex',
          width: 100,
          maxWidth: 100,
          flexDirection: 'column',
          ...(disablePadding ? { p: 0 } : { pl: 8 }),
        }}
        style={{ minWidth: 0 }}
      >
        <TruncateMiddle
          arrow
          title={name}
          TypgraphyProps={{ weight: 'demi' }}
          sx={{ mb: 4, width: 100 }}
        >
          {name}
        </TruncateMiddle>
        <Box sx={{ display: 'flex', width: 100, alignItems: 'center' }}>
          <Box style={{ minWidth: 'fit-content' }}>
            <Typography component="span" sx={{ mr: 4, textColor: 'neutral-600' }}>
              ID
            </Typography>
            <Typography component="span">{id}</Typography>
          </Box>

          <Box
            style={{ minWidth: 3, width: 3, height: 3 }}
            sx={{ borderRadius: 'circle', my: 0, mx: 4, bgColor: 'neutral-500' }}
          />

          <Box sx={{ display: 'flex', alignItems: 'center' }} style={{ minWidth: 'fit-content' }}>
            {!!StatusIcon && <StatusIcon fontSize={16} sx={{ mr: 4 }} />}
            {statusLabel}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
