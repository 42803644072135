import React from 'react';
import { DataGrid, ColumnDef } from '@applift/datagrid';
import { CellContext } from '@tanstack/react-table';
import { Box, TruncateMiddle, TypoTooltip, sx } from '@applift/factor';
import { Delete } from '@applift/icons';

import { BudgetTypeIconMapperApplift, IOStatusMapperApplift } from 'constants/insertionOrder';
import { InsertionOrderBasic } from 'models/InsertionOrder';

interface Props {
  onRemove: () => void;
  selectedInsertionOrder: InsertionOrderBasic;
}

const getColDef = (removeItem: (ioId: number) => void): ColumnDef<InsertionOrderBasic>[] => [
  {
    header: 'ID',
    id: 'ioId',
    cell: (props: CellContext<InsertionOrderBasic, any>) => props.row.original.ioId,
    size: 120,
  },
  {
    header: 'Insertion Order Name',
    id: 'ioName',
    cell: (props: CellContext<InsertionOrderBasic, any>) => {
      const { ioBudgetTypeId, ioName } = props.row.original || {};
      const BudgetIcon = BudgetTypeIconMapperApplift[ioBudgetTypeId];

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 100, maxWidth: 100 }}>
          {!!BudgetIcon && <BudgetIcon fontSize={24} sx={{ textColor: 'primary-600', mr: 8 }} />}
          <Box sx={{ maxWidth: 100, width: 100 }} style={{ minWidth: 0 }}>
            <TruncateMiddle
              arrow
              title={ioName}
              sx={{ maxWidth: 100, width: 100 }}
              style={{ minWidth: 0 }}
            >
              {ioName}
            </TruncateMiddle>
          </Box>
        </Box>
      );
    },
    size: 574,
  },
  {
    header: 'Status',
    id: 'ioStatusId',
    cell: (props: CellContext<InsertionOrderBasic, any>) => {
      const { ioStatusId } = props.row.original || {};
      const { icon: StatusIcon, label } = IOStatusMapperApplift[ioStatusId] || {};

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 100, maxWidth: 100 }}>
          {!!StatusIcon && <StatusIcon fontSize={16} sx={{ mr: 4 }} />}
          <TypoTooltip arrow title={label}>
            {label || '-'}
          </TypoTooltip>
        </Box>
      );
    },
    size: 152,
  },
  {
    header: 'Action',
    id: 'action',
    cell: (props: CellContext<InsertionOrderBasic, any>) => {
      const { ioId } = props.row.original || {};

      return (
        <Box
          onClick={() => removeItem(ioId)}
          sx={{ display: 'flex', alignItems: 'center' }}
          role="button"
          style={{ cursor: 'pointer' }}
        >
          <Delete fontSize={24} sx={{ textColor: 'neutral-400' }} />
        </Box>
      );
    },
    size: 80,
  },
];

export const SelectedInsertionOrderTable = (props: Props) => {
  const { onRemove, selectedInsertionOrder } = props;

  const colDef = React.useMemo(() => getColDef(onRemove), [onRemove]);
  const rowKeyExtractor = React.useCallback((row: InsertionOrderBasic) => `${row.ioId}`, []);

  return (
    <Box style={{ height: 82 }}>
      <DataGrid
        data={selectedInsertionOrder ? [selectedInsertionOrder] : []}
        getRowId={rowKeyExtractor}
        columns={colDef}
        hideFooter
        hideHeader
        headerHeight={40}
        rowHeight={40}
        showCellRightBorder
        showColumnRightBorder
        classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      />
    </Box>
  );
};
