import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from 'factor';

import { AppState } from 'store';
import { TableAudienceItem } from 'models/Table';
import { applicationActions, OpenDialog } from 'store/app/actions';
import { ICampaign } from 'api/AddToCampaign';
import { TDialog } from 'store/app/reducer';
import { CAMPAIGN_MAX_AUDIENCE_COUNT, PRE_BID_AUDIENCE_TYPE_ID } from 'constants/audiences';
import { Tooltip } from '@applift/factor';

export interface ICampaignInformation {
  length: void;
  id: number;
  name: string;
  status: string;
  clicks: number;
  impression: number;
  conversionCount: number;
  uniqueCount: number;
}

export interface SelectedIdsCollection {
  groups: number[];
  campaigns: number[];
}

interface Props extends OpenDialog {
  className?: string;
  selectedAudiences: TableAudienceItem[];
  dialog: TDialog;
}

export type Option<T = any> = {
  label: string;
  value: T;
};

export interface OptionID extends Option {
  id: number;
  options?: Option[];
}

export interface GroupsList {
  value: number;
  label: string;
  options?: OptionID[];
}

export interface ICampaignInformationWithOption extends ICampaign, Option<number> {
  isSelected: boolean;
}

const AddToCampaignButtonComponent = memo((props: Props) => {
  const { className = '', selectedAudiences, openDialog } = props;
  const includedCampaignList = useMemo(
    () => (selectedAudiences.length === 1 ? selectedAudiences[0].includedCampaignList : []),
    [selectedAudiences],
  );
  const excludedCampaignList = useMemo(
    () => (selectedAudiences.length === 1 ? selectedAudiences[0].excludedCampaignList : []),
    [selectedAudiences],
  );

  if (!selectedAudiences.length) {
    return null;
  }

  const maxAudiencesExceeded = selectedAudiences.length > CAMPAIGN_MAX_AUDIENCE_COUNT;

  return (
    <Tooltip
      title={(() => {
        if (maxAudiencesExceeded)
          return `Maximum limit of ${CAMPAIGN_MAX_AUDIENCE_COUNT} audiences per campaign is reached`;
        return null;
      })()}
    >
      <span>
        <Button
          variant="tertiary2"
          size="large"
          className={className}
          onClick={() =>
            openDialog({
              type: 'addToCampaign',
              otherInfo: {
                includedCampaignIds: includedCampaignList ?? [],
                excludedCampaignIds: excludedCampaignList ?? [],
                audienceName: selectedAudiences[0].audienceName,
                selectedAudienceIds: selectedAudiences.map((audience) => audience.id),
                audienceStatus: selectedAudiences[0].status,
                fetchAssociatedCampaigns: selectedAudiences.every(
                  (item) => item.audienceTypeId !== PRE_BID_AUDIENCE_TYPE_ID,
                ),
              },
            })
          }
          disabled={maxAudiencesExceeded}
        >
          Add To Campaign
        </Button>
      </span>
    </Tooltip>
  );
});

const mapState = (state: AppState) => ({
  selectedAudiences: state.table.selectedAudiences,
  dialog: state.app.dialog,
});

const mapAction = {
  openDialog: applicationActions.openDialog,
};

export const AddToCampaignButton = connect(mapState, mapAction)(AddToCampaignButtonComponent);
