import { useInfiniteQuery } from '@tanstack/react-query';
import { API } from 'api';
import { InsertionOrderListResponse } from 'api/InsertionOrder';
import { getInsertionOrderListKey } from 'api/queryKey';

interface UseInsertionOrderListParams {
  searchField?: string;
  sortBy?: string;
  ioStatusIdsList?: number[];
  noOfEntries?: number;
}

export const useInsertionOrderList = (
  params: UseInsertionOrderListParams,
  options?: { enabled: boolean },
) => {
  const { enabled } = options || {};

  return useInfiniteQuery({
    queryKey: getInsertionOrderListKey.keys({ scope: 'getInsertionOrderList', ...params }),
    queryFn: API.InsertionOrder.getInsertionOrderList,

    getNextPageParam: (
      lastPage: InsertionOrderListResponse,
      allPages: InsertionOrderListResponse[],
    ) => {
      const recordsLoaded = allPages.reduce(
        (acc, curr) => acc + (curr?.ioBasicDetailsList?.length || 0),
        0,
      );
      if (recordsLoaded < (lastPage?.filteredRecords || -1)) {
        return allPages.length + 1;
      }
      return undefined;
    },
    enabled,
  });
};
