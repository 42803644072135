import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Tabs,
  Button,
  Chip,
  Icon,
  TextField,
  UploadFileButtons,
  ProgressCircle,
  Tooltip,
} from 'factor';
import { ORIGIN_URL, validationUtils, filesUtils } from 'iqm-framework';
import Axios from 'axios';
import { debounce, uniq, uniqBy, uniqueId, uniqWith } from 'lodash';

import { URLPreviewMetadata } from 'models/ContextualAudience';
import { OpenSnackbar } from 'store/snackbar/actions';
import {
  getRecommendedKeywords,
  searchKeywords as APISearchKeywords,
  getURLPreview,
  validateUrl,
} from 'api/ContextualAudience';
import { RUMLogger } from 'services/RUMLogger';
import { fileUploadErrorMsgs } from 'services/RUMLogger/actions/contextualAudience';

import Skeleton from '../../../../Skeleton';
import EmptyListIllustration from '../EmptyListIllustration';

import styles from './styles.module.scss';

interface IUrlObj {
  url: string;
  validating: boolean;
  isValid: boolean;
}

interface Props extends OpenSnackbar {
  addedKeywords: string[];
  addedUrls: string[];
  addedRecommendedKeywords: string[];
  setAddedKeywords: (addedKeywords: string[]) => void;
  setAddedUrls: (data: string[]) => void;
  setAddedRecommendedKeywords: (data: string[]) => void;
  isEdit?: boolean;
  validatedUrlsObj: IUrlObj[];
  setValidatedUrlsObj: any;
  alreadyValidatedUrls: any;
}

type Tab = {
  title: string;
  value: number;
};

const ContextualAudienceKeywordsAndUrls = (props: Props) => {
  const {
    openSnackbar,
    addedKeywords,
    addedUrls,
    addedRecommendedKeywords,
    setAddedKeywords,
    setAddedUrls,
    setAddedRecommendedKeywords,
    isEdit,
    validatedUrlsObj,
    setValidatedUrlsObj,
    alreadyValidatedUrls,
  } = props;
  const [tab, setTab] = useState(1);
  const [keywordsSearchList, setKeywordsSearchList] = useState<string[]>([]);
  const [keywordSearchValue, setKeywordSearchValue] = useState<string>('');
  const [isKeywordsSearchListOpen, setIsKeywordsSearchListOpen] = useState(false);
  const [urlInputValue, setUrlInputValue] = useState<string>('');
  const [isUrlInputValueValid, setIsUrlInputValueValid] = useState(false);
  const [urlMetadata, setUrlMetadata] = useState<URLPreviewMetadata>();
  const [isUrlMetadataLoading, setIsUrlMetadataLoading] = useState<boolean>(false);
  const [recommendedKeywords, setRecommendedKeywords] = useState<string[]>([]);
  const [isKeywordsSearchLoading, setIsKeywordsSearchLoading] = useState<boolean>(false);
  const [isRecommendedKeywordsLoading, setIsRecommendedKeywordsLoading] = useState<boolean>(false);
  const lastCancelTokenRef = useRef<any>(null);
  const validateUrlTokenRef = useRef<any>(null);

  useEffect(() => {
    if (!addedKeywords.length) {
      setRecommendedKeywords([]);
    }
  }, [addedKeywords]);

  const checkHttp = (url: string) => {
    if (url.includes('https') || url.includes('http')) {
      return url;
    }
    return `https://${url}`;
  };

  const validatedUrl = useCallback(
    async (urlArr: string[]) => {
      urlArr.forEach((url) => alreadyValidatedUrls.current.push(url));
      try {
        const source = Axios.CancelToken.source();
        validateUrlTokenRef.current = source;
        const domainUrl = urlArr.map((url) => checkHttp(url));
        const result = await validateUrl(domainUrl, source);
        if (result.success && result.data) {
          const urlObj = Object.entries(result.data).map((value) => ({
            url: value[0],
            isValid: value[1],
            validating: false,
          }));

          setValidatedUrlsObj((prev: IUrlObj[]) =>
            prev.map((obj: IUrlObj) => {
              if (urlArr.includes(obj.url)) {
                return {
                  url: obj.url,
                  validating: false,
                  isValid: urlObj.filter((tem) => tem.url === obj.url)[0]?.isValid,
                };
              }
              return obj;
            }),
          );
        }
        validateUrlTokenRef.current = null;
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.log(`error: ${e}`);
      }
    },
    [setValidatedUrlsObj, alreadyValidatedUrls],
  );

  useEffect(() => {
    const filteredUrl = addedUrls
      .map((url) => checkHttp(url))
      .filter((url) => !alreadyValidatedUrls.current.includes(url));
    if (filteredUrl.length > 0) {
      setValidatedUrlsObj((prev: any[]) => [
        ...prev,
        ...filteredUrl.map((urls) => ({ url: urls, validating: true, isValid: null })),
      ]);
      validatedUrl(filteredUrl);
    }
  }, [addedUrls, validatedUrl, setValidatedUrlsObj, alreadyValidatedUrls]);

  // eslint-disable-next-line
  const searchKeywords = useCallback(
    debounce(async (value: string) => {
      if (value.length >= 3) {
        setIsKeywordsSearchLoading(true);
        const { data, success } = await APISearchKeywords(value.trim());
        setIsKeywordsSearchLoading(false);
        if (data && success) {
          setKeywordsSearchList(data);
          if (data.length > 0) {
            setIsKeywordsSearchListOpen(true);
          } else {
            setIsKeywordsSearchListOpen(false);
          }
        }
      }
    }, 300),
    [],
  );

  const tabs: Tab[] = useMemo(
    () => [
      {
        title: `By Keywords (${addedKeywords.length})`,
        value: 1,
      },
      {
        title: `By URL (${addedUrls.length})`,
        value: 2,
      },
    ],
    [addedKeywords, addedUrls],
  );

  const onFileUpload = (files: FileList) => {
    if (files[0].size > 3145728) {
      RUMLogger.uploadContextualAudienceFile({
        success: false,
        file: files[0],
        errorMsg: fileUploadErrorMsgs.size,
      });
      openSnackbar({ type: 'error', message: "File size can't be larger that 3MB" });
      return;
    }
    filesUtils.getFileData(
      files[0],
      (data: string[][]) => {
        let isDataAddedOrAlreadyPresent = false;
        let datadogErr: string | null = fileUploadErrorMsgs.default;
        let byType;
        const dataCopy = Array.isArray(data) ? [...data] : data;

        if (data) {
          const header = data.shift();
          byType = header && header[0].toLowerCase();
          if (byType === 'keyword') {
            const addedKeywordsLowercase = addedKeywords.map((keyword) => keyword.toLowerCase());

            const fileKeywords = uniqBy(
              data.map((keyword) => keyword[0]),
              (keyword) => keyword?.toLowerCase(),
            ).filter((val) => val && !addedKeywordsLowercase.includes(val.toLowerCase()));
            const fileAndExistingKeywords = [...addedKeywords, ...fileKeywords];
            const keywordsToAdd = uniq(fileAndExistingKeywords);
            if (tab === 1) {
              setAddedKeywords(keywordsToAdd);
            }

            if (fileKeywords.length && tab === 1) {
              isDataAddedOrAlreadyPresent = true;
              openSnackbar({
                message: `${fileKeywords.length} ${
                  fileKeywords.length > 1 ? 'Keywords' : 'Keyword'
                } added successfully`,
                type: 'success',
              });
            }

            if (!fileKeywords.length && data.length) {
              isDataAddedOrAlreadyPresent = true;
              openSnackbar({
                message: `${data.length > 1 ? 'Keywords' : 'Keyword'} already added.`,
                type: 'warning',
              });
            }

            if (!isDataAddedOrAlreadyPresent) {
              datadogErr =
                tab !== 1 ? fileUploadErrorMsgs.headerMismatch : fileUploadErrorMsgs.noKeywords;
            }
            RUMLogger.uploadContextualAudienceFile({
              success: isDataAddedOrAlreadyPresent,
              errorMsg: isDataAddedOrAlreadyPresent ? undefined : datadogErr,
              file: files[0],
              byType,
              parseResult: dataCopy,
              numFileKeywords: fileKeywords.length,
              numFileUrls: 0,
              numKeywords:
                tab === 1
                  ? fileKeywords.length - (fileAndExistingKeywords.length - keywordsToAdd.length)
                  : 0,
              numUrls: 0,
            });
            datadogErr = null;
          } else if (byType === 'url') {
            const fileURLs = uniq(
              data.map((url) => url[0]).filter((fileURL) => validationUtils.isValidURL(fileURL)),
            ).filter((val) => !addedUrls.includes(val));
            const fileAndExistingUrls = [...addedUrls, ...fileURLs];
            const urlsToAdd = uniq(fileAndExistingUrls);
            if (tab === 2) {
              setAddedUrls(urlsToAdd.map((url) => checkHttp(url)));
            }

            if (fileURLs.length && tab === 2) {
              isDataAddedOrAlreadyPresent = true;
              openSnackbar({
                message: `${fileURLs.length} ${
                  fileURLs.length > 1 ? 'URLs' : 'URL'
                } added successfully`,
                type: 'success',
              });
            }

            if (!fileURLs.length && data.length) {
              isDataAddedOrAlreadyPresent = true;
              openSnackbar({
                message: `${data.length > 1 ? 'URLs' : 'URL'} already added.`,
                type: 'warning',
              });
            }

            if (!isDataAddedOrAlreadyPresent) {
              datadogErr =
                tab !== 2 ? fileUploadErrorMsgs.headerMismatch : fileUploadErrorMsgs.noUrls;
            }
            RUMLogger.uploadContextualAudienceFile({
              success: isDataAddedOrAlreadyPresent,
              errorMsg: isDataAddedOrAlreadyPresent ? undefined : datadogErr,
              file: files[0],
              byType,
              parseResult: dataCopy,
              numFileKeywords: data.length - fileURLs.length,
              numFileUrls: fileURLs.length,
              numKeywords: 0,
              numUrls:
                tab === 2 ? fileURLs.length - (fileAndExistingUrls.length - urlsToAdd.length) : 0,
            });
            datadogErr = null;
          } else if (!data.length) {
            datadogErr = fileUploadErrorMsgs.noRows;
          } else {
            datadogErr = fileUploadErrorMsgs.invalidHeader;
          }
        }
        if (!isDataAddedOrAlreadyPresent) {
          openSnackbar({
            message: 'Unable to read file content. Please try again.',
            type: 'error',
          });
        }
        if (datadogErr) {
          RUMLogger.uploadContextualAudienceFile({
            success: false,
            errorMsg: datadogErr,
            file: files[0],
            byType,
            parseResult: dataCopy,
          });
        }
      },
      () => {
        RUMLogger.uploadContextualAudienceFile({
          success: false,
          errorMsg: fileUploadErrorMsgs.fileUtils,
          file: files[0],
        });
        openSnackbar({
          message: 'Upload file error',
          type: 'error',
        });
      },
    );
  };

  const onAddKeyword = async (keyword: string, isRecommendedKeyword: boolean = false) => {
    let splitKeyword = keyword
      .split(',')
      .map((value) => value.trim())
      .filter(
        (value) =>
          value.length && !addedKeywords.some((item) => item.toLowerCase() === value.toLowerCase()),
      );
    splitKeyword = uniqWith(splitKeyword, (a, b) => a.toLowerCase() === b.toLowerCase());
    if (splitKeyword.length) {
      setIsKeywordsSearchListOpen(false);
      setAddedKeywords([...addedKeywords, ...splitKeyword]);
      if (isRecommendedKeyword) {
        setAddedRecommendedKeywords([...addedRecommendedKeywords, keyword]);
      }
      setKeywordSearchValue('');
      setIsRecommendedKeywordsLoading(true);
      const { data, success } = await getRecommendedKeywords(splitKeyword[0]);
      setIsRecommendedKeywordsLoading(false);
      if (data && success) {
        setRecommendedKeywords(data);
      }
    } else {
      openSnackbar({
        message: 'Keyword(s) already added',
        type: 'warning',
      });
    }
  };

  const onRemoveKeyword = (keyword: string) => {
    setAddedKeywords(addedKeywords.filter((addedKeyword) => keyword !== addedKeyword));
    setAddedRecommendedKeywords(
      addedRecommendedKeywords.filter((addedKeyword) => keyword !== addedKeyword),
    );
  };

  const preventMultipleSpaceInput = (value: string) => {
    return value.replace(/[ ]{2,}/gm, ' ');
  };

  const renderKeywordsTab = () => {
    return (
      <div className={styles.keywords}>
        {!isEdit && (
          <div className={styles.addKeywords}>
            <div className={styles.searchAndUploadKeywords}>
              <div className={styles.keywordSearch}>
                <div className={styles.keywordSearchTip}>
                  Type the keyword and press 'Enter' to add
                </div>
                <div className={styles.keywordSearchWithButton}>
                  <div className={styles.keywordSearchField}>
                    <TextField
                      className={styles.keywordSearchInput}
                      label="Keyword"
                      placeholder="Search here"
                      value={keywordSearchValue}
                      onChange={(value: string) => {
                        setIsKeywordsSearchListOpen(false);
                        setKeywordSearchValue(preventMultipleSpaceInput(value));
                        searchKeywords(value);
                      }}
                      variant="withoutTickbox"
                      underline={false}
                      onSubmitted={(value: string) => {
                        if (value?.trim().length) {
                          setIsKeywordsSearchListOpen(false);
                          onAddKeyword(value);
                        }
                      }}
                      helpActions={isKeywordsSearchLoading && <ProgressCircle size={18} />}
                      showResetBtn
                    />
                    {isKeywordsSearchListOpen && (
                      <div className={styles.keywordSearchList}>
                        {keywordsSearchList.map((keyword) => (
                          <div
                            className={styles.keywordSearchListItem}
                            onClick={() => {
                              setIsKeywordsSearchListOpen(false);
                              setKeywordSearchValue(keyword);
                            }}
                          >
                            {keyword}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <Button
                    className={styles.keywordSearchButton}
                    variant="bold-link"
                    disabled={!keywordSearchValue.trim().length}
                    onClick={() => onAddKeyword(keywordSearchValue)}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <UploadFileButtons
                className={styles.uploadKeywords}
                onUpload={onFileUpload}
                buttonText="Upload File"
                fileTypes={['xlsx', 'csv']}
                sampleFileURL={`${ORIGIN_URL}/example-files/contextual-audience-keywords-example.csv`}
                sampleFileName="contextual-audience-keywords-example"
              />
            </div>
            {((addedKeywords.length > 0 && isRecommendedKeywordsLoading) ||
              (!isRecommendedKeywordsLoading && recommendedKeywords.length > 0)) && (
              <div className={styles.recommendedKeywords}>
                <div className={styles.recommendedKeywordsTitle}>Recommended Keywords</div>
                <ul className={styles.recommendedKeywordsChips}>
                  {isRecommendedKeywordsLoading
                    ? [...Array(12)].map(() => <Skeleton className={styles.keywordSkeletonChip} />)
                    : recommendedKeywords.map((keyword) => (
                        <li
                          key={uniqueId(keyword)}
                          onClick={() => onAddKeyword(keyword, true)}
                          className={styles.keywordChipIcon}
                        >
                          <Chip
                            className={styles.keywordChip}
                            item={{
                              label: keyword,
                              icon: <Icon name="Plus" />,
                              value: keyword,
                            }}
                            readonly
                          />
                        </li>
                      ))}
                </ul>
              </div>
            )}
          </div>
        )}
        {addedKeywords.length > 0 ? (
          <>
            {!isEdit && <hr className={styles.divider} />}
            <div className={styles.addedKeywords}>
              <div className={styles.addedKeywordsTitle}>
                {`Added Keywords (${addedKeywords.length})`}
              </div>
              <div className={styles.addedKeywordsChipsContainer}>
                <div className={styles.addedKeywordsChips}>
                  {addedKeywords.map((keyword) => (
                    <Chip
                      className={styles.keywordChip}
                      displayTooltip
                      item={{
                        label: keyword,
                        value: keyword,
                      }}
                      key={uniqueId(keyword)}
                      onRemove={() => onRemoveKeyword(keyword)}
                      readonly={isEdit}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {isEdit && (
              <EmptyListIllustration
                className={styles.emptyList}
                text="No Keywords added in this audience"
              />
            )}
          </>
        )}
      </div>
    );
  };

  // eslint-disable-next-line
  const getUrlPreviewFn = useCallback(
    debounce(async (url) => {
      setIsUrlMetadataLoading(true);
      try {
        if (lastCancelTokenRef.current !== null) {
          lastCancelTokenRef.current.cancel();
          setUrlMetadata(undefined);
        }
        const source = Axios.CancelToken.source();
        lastCancelTokenRef.current = source;
        const result = await getURLPreview(url, source);
        lastCancelTokenRef.current = null;
        setUrlMetadata(result.data);
      } catch (e) {
        setUrlMetadata(undefined);
      }
      setIsUrlMetadataLoading(false);
    }, 300),
    [],
  );

  useEffect(() => {
    if (isUrlInputValueValid && urlInputValue.length) {
      getUrlPreviewFn(urlInputValue);
    }
  }, [urlInputValue, getUrlPreviewFn, isUrlInputValueValid]);

  const onAddUrl = (url: string) => {
    if (addedUrls.includes(checkHttp(url))) {
      openSnackbar({
        message: 'URL already added.',
        type: 'warning',
      });
    } else {
      setAddedUrls([...addedUrls, checkHttp(url)]);
      setUrlInputValue('');
      openSnackbar({
        message: 'URL successfully added.',
        type: 'success',
      });
    }
  };

  const onDeleteUrl = (url: string) => {
    setAddedUrls(addedUrls.filter((addedUrl) => addedUrl !== url));
    setValidatedUrlsObj((prev: any) => prev.filter((obj: any) => obj.url !== url));
    alreadyValidatedUrls.current = alreadyValidatedUrls.current.filter(
      (Vurl: string) => Vurl !== url,
    );
  };

  const isAnyProgrammaticUrlInvalid = useMemo(
    () => validatedUrlsObj.some((val) => !val.isValid && !val.validating),
    [validatedUrlsObj],
  );

  const showProgrammaticAlert = (isValid: boolean | null) => {
    switch (isValid) {
      case false:
        return (
          <Tooltip
            label="The URL is outside of programmatic universe."
            position="top"
            auto={false}
            labelMaxWidth={170}
            portal
          >
            <Icon className={styles.urlItemWarningIcon} name="WarningTriangle" />
          </Tooltip>
        );
      case null:
        return (
          <Tooltip
            label="This URL could not be validated. It may not show the targeted ad."
            position="top"
            auto={false}
            labelMaxWidth={170}
            portal
          >
            <Icon className={styles.urlItemWarningIcon} name="AlertCircle" />
          </Tooltip>
        );
      default:
        return <></>;
    }
  };

  const renderURLsTab = () => {
    return (
      <div className={styles.urls}>
        {!isEdit && (
          <div className={styles.addUrls}>
            <div className={styles.inputAndUploadUrls}>
              <div className={styles.urlInputContainer}>
                <div className={styles.urlInputTip}>Type the URL and press 'Enter' to add</div>
                <div className={styles.urlInputWithButton}>
                  <TextField
                    className={styles.urlInput}
                    label="URL"
                    placeholder="Add URL"
                    value={urlInputValue}
                    onChange={(value: string) => setUrlInputValue(value)}
                    variant="withoutTickbox"
                    validationRules={[validationUtils.validateURL('URL')]}
                    onValidate={setIsUrlInputValueValid}
                    underline={false}
                    type="url"
                    onSubmitted={() => {
                      if (isUrlInputValueValid && urlInputValue) {
                        onAddUrl(urlInputValue);
                      }
                    }}
                  />
                  <Button
                    className={styles.urlInputButton}
                    variant="bold-link"
                    disabled={!urlInputValue || !isUrlInputValueValid}
                    onClick={() => urlInputValue && onAddUrl(urlInputValue)}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <UploadFileButtons
                className={styles.uploadUrls}
                onUpload={onFileUpload}
                buttonText="Upload File"
                fileTypes={['xlsx', 'csv']}
                sampleFileURL={`${ORIGIN_URL}/example-files/contextual-audience-urls-example.csv`}
                sampleFileName="contextual-audience-urls-example"
              />
            </div>
            {isUrlInputValueValid && urlInputValue.length > 0 && (
              <div className={styles.urlPreviewContainer}>
                <div className={styles.urlPreviewTitle}>URL Preview</div>
                <div className={styles.urlPreview}>
                  {isUrlMetadataLoading ? (
                    <>
                      <div className={styles.urlPreviewImgAndInfo}>
                        <Skeleton className={styles.urlPreviewImg} />
                        <div className={styles.urlPreviewInfo}>
                          <Skeleton className={styles.urlPreviewInfoTitleSkeleton} />
                          <Skeleton className={styles.urlPreviewInfoDescriptionSkeleton} />
                        </div>
                      </div>
                      <Skeleton className={styles.urlPreviewLink} />
                    </>
                  ) : (
                    <>
                      {urlMetadata ? (
                        <>
                          <div className={styles.urlPreviewImgAndInfo}>
                            <div className={styles.urlPreviewImg}>
                              <img src={urlMetadata?.image} alt="url-preview-img" />
                            </div>
                            <div className={styles.urlPreviewInfo}>
                              <p className={styles.urlPreviewInfoTitle}>{urlMetadata?.title}</p>
                              <div className={styles.urlPreviewInfoDescription}>
                                {urlMetadata?.description}
                              </div>
                            </div>
                          </div>
                          <div className={styles.urlPreviewLink}>{urlMetadata?.url}</div>
                        </>
                      ) : (
                        <div className={styles.urlPreviewInfoTitle}>No URL information found</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <>
          {addedUrls.length > 0 ? (
            <>
              {!isEdit && <hr className={styles.divider} />}
              <div className={styles.addedUrls}>
                <div className={styles.addedUrlsHeader}>
                  <div
                    className={styles.addedUrlsHeaderTitle}
                  >{`Added URLs (${addedUrls.length})`}</div>
                  {isAnyProgrammaticUrlInvalid && (
                    <div className={styles.addedUrlsHeaderWarning}>
                      <div className={styles.allAssinged}>
                        <Icon name="WarningTriangle" />
                        <div className={styles.text}>
                          Audiences won't be captured from URL outside the programmatic universe
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={styles.addedUrlsItems}
                  style={{ '--item-height': isEdit ? '445px' : '150px' } as React.CSSProperties}
                >
                  {validatedUrlsObj.map((urlObj: any) => (
                    <div className={styles.urlItem} key={urlObj.url}>
                      <Icon name="Link" />
                      <div className={styles.urlItemText}>{urlObj.url}</div>
                      {!urlObj.validating ? (
                        showProgrammaticAlert(urlObj.isValid)
                      ) : (
                        <ProgressCircle className="ml-auto" size={16} borderWidth={2} />
                      )}
                      {!isEdit && (
                        <Button
                          iconName="Close"
                          className={styles.urlItemRemoveButton}
                          variant="tertiary"
                          onClick={() => onDeleteUrl(urlObj.url)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              {isEdit && (
                <EmptyListIllustration
                  className={styles.emptyList}
                  text="No URLs added in this audience"
                />
              )}
            </>
          )}
        </>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabs}
        items={tabs}
        value={tab}
        onChange={({ value }: Tab) => setTab(value)}
        variant="normal"
      />
      {tab === 1 && renderKeywordsTab()}
      {tab === 2 && renderURLsTab()}
    </div>
  );
};

export default ContextualAudienceKeywordsAndUrls;
