import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isBoolean from 'lodash/isBoolean';
import moment, { Moment } from 'moment';
import { isEmpty, get } from 'lodash';
import {
  SelectIOCampaignList,
  NormalizedIOCampaignList,
  SelectIOCampaignApiRefType,
} from '@applift/platform';
import { Dialog, TextField, NumberFormatter, Icon, Pill, Button, Tooltip } from 'factor';
import { applicationActions, ResetSidebar } from 'store/app/actions';
import { AppState } from 'store';
import { TDialog } from 'store/app/reducer';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { ICampaignInformationWithOption } from 'components/Audiences/TableSelectedActions/AddToCampaignButton';
import { tableActions, UpdateSorting } from 'store/table/actions';
import { COMMON_DIALOG_MODAL_PROPS, SORTING_DIRECTION, SORTING_VALUE } from 'constants/audiences';
import { SOMETHING_WENT_WRONG_MSG } from 'constants/errors';
import { API } from 'api';
import { CampaignDetail, IAudienceDetails } from 'api/CampaignAudience';
import { tableStatusIconsMap } from 'components/consts';
import {
  campaignAudienceActions,
  CreateCampaignAudience,
  UpdateCampaignAudience,
} from 'store/campaignAudience/actions';
import { EditCampaignAudienceData } from 'models/CampaignAudience';
import { audienceNameValidationRule } from 'utils/validation';
import { RUM_ACTION_ATTRIBUTE } from 'services/RUMLogger/constants';

import { DatePicker, ONE_DAY_SECONDS } from '../DatePicker';
import CampaignsTable, { ICampaignAttributeSelection } from '../CampaignTable';

import styles from './styles.module.scss';

const CAMPAIGN_STATUSES = [
  'draft',
  'pending',
  'deleted',
  'rejected',
  'running',
  'expired',
  'paused',
];

interface Props
  extends ResetSidebar,
    OpenSnackbar,
    CreateCampaignAudience,
    UpdateCampaignAudience,
    UpdateSorting {
  handleClose?: (() => void) | null;
  dialog: TDialog;
  submitInProgress: boolean;
  title: string;
  isView?: boolean;
  dialogVisibility?: boolean;
  isIncluded?: boolean;
}

const CampaignAudienceDialogComponent = (props: Props) => {
  const {
    handleClose,
    resetSidebar,
    updateSorting,
    dialog,
    title,
    isView,
    isIncluded,
    createCampaignAudience,
    dialogVisibility = true,
    updateCampaignAudience,
    openSnackbar,
  } = props;

  const [audienceName, setAudienceName] = useState<string>('');
  const [previousAudienceName, setPreviousAudienceName] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedCampaigns, setSelectedCampaigns] = useState<ICampaignInformationWithOption[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [cmpAttributeSelection, setCmpAttributeSelection] = useState<ICampaignAttributeSelection>({
    clicks: [],
    conversions: [],
    impressions: [],
  });
  const [audienceDetails, setAudienceDetails] = useState<IAudienceDetails>();
  const history = useHistory();
  const { type, audienceId } = dialog;
  const isDuplicateAudience = type === 'duplicate';
  const TextFieldInputRef = useRef<HTMLInputElement | null>(null);
  const selectCampaignsRef = useRef<SelectIOCampaignApiRefType>();

  useEffect(() => {
    if (audienceId && dialogVisibility && history.location.state) {
      if (!isDuplicateAudience) {
        setIsEdit(true);
      }
      const { audienceData } = history.location.state as EditCampaignAudienceData;
      setAudienceName(
        isDuplicateAudience ? `Copy of ${audienceData.audienceName}` : audienceData.audienceName,
      );
      setPreviousAudienceName(audienceData.audienceName);
      setStartDate(new Date(audienceData.startDate));
      setEndDate(new Date(audienceData.endDate));
    }
  }, [audienceId, history, isDuplicateAudience, dialogVisibility]);

  useEffect(() => {
    if (selectCampaignsRef.current) {
      const rowSelection = Array.from(selectCampaignsRef.current.getRowSelectionData().entries());

      if (rowSelection.length > selectedCampaigns.length) {
        const newSelection = rowSelection
          .filter((selected) =>
            selectedCampaigns.find((cmp) => cmp.id === Number.parseInt(selected[0], 10)),
          )
          .reduce((acc, curr) => {
            Object.assign(acc, { [curr[0]]: curr[1] });
            return acc;
          }, {} as Record<string, NormalizedIOCampaignList>);

        selectCampaignsRef.current.reinitializeRowSelection(newSelection);
      }
    }
  }, [selectedCampaigns]);

  const resetAndClose = useCallback(() => {
    if (handleClose) {
      handleClose();
    }
    resetSidebar();
    if (isEdit || isDuplicateAudience) {
      history.goBack();
    }
  }, [handleClose, history, isEdit, resetSidebar, isDuplicateAudience]);

  const handleCrossClick = useCallback(() => {
    setSelectedCampaigns((selected) =>
      Array.isArray(selected) ? selected.map((cmp) => ({ ...cmp, isSelected: false })) : selected,
    );
  }, [setSelectedCampaigns]);

  const removeItemFromArray = (array: any[], itemToRemove: any) => {
    if (array.includes(itemToRemove)) {
      const index = array.indexOf(itemToRemove);
      if (index) {
        array.splice(index, 1);
      }
    }
    return array;
  };

  const removeCampaignsFromRequest = useCallback(() => {
    selectedCampaigns.forEach((campaign) => {
      if (campaign.isSelected) {
        setCmpAttributeSelection((currVal) => ({
          ...currVal,
          clicks: removeItemFromArray(currVal.clicks, campaign.id),
          impressions: removeItemFromArray(currVal.impressions, campaign.id),
          conversions: removeItemFromArray(currVal.conversions, campaign.id),
        }));
      }
    });
  }, [selectedCampaigns]);

  const getInputRef = useCallback((ref: HTMLInputElement) => {
    TextFieldInputRef.current = ref;
  }, []);

  useEffect(() => {
    if (TextFieldInputRef.current) {
      TextFieldInputRef.current.focus();
      if (isEdit) {
        TextFieldInputRef.current.blur();
      }
    }
  }, [TextFieldInputRef, isEdit]);

  const setCampaignAudience = useCallback(async () => {
    if (endDate && startDate) {
      createCampaignAudience(
        {
          audienceName,
          campaignDetail: { ...cmpAttributeSelection },
          startDate: new Date(startDate).getTime(),
          endDate: new Date(endDate).getTime(),
        },
        selectedCampaigns,
      );
      updateSorting({
        field: SORTING_VALUE.CREATED_ON,
        direction: SORTING_DIRECTION.DESC,
      });
      resetAndClose();
    }
  }, [
    audienceName,
    cmpAttributeSelection,
    createCampaignAudience,
    endDate,
    resetAndClose,
    updateSorting,
    startDate,
    selectedCampaigns,
  ]);

  const handleAudienceUpdate = useCallback(async () => {
    if (dialog.audienceId && dialog.audienceTypeId) {
      updateCampaignAudience({
        audienceId: dialog.audienceId as number,
        audienceName,
        audienceTypeId: dialog.audienceTypeId as number,
      });
      resetAndClose();
    }
  }, [
    audienceName,
    dialog.audienceId,
    dialog.audienceTypeId,
    resetAndClose,
    updateCampaignAudience,
  ]);

  const actionButtons = useMemo(() => {
    if (isView) {
      return [
        {
          title: 'Done',
          handler: resetAndClose,
        },
      ];
    }

    if (isEdit) {
      return [
        {
          variant: 'secondary',
          title: 'Cancel',
          handler: () => resetAndClose(),
        },
        {
          disabled:
            !audienceName.trim() ||
            previousAudienceName.trim() === audienceName.trim() ||
            audienceName.trim().length >= 255,
          title: 'Save',
          handler: handleAudienceUpdate,
        },
      ];
    }

    if (isDuplicateAudience) {
      return [
        {
          variant: 'secondary',
          title: 'Cancel',
          handler: () => resetAndClose(),
        },
        {
          disabled:
            !audienceName.trim() ||
            !startDate ||
            !endDate ||
            !selectedCampaigns.length ||
            previousAudienceName.trim() === audienceName.trim() ||
            audienceName.trim().length >= 255,
          title: 'Create',
          handler: () => setCampaignAudience(),
        },
      ];
    }

    return [
      {
        variant: 'secondary',
        title: 'Cancel',
        handler: () => resetAndClose(),
      },
      {
        title: 'Add Audience',
        handler: () => setCampaignAudience(),
        disabled:
          !audienceName.trim() ||
          !startDate ||
          !endDate ||
          !selectedCampaigns.length ||
          audienceName.trim().length >= 255,
        ...{ [RUM_ACTION_ATTRIBUTE]: 'Create Campaign Audience' },
      },
    ];
  }, [
    audienceName,
    endDate,
    handleAudienceUpdate,
    isDuplicateAudience,
    isEdit,
    isView,
    previousAudienceName,
    resetAndClose,
    selectedCampaigns.length,
    setCampaignAudience,
    startDate,
  ]);

  const onEndDateChange = (date: Moment) => {
    setEndDate(new Date(date.unix() * 1000));
  };

  const onStartDateChange = (date: Moment) => {
    setStartDate(new Date(date.unix() * 1000));
    if (endDate !== null && endDate.getTime() - date.unix() * 1000 <= ONE_DAY_SECONDS) {
      onEndDateChange(date.clone().add(1, 'day'));
    }
  };

  const startDateChangeHandler = (change: Moment): void => {
    onStartDateChange(change);
  };

  const endDateChangeHandler = (change: Moment): void => {
    onEndDateChange(change);
  };

  const getAudienceDetails = useCallback(async () => {
    if (dialog.audienceId) {
      try {
        const response = await API.CampaignAudience.getAudienceDetails(
          dialog.audienceId.toString(),
        );
        if (response.success && response.data) {
          setAudienceDetails(response.data);
          if (isDuplicateAudience || !dialogVisibility) {
            const { name, startDate: startDateEdit, endDate: endDateEdit } = response.data;
            setStartDate(new Date(startDateEdit));
            setEndDate(new Date(endDateEdit));
            setAudienceName(isDuplicateAudience ? `Copy of ${name}` : name);
            setPreviousAudienceName(name);
          }

          Object.keys(response.data.campaignDetail as {}).map((val: string) =>
            setCmpAttributeSelection((currVal) => ({
              ...currVal,
              [val]: Object.keys(
                response.data?.campaignDetail[val as keyof CampaignDetail] as {},
              ).map((id) => Number(id)),
            })),
          );

          const campaignIds = Object.values(response.data.campaignDetail as {}).map((val) =>
            Object.keys(val as {}).filter((id) => id),
          );

          const flatCampaignIds = campaignIds.reduce((acc, curVal) => {
            return acc.concat(curVal);
          }, []);
          const campaignsInfo = await API.AddToCampaign.getCampaignInfo({
            id: flatCampaignIds.join().toString(),
          });

          if (campaignsInfo.success && campaignsInfo.data) {
            setSelectedCampaigns(
              campaignsInfo.data.data.map((campaign) => ({
                ...campaign,
                label: campaign.name,
                value: campaign.id,
                isSelected: false,
              })) as ICampaignInformationWithOption[],
            );
          }
        }
      } catch (err) {
        openSnackbar({
          message: get(err, 'errorObjects[0].error', SOMETHING_WENT_WRONG_MSG),
          type: 'error',
        });
        if (isView && handleClose) {
          handleClose();
        }
      }
    }
  }, [dialog.audienceId, isDuplicateAudience, dialogVisibility, openSnackbar, isView, handleClose]);

  const isAllSelected = useMemo(() => selectedCampaigns.every((cmp) => cmp.isSelected), [
    selectedCampaigns,
  ]);

  const removeCampaign = useCallback(() => {
    removeCampaignsFromRequest();
    if (isAllSelected) {
      setCmpAttributeSelection({ clicks: [], impressions: [], conversions: [] });
      return setSelectedCampaigns([]);
    }
    setSelectedCampaigns((currVal) => currVal.filter((campaign) => !campaign.isSelected));
    return null;
  }, [isAllSelected, removeCampaignsFromRequest]);

  useEffect(() => {
    if (isView || isEdit || isDuplicateAudience) {
      getAudienceDetails();
    }
  }, [getAudienceDetails, isDuplicateAudience, isEdit, isView]);

  const handleCampaignSelect = useCallback(
    async (data: Record<string, NormalizedIOCampaignList>, reason: any) => {
      if (reason !== 'userAction') {
        return null;
      }

      const selectedIds = Object.keys(data || {}).join(',');

      if (!data || !selectedIds.length) {
        return setSelectedCampaigns([]);
      }

      const response = await API.AddToCampaign.getCampaignInfo({ id: selectedIds });
      if (response.success && response.data?.data?.length) {
        const { data: campaigns } = response.data;
        const newCampaigns = campaigns.filter(
          (campaign) =>
            !cmpAttributeSelection.clicks.includes(campaign.id) &&
            !cmpAttributeSelection.conversions.includes(campaign.id),
        );

        setCmpAttributeSelection((currVal) => ({
          ...currVal,
          impressions: newCampaigns.map((val) => val.id),
        }));

        return setSelectedCampaigns(
          response.data.data.map((campaign) => ({
            ...campaign,
            label: campaign.name,
            value: campaign.id,
            isSelected: false,
          })) as ICampaignInformationWithOption[],
        );
      }
      return null;
    },
    [cmpAttributeSelection.clicks, cmpAttributeSelection.conversions],
  );

  useEffect(() => {
    if (!!selectedCampaigns.length && !(isEdit || isDuplicateAudience)) {
      // eslint-disable-next-line
      window.Intercom?.('trackEvent', 'show-campaign-audience');
    }
  }, [selectedCampaigns, isEdit, isDuplicateAudience]);

  // eslint-disable-next-line
  const noOptionValue = (
    <div className={styles.noOptionValue}>
      No campaigns found for given dates.
      <br />
      Please change the dates.
    </div>
  );

  const renderContent = (
    <>
      <div className="d-flex">
        {isView ? (
          <div className="ml-3">
            {audienceDetails && (
              <div>
                <div className={styles.titleContainer}>
                  <div className={styles.icon}>
                    <Icon name="AudienceCampaign" />
                  </div>
                  <Tooltip
                    label={audienceDetails.name}
                    position="right"
                    auto={false}
                    labelMaxWidth={600}
                  >
                    <div className={styles.audienceNameView}>{audienceDetails.name}</div>
                  </Tooltip>
                  {isBoolean(isIncluded) && (
                    <Pill
                      className={isIncluded ? styles.successPill : styles.dangerPill}
                      label={isIncluded ? 'Allowed' : 'Blocked'}
                    />
                  )}
                </div>
                <div className={styles.info}>
                  <div className={styles.infoWrapper}>
                    <div className={styles.infoLabel}>Created on:</div>
                    <div className={styles.infoText}>
                      {moment(audienceDetails.createdOn * 1000).format('MM/DD/yyyy')}
                    </div>
                  </div>
                  <div className={styles.infoWrapper}>
                    <div className={styles.infoLabel}>Data Collection Period:</div>
                    <div className={styles.infoText}>{`${moment(audienceDetails.startDate).format(
                      'MM/DD/yyyy',
                    )}-${moment(audienceDetails.endDate).format('MM/DD/yyyy')}`}</div>
                  </div>
                  <div className={styles.infoWrapper}>
                    <div className={styles.infoLabel}>Reach</div>
                    <div className={styles.infoText}>
                      <NumberFormatter>{audienceDetails.uniques}</NumberFormatter>
                    </div>
                  </div>
                  <div className={styles.infoWrapper}>
                    <Icon
                      name={tableStatusIconsMap[audienceDetails.status]}
                      className={styles.statusIcon}
                    />
                    <div className={styles.infoStatus}>{audienceDetails.status}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={styles.inputContainer}>
              <TextField
                inputRef={getInputRef}
                onChange={setAudienceName}
                value={audienceName}
                placeholder="Enter Audience Name"
                label="Audience Name"
                variant="withoutTickbox"
                validationRules={audienceNameValidationRule}
              />
            </div>
            <div className={styles.inputContainer}>
              <DatePicker
                disabled={isEdit}
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={startDateChangeHandler}
                onEndDateChange={endDateChangeHandler}
              />
            </div>
            <div className={styles.inputContainer}>
              <SelectIOCampaignList
                size="large"
                disabled={isEdit || isView}
                enabled={!!(startDate && endDate) && !isEdit}
                useCase="audience"
                params={{
                  startDate: startDate?.valueOf(),
                  endDate: endDate?.valueOf(),
                  status: CAMPAIGN_STATUSES,
                }}
                TextFieldProps={{
                  variant: 'outlinedDash',
                  label: 'Campaigns',
                  fullWidth: true,
                  placeholder: 'Select Campaigns',
                }}
                multiple
                placeholder="Select Campaigns"
                onChange={handleCampaignSelect}
                slotProps={{
                  PaperProps: {
                    style: {
                      width: 450,
                      minWidth: 450,
                    },
                  },
                }}
                /* @ts-ignore-next-line */
                ref={selectCampaignsRef}
              />
            </div>
          </>
        )}
      </div>
      <div className={`${styles.tableWrapper} ${isView ? 'mt-1' : 'mt-3'}`}>
        <div className={styles.tableActions}>
          {selectedCampaigns.filter((campaign) => campaign.isSelected).length ? (
            <>
              <Pill
                label={`${
                  selectedCampaigns.filter((campaign) => campaign.isSelected).length
                } selected`}
                removable
                removeClicked={handleCrossClick}
              />
              <Button onClick={removeCampaign} variant="danger">
                Remove
              </Button>
            </>
          ) : null}
        </div>
        <div className={styles.table}>
          <div className={styles.tableWrapperCampaign}>
            {selectedCampaigns.length ? (
              <CampaignsTable
                selectedCampaigns={selectedCampaigns}
                setSelectedCampaigns={setSelectedCampaigns}
                cmpAttributeSelection={cmpAttributeSelection}
                setCmpAttributeSelection={setCmpAttributeSelection}
                isView={!!isView}
                isEdit={!!isEdit}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );

  return dialogVisibility ? (
    <Dialog
      dialogTitle={title}
      open={!isEmpty(dialog)}
      modalProps={COMMON_DIALOG_MODAL_PROPS}
      crossButton
      headerFooterBorders
      className={styles.container}
      onCrossButtonClick={() => {
        resetAndClose();
      }}
      actionButtons={actionButtons}
    >
      {renderContent}
    </Dialog>
  ) : (
    <>{renderContent}</>
  );
};

const mapState = (state: AppState) => ({
  submitInProgress: state.contextualAudience.submitInProgress,
});

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  createCampaignAudience: campaignAudienceActions.createCampaignAudience,
  updateCampaignAudience: campaignAudienceActions.updateCampaignAudience,
  openSnackbar: snackbarActions.openSnackbar,
  updateSorting: tableActions.updateSorting,
};

const CampaignAudienceDialog = connect(mapState, mapAction)(CampaignAudienceDialogComponent);

export default CampaignAudienceDialog;
