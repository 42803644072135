import React from 'react';
import { TDialog } from 'store/app/reducer';
import GeofarmedAudienceDialog from './editGeofarmedAudience';

interface Props {
  dialog: TDialog;
  handleClose: () => void;
}

const EditGeofarmedAudience = (props: Props) => {
  const { dialog, handleClose } = props;

  return (
    <GeofarmedAudienceDialog dialog={dialog} handleClose={handleClose} title="Geofarmed Audience" />
  );
};

export default EditGeofarmedAudience;
