import * as React from 'react';
import { Box, Paper, Tooltip, Typography } from '@applift/factor';
import { Devices, InfoCircle, MapPin, UserTagAlt, UsersSpaced } from '@applift/icons';
import { AxiosError } from 'axios';
import { WithResponse } from 'models/Response';
import { useSegmentAudienceBreakdown } from 'hooks/useSegmentedAudience';
import { AgeBarChart, DeviceBarChart, GenderPieChart, GeographyMap } from './Graphs';

interface Props {
  audienceId?: number;
  showPlaceholder: boolean;
  audienceStatus?: string;
}

const isTimeoutError = (error: AxiosError<WithResponse<any>>) => {
  return error && error.code === 'ECONNABORTED' && error.message?.includes?.('timeout');
};

export const AudienceBreakdown = (props: Props) => {
  const { audienceId, showPlaceholder, audienceStatus } = props;

  const [errorType, setErrorType] = React.useState<'Timeout' | 'Error' | ''>('');

  const {
    data: audienceBreakdownDataResponse,
    isLoading: isAudienceBreakdownDataLoading,
    error,
    refetch: refetchAudienceBreakdownInfo,
  } = useSegmentAudienceBreakdown(audienceId, { enabled: !showPlaceholder });

  const deviceTypeData = () => {
    const deviceTypeBreakdownData = audienceBreakdownDataResponse?.data?.widgets.filter(
      (val: { title: string }) => val.title === 'deviceType',
    );
    return deviceTypeBreakdownData && deviceTypeBreakdownData.length
      ? deviceTypeBreakdownData[0]?.data.sort((val) => val.srNo)
      : [];
  };

  const ageTypeData = () => {
    const deviceTypeBreakdownData = audienceBreakdownDataResponse?.data?.widgets.filter(
      (val: { title: string }) => val.title === 'age',
    );
    return deviceTypeBreakdownData && deviceTypeBreakdownData.length
      ? deviceTypeBreakdownData[0]?.data.sort((val) => val.srNo)
      : [];
  };

  const genderTypeData = () => {
    const deviceTypeBreakdownData = audienceBreakdownDataResponse?.data?.widgets.filter(
      (val: { title: string }) => val.title === 'gender',
    );
    return deviceTypeBreakdownData && deviceTypeBreakdownData.length
      ? deviceTypeBreakdownData[0]?.data.sort((val) => val.srNo)
      : [];
  };

  const geoTypeData = () => {
    const deviceTypeBreakdownData = audienceBreakdownDataResponse?.data?.widgets.filter(
      (val) => val.title === 'state',
    );
    return deviceTypeBreakdownData && deviceTypeBreakdownData.length
      ? deviceTypeBreakdownData[0]?.data.sort((val) => val.srNo)
      : [];
  };

  const isStatusReady = audienceStatus?.toLowerCase() === 'ready';

  React.useEffect(() => {
    if (error && isTimeoutError(error)) {
      setErrorType('Timeout');
    } else if (error && !isTimeoutError(error)) {
      setErrorType('Error');
    } else {
      setErrorType('');
    }
  }, [error]);

  return (
    <Box sx={{ width: 100, display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" sx={{ fontSize: 14, mb: 4 }}>
        Audience Breakdown
      </Typography>
      {!isStatusReady ? (
        <Box sx={{ display: 'flex', alignItems: 'start', textColor: 'neutral-500' }}>
          <Typography lineHeight="single-line" variant="p" sx={{ fontSize: 12 }}>
            Available after the audience is ready
          </Typography>
          <Tooltip
            arrow
            placement="top-end"
            title="This audience will be ready within the next 24-48 hours."
          >
            <InfoCircle fontSize={16} />
          </Tooltip>
        </Box>
      ) : null}

      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          py: 16,
          px: 12,
          my: 8,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UserTagAlt fontSize={24} sx={{ textColor: 'neutral-400', flexShrink: 0 }} />
          <Typography sx={{ ml: 4 }}>Gender</Typography>
        </Box>

        <GenderPieChart
          isPlaceholder={showPlaceholder}
          isLoading={isAudienceBreakdownDataLoading}
          genderTypeData={genderTypeData()}
          errorType={errorType}
          refetchAudienceBreakdownInfo={refetchAudienceBreakdownInfo}
        />
      </Paper>

      <Paper elevation={2} sx={{ py: 16, px: 12, mb: 8 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <UsersSpaced fontSize={24} sx={{ textColor: 'neutral-400' }} />
          <Typography sx={{ ml: 4 }}>Age</Typography>
        </Box>
        <AgeBarChart
          isPlaceholder={showPlaceholder}
          isLoading={isAudienceBreakdownDataLoading}
          ageTypeData={ageTypeData()}
          errorType={errorType}
          refetchAudienceBreakdownInfo={refetchAudienceBreakdownInfo}
        />
      </Paper>

      <Paper elevation={2} sx={{ py: 16, px: 12, mb: 8 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Devices fontSize={24} sx={{ textColor: 'neutral-400' }} />
          <Typography sx={{ ml: 4 }}>Device</Typography>
        </Box>
        <DeviceBarChart
          isPlaceholder={showPlaceholder}
          isLoading={isAudienceBreakdownDataLoading}
          deviceTypeData={deviceTypeData()}
          errorType={errorType}
          refetchAudienceBreakdownInfo={refetchAudienceBreakdownInfo}
        />
      </Paper>

      <Paper elevation={2} sx={{ py: 16, px: 12, mb: 8 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MapPin fontSize={24} sx={{ textColor: 'neutral-400' }} />
          <Typography sx={{ ml: 4 }}>Geography</Typography>
        </Box>
        <GeographyMap
          isPlaceholder={showPlaceholder}
          geoTypeData={geoTypeData()}
          isLoading={isAudienceBreakdownDataLoading}
          errorType={errorType}
          refetchAudienceBreakdownInfo={refetchAudienceBreakdownInfo}
        />
      </Paper>
    </Box>
  );
};
