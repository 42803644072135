import React from 'react';
import { ICloudProviderProps } from 'models/Cloud';
import GoogleDriveUploader from '../googleDriveUploader/GoogleDriveUploader';
import DropboxUploader from '../dropboxUploader/DropboxUploader';
// import OneDriveUploader from '../oneDriveUploader/OneDriveUploader';
import { Hubspot } from '../Hubspot';
import { NationBuilder } from '../NationBuilder';
import styles from './styles.module.scss';

const providers = [Hubspot, NationBuilder, DropboxUploader, GoogleDriveUploader]; // , OneDriveUploader

export const CloudProviderBar = ({
  acceptFormats,
  handleCloudFileUpload,
  setUnsupportedFiles,
  unsupportedFiles,
  select,
}: ICloudProviderProps) => {
  return (
    <div className={styles.bar}>
      <label className={styles.label}>Import From:</label>
      <div className={styles.container}>
        {providers.map((Provider) => (
          <Provider
            acceptFormats={acceptFormats}
            handleCloudFileUpload={handleCloudFileUpload}
            setUnsupportedFiles={setUnsupportedFiles}
            unsupportedFiles={unsupportedFiles}
            select={select}
          />
        ))}
      </div>
    </div>
  );
};
