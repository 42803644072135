import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DataDogRumRoute } from 'iqm-framework';

import { Route as TRoute } from 'models/Route';
import { routes, fallbackRoute, validRoutes } from '../../routes';
import { Scene } from '../Layout/Scene';

const PrivateRoutesComponent = () => {
  const routePaths = routes.map((route: TRoute) => route.path);
  const validRoutePaths = validRoutes.map((route: TRoute) => route.path);

  return (
    <Switch>
      <DataDogRumRoute path={routePaths} exact>
        <Scene>
          <Switch>
            <Route path={validRoutePaths} exact>
              {validRoutes.map((route) => (
                <DataDogRumRoute
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                />
              ))}
            </Route>
            <DataDogRumRoute
              key={fallbackRoute.path}
              path={fallbackRoute.path}
              component={fallbackRoute.component}
              exact={fallbackRoute.exact}
            />
          </Switch>
        </Scene>
      </DataDogRumRoute>
    </Switch>
  );
};

export const PrivateRoutes = PrivateRoutesComponent;
