import { enqueueSnackbar } from '@applift/factor';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  downloadInsights,
  emailInsights,
  generateInsights,
  getInsightsStatusList,
  reGenerateInsights,
} from 'api/insights';
import { getInsightsStatusQueryKey } from 'api/queryKey';
import { CreateAudienceInsightsResponse } from 'models/Insights';
import { WithResponse } from '../models/Response';

export const useDownloadInsights = (
  onSuccess?: UseMutationOptions<
    WithResponse<{
      insightsReportUrl: string;
    }>
  >['onSuccess'],
  onError?: (error: any) => void,
) => {
  const getDownloadUrlMutation = useMutation(downloadInsights, {
    mutationKey: ['downloadInsights'],
    onSuccess: (response: any, payload: any, context: any) => {
      onSuccess?.(response, payload, context);
    },
    onError,
  });
  return getDownloadUrlMutation;
};

export const useSendEmail = (onSuccess?: () => void, onError?: () => void) => {
  const sendEmailMutation = useMutation(emailInsights, {
    mutationKey: ['sendInsightEmail'],
    onSuccess,
    onError,
  });
  return sendEmailMutation;
};

export const useCreateAudienceInsight = (
  onSuccess?: (res: WithResponse<CreateAudienceInsightsResponse>) => void,
  onError?: () => void,
) => {
  const mutationResult = useMutation(generateInsights, {
    mutationKey: ['createAudienceInsights'],
    onSuccess: (response: any, payload: any, context: any) => {
      // @ts-ignore
      onSuccess?.(response, payload, context);
    },
    onError: (e: any, payload: any, context: any) => {
      enqueueSnackbar(
        e.errorObjects
          ? (e.errorObjects[0]?.error as string)
          : 'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        },
      );
      // @ts-ignore
      onError?.(e, payload, context);
    },
  });

  return mutationResult;
};

export const useRegenerateAudienceInsight = (
  onSuccess?: (res: WithResponse<CreateAudienceInsightsResponse>) => void,
  onError?: () => void,
) => {
  const mutationResult = useMutation(reGenerateInsights, {
    mutationKey: ['regenerateAudienceInsights'],
    onSuccess: (response: any, payload: any, context: any) => {
      // @ts-ignore
      onSuccess?.(response, payload, context);
    },
    onError: (e: any, payload: any, context: any) => {
      enqueueSnackbar(
        e.errorObjects
          ? (e.errorObjects[0]?.error as string)
          : 'Something went wrong. Please try after sometime.',
        {
          variant: 'error',
        },
      );
      // @ts-ignore
      onError?.(e, payload, context);
    },
  });

  return mutationResult;
};

type useInsightsStatusProps = Pick<UseQueryOptions, 'enabled' | 'onSuccess'>;

export const useInsightsStatus = (props?: useInsightsStatusProps) => {
  const data = useQuery(
    getInsightsStatusQueryKey.keys({
      scope: 'getInsightsCount',
    }),
    getInsightsStatusList,
    {
      enabled: props?.enabled ?? true,
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e.errorObjects ? (e.errorObjects[0]?.error as string) : 'Something went wrong!',
          {
            variant: 'error',
          },
        );
      },
    },
  );
  return data;
};
