import {
  StatusAll,
  StatusRejected,
  StatusPending,
  StatusReady,
  StatusFailed,
  StatusProcessing,
} from '@applift/icons';
import { isProd } from '../config';

export const MATCHED_AUDIENCE_TYPE_ID = 1;
export const SEGMENTED_AUDIENCE_TYPE_ID = 2;
export const RETARGETED_AUDIENCE_TYPE_ID = 3;
export const GEOFARMED_AUDIENCE_TYPE_ID = 4;
export const CONTEXTUAL_AUDIENCE_TYPE_ID = 5;
export const LOOKALIKE_AUDIENCE_TYPE_ID = 6;
export const CAMPAIGN_AUDIENCE_TYPE_ID = 7;
export const PRE_BID_AUDIENCE_TYPE_ID = 8;

export const ALL_AUDIENCE_TYPE_IDS = [
  MATCHED_AUDIENCE_TYPE_ID,
  SEGMENTED_AUDIENCE_TYPE_ID,
  RETARGETED_AUDIENCE_TYPE_ID,
  GEOFARMED_AUDIENCE_TYPE_ID,
  CONTEXTUAL_AUDIENCE_TYPE_ID,
  LOOKALIKE_AUDIENCE_TYPE_ID,
  CAMPAIGN_AUDIENCE_TYPE_ID,
  PRE_BID_AUDIENCE_TYPE_ID,
].join(',');

export const audiences = [
  'matched',
  'segmented',
  'retargeted',
  'lookalike',
  'contextual',
  'geofarmed',
  'campaign',
  'prebid',
];

export const audienceIDMapForLabel: { [key: number]: string } = {
  1: 'Matched',
  2: 'Segmented',
  3: 'Retargeted',
  4: 'Geofarmed',
  5: 'Contextual',
  6: 'Lookalike',
  7: 'Campaign',
  8: 'Prebid',
};

export const LIVE_RAMP_DATA_PARTNER_NAME = 'LiveRamp';

export const AUDIENCE_STATUSES = {
  PENDING_FOR_APPROVAL: 'Pending For Approval',
  PENDING: 'Pending',
  ALL: 'All',
  READY: 'Ready',
  REJECTED: 'Rejected',
  FAILED: 'Failed',
  PROCESSING: 'Processing',
};

export const COMMON_DIALOG_MODAL_PROPS = {
  style: {
    position: 'relative',
    zIndex: '101',
  },
};

export const SORTING_VALUE = {
  CREATED_ON: 'createdOn',
};

export const EMPTY_SELECTED_AUDIENCE_STATUS = {
  '': '',
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
  7: '',
  8: '',
  9: '',
};

export const SORTING_DIRECTION: { DESC: 'desc'; ASC: 'asc' } = {
  DESC: 'desc',
  ASC: 'asc',
};

export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || '';

export const INSIGHTS_MIN_UNIQUES = isProd ? 10000 : 50;
export const NAME_MAX_CHARS = 255;

export const ALL_CATEGORY_CREATIVE_TYPE_IDS = '11,13,14';
export const DISPLAY_CATEGORY_CREATIVE_TYPE_IDS = '11,13';
export const VIDEO_CATEGORY_CREATIVE_TYPE_IDS = '14';

export const CAMPAIGN_MAX_AUDIENCE_COUNT = 20;

export const AudienceStatusIconMap = {
  all: StatusAll,
  processing: StatusProcessing,
  ready: StatusReady,
  failed: StatusFailed,
  'pending for approval': StatusPending,
  rejected: StatusRejected,
  pending: StatusPending,
};
