import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { enqueueSnackbar } from '@applift/factor';

import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import { applicationActions } from 'store/app/actions';
import { setRowAnimation } from 'styles/tableRowAnimation';
import { EMPTY_SELECTED_AUDIENCE_STATUS } from 'constants/audiences';
import { createAction } from '../../utils/actions';
import { tableConstants } from '../table/constants';
import { tableActions } from '../table/actions';
import { AppState } from '..';

export const preBidAudienceActions = {
  onSuccessfulPreBidCreation(successMessage: string) {
    return (dispatch: Dispatch) => {
      enqueueSnackbar(successMessage, {
        variant: 'success',
      });
      if (tableComponentInstanceRef?.current) {
        tableComponentInstanceRef.current.clearSelected();
        tableComponentInstanceRef.current.getNewData();
      }
      dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
      dispatch(
        createAction(
          tableConstants.SET_AUDIENCES_SELECTED_STATUSES,
          EMPTY_SELECTED_AUDIENCE_STATUS,
        ),
      );
      // datadog part yet to be updated
      // RUMLogger.createLookalikeAudience({
      //   success: true,
      //   latency: response.data?.latency,
      //   statusCode: response.status,
      //   createAudienceData,
      // });
      (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
      setRowAnimation();
    };
  },
  onPreBidSuccessfulEdit(successMessage: string) {
    return (dispatch: Dispatch) => {
      enqueueSnackbar(successMessage, {
        variant: 'success',
      });
      if (tableComponentInstanceRef?.current) {
        tableComponentInstanceRef.current.clearSelected();
        tableComponentInstanceRef.current.getNewData();
      }
      dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
      (dispatch as ThunkDispatch<AppState, void, AnyAction>)(applicationActions.resetSidebar());
    };
  },
};
