import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Table, Checkbox, Pill, Button, Tooltip } from 'factor';
import moment from 'moment';

import styles from './styles.module.scss';

type Options = {
  label: string;
  value: number;
  reactLabel: JSX.Element;
  matchRate: string;
  uniques: string;
  created: string;
};

interface Props {
  selectedAudiences: any;
  setSelectedAudiences: React.Dispatch<React.SetStateAction<any>>;
  isEdit: boolean;
  isView?: boolean;
}

export const SelectedAudiencesTable = (props: Props) => {
  const { selectedAudiences, setSelectedAudiences, isEdit, isView } = props;
  const [selectedAudienceIdsForRemoval, setSelectedAudienceIdsForRemoval] = useState<number[]>([]);
  const [tableConfig, setTableConfig] = useState<{
    header: unknown[];
    body: unknown[];
    data: unknown[];
  }>({
    header: [],
    body: [],
    data: [],
  });

  const selectAudiencesToRemove = useCallback(
    (id: number) => {
      setSelectedAudienceIdsForRemoval((currentSelection) => {
        if (currentSelection.includes(id)) {
          const index = currentSelection.indexOf(id);
          currentSelection.splice(index, 1);
          return [...currentSelection];
        }
        return [...currentSelection, id];
      });
    },
    [setSelectedAudienceIdsForRemoval],
  );

  const removeOrSelectAllFromTable = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setSelectedAudienceIdsForRemoval(
        selectedAudienceIdsForRemoval.length
          ? []
          : selectedAudiences.map((audience: Options) => audience.value),
      );
    },
    [selectedAudienceIdsForRemoval, selectedAudiences],
  );

  const removeSelectedAudiences = () => {
    const audiencedropDownSelection = selectedAudiences.filter(
      (audience: { value: number }) => !selectedAudienceIdsForRemoval.includes(audience.value),
    );
    if (setSelectedAudiences) {
      setSelectedAudiences(audiencedropDownSelection);
    }
    setSelectedAudienceIdsForRemoval([]);
  };

  const loadTableData = useCallback(() => {
    setTableConfig({
      header: [
        ...(!isEdit && !isView
          ? [
              {
                label: (
                  <div
                    className={
                      selectedAudienceIdsForRemoval?.length ? styles.deselectAll : styles.checkBox
                    }
                    onClick={(e) => removeOrSelectAllFromTable(e)}
                  >
                    <Icon
                      name={selectedAudienceIdsForRemoval?.length ? 'SquareMinus' : 'CheckboxBlank'}
                    />
                  </div>
                ),
                className: `_deSelectbox`,
              },
            ]
          : []),
        { label: 'ID', sortingKey: 'id', className: isEdit ? '_idEditMode' : '_idCreateMode' },
        {
          label: 'Audience Name',
          sortingKey: 'audienceName',
          className: isEdit ? '_nameEditMode' : '_nameCreateMode',
        },
        {
          label: 'Created',
          sortingKey: 'createdOn',
          className: isEdit ? '_createdOnEditMode' : '_createdOnCreateMode',
        },
        {
          label: 'Match Rate',
          sortingKey: 'matchRate',
          className: isEdit ? '_matchRateEditMode' : '_matchRateCreateMode',
        },
        {
          label: 'Reach',
          sortingKey: 'uniques',
          className: isEdit ? '_uniqueEditMode' : '_uniqueCreateMode',
        },
      ],
      body: [
        ...(!isEdit && !isView
          ? [
              {
                key: (audience: any) => {
                  return (
                    <Checkbox
                      label=""
                      className={styles.checkBox}
                      onChange={() => selectAudiencesToRemove(audience.id)}
                      checked={selectedAudienceIdsForRemoval?.includes(audience.id)}
                    />
                  );
                },
                className: `_deSelectbox`,
                rowClassName: '_selected',
              },
            ]
          : []),
        {
          key: (audience: any) => audience.id,
          className: isEdit ? '_idEditMode' : '_idCreateMode',
        },
        {
          key: (audience: any) => {
            return (
              <div className={styles.audienceName}>
                <Tooltip
                  label={audience.audienceName}
                  auto={false}
                  position="top-left"
                  labelMaxWidth={400}
                  portal
                >
                  <>
                    <span className="mr-2">
                      <Icon name="AudienceMatched" className="_size-20" />
                    </span>
                    <span className={styles.nameInner}>{audience.audienceName}</span>
                  </>
                </Tooltip>
              </div>
            );
          },
          className: isEdit ? '_nameEditMode' : '_nameCreateMode',
        },
        {
          key: (audience: any) =>
            moment
              .unix(audience.createdOn)
              .tz('UTC')
              .format('MM/DD/yyyy'),
          className: isEdit ? '_createdOnEditMode' : '_createdOnCreateMode',
        },
        {
          key: (audience: any) => `${audience.matchRate}%`,
          className: isEdit ? '_matchRateEditMode' : '_matchRateCreateMode',
        },
        {
          key: (audience: any) => audience.uniques,
          className: isEdit ? '_uniqueEditMode' : '_uniqueCreateMode',
        },
      ],
      data: selectedAudiences.map((audience: Options) => ({
        audienceName: audience.label,
        id: audience.value,
        createdOn: audience.created,
        matchRate: audience.matchRate,
        uniques: audience.uniques,
        ...(selectedAudienceIdsForRemoval.includes(audience.value) && {
          rowClassName: '_selected',
        }),
      })),
    });
  }, [
    selectedAudienceIdsForRemoval,
    removeOrSelectAllFromTable,
    selectAudiencesToRemove,
    selectedAudiences,
    isEdit,
    isView,
  ]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  return (
    <>
      <div className="row">
        <div className={`col-12 ${styles.tableColumn}`}>
          {selectedAudienceIdsForRemoval.length ? (
            <div className={styles.pillContainer}>
              <Pill
                removable
                className={styles.pill}
                removeClicked={() => setSelectedAudienceIdsForRemoval([])}
                label={`${selectedAudienceIdsForRemoval?.length} selected`}
              />
              <Button
                variant="danger"
                size="small"
                className={styles.removeBtn}
                onClick={() => removeSelectedAudiences()}
                disabled={false}
              >
                Remove
              </Button>
            </div>
          ) : null}
          <Table
            className={styles.table}
            data={tableConfig.data}
            header={tableConfig.header}
            sorting={{
              field: 'createdOn',
              direction: 'desc',
            }}
            body={tableConfig.body}
            rowKeyExtractor={(data: any) => data.id}
          />
        </div>
      </div>
    </>
  );
};
