import React, { Suspense, ReactNode } from 'react';
import { ProgressCircle } from 'factor';

export const DefaultFallback = (
  <div className="row w-100 h-100 align-items-center justify-content-center">
    <ProgressCircle />
  </div>
);

export const withSuspense = (children: ReactNode, fallback = DefaultFallback) => {
  return <Suspense fallback={fallback}>{children}</Suspense>;
};
