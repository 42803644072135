import React from 'react';
import { WithMiddleEllipses, Icon } from 'factor';
import { IOBudgetTypeMapper } from 'iqm-framework';
import styles from './styles.module.scss';

interface Props {
  ioId: number;
  ioName: string;
  ioBudgetTypeId: number;
}

export const Header = (props: Props) => {
  const { ioId, ioName, ioBudgetTypeId } = props;
  const budgetTypeIcon = IOBudgetTypeMapper[ioBudgetTypeId]?.icon;

  return (
    <div className={styles.wrapper}>
      {!!budgetTypeIcon && <Icon name={budgetTypeIcon} className={styles.budgetTypeIcon} />}
      <div className={styles.headerLabel}>
        <WithMiddleEllipses text={ioName} />
      </div>
      <div className={styles.separator} />
      <div className={styles.infoItem}>
        <span className={styles.infoLabel}>ID</span>
        <span className={styles.infoValue}>{ioId}</span>
      </div>
    </div>
  );
};
