import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'factor';

import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { copy, selectText } from 'utils/text';

import styles from './styles.module.scss';

interface Props extends OpenSnackbar {
  className?: string;
  tagScript: string;
  isRenderedViaIFrame?: boolean;
}

const PixelScriptCodeComponent = (props: Props) => {
  const { className, tagScript, openSnackbar, isRenderedViaIFrame } = props;

  const copyScript = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    copy(tagScript);
    if (isRenderedViaIFrame) {
      if (window.top) {
        window.top.postMessage('copiedTagScript', '*');
      }
    } else {
      openSnackbar({
        message: 'Tag script copied to clipboard',
        type: 'success',
      });
    }
  };

  const handleSelectText = () => {
    selectText('tag-script');
  };

  return (
    <div
      className={[styles.tagScriptText, className].join(' ')}
      onClick={handleSelectText}
      id="tag-script"
    >
      <span>Pixel Script Code</span>
      <span>{tagScript}</span>
      <div className={styles.copyIcon} onClick={copyScript}>
        <Icon name="Copy" />
      </div>
    </div>
  );
};

const mapAction = {
  openSnackbar: snackbarActions.openSnackbar,
};

const PixelScriptCode = connect(null, mapAction)(PixelScriptCodeComponent);
export default PixelScriptCode;
