import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import {
  CreateGeofarmedAudienceData,
  CreateGeofarmedAudienceResponse,
  FrequencyTypeOptionsResponse,
  GeofarmedAudienceData,
  UpdateGeofarmedAudienceResponse,
  UpdateGeofarmedAudienceData,
} from 'models/GeofarmedAudience';
import { WithResponse, GetResponseWithData, Response } from 'models/Response';
import { Option, ICountryOption, DistrictOption } from 'models/Option';
import { Logger } from 'utils/log';
import { getInstance } from './Instance';

interface SegmentRequestParams {
  segmentIds: string;
}

export const createAudience = async (
  createAudienceData: CreateGeofarmedAudienceData,
): Promise<AxiosResponse<WithResponse<CreateGeofarmedAudienceResponse>>> => {
  try {
    const response: AxiosResponse<WithResponse<
      CreateGeofarmedAudienceResponse
    >> = await getInstance().post(`/v3/audience/geo-farmed/add`, {
      ...createAudienceData,
    });
    return response;
  } catch (error) {
    return Promise.reject(get(error, 'response', error));
  }
};

export const getAudience = async (id: number): Promise<WithResponse<GeofarmedAudienceData>> => {
  try {
    const response: AxiosResponse<WithResponse<GeofarmedAudienceData>> = await getInstance().get(
      `/v3/audience/geo-farmed/${id}`,
    );
    return response.data;
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getFrequencyOptions = async (): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<WithResponse<
      FrequencyTypeOptionsResponse[]
    >> = await getInstance().get(`/v3/audience/static/frequency-type`);
    return (
      response.data.data?.map((one) => ({
        label: one.displayName,
        value: one.id,
      })) ?? []
    );
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const getCountries = async (params: SegmentRequestParams): Promise<ICountryOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<
      { name: string; id: number; abbreviation: string }[]
    >> = await getInstance().get(`/v2/master/segment/country`, {
      params,
    });
    const countries = response.data?.responseObject?.data || [];
    return countries.map((country) => ({
      label: country.name,
      value: country.id,
      shortName: country.abbreviation,
    }));
  } catch (e) {
    Logger.log('Error while getting countries', e);
    return Promise.reject(get(e, 'response', e));
  }
};

export const Countries = async (params: SegmentRequestParams): Promise<ICountryOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<
      { name: string; id: number; abbreviation: string }[]
    >> = await getInstance().get(`/v2/master/segment/country`, {
      params,
    });
    const countries = response.data?.responseObject?.data || [];
    return countries.map((country) => ({
      label: country.name,
      value: country.id,
      shortName: country.abbreviation,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const States = async (params: SegmentRequestParams): Promise<Option<number>[]> => {
  try {
    const response: AxiosResponse<any> = await getInstance().get(`/v2/master/segment/states`, {
      params,
    });
    const data = response.data?.responseObject?.data || [];
    return data.map((state: any) => ({
      label: state.name,
      value: state.id,
      abbreviation: state.abbreviation,
      stateCode: state.id,
      v2Id: state.id,
    }));
  } catch (e) {
    Logger.log('Error while getting states', e);
    return [];
  }
};

export const Cities = async (params: SegmentRequestParams): Promise<DistrictOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<
      { name: string; id: number; stateId: number }[]
    >> = await getInstance().get(`/v2/master/segment/city`, {
      params,
    });
    const cities = response.data?.responseObject?.data || [];
    return cities.map((city) => ({
      label: city.name,
      value: city.id,
      stateId: city.stateId,
    }));
  } catch (e) {
    Logger.log('Error while getting cities', e);
    return [];
  }
};

export const Counties = async (params: SegmentRequestParams): Promise<DistrictOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<
      { name: string; id: number; stateId: number }[]
    >> = await getInstance().get(`/v2/master/segment/county`, {
      params,
    });
    const counties = response.data?.responseObject?.data || [];
    return counties.map((county) => ({
      label: county.name,
      value: county.id,
      stateId: county.stateId,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const Houses = async (params: SegmentRequestParams): Promise<DistrictOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<
      { name: string; id: number; parentId: number }[]
    >> = await getInstance().get(`/v2/master/segment/house-district`, {
      params,
    });
    const houses = response.data?.responseObject?.data || [];
    return houses.map((house) => ({
      label: house.name,
      value: house.id,
      stateId: house.parentId,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const Senates = async (params: SegmentRequestParams): Promise<DistrictOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<
      { name: string; id: number; parentId: number }[]
    >> = await getInstance().get(`/v2/master/segment/senate-district`, {
      params,
    });
    const senates = response.data?.responseObject?.data || [];
    return senates.map((senate) => ({
      label: senate.name,
      value: senate.id,
      stateId: senate.parentId,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const congressionalDistricts = async (
  params: SegmentRequestParams,
): Promise<DistrictOption[]> => {
  try {
    const response: AxiosResponse<GetResponseWithData<
      { name: string; id: number; parentId: number }[]
    >> = await getInstance().get(`/v2/master/segment/congressional-district`, {
      params,
    });
    const congressionalDistrictsResult = response.data?.responseObject?.data || [];
    return congressionalDistrictsResult.map((congressionalDistrict) => ({
      label: congressionalDistrict.name,
      value: congressionalDistrict.id,
      stateId: congressionalDistrict.parentId,
    }));
  } catch (e) {
    Logger.log('Error while getting counties', e);
    return [];
  }
};

export const updateAudience = async (
  updateAudienceData: UpdateGeofarmedAudienceData,
): Promise<UpdateGeofarmedAudienceResponse> => {
  try {
    const { audienceId, audienceName, audienceTypeId } = updateAudienceData;
    const response: AxiosResponse<Response<
      UpdateGeofarmedAudienceResponse
    >> = await getInstance().patch(`/v2/audience/updateName/${audienceId}`, {
      audienceName,
      audienceTypeId,
    });
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};
