import { datadogRum } from 'iqm-framework';
import get from 'lodash/get';

import { CreateContextualAudienceData } from 'models/ContextualAudience';
import { getFileExtension } from 'utils/file';

import { SUCCESS, FAILED, service } from '../../constants';
import { TActionParams } from '../../types';
import { actionTypes } from '../actionTypes';

const actions = {
  createContextualAudience: {
    id: 'createContextualAudience',
    text: 'Create Contextual Audience',
  },
  uploadContextualAudienceFile: {
    id: 'uploadContextualAudienceFile',
    text: 'Upload Contextual Audience File',
  },
};

export const fileUploadErrorMsgs = {
  default: 'Unable to read file content',
  size: "File size can't be larger than 3MB",
  headerMismatch: 'File header and tab mismatch',
  noKeywords: 'No keywords found in file',
  noUrls: 'No URLs found in file',
  noRows: 'No rows found in file',
  invalidHeader: 'Invalid header in file',
  fileUtils: 'fileUtils upload file error',
};

interface TCreateContextualAudienceParams extends TActionParams {
  createAudienceData: CreateContextualAudienceData & { addedRecommendedKeywords: string[] };
}

export const createContextualAudience = (params: TCreateContextualAudienceParams) => {
  const keywords = get(params, 'createAudienceData.keywords');
  const urls = get(params, 'createAudienceData.urls');
  datadogRum.addAction(
    `${actions.createContextualAudience.text} ${params.success ? SUCCESS : FAILED}`,
    {
      action: `${service}/${actions.createContextualAudience.id}`,
      actionType: `${service}/${actionTypes.createAudience.id}`,
      success: params.success,
      errorMsg: params.errorMsg,
      latency: params.latency,
      statusCode: params.statusCode,
      info: {
        recommendedCount: get(params, 'createAudienceData.addedRecommendedKeywords.length'),
        keywordCount: keywords ? keywords.split(',').length : 0,
        urlCount: urls ? urls.split(',').length : 0,
      },
    },
  );
};

interface TUploadContextualParams extends TActionParams {
  file: File;
  parseResult?: string[][];
  numKeywords?: number;
  numUrls?: number;
  numFileKeywords?: number;
  numFileUrls?: number;
  byType?: string;
}

export const uploadContextualAudienceFile = (params: TUploadContextualParams) => {
  datadogRum.addAction(
    `${actions.uploadContextualAudienceFile.text} ${params.success ? SUCCESS : FAILED}`,
    {
      success: params.success,
      errorMsg: params.errorMsg,
      info: {
        numColumns: Array.isArray(params.parseResult)
          ? get(params, 'parseResult[0].length', 0)
          : undefined,
        numRows: params.parseResult?.length,
        fileSizeBytes: params.file?.size,
        fileMIME: params.file?.type,
        fileExtension: params.file ? getFileExtension(params.file) : null,
        numUrls: params.numUrls,
        numKeywords: params.numKeywords,
        numFileUrls: params.numFileUrls,
        numFileKeywords: params.numFileKeywords,
        fileHeader: params.byType,
      },
    },
  );
};
