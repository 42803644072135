import React from 'react';

import { MainContent } from '../MainContent';
import { Layout } from '.';
import { Audiences } from '../../pages/Audiences';

interface Props {
  children: React.ReactNode;
}

export const Scene = (props: Props) => {
  const { children } = props;

  return (
    <Layout sidebar={<>{children}</>}>
      <MainContent>
        <Audiences />
      </MainContent>
    </Layout>
  );
};
