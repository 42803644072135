import React from 'react';

import { TDialog } from 'store/app/reducer';
import CampaignAudienceDialog from '../components/CampaignAudienceDialog';

interface Props {
  dialog: TDialog;
  handleClose: () => void;
}

const EditCampaignAudience = (props: Props) => {
  const { dialog, handleClose } = props;

  return (
    <CampaignAudienceDialog dialog={dialog} handleClose={handleClose} title="Campaign Audience" />
  );
};

export default EditCampaignAudience;
