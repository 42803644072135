export const audienceNameValidationRule = [
  {
    func: (name: string) => name.length < 255,
    error: () => '* Maximum 255 characters',
  },
  {
    func: (name: string) => name.trim().length > 0,
    error: () => '',
  },
];
