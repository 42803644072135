import React from 'react';

import { TableAudienceItem } from 'models/Table';
import { AudienceTableReachFormatter } from 'utils/format';
import { Typography } from '@applift/factor';

const getSegmentedBodyMapper = () => ({
  uniques: {
    key: (data: TableAudienceItem) => {
      const status = data.status?.toLowerCase();

      if (status === 'processing') {
        return <Typography weight="demi">—</Typography>;
      }

      return (
        <Typography weight="demi">
          {status === 'ready' ? '' : '~'}
          {AudienceTableReachFormatter.format(data.uniques || 0)}
        </Typography>
      );
    },
    className: `_right`,
  },
});

export default getSegmentedBodyMapper;
