import uniqueId from 'lodash/uniqueId';

import { SOMETHING_WENT_WRONG_MSG } from 'constants/errors';

import { createAction } from '../../utils/actions';
import { snackbarConstants } from './constants';
import { ToastMessageActionParam, ToastMessage } from './reducers';

export interface OpenSnackbar {
  openSnackbar: (toastMsg: Omit<ToastMessageActionParam, 'id'>) => void;
}

export interface UpdateSnackbars {
  updateSnackbars: (messages: ToastMessageActionParam[]) => void;
}

export interface SnackbarActions {
  openSnackbar: OpenSnackbar;
  closeSnackbar: () => void;
  updateSnackbars: UpdateSnackbars;
}

export const snackbarActions = {
  openSnackbar: ({
    message,
    type = 'info',
    action,
    iconName,
  }: Omit<ToastMessageActionParam, 'id'>) => {
    return createAction<ToastMessage>(snackbarConstants.OPEN_SNACKBAR, {
      message: message instanceof Error ? SOMETHING_WENT_WRONG_MSG : message,
      type,
      action,
      iconName,
      id: uniqueId('toast-'),
    });
  },
  closeSnackbar() {
    return createAction(snackbarConstants.CLOSE_SNACKBAR);
  },
  updateSnackbars(messages: ToastMessageActionParam[]) {
    return createAction<ToastMessage[]>(
      snackbarConstants.UPDATE_SNACKBARS,
      messages.map((msgItem) => ({
        ...msgItem,
        message: msgItem.message instanceof Error ? SOMETHING_WENT_WRONG_MSG : msgItem.message,
      })),
    );
  },
};
