import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { applicationConstants } from './constants';

export type RetargetedDialogType = 'view' | 'edit' | 'create' | 'duplicate' | 'tag';
export type MatchedDialogType = 'view' | 'edit' | 'create' | 'duplicate';
export type SegmentedDialogType = 'view' | 'edit' | 'create' | 'duplicate';
export type LookalikeDialogType = 'view' | 'edit' | 'create' | 'duplicate';
export type ContextualDialogType = 'view' | 'edit' | 'create' | 'duplicate';
export type CampaignDialogType = 'view' | 'edit' | 'create' | 'duplicate';
export type GeofarmedDialogType = 'view' | 'edit' | 'create' | 'duplicate';
export type PreBidDialogType = 'view' | 'edit' | 'create' | 'duplicate';
export type InsightActionsDialogType = 'generate' | 'download' | 'email';
export type AddToCampaignDialogType = 'addToCampaign';
export type InsightsDialogType = 'insights';

export type DialogType =
  | RetargetedDialogType
  | MatchedDialogType
  | SegmentedDialogType
  | AddToCampaignDialogType
  | InsightsDialogType;

export type TDialog = {
  audienceId?: number;
  audienceTypeId?: number;
  type?: DialogType;
  status?: string;
  otherInfo?: any;
};
export interface ApplicationState {
  isSidebarOpened: boolean;
  isOpenExchangeMode: boolean;
  dialog: TDialog;
}

const defaultApplicationState: ApplicationState = {
  isSidebarOpened: true,
  isOpenExchangeMode: true,
  dialog: {},
};

const toggleSidebar = (state: ApplicationState): ApplicationState => {
  return {
    ...state,
    isSidebarOpened: !state.isSidebarOpened,
  };
};

const openDialog = (state: ApplicationState, action: Action<TDialog>): ApplicationState => {
  return {
    ...state,
    dialog: {
      ...action.payload,
    },
  };
};

const reducer = reducerFromMap<ApplicationState>(defaultApplicationState, {
  [applicationConstants.TOGGLE__SIDEBAR_MENU]: toggleSidebar,
  [applicationConstants.OPEN__DIALOG]: openDialog,
});

export const app = (state: ApplicationState = defaultApplicationState, action: Action<any>) =>
  reducer(state, action);
