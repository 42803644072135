import { getPathNumber } from 'iqm-framework';
import { GroupItem, SegmentGroup } from '../models/SegmentedAudience';

export const checkNoSegmentsSelected = (groups: SegmentGroup[]) => {
  for (let i = 0; i < groups.length; i += 1) {
    if (groups[i].type === 'segmentgroup') {
      const nonEmptyItems = groups[i].items.filter((item: GroupItem) => !!item.segment);
      if (nonEmptyItems.length) {
        return false;
      }
    }
  }
  return true;
};

export const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
  );

export const getCampaignUrl = (mapType?: string) => {
  const CAMPAIGN_URL = '/campaigns';
  const origin = isLocalhost()
    ? `http://localhost:${process.env.REACT_APP_CAMPAIGN_APP_LOCAL_PORT}`
    : `${CAMPAIGN_URL}/u/${getPathNumber()}`;
  return `${origin}/#/apps/${mapType}`;
};

export const detectHashType = (hash: string) => {
  let hashType;
  switch (true) {
    case /^[a-f0-9]{32}$/.test(hash):
      hashType = 'MD5';
      break;
    case /^[a-f0-9]{40}$/.test(hash):
      hashType = 'SHA1';
      break;
    case /^[a-f0-9]{64}$/.test(hash):
      hashType = 'SHA256';
      break;
    case /^[a-f0-9]{128}$/.test(hash):
      hashType = 'SHA512';
      break;
    default:
      hashType = 'Other';
      break;
  }
  return hashType;
};
