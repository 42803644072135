import { CampaignSimple } from 'models/CampaignAudience';
import { OptionPayload } from 'models/Option';
import { ICampaign, CampaignsBasicInfo } from 'api/AddToCampaign';
import { IOCampaign } from 'models/InsertionOrder';

export const extractCampaignOption = (
  dataParam: CampaignsBasicInfo | ICampaign | IOCampaign,
): OptionPayload<Omit<CampaignSimple, 'ioId' | 'ioName' | 'ioBudgetTypeId' | 'ioStatusId'>> => {
  const data: any = dataParam as any;
  return {
    value: `${data.campaignId || data.id} ${data.name} ${data.status}`,
    label: data.name,
    id: data.campaignId || data.id,
    order: data.lastModified || data.modifiedAt,
    payload: {
      id: data.campaignId || data.id,
      name: data.name,
      creativeTypeId: data.creativeTypeId,
      status: data.status,
      owId: data.owId,
      organizationName: data.organizationName,
      lastModified: data.lastModified || data.modifiedAt,
      targetedAudienceIds: data.targetedAudienceIds,
    },
  };
};

export const extractCampaignIOOption = (
  dataParam: CampaignsBasicInfo | ICampaign | IOCampaign,
): OptionPayload<CampaignSimple> => {
  const data: any = dataParam as any;
  const partial = extractCampaignOption(data);
  return {
    ...partial,
    payload: {
      ...partial.payload,
      ioId: data.ioId,
      ioName: data.ioName,
      ioBudgetTypeId: data.ioBudgetTypeId,
      ioStatusId: data.ioStatusId,
    },
  };
};
