import { Route } from 'models/Route';
import { DESCRIPTION_TEXTS } from 'components/consts';
import { NoMatchPage } from '../pages/NoMatchPage';
import { DefaultPage } from '../pages/DefaultPage';

export const fallbackRoute: Route = {
  path: '*',
  exact: true,
  component: NoMatchPage,
};

export const validRoutes: Route[] = [
  {
    title: 'All',
    path: '/',
    exact: true,
    component: DefaultPage,
    icon: 'AudienceAll',
    value: '',
    description: DESCRIPTION_TEXTS.ALL_AUDIENCE,
  },
  {
    title: 'Matched Audience',
    path: '/matched',
    exact: true,
    component: DefaultPage,
    icon: 'AudienceMatched',
    value: '1',
    description: DESCRIPTION_TEXTS.MATCHED_AUDIENCE,
  },
  {
    title: 'Segmented Audience',
    path: '/segmented',
    exact: true,
    component: DefaultPage,
    icon: 'AudienceSegmented',
    value: '2',
    description: DESCRIPTION_TEXTS.SEGMENTED_AUDIENCE,
  },
  {
    title: 'Retargeted Audience',
    path: '/retargeted',
    exact: true,
    component: DefaultPage,
    icon: 'AudienceRetargeted',
    value: '3',
    description: DESCRIPTION_TEXTS.RETARGETED_AUDIENCE,
  },
  {
    title: 'Geofarmed Audience',
    path: '/geofarmed',
    exact: true,
    component: DefaultPage,
    icon: 'AudienceGeo',
    value: '4',
    description: DESCRIPTION_TEXTS.GEOFARMED_AUDIENCE,
  },
  {
    title: 'Contextual Audience',
    path: '/contextual',
    exact: true,
    component: DefaultPage,
    icon: 'AudienceContextual',
    value: '5',
    description: DESCRIPTION_TEXTS.CONTEXTUAL_AUDIENCE,
  },
  {
    title: 'Lookalike Audience',
    path: '/lookalike',
    exact: true,
    component: DefaultPage,
    icon: 'AudienceLookalike',
    value: '6',
    description: DESCRIPTION_TEXTS.LOOKALIKE_AUDIENCE,
  },
  {
    title: 'Campaign Audience',
    path: '/campaign',
    exact: true,
    component: DefaultPage,
    icon: 'AudienceCampaign',
    value: '7',
    description: DESCRIPTION_TEXTS.CAMPAIGN_AUDIENCE,
  },
  {
    title: 'Pre-bid Audience',
    path: '/pre-bid',
    exact: true,
    component: DefaultPage,
    icon: 'AudiencePreBid',
    value: '8',
    description: DESCRIPTION_TEXTS.PRE_BID_AUDIENCE,
  },
  {
    path: '/matched/:id',
    exact: true,
    component: DefaultPage,
  },
  {
    path: '/segmented/:id',
    exact: true,
    component: DefaultPage,
  },
  {
    path: '/lookalike/:id',
    exact: true,
    component: DefaultPage,
  },
  {
    path: '/retargeted/:id',
    exact: true,
    component: DefaultPage,
  },
  {
    path: '/geofarmed/:id',
    exact: true,
    component: DefaultPage,
  },
  {
    path: '/contextual/:id',
    exact: true,
    component: DefaultPage,
  },
  {
    path: '/campaign/:id',
    exact: true,
    component: DefaultPage,
  },
  {
    path: '/pre-bid/:id',
    exact: true,
    component: DefaultPage,
  },
];

export const routes: Route[] = [...validRoutes, fallbackRoute];
