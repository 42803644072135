import moment from 'moment-timezone';

export const CurrencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const ReachFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumFractionDigits: 1,
});

export const AudienceTableReachFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
});

export const formatDate = (date: number, timezone?: string) => {
  return moment
    .unix(date)
    .tz(timezone || 'UTC')
    .format('MM/DD/YYYY');
};
