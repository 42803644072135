import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import get from 'lodash/get';

import { snackbarActions } from 'store/snackbar/actions';
import { setRowAnimation } from 'styles/tableRowAnimation';
import {
  EMPTY_SELECTED_AUDIENCE_STATUS,
  SORTING_DIRECTION,
  SORTING_VALUE,
} from 'constants/audiences';
import { createAction } from '../../utils/actions';
import { API } from '../../api';
import {
  ColumnMapping,
  EditableMatchedAudience,
  UpdateMatchedAudienceData,
  CreateOrDuplicateMatchedAudience,
  DataPartners,
  Column,
  DataFormats,
} from '../../models/MatchedAudience';
import { matchedAudienceConstants } from './constants';
import { applicationActions } from '../app/actions';
import { AppState } from '../index';
// import { tableComponentInstance } from 'components/Audiences/AudiencesTable';
import { tableConstants } from '../table/constants';
import { tableActions } from '../table/actions';

export interface UploadAudienceFile {
  uploadAudienceFile: () => void;
}

export interface GetMatchedAudience {
  getMatchedAudience: (audienceId: number) => void;
}

export interface SetSuccessfullyCreated {
  setSuccessfullyCreated: (isSuccess: boolean | null) => void;
}

export interface SetDataPartners {
  setDataPartners: () => void;
}

export interface SetDataFormats {
  setDataFormats: () => void;
}

export const matchedAudienceActions = {
  getColumnsList() {
    return async (dispatch: Dispatch) => {
      const list = await API.MatchedAudience.getColumns();
      dispatch(createAction<Column[]>(matchedAudienceConstants.SAVE_COLUMNS_LIST, list));
    };
  },
  getMatchedAudience(audienceId: number) {
    return async (dispatch: Dispatch) => {
      const matchedAudience = await API.MatchedAudience.getAudience(audienceId);
      dispatch(
        createAction<EditableMatchedAudience>(
          matchedAudienceConstants.SET_EDITABLE_AUDIENCE,
          matchedAudience,
        ),
      );
    };
  },
  setAudienceName(audienceName: string) {
    return createAction<string>(matchedAudienceConstants.SET_AUDIENCE_NAME, audienceName);
  },
  saveColumnsMapping(columnsMapping: ColumnMapping[]) {
    return createAction<ColumnMapping[]>(
      matchedAudienceConstants.SAVE_COLUMNS_MAPPING,
      columnsMapping,
    );
  },
  createOrDuplicateMatchedAudience(audience: CreateOrDuplicateMatchedAudience) {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(matchedAudienceConstants.START_CREATING));
      const {
        audienceId,
        isDuplicateAudience,
        audienceName,
        details,
        resetSetup,
        closeDialog,
        updateSorting,
      } = audience;
      try {
        let message;
        if (isDuplicateAudience && audienceId && audienceName) {
          message = await API.MatchedAudience.duplicateMatchedAudience(audienceId, audienceName);
        } else if (!isDuplicateAudience && details) {
          message = await API.MatchedAudience.uploadMatchedAudience(details);
        }
        closeDialog();
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(
          createAction(
            tableConstants.SET_AUDIENCES_SELECTED_STATUSES,
            EMPTY_SELECTED_AUDIENCE_STATUS,
          ),
        );
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        // update audiences count by type
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
        setRowAnimation();
        dispatch(createAction<void>(matchedAudienceConstants.RESET));
        updateSorting({
          field: SORTING_VALUE.CREATED_ON,
          direction: SORTING_DIRECTION.DESC,
        });
        resetSetup();
      } catch (e) {
        // @ts-ignore
        if (!(e.errorCode >= 400 && e.errorCode < 500)) {
          dispatch(createAction<void>(matchedAudienceConstants.RESET));
          updateSorting({
            field: SORTING_VALUE.CREATED_ON,
            direction: SORTING_DIRECTION.DESC,
          });
        }
        dispatch(
          snackbarActions.openSnackbar({
            message: isDuplicateAudience
              ? get(e, 'errorObjects[0].error', '')
              : get(e, 'errorMsg', ''),
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<void>(matchedAudienceConstants.END_CREATING));
      }
    };
  },
  updateMatchedAudience(details: UpdateMatchedAudienceData) {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<void>(matchedAudienceConstants.START_CREATING));
      try {
        const message = await API.MatchedAudience.updateMatchedAudience(details);
        dispatch(
          snackbarActions.openSnackbar({
            message,
            type: 'success',
          }),
        );
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        (dispatch as ThunkDispatch<AppState, void, AnyAction>)(applicationActions.resetSidebar());
      } catch (e) {
        dispatch(
          snackbarActions.openSnackbar({
            message: get(e, 'errorMsg', ''),
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction(tableConstants.SET_SELECTED_AUDIENCES, []));
        dispatch(createAction<void>(matchedAudienceConstants.END_CREATING));
      }
    };
  },
  reset() {
    return createAction<void>(matchedAudienceConstants.RESET);
  },
  setSuccessfullyCreated(isSuccess: boolean | null) {
    return createAction<boolean | null>(matchedAudienceConstants.SUCCESSFULLY_CREATED, isSuccess);
  },
  setDataPartners() {
    return async (dispatch: Dispatch) => {
      const list = await API.MatchedAudience.getDataPartners();
      dispatch(createAction<DataPartners[]>(matchedAudienceConstants.SET_DATA_PARTNERS, list));
    };
  },
  setDataFormats() {
    return async (dispatch: Dispatch) => {
      const list = await API.MatchedAudience.getDataFormats();
      dispatch(createAction<DataFormats[]>(matchedAudienceConstants.SET_DATA_FORMATS, list));
    };
  },
};
