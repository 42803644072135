import React from 'react';
import { connect } from 'react-redux';

import { useCreatePreBidAudience } from 'hooks/usePreBidAudience';
import { TDialog } from 'store/app/reducer';
import { applicationActions, ResetSidebar } from 'store/app/actions';
import { tableActions, UpdateSorting } from 'store/table/actions';
import { preBidAudienceActions } from 'store/preBidAudience/actions';
import { SORTING_VALUE, SORTING_DIRECTION } from 'constants/audiences';
import { PreBidAudienceDialog } from '../components/PreBidAudienceDialog/index';

interface Props extends ResetSidebar, UpdateSorting {
  handleClose: () => void;
  dialog: TDialog;
  onSuccessfulAudienceCreation: (message: string) => void;
}

const CreatePreBidAudienceComponent = (props: Props) => {
  const { dialog, handleClose, resetSidebar, updateSorting, onSuccessfulAudienceCreation } = props;

  const onSuccessfulCreation = (data: { message: string }) => {
    handleClose();
    resetSidebar();
    onSuccessfulAudienceCreation(data.message);
    updateSorting({
      field: SORTING_VALUE.CREATED_ON,
      direction: SORTING_DIRECTION.DESC,
    });
  };

  const createPreBidAudience = useCreatePreBidAudience({ onSuccess: onSuccessfulCreation });

  return (
    <PreBidAudienceDialog
      dialog={dialog}
      handleClose={handleClose}
      title="Pre-bid Audience"
      isLoading={createPreBidAudience.isLoading}
      onCompletion={({ audienceName, ids }: { audienceName: string; ids: number[] }) => {
        createPreBidAudience.mutate({
          prebidAudienceName: audienceName,
          prebidAudienceSegmentIdList: ids,
        });
      }}
    />
  );
};

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  updateSorting: tableActions.updateSorting,
  onSuccessfulAudienceCreation: preBidAudienceActions.onSuccessfulPreBidCreation,
};

export const CreatePreBidAudience = connect(null, mapAction)(CreatePreBidAudienceComponent);
