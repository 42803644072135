import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Dialog } from 'factor';
import { connect } from 'react-redux';

import { TDialog } from 'store/app/reducer';
import { AppState } from 'store';
import { applicationActions, ResetSidebar } from 'store/app/actions';
import { MatchedAudienceCreation } from './components/MatchedAudienceCreation';
import { MatchedAudienceDialogContext } from './context';

import styles from './styles.module.scss';

interface Props extends ResetSidebar {
  handleClose: () => void;
  dialog: TDialog;
  title: string;
  isCreationInProgress: boolean;
}

const MatchedAudienceDialogComponent = (props: Props) => {
  const { handleClose, dialog, resetSidebar, title, isCreationInProgress } = props;

  const history = useHistory();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [create, setCreate] = useState<{
    onClick?: () => void;
    disabled: boolean;
    resetSetup: () => void;
  }>({
    disabled: true,
    resetSetup: () => {},
  });
  const isDuplicateAudience = dialog?.type === 'duplicate';
  const { id: audienceId } = useParams<{ id: string }>();

  useEffect(() => {
    if (audienceId) {
      setIsEdit(true);
    }
  }, [audienceId]);

  const resetAndClose = useCallback(() => {
    handleClose();
    create.resetSetup();
    resetSidebar();
    if (isEdit) {
      history.goBack();
    }
  }, [handleClose, create, isEdit, history, resetSidebar]);

  const contextValue = useMemo(
    () => ({
      setCreate,
      handleClose,
    }),
    [setCreate, handleClose],
  );

  const actionButtons = useMemo(
    () => [
      {
        variant: 'secondary',
        title: 'Cancel',
        handler: resetAndClose,
        disabled: isCreationInProgress,
      },
      {
        title: isEdit && !isDuplicateAudience ? 'Save' : 'Create',
        handler: create.onClick,
        disabled: create.disabled,
        showLoadingIndicator: isCreationInProgress,
      },
    ],
    [
      resetAndClose,
      create.onClick,
      create.disabled,
      isEdit,
      isDuplicateAudience,
      isCreationInProgress,
    ],
  );

  return (
    <MatchedAudienceDialogContext.Provider value={contextValue}>
      <Dialog
        dialogTitle={title}
        open={dialog}
        crossButton
        headerFooterBorders
        className={styles.dialogWrapper}
        onCrossButtonClick={resetAndClose}
        actionButtons={actionButtons}
      >
        <MatchedAudienceCreation isEdit={isEdit} isDuplicateAudience={isDuplicateAudience} />
      </Dialog>
    </MatchedAudienceDialogContext.Provider>
  );
};

const mapState = (state: AppState) => ({
  isCreationInProgress: state.matchedAudience.submitInProgress,
});

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
};

export const MatchedAudienceDialog = connect(mapState, mapAction)(MatchedAudienceDialogComponent);
