export const tableStatusIconsMap: { [key: string]: string } = {
  All: 'StatusAllColor',
  Processing: 'StatusProcessingColor',
  Pending: 'StatusPendingColor',
  Approved: 'StatusApprovedColor',
  Ready: 'StatusReadyColor',
  Rejected: 'StatusRejectedColor',
  'In-use': 'StatusRunningColor',
  'Pending For Approval': 'StatusPendingColor',
  Failed: 'StatusFailedColor',
  Deleted: 'StatusDeletedColor',
  Unused: 'StatusUnusedColor',
};

export const COUNTRY_CODES = {
  US: {
    LABEL: 'United States',
    CODE: 30100001,
  },
};

export const DESCRIPTION_TEXTS = {
  ALL_AUDIENCE: 'Explore all types of audiences and create one that meets your campaign’s needs',
  MATCHED_AUDIENCE:
    'Match your user data using proprietary matching technologies keeping user privacy intact',
  SEGMENTED_AUDIENCE:
    'Use our curated audience segments or create your own from thousands of customer attributes',
  RETARGETED_AUDIENCE: 'Increase engagement by bringing visitors back to your website',
  GEOFARMED_AUDIENCE:
    'Draw flexible boundary (polygons) on a map and target audience visiting that location',
  LOOKALIKE_AUDIENCE: 'Reach new audience with similar characteristics as your existing audience',
  CONTEXTUAL_AUDIENCE:
    'Reach audience based on content they consume - by matching keywords, topics or central theme of a website',
  CAMPAIGN_AUDIENCE:
    'Create dynamic funnel and re-engage users who have shown interest in your past ad campaigns',
  PRE_BID_AUDIENCE:
    'Target brand-safety, fraud & IVT, and Viewability pre-bid segments from our partners',
};

export const creativeIconMap = {
  '11': 'CreativeImage',
  '13': 'CreativeHtml',
  '14': 'CreativeVideo',
  '15': 'CreativeNative',
  '16': 'CreativeTicker',
  '17': 'CreativeAudio',
  '18': 'CreativeGotv',
};

export const CAMPAIGN_STATUSES = {
  EXPIRED: 'expired',
  PENDING: 'pending',
  REJECTED: 'rejected',
  DELETED: 'deleted',
};

export const MATCHED_AUD_SYSTEM_FIELD_KEYS = {
  EMAIL: 'Email',
  PHONE: 'Phone',
  FULL_ADDRESS: 'Residence_FullAddress',
  ZIP: 'Residence_Addresses_Zip',
  STATE: 'Residence_Addresses_State',
  STREET_ADDRESS: 'Residence_Addresses_AddressLine',
  FIRST_NAME: 'Voters_FirstName',
  LAST_NAME: 'Voters_LastName',
  VOTER_ID: 'Voters_ID',
  NPI_ID: 'NPI_ID',
};

export const INSIGHT_STATUSES = {
  READY: 2,
  PROCESSING: 1,
  FAILED: 3,
};
