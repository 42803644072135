import { Dispatch } from 'redux';
import { createAction } from '../../utils/actions';
import { applicationConstants } from './constants';

import { matchedAudienceConstants } from '../matchedAudience/constants';
import { retargetedAudienceConstants } from '../retargetedAudience/constants';
import { segmentedAudienceConstants } from '../segmentedAudience/constants';
import { TDialog } from './reducer';

export const applicationActions = {
  toggleSidebar() {
    return createAction<void>(applicationConstants.TOGGLE__SIDEBAR_MENU);
  },
  resetSidebar() {
    return (dispatch: Dispatch) => {
      dispatch(createAction<void>(matchedAudienceConstants.RESET));
      dispatch(createAction<void>(retargetedAudienceConstants.RESET));
      dispatch(createAction<void>(segmentedAudienceConstants.RESET));
    };
  },
  openDialog(dialog: TDialog) {
    return createAction(applicationConstants.OPEN__DIALOG, dialog);
  },
};

export interface ToggleSidebarMenu {
  toggleSidebar: () => void;
}

export interface ResetSidebar {
  resetSidebar: () => void;
}

export interface OpenDialog {
  openDialog: (dialog: TDialog) => void;
}
