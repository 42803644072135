import React from 'react';
import { Button, Icon } from 'factor';

import styles from './styles.module.scss';

interface Props {
  audienceInfo: any[];
}

interface ItemInterface {
  iconName: string;
  title: string;
  description: string;
}

export const AudienceBlankPage = (props: Props) => {
  const { audienceInfo } = props;

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.mainImgContainer}>
          <img src={audienceInfo[0]?.imagePath} alt="" />
        </div>
        <div className={styles.mainInfo}>
          <div className={styles.mainInfoTitle}>{audienceInfo[0]?.title}</div>
          <div className={styles.mainInfoBody}>{audienceInfo[0]?.infoText}</div>
          <div className={styles.mainInfoBtn}>
            <Button
              variant="tertiary2"
              size="small"
              className={`mr-2 ${styles.createBtn}`}
              iconName="Plus"
              onClick={audienceInfo[0]?.handler}
            >
              {`Create ${audienceInfo[0]?.title}`}
            </Button>
          </div>
        </div>
      </div>
      {audienceInfo[0]?.subItem.map((item: ItemInterface) => {
        return (
          <div className={styles.subItem} key={item.title}>
            <Icon name={item.iconName} className={styles.subIcon} />
            <div className={styles.subItemTitle}>{item.title}</div>
            <div className={styles.subItemBody}>{item.description}</div>
          </div>
        );
      })}
    </div>
  );
};
