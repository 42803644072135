import React, { useEffect, useMemo, useState } from 'react';
import isBoolean from 'lodash/isBoolean';
import get from 'lodash/get';
import { TDialog } from 'store/app/reducer';
import {
  Dialog,
  Icon,
  ProgressCircle,
  NumberFormatter,
  Tooltip,
  ProportionalSelect,
  Pill,
} from 'factor';
import moment from 'moment';
import { connect } from 'react-redux';

import { AppState } from 'store';
import {
  GetLookalikeAudienceResponse,
  GeoPoliticalInfo,
  GeoPoliticalInfoErrors,
  SourceAudienceInfo,
} from 'models/LookalikeAudience';
import { COMMON_DIALOG_MODAL_PROPS } from 'constants/audiences';
import { tableStatusIconsMap } from 'components/consts';
import { SOMETHING_WENT_WRONG_MSG, FAILED_TO_LOAD_MSG } from 'constants/errors';
import { lookalikeAudienceActions, GetLookalikeAudience } from 'store/lookalikeAudience/actions';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { SelectedAudiencesTable } from '../components/LookalikeAudienceSelection/SelectedAudienceTable';

import styles from './styles.module.scss';

interface Props extends GetLookalikeAudience, OpenSnackbar {
  dialog: TDialog;
  handleClose?: () => void;
  sourceAudienceInfo?: SourceAudienceInfo | null;
  geoPoliticalInfo?: GeoPoliticalInfo | null;
  geoPoliticalInfoErrors?: GeoPoliticalInfoErrors | null;
  audienceInfo?: GetLookalikeAudienceResponse | null;
  resetLookalikeAudience: () => void;
  dialogVisibility?: boolean;
  isIncluded?: boolean;
}

interface tableDataInterface {
  created: number;
  label: string;
  matchRate: number;
  uniques?: number;
  value: number;
}

type SelectFieldData = {
  audienceName: string;
  id: number;
  uniques: number;
  matchRate: number;
  createdOn: number;
};

type geopoliticalValue = {
  name: string;
};

const ViewLookalikeAudienceComponent = (props: Props) => {
  const {
    dialog,
    handleClose,
    sourceAudienceInfo,
    getLookalikeAudience,
    geoPoliticalInfo,
    geoPoliticalInfoErrors,
    audienceInfo,
    resetLookalikeAudience,
    dialogVisibility = true,
    isIncluded,
    openSnackbar,
  } = props;

  const [audienceTableData, setAudienceTableData] = useState<tableDataInterface[]>([]);

  const concatenateStringArr = (stringArr: string[]): string => {
    return stringArr.reduce((information, curr, index) => {
      return index === stringArr.length - 1
        ? `${information} & ${curr}`
        : `${information}, ${curr}`;
    });
  };

  const options = useMemo(
    () => [
      {
        topLabel: '1',
        bottomLabel: '10',
        value: 1,
      },
      {
        topLabel: '2',
        bottomLabel: '9',
        value: 2,
      },
      {
        topLabel: '3',
        bottomLabel: '8',
        value: 3,
      },
      {
        topLabel: '4',
        bottomLabel: '7',
        value: 4,
      },
      {
        topLabel: '5',
        bottomLabel: '6',
        value: 5,
      },
      {
        topLabel: '6',
        bottomLabel: '5',
        value: 6,
      },
      {
        topLabel: '7',
        bottomLabel: '4',
        value: 7,
      },
      {
        topLabel: '8',
        bottomLabel: '3',
        value: 8,
      },
      {
        topLabel: '9',
        bottomLabel: '2',
        value: 9,
      },
      {
        topLabel: '10',
        bottomLabel: '1',
        value: 10,
      },
    ],
    [],
  );

  const concatenateInfo = (infoArr: geopoliticalValue[]): any => {
    if (infoArr) {
      const nameArr = infoArr.map((value) => value.name);
      if (nameArr.length > 2) {
        return (
          <div>
            <Tooltip
              label={concatenateStringArr(nameArr)}
              auto={false}
              position="bottom"
              labelMaxWidth={270}
            >
              {`${nameArr[0]} & ${nameArr.length - 1} more`}
            </Tooltip>
          </div>
        );
      }
      return <div>{concatenateStringArr(nameArr)}</div>;
    }
    return <span>-</span>;
  };

  useEffect(() => {
    if (dialog?.audienceId) {
      getLookalikeAudience(dialog.audienceId, !dialogVisibility, false).catch((err) => {
        openSnackbar({
          message: get(err, 'errorObjects[0].error', SOMETHING_WENT_WRONG_MSG),
          type: 'error',
        });
        if (handleClose) {
          handleClose();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, getLookalikeAudience, openSnackbar, dialogVisibility]);

  const actionButtons = useMemo(
    () => [
      {
        title: 'Done',
        handler: () => {
          if (handleClose) {
            handleClose();
          }
          resetLookalikeAudience();
        },
      },
    ],
    [handleClose, resetLookalikeAudience],
  );

  const mapSelectFieldData = (data: SelectFieldData[]) =>
    data.map((audience) => ({
      label: audience.audienceName,
      value: audience.id,
      uniques: audience.uniques,
      matchRate: audience.matchRate,
      created: audience.createdOn,
      audienceName: audience.audienceName,
      reactLabel: (
        <span className={styles.audienceOption}>
          <i className={styles.audienceIcon}>
            <Icon name="AudienceMatched" className="_size-20" />
          </i>
          <span className={styles.audienceLabel}>
            {audience.audienceName.length > 100
              ? `${audience.audienceName.substring(0, 99)}...`
              : audience.audienceName}
          </span>
        </span>
      ),
    }));

  useEffect(() => {
    if (sourceAudienceInfo?.length) {
      const mappedData = mapSelectFieldData(sourceAudienceInfo);
      setAudienceTableData(mappedData);
    }
  }, [sourceAudienceInfo, setAudienceTableData]);

  const getTooltipLabel = () => {
    return (
      <>
        <div className={styles.toolTipText}>
          Audience size is in inverse proportion to the similarity levels.
        </div>
        <div className={`mt-1 ${styles.toolTipText}`}>
          A highly similar and close to your source audience will have limited expansion in audience
          size.
        </div>
        <div className={`mt-1 ${styles.toolTipText}`}>
          The large audience size is achievable by considering the basic <br />
          similarity level.
        </div>
      </>
    );
  };

  const renderContent =
    sourceAudienceInfo && geoPoliticalInfo && audienceInfo ? (
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.icon}>
            <Icon name="AudienceLookalike" />
          </div>
          {audienceInfo.name.length > 60 ? (
            <Tooltip
              className={styles.toolTip}
              label={audienceInfo.name}
              position="bottom"
              auto={false}
            >
              <div className={`${styles.name} ${styles.restrictName}`}>{audienceInfo.name}</div>
            </Tooltip>
          ) : (
            <div className={styles.name}>{audienceInfo.name}</div>
          )}
          {isBoolean(isIncluded) && (
            <Pill
              className={isIncluded ? styles.successPill : styles.dangerPill}
              label={isIncluded ? 'Allowed' : 'Blocked'}
            />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.infoWrapper}>
            <div className={styles.infoLabel}>Created on:</div>
            <div className={styles.infoText}>
              {moment.unix(audienceInfo.createdOn).format('MM/DD/yyyy')}
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.infoLabel}>Reach</div>
            <div className={styles.infoText}>
              <NumberFormatter>{audienceInfo.uniques}</NumberFormatter>
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <Icon name={tableStatusIconsMap[audienceInfo.status]} className={styles.icon} />
            <div className={styles.infoStatus}>{audienceInfo.status}</div>
          </div>
        </div>
        <div className="row">
          <div className={`col-12 ${styles.tableColumn}`}>
            {audienceTableData && (
              <SelectedAudiencesTable
                selectedAudiences={audienceTableData}
                setSelectedAudiences={setAudienceTableData}
                isEdit={false}
                isView
              />
            )}
          </div>
        </div>
        <div className={styles.detailHeading}>Geo Expansion Settings</div>
        <div className={styles.detailContainer}>
          <div className={styles.detailrow}>
            <div className={styles.detailLabel}>Country:</div>
            <div className={styles.detailText}>
              {geoPoliticalInfoErrors?.country
                ? FAILED_TO_LOAD_MSG
                : geoPoliticalInfo.country[0].name}
            </div>
          </div>
          <div className={styles.detailrow}>
            <div className={styles.detailLabel}>States:</div>
            <div className={styles.detailText}>
              {geoPoliticalInfoErrors?.states
                ? FAILED_TO_LOAD_MSG
                : concatenateInfo(geoPoliticalInfo.states)}
            </div>
          </div>
          <div className={styles.detailrow}>
            <div className={styles.detailLabel}>Cities:</div>
            <div className={styles.detailText}>
              {geoPoliticalInfoErrors?.cities
                ? FAILED_TO_LOAD_MSG
                : concatenateInfo(geoPoliticalInfo.cities)}
            </div>
          </div>
          <div className={styles.detailrow}>
            <div className={styles.detailLabel}>County:</div>
            <div className={styles.detailText}>
              {geoPoliticalInfoErrors?.counties
                ? FAILED_TO_LOAD_MSG
                : concatenateInfo(geoPoliticalInfo.counties)}
            </div>
          </div>
          <div className={styles.detailrow}>
            <div className={styles.detailLabel}>Senates:</div>
            <div className={styles.detailText}>
              {geoPoliticalInfoErrors?.senates
                ? FAILED_TO_LOAD_MSG
                : concatenateInfo(geoPoliticalInfo.senates)}
            </div>
          </div>
          <div className={styles.detailrow}>
            <div className={styles.detailLabel}>Congressional District:</div>
            <div className={styles.detailText}>
              {geoPoliticalInfoErrors?.congressionalDistricts
                ? FAILED_TO_LOAD_MSG
                : concatenateInfo(geoPoliticalInfo.congressionalDistricts)}
            </div>
          </div>
          <div className={styles.detailrow}>
            <div className={styles.detailLabel}>State Houses:</div>
            <div className={styles.detailText}>
              {geoPoliticalInfoErrors?.stateHouses
                ? FAILED_TO_LOAD_MSG
                : concatenateInfo(geoPoliticalInfo.stateHouses)}
            </div>
          </div>
          <div className={styles.detailrow} />
          <div className={styles.accuracyrow}>
            <div className="d-flex">
              <div className={`${styles.accuracyLabel} mb-2`}>Audience Size & Similarity</div>
              <Tooltip label={getTooltipLabel()} auto={false} position="right" labelMaxWidth={400}>
                <Icon name="Info" className="ml-1" />
              </Tooltip>
            </div>
            <ProportionalSelect
              value={geoPoliticalInfo?.audienceSize}
              options={options}
              topLabel="Audience Size"
              bottomLabel="Similarity"
              isActive={(option: { value: number }, selectedOption: number) =>
                option.value === selectedOption
              }
              labelAfter
              gap={12}
              disabled
            />
          </div>
        </div>
      </div>
    ) : (
      <div className={styles.loading}>
        <ProgressCircle />
      </div>
    );

  return dialogVisibility ? (
    <Dialog
      dialogTitle="Audience Details"
      open={dialog}
      crossButton
      modalProps={COMMON_DIALOG_MODAL_PROPS}
      headerFooterBorders
      className={styles.container}
      onCrossButtonClick={() => {
        if (handleClose) {
          handleClose();
        }
        resetLookalikeAudience();
      }}
      actionButtons={actionButtons}
    >
      {renderContent}
    </Dialog>
  ) : (
    renderContent
  );
};

const mapState = (state: AppState) => ({
  sourceAudienceInfo: state.lookalikeAudience.sourceAudienceInfo,
  geoPoliticalInfo: state.lookalikeAudience.geoPoliticalInfo,
  geoPoliticalInfoErrors: state.lookalikeAudience.geoPoliticalInfoErrors,
  audienceInfo: state.lookalikeAudience.audienceInfo,
});

const mapAction = {
  getLookalikeAudience: lookalikeAudienceActions.getLookalikeAudience,
  resetLookalikeAudience: lookalikeAudienceActions.reset,
  openSnackbar: snackbarActions.openSnackbar,
};

const ViewLookalikeAudience = connect(mapState, mapAction)(ViewLookalikeAudienceComponent);

export default ViewLookalikeAudience;
