import * as React from 'react';
import { SvgIconProps } from '@applift/factor';
import { AudienceStatusIconMap } from 'constants/audiences';

export const getAudienceStatusIcon = (audienceStatus: string, props: Partial<SvgIconProps>) => {
  // @ts-ignore
  const Icon = AudienceStatusIconMap[audienceStatus.toLowerCase()];

  if (!Icon) {
    return null;
  }

  switch (audienceStatus.toLowerCase()) {
    case 'all':
    case 'ready':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Icon {...props} sx={{ textColor: 'info-400', ...(props.sx || {}) }} />;
    case 'failed':
    case 'rejected':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Icon {...props} sx={{ textColor: 'danger-400', ...(props.sx || {}) }} />;
    case 'pending':
    case 'pending for approval':
    case 'processing':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Icon {...props} sx={{ textColor: 'warning-400', ...(props.sx || {}) }} />;
    default:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Icon {...props} />;
  }
};
