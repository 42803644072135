import React from 'react';
import { NumberFormatter } from 'factor';

import { TableAudienceItem } from 'models/Table';

import styles from './styles.module.scss';

const getCampaignBodyMapper = () => ({
  uniques: {
    key: (data: TableAudienceItem) => {
      return <NumberFormatter>{data.uniques as number}</NumberFormatter>;
    },
    className: `_right ${styles.boldtext}`,
  },
});

export default getCampaignBodyMapper;
