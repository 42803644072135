import { datadogRum } from 'iqm-framework';
import get from 'lodash/get';

import { ICampaignInformationWithOption } from 'components/Audiences/TableSelectedActions/AddToCampaignButton';
import { CreateCampaignAudienceData } from 'models/CampaignAudience';

import { SUCCESS, FAILED, service } from '../../constants';
import { TActionParams } from '../../types';
import { actionTypes } from '../actionTypes';

const actions = {
  createCampaignAudience: {
    id: 'createCampaignAudience',
    text: 'Create Campaign Audience',
  },
};

interface TCreateCampaignAudienceParams extends TActionParams {
  createAudienceData: CreateCampaignAudienceData;
  selectedCampaigns: ICampaignInformationWithOption[];
}

export const createCampaignAudience = (params: TCreateCampaignAudienceParams) => {
  datadogRum.addAction(
    `${actions.createCampaignAudience.text} ${params.success ? SUCCESS : FAILED}`,
    {
      action: `${service}/${actions.createCampaignAudience.id}`,
      actionType: `${service}/${actionTypes.createAudience.id}`,
      success: params.success,
      errorMsg: params.errorMsg,
      latency: params.latency,
      statusCode: params.statusCode,
      info: {
        campaignCount: get(params, 'selectedCampaigns.length'),
        clickCount: get(params, 'createAudienceData.campaignDetail.clicks.length'),
        impressionCount: get(params, 'createAudienceData.campaignDetail.impressions.length'),
        conversionCount: get(params, 'createAudienceData.campaignDetail.conversions.length'),
      },
    },
  );
};
