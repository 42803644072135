import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from 'factor';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import { TDialog } from 'store/app/reducer';
import { applicationActions, ResetSidebar } from 'store/app/actions';
import { getRetargetedAudience } from 'api/RetargetedAudience';
import { RetargetedAudience } from 'models/RetargetedAudience';
import { RetargetedAudienceTagIntegration } from '../components/RetargetedAudienceTagIntegration';

import styles from './styles.module.scss';

interface Props extends ResetSidebar {
  dialog: TDialog;
  handleClose: () => void;
}

const TagRetargetedAudienceComponent = (props: Props) => {
  const { dialog, handleClose, resetSidebar } = props;

  const [audience, setAudience] = useState<RetargetedAudience | undefined>();

  useEffect(() => {
    if (dialog?.audienceId) {
      getRetargetedAudience(dialog.audienceId).then((res) => setAudience(res.data));
    }
  }, [dialog]);

  const resetAndClose = useCallback(() => {
    handleClose();
    resetSidebar();
  }, [handleClose, resetSidebar]);

  const actionButtons = useMemo(
    () => [
      {
        title: 'Done',
        handler: resetAndClose,
      },
    ],
    [resetAndClose],
  );

  return (
    <>
      {audience ? (
        <Dialog
          dialogTitle="Integrate Tag"
          open={!isEmpty(dialog)}
          crossButton
          headerFooterBorders
          className={styles.container}
          onCrossButtonClick={resetAndClose}
          actionButtons={actionButtons}
        >
          <RetargetedAudienceTagIntegration
            tagScript={`<script src='https://pxl.iqm.com/c/${audience.tagId}' async></script>`}
          />
        </Dialog>
      ) : (
        'loading...'
      )}
    </>
  );
};

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
};

const TagRetargetedAudience = connect(null, mapAction)(TagRetargetedAudienceComponent);

export default TagRetargetedAudience;
