import { AxiosError, AxiosResponse } from 'axios';
import get from 'lodash/get';

import { CreateAudienceInsightsResponse, InsightsStatusResponse } from 'models/Insights';
import { getInstance } from './Instance';
import { WithResponse } from '../models/Response';

export const downloadInsights = async (downloadInfo: FormData) => {
  try {
    const response: AxiosResponse<WithResponse<{
      insightsReportUrl: string;
    }>> = await getInstance().post('/v3/audience/ins/insights/download', downloadInfo);
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const emailInsights = async (emailInfo: FormData) => {
  try {
    const response: AxiosResponse<WithResponse<string>> = await getInstance().post(
      '/v3/audience/ins/insights/send-email',
      emailInfo,
    );
    return response.data;
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data);
  }
};

export const getInsightsStatusList = async (): Promise<WithResponse<InsightsStatusResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<InsightsStatusResponse>> = await getInstance().get(
      '/v3/ins/static/insights/status/list',
    );
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const generateInsights = async (
  audienceIds: string,
): Promise<WithResponse<CreateAudienceInsightsResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<
      CreateAudienceInsightsResponse
    >> = await getInstance().post(`/v3/audience/insights/add?audienceIds=${audienceIds}`);
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const reGenerateInsights = async (
  insightsId: string,
): Promise<WithResponse<CreateAudienceInsightsResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<
      CreateAudienceInsightsResponse
    >> = await getInstance().post(`/v3/audience/ins/insights/regenerate/${insightsId}`);
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};
