const isElementVisible = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const setRowAnimation = async () => {
  const getTableBodyElements = (
    query: HTMLCollectionOf<HTMLTableSectionElement>,
  ): Promise<HTMLCollectionOf<HTMLTableSectionElement>> =>
    new Promise((resolve) => {
      const observer = new MutationObserver(() => {
        // to check if the childNodes have content inside them and return the tbody
        if (query[1]?.childNodes.length && isElementVisible(query[1])) {
          resolve(query);
          observer.disconnect();
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });

  const bodyElements: HTMLCollectionOf<HTMLTableSectionElement> = await getTableBodyElements(
    document.getElementsByTagName('tbody'),
  );
  const tableBodyElement = bodyElements[1]; // getting the tbody with content
  tableBodyElement.style.position = 'relative';
  tableBodyElement.style.top = '-2.5rem';

  const setTdInnerElementsStyling = (properties: string[], values: (string | number)[]) => {
    if (tableBodyElement.firstChild?.childNodes) {
      for (
        let childNodeIndex = 0;
        childNodeIndex < tableBodyElement.firstChild.childNodes.length;
        childNodeIndex += 1
      ) {
        properties.forEach((property, index) => {
          const tdInnerElement = tableBodyElement.firstChild?.childNodes[childNodeIndex].firstChild
            ?.firstChild as HTMLElement;
          if (tdInnerElement) {
            // @ts-ignore
            tdInnerElement.style[property] = values[index];
          }
        });
      }
    }
  };

  const highlightBackground = () => {
    let color = 'transparent';
    let bgChangeCount = 0;
    const backgroundChangeInterval = setInterval(() => {
      color = color === 'transparent' ? '#F2F5FF' : 'transparent';
      if (tableBodyElement.firstChild?.childNodes) {
        for (
          let childNodeIndex = 0;
          childNodeIndex < tableBodyElement.firstChild.childNodes.length;
          childNodeIndex += 1
        ) {
          const tdParentElement = tableBodyElement.firstChild.childNodes[childNodeIndex]
            .firstChild as HTMLElement;
          if (tdParentElement) {
            tdParentElement.style.backgroundColor = color;
          }
        }
      }
      bgChangeCount += 1;
      if (bgChangeCount === 6) {
        clearInterval(backgroundChangeInterval);
        setTdInnerElementsStyling(['fontWeight', 'fontSize'], ['normal', '']);
      }
    }, 400);
  };

  const renderRowContent = () => {
    let opacity = 0;
    const opacityInterval = setInterval(() => {
      opacity += 0.2;
      setTdInnerElementsStyling(['opacity'], [opacity]);
      if (opacity === 1) {
        clearInterval(opacityInterval);
        highlightBackground();
      }
    }, 100);
  };

  setTdInnerElementsStyling(['opacity', 'fontWeight', 'fontSize'], [0, 'bold', '.9rem']);

  let topOffSet = -2.5;
  const moveRowInterval = setInterval(() => {
    topOffSet += 0.1;
    tableBodyElement.style.top = `${topOffSet}rem`;
    if (topOffSet >= 0) {
      tableBodyElement.style.top = `0`;
      tableBodyElement.style.position = 'static';
      clearInterval(moveRowInterval);
      renderRowContent();
    }
  }, 5);
};
