import React from 'react';
import moment from 'moment';
import { ButtonCircle } from 'factor';
import styles from './Index.module.scss';

interface IEvent {
  event: {
    start: Date;
    end: Date;
    id: string;
  };
}

interface Props {
  info: IEvent;
  onRemove: (eventId: string) => void;
  isReadOnly: boolean;
}

const convertDate = (date: Date) => {
  const mDate = moment(date);
  if (!mDate.isValid()) {
    return '';
  }
  // add a second to handle `23:59:59` resulting in `11 pm` instead of `12 am`
  return mDate.add(1, 'second').format('hh a');
};

export const Event = ({ info, onRemove, isReadOnly }: Props) => {
  return (
    <div data-qa="383" className={styles.wrapper} style={{ boxSizing: 'border-box' }}>
      <div data-qa="384" className={styles.time}>
        {`${convertDate(info.event.start)} - ${convertDate(info.event.end)}`}
      </div>
      {!isReadOnly && <ButtonCircle iconName="Close" outline onClick={onRemove(info.event.id)} />}
    </div>
  );
};
