import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import uniqueId from 'lodash/uniqueId';
import { TextField, FileUploader } from 'factor';
import { connect } from 'react-redux';
import { ORIGIN_URL } from 'iqm-framework';
import { API } from 'api';
import { ICloudFile } from 'api/Cloud';

import { matchedAudienceActions } from 'store/matchedAudience/actions';
import { AppState } from 'store';
import { ColumnMapping, CSV_FORMAT, EXCEL_FORMATS } from 'models/MatchedAudience';

import { DefaultFallback } from 'utils/suspense';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { RUMLogger } from 'services/RUMLogger';

import { MatchedAudienceSetup } from '../MatchedAudienceSetup';

import styles from './styles.module.scss';
import { CloudProviderBar } from '../../../CloudProviderBar';

interface Props extends OpenSnackbar, ReturnType<typeof mapState> {
  getColumnsList: () => void;
  setAudienceName: (name: string) => void;
  saveColumnsMapping: (arr: ColumnMapping[]) => void;
  isEdit: boolean;
  isDuplicateAudience: boolean;
}

const MatchedAudienceCreationComponent = (props: Props) => {
  const {
    getColumnsList,
    isColumnsMasterlistAvailable,
    setAudienceName,
    audienceName,
    columnsMasterlist,
    saveColumnsMapping,
    columnsMapping,
    openSnackbar,
    editableAudience,
    isEdit,
    isDuplicateAudience,
  } = props;

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const TextFieldInputRef = useRef<HTMLInputElement | null>(null);

  const intl = useIntl();
  const getInputRef = useCallback((ref: HTMLInputElement) => {
    TextFieldInputRef.current = ref;
  }, []);

  const getCloudFile = async (file: ICloudFile) => {
    const response = await API.Cloud.downloadFileAsync(file);
    if (response) {
      setUploadedFile(new File([response], file.name, { type: file.type }));
    }
  };

  useEffect(() => {
    if (TextFieldInputRef.current && !isEdit) {
      TextFieldInputRef.current.focus();
    }
  }, [TextFieldInputRef, isEdit]);

  useEffect(() => {
    if (!isColumnsMasterlistAvailable) {
      getColumnsList();
    }
  }, [getColumnsList, isColumnsMasterlistAvailable]);

  useEffect(() => {
    if (!columnsMapping.length && !isEdit) {
      saveColumnsMapping(
        Array(3)
          .fill({
            columnLabel: '',
            columnKey: '',
          })
          .map((item) => ({ ...item, uniqueId: uniqueId() })),
      );
    }
  }, [columnsMapping.length, columnsMasterlist, isEdit, saveColumnsMapping]);

  useEffect(() => {
    if (uploadedFile) {
      saveColumnsMapping(
        Array(3)
          .fill({
            columnLabel: '',
            columnKey: '',
          })
          .map((item) => ({ ...item, uniqueId: uniqueId() })),
      );
    }
  }, [uploadedFile, saveColumnsMapping]);

  const onAudienceUploaded = useCallback(
    async (fileList: FileList) => {
      if (fileList) {
        const file = fileList[0];
        if (fileList[0].size / (1024 * 1024 * 1024) > 1) {
          openSnackbar({
            message: 'File size should not exceed 1GB',
            type: 'error',
          });
          RUMLogger.uploadMatchedAudienceFile({
            success: false,
            file: fileList[0],
            errorMsg: 'File size exceeds 1GB',
          });
          return;
        }
        if ([...EXCEL_FORMATS, CSV_FORMAT].includes(file.type)) {
          setUploadedFile(fileList[0]);

          // eslint-disable-next-line
          window.Intercom?.('trackEvent', 'matched-audience-file-uploaded');
        } else {
          RUMLogger.uploadMatchedAudienceFile({
            success: false,
            file: fileList[0],
            errorMsg: 'Invalid file type',
          });
          openSnackbar({
            message: intl.formatMessage({ id: 'matched-audience.file-upload-error' }),
            type: 'error',
          });
        }
      } else {
        RUMLogger.uploadMatchedAudienceFile({
          success: false,
          file: fileList[0],
          errorMsg: 'No file list',
        });
      }
    },
    [openSnackbar, intl],
  );

  const removeFile = () => {
    setUploadedFile(null);
  };

  const isMatchedAudienceSetupVisible = useMemo(
    () => (uploadedFile || editableAudience) && isColumnsMasterlistAvailable,
    [uploadedFile, editableAudience, isColumnsMasterlistAvailable],
  );

  const checkNameValidity = (name: string) => {
    return name.trim().length < 255;
  };

  if (isEdit && !editableAudience) {
    return <>{DefaultFallback}</>;
  }

  const handleChange = (name: string) => {
    setAudienceName(name.replace(/[ ]{2,}/gm, ' '));
  };

  const browseText = (
    <div>
      Drag & drop your file here or <span className={styles.browseText}>Browse</span>
    </div>
  );

  return (
    <div>
      {!uploadedFile && !editableAudience && !isDuplicateAudience && (
        <>
          <div className="row">
            <div className="col-6">
              <TextField
                inputRef={getInputRef}
                variant="withoutTickbox"
                placeholder="Enter audience name"
                label="Audience Name"
                value={audienceName}
                onChange={handleChange}
                validationRules={[
                  {
                    func: checkNameValidity,
                    error: () => '* Maximum 255 characters',
                  },
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className={styles.fileUploaderWrapper}>
                <br />
                <CloudProviderBar
                  handleCloudFileUpload={getCloudFile}
                  setUnsupportedFiles={(files) => setUploadedFile(files[0])}
                  unsupportedFiles={[]}
                  acceptFormats=".csv, .xlsx"
                />
                <FileUploader
                  accept=".csv, .xlsx"
                  className={styles.fileUploader}
                  onFileUploaded={onAudienceUploaded}
                  browseFileButtonTitle={browseText}
                  isMultiple={false}
                  isSampleAtBottom
                  exampleDataURL={`${ORIGIN_URL}/example-files/matched-audience-example.csv`}
                  notice={
                    <div className="p2-n2">
                      <div>Allow only to Upload XLSX/CSV file</div>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
      {isMatchedAudienceSetupVisible && (
        <MatchedAudienceSetup
          uploadedFile={uploadedFile}
          removeFile={removeFile}
          isEdit={isEdit}
          isDuplicateAudience={isDuplicateAudience}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

const mapState = (state: AppState) => ({
  /* .mandatoryColumns */
  isColumnsMasterlistAvailable: state.matchedAudience.columnsMasterlist.length > 0,
  audienceName: state.matchedAudience.audienceName,
  columnsMasterlist: state.matchedAudience.columnsMasterlist,
  columnsMapping: state.matchedAudience.columnsMapping,
  editableAudience: state.matchedAudience.editableAudience,
});

const mapActions = {
  getColumnsList: matchedAudienceActions.getColumnsList,
  setAudienceName: matchedAudienceActions.setAudienceName,
  saveColumnsMapping: matchedAudienceActions.saveColumnsMapping,
  openSnackbar: snackbarActions.openSnackbar,
};

export const MatchedAudienceCreation = connect(
  mapState,
  mapActions,
)(MatchedAudienceCreationComponent);
