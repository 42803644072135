import { AxiosError, AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import get from 'lodash/get';

import { Response, WithResponse } from 'models/Response';
import { InsertionOrderWithCampaigns } from 'models/InsertionOrder';

import { getInstance } from './Instance';

export interface IAddAudienceToCampaign {
  [key: string]: {
    includedAudienceList: number[] | [];
    excludedAudienceList: number[] | [];
    removedAudienceList: number[] | [];
  };
}

export const addAudienceToCampaign = async (
  data: IAddAudienceToCampaign,
  source: CancelTokenSource,
): Promise<WithResponse<{ message: string }>> => {
  try {
    const response: AxiosResponse<WithResponse<{
      message: string;
    }>> = await getInstance().post(`/v3/cmp/target/audience`, data, {
      cancelToken: source?.token,
    });
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

interface GetCampaignDetails {
  /** Format: int32 */
  statusCode: number;
  /**
   * ResponseObject29
   * @example {
   *   "owId": 100288,
   *   "parentOrganizationName": "Local Iqm Dev",
   *   "id": 227869,
   *   "uowId": 4767,
   *   "campaignName": "Create_draft_test_stage",
   *   "advertiserDomain": "https://iqm.com",
   *   "creativeType": 11,
   *   "campaignType": 1,
   *   "totalBudgetPacing": false,
   *   "isTvAd": false,
   *   "budgetDay": 120,
   *   "budgetTotal": 1200,
   *   "maxBid": 12,
   *   "timezone": 11,
   *   "startTime": 1640543431,
   *   "endTime": 1640629831,
   *   "status": "draft",
   *   "dspMargin": 0,
   *   "platformMargin": 0,
   *   "userDealMargin": 0,
   *   "spentScale": false,
   *   "creativeIds": "394572",
   *   "conversionType": "None",
   *   "bidOptimization": true,
   *   "bidPacing": true,
   *   "impressionCapping": 0,
   *   "deviceType": "13,15,11,12",
   *   "trafficType": "11,14,13,12",
   *   "exchanges": "27,21,26,28,29,42,22,17,23,41,30,15,40,39,47,18,1,38,36,31,13,14,19,65,44,45,16,25,12,11,32,37,24,20,52,60,61,62,63,64,51,53,54,55,56,57,58,59,48,50,33,46,34,49,66,43,35",
   *   "countryId": "246",
   *   "locationDetails": {},
   *   "blackListedCustomAudienceIds": "888981",
   *   "whiteListedCustomAudienceIds": "889185",
   *   "isCampaignFromNewPlatform": true,
   *   "whiteListedAudienceSegmentGroupIds": "900269",
   *   "blackListedAudienceSegmentGroupIds": "900254",
   *   "blackListedConversionTagIds": "901341",
   *   "whiteListedConversionTagIds": "901342",
   *   "blackListedAudienceIds": "888981,900254,901341,901537",
   *   "whiteListedAudienceIds": "889185,900269,901342,901538",
   *   "organizationName": "Company 288",
   *   "userName": "Display Name 288",
   *   "isEditAccess": false,
   *   "isApprovalAccess": true,
   *   "isMarginSet": true,
   *   "isParentInvoiceTemplateSet": true,
   *   "isUnapprovedAudienceTargeted": true,
   *   "isAllAudienceUnapproved": false
   * }
   */
  responseObject: {
    /** Format: int32 */
    owId: number;
    parentOrganizationName: string;
    /** Format: int32 */
    id: number;
    /** Format: int32 */
    uowId: number;
    campaignName: string;
    advertiserDomain: string;
    /** Format: int32 */
    creativeType: number;
    /** Format: int32 */
    campaignType: number;
    totalBudgetPacing: boolean;
    isTvAd: boolean;
    /** Format: int32 */
    budgetDay: number;
    /** Format: int32 */
    budgetTotal: number;
    /** Format: int32 */
    maxBid: number;
    /** Format: int32 */
    timezone: number;
    /** Format: int32 */
    startTime: number;
    /** Format: int32 */
    endTime: number;
    status: string;
    /** Format: int32 */
    dspMargin: number;
    /** Format: int32 */
    platformMargin: number;
    /** Format: int32 */
    userDealMargin: number;
    spentScale: boolean;
    creativeIds: string;
    conversionType: string;
    bidOptimization: boolean;
    bidPacing: boolean;
    /** Format: int32 */
    impressionCapping: number;
    deviceType: string;
    trafficType: string;
    exchanges: string;
    countryId: string;
    locationDetails: Record<string, never>;
    blackListedCustomAudienceIds: string;
    whiteListedCustomAudienceIds: string;
    isCampaignFromNewPlatform: boolean;
    whiteListedAudienceSegmentGroupIds: string;
    blackListedAudienceSegmentGroupIds: string;
    blackListedConversionTagIds: string;
    whiteListedConversionTagIds: string;
    blackListedAudienceIds: string;
    whiteListedAudienceIds: string;
    organizationName: string;
    userName: string;
    isEditAccess: boolean;
    isApprovalAccess: boolean;
    isMarginSet: boolean;
    isParentInvoiceTemplateSet: boolean;
    isUnapprovedAudienceTargeted: boolean;
    isAllAudienceUnapproved: boolean;
  };
}

export const getCampaign = async (id: number, cancelToken?: CancelToken) => {
  const res = await getInstance().get(`/v2/cmp/campaign/${id}`, {
    cancelToken,
  });
  return (res.data as GetCampaignDetails).responseObject;
};

export interface ICampaign {
  clicks: number;
  conversionCount: number;
  id: number;
  impression: number;
  name: string;
  status: string;
  uniqueCount?: number;
  advertiserId?: number;
  dspId?: number;
  owId?: number;
  uowId?: number;
  creativeType?: string;
  length: void;
}

export interface ICampaignGroups {
  id: number;
  name: string;
  rootParentId: string;
  campaignCount: number;
  campaigns: ICampaign[];
}

export type CampaignsBasicInfo = {
  campaignId: number;
  name: string;
  creativeTypeId: 11 | 13 | 14 | 16 | 17;
  status: string;
  startTime: number;
  endTime: number;
  createdAt: number;
  modifiedAt: number;
  owId: number;
  organizationName: string;
  organizationLogo: string;
  ioId: number;
  ioName: string;
  ioStatusId: number;
  ioBudgetTypeId: number;
};

export const getCampaignsList = async (
  status: string = 'running,paused,expired,draft,pending',
): Promise<Response<ICampaignGroups[]>> => {
  try {
    const response: AxiosResponse<Response<ICampaignGroups[]>> = await getInstance().get(
      `v2/cmp/campaigngroup/campaign/list?creative_type_ids=&owIds=&status=${status}`,
    );
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const getCampaignInfo = async (data: {
  id: string;
}): Promise<WithResponse<{ data: ICampaign[] }>> => {
  try {
    const response: AxiosResponse<WithResponse<{ data: ICampaign[] }>> = await getInstance().get(
      `v3/cmp/campaigns/report/data?status=&sortBy=-id&limit=50&pageNo=-1&searchField=&campaignIds=${data.id}`,
    );
    return response.data;
  } catch (e) {
    return Promise.reject(get(e, 'response.data', e));
  }
};

export const getCampaignsBasicInfo = async (
  campaignIds: number[],
  params: { sortBy?: string; order: 'asc' | 'desc' },
  campaignTypeIds?: number[],
): Promise<WithResponse<CampaignsBasicInfo[]>> => {
  try {
    const response: AxiosResponse<Response<CampaignsBasicInfo[]>> = await getInstance().post(
      'v3/cmp/basic/list',
      {
        campaignIds,
        campaignTypeIds,
        pageNo: -1,
        ...params,
      },
    );
    // @ts-ignore
    return get(response, 'data.data', {});
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

interface GetCampaignsByInsertionOrderParams {
  sortBy?: string;
  searchField?: string;
  pageNo?: number;
  noOfEntries?: number;
  status?: string[];
  includeTargetedAudienceList?: boolean;
  campaignTypeIds?: number[];
}

interface GetCampaignsByInsertionOrderResponse {
  ioCampaignsList: InsertionOrderWithCampaigns[];
  totalRecords: number;
  filteredRecords: number;
}

export const getCampaignsByInsertionOrder = async (params: GetCampaignsByInsertionOrderParams) => {
  try {
    const res: AxiosResponse<WithResponse<
      GetCampaignsByInsertionOrderResponse
    >> = await getInstance().post('v3/cmp/io/campaign/basic/list', params);

    if (res.data.success) {
      return res.data.data?.ioCampaignsList || [];
    }
    throw res;
  } catch (err) {
    return Promise.reject((err as AxiosError)?.response || err);
  }
};
