import React, { useEffect, useRef, useCallback } from 'react';
import { Moment } from 'moment';
import { Select, TextField, Tabs } from 'factor';

import { Option } from 'models/Option';
import { DatePicker } from '../DatePicker';

import styles from './styles.module.scss';

interface Props {
  audienceName: string;
  dataCollectionPeriod: Option<number> | null;
  endDate: Date | null;
  handleChangeDataCollectionPeriod: (value: Option<number>) => void;
  handleTabs: ({ value }: { value: string }) => void;
  handleEndDate: (date: Moment) => void;
  handleStartDate: (date: Moment) => void;
  handleAudienceName: React.Dispatch<React.SetStateAction<string>>;
  setIsAudienceEdited: React.Dispatch<React.SetStateAction<boolean>>;
  startDate: Date;
  tabValue: string;
  tabs: {
    title: string;
    value: string;
  }[];
  dataCollectionOptions: Option<number>[];
  isDateRange: boolean;
  isDuplicateAudience: boolean;
  setReasonValidity: (parameter: boolean | (() => boolean)) => void;
  setEndDate: (date: Date | null) => void;
  setStartDate: (date: Date) => void;
  isEdit: boolean;
}

const RetargetedAudienceCreation = (props: Props) => {
  const {
    audienceName,
    dataCollectionPeriod,
    endDate,
    handleChangeDataCollectionPeriod,
    handleTabs,
    handleEndDate,
    handleStartDate,
    handleAudienceName,
    startDate,
    tabValue,
    tabs,
    dataCollectionOptions,
    isDateRange,
    isDuplicateAudience,
    setReasonValidity,
    setIsAudienceEdited,
    setEndDate,
    setStartDate,
    isEdit,
  } = props;

  const audienceNameUpdated = useRef<boolean>(false);
  const TextFieldInputRef = useRef<HTMLInputElement | null>(null);

  const getInputRef = useCallback((ref: HTMLInputElement) => {
    TextFieldInputRef.current = ref;
  }, []);

  useEffect(() => {
    if (TextFieldInputRef.current) {
      TextFieldInputRef.current.focus();
      if (isEdit) {
        TextFieldInputRef.current.blur();
      }
    }
  }, [isEdit, TextFieldInputRef]);

  useEffect(() => {
    if (isDuplicateAudience && !audienceNameUpdated.current && audienceName) {
      handleAudienceName(`Copy of ${audienceName}`);
      audienceNameUpdated.current = true;
    }
  });

  const checkReasonValidity = (reason: string) => reason.trim().length < 255;

  const checkEmptystring = (string: string) => string.trim().length;

  const handleTextChange = (change: string) => {
    handleAudienceName(change.replace(/[ ]{2,}/gm, ' '));
    setReasonValidity(!checkReasonValidity(change));
    if (!checkEmptystring(change)) {
      setReasonValidity(true);
    }
    setIsAudienceEdited(true);
  };

  const ResetDateSelection = () => {
    handleChangeDataCollectionPeriod(dataCollectionOptions[0]);
    setEndDate(null);
    setStartDate(new Date());
  };

  const tabChangeHandler = (change: { value: string }): void => {
    handleTabs(change);
    setIsAudienceEdited(true);

    ResetDateSelection();
  };

  const startDateChangeHandler = (change: Moment): void => {
    handleStartDate(change);
    setIsAudienceEdited(true);
  };

  const endDateChangeHandler = (change: Moment): void => {
    handleEndDate(change);
    setIsAudienceEdited(true);
  };

  const selectChangeHandler = (change: Option<number>): void => {
    handleChangeDataCollectionPeriod(change);
    setIsAudienceEdited(true);
  };

  return (
    <div className={styles.container}>
      <>
        <div className="row mb-5">
          <div className="col-6">
            <TextField
              inputRef={getInputRef}
              variant="withoutTickbox"
              label="Audience Name"
              value={audienceName}
              onChange={handleTextChange}
              placeholder="Enter Audience Name"
              validationRules={[
                {
                  func: checkReasonValidity,
                  error: () => '* Maximum 255 characters',
                },
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <span className="p2-n2 d-block mb-3">Data collection by</span>
            <Tabs
              className={styles.tabs}
              items={tabs}
              variant="capsule"
              value={tabValue}
              onChange={tabChangeHandler}
            />
          </div>
          <div className="col-6">
            {isDateRange ? (
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                onStartDateChange={startDateChangeHandler}
                onEndDateChange={endDateChangeHandler}
              />
            ) : (
              <div>
                <Select
                  options={dataCollectionOptions}
                  label="Data collection period"
                  placeholder="Select period"
                  value={dataCollectionPeriod || dataCollectionOptions[0]}
                  onChange={selectChangeHandler}
                  showLabelAlways
                  underline
                  fitContainer
                  isNaked
                />
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default RetargetedAudienceCreation;
