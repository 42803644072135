import * as React from 'react';
import { EmailAudienceInsightsDialog } from '@applift/insight-app';
import { IEmailObject } from '@applift/insight-app/lib/models/AudienceInsight';
import { Dialog, enqueueSnackbar, Typography } from '@applift/factor';
import { useSendEmail } from 'hooks/useInsights';
import { TableAudienceItem } from 'models/Table';

interface EmailInsightProps {
  closeDialog: () => void;
  audienceData: TableAudienceItem;
  fileType: string;
}

export const EmailInsight = (props: EmailInsightProps) => {
  const { closeDialog, audienceData, fileType } = props;
  const [selectedImage, setSelectedImage] = React.useState<File>();
  const [recipientEmails, setRecipientEmails] = React.useState<IEmailObject[]>([]);
  const [selectedLogoMethod, setSelectedLogoMethod] = React.useState<
    'default' | 'custom' | undefined
  >(undefined);

  const isEmailValid = React.useMemo(() => recipientEmails.every((one) => one.error === false), [
    recipientEmails,
  ]);

  const actionBtnDisabled = React.useMemo(() => {
    if (fileType === 'xlsx') {
      return recipientEmails.length > 0 ? !isEmailValid : true;
    }
    if (fileType === 'pdf') {
      return (
        !selectedLogoMethod ||
        (selectedLogoMethod === 'custom' && !selectedImage) ||
        (recipientEmails.length > 0 ? !isEmailValid : true)
      );
    }
    return true;
  }, [recipientEmails, isEmailValid, selectedImage, selectedLogoMethod, fileType]);

  const onSuccessSendEmail = React.useCallback(() => {
    enqueueSnackbar(
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="span" sx={{ textWeight: 'demi' }}>
          {`${audienceData.audienceName}_insights ${fileType === 'pdf' ? 'PDF' : 'XLSX'}`}
        </Typography>
        &nbsp;
        {'emailed successfully.'}
      </Typography>,
      {
        variant: 'success',
      },
    );
    closeDialog();
  }, [closeDialog, audienceData, fileType]);

  const onErrorSendEmail = React.useCallback(() => {
    enqueueSnackbar(
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        Something went wrong. Please try after some time.
      </Typography>,
      {
        variant: 'error',
      },
    );
    closeDialog();
  }, [closeDialog]);

  const getSendEmailMutation = useSendEmail(onSuccessSendEmail, onErrorSendEmail);

  const onSendEmail = React.useCallback(() => {
    const emailPayloadForm = new FormData();
    emailPayloadForm.append('id', `${audienceData.id}`);
    emailPayloadForm.append('attachmentType', fileType);
    emailPayloadForm.append(
      'recipientEmails',
      recipientEmails.map((email) => email.label).join(','),
    );
    emailPayloadForm.append('audienceId', audienceData.id.toString());
    if (selectedLogoMethod === 'custom') {
      // @ts-ignore
      emailPayloadForm.append('file', selectedImage);
    }
    getSendEmailMutation.mutate(emailPayloadForm);
  }, [
    getSendEmailMutation,
    selectedImage,
    selectedLogoMethod,
    recipientEmails,
    fileType,
    audienceData,
  ]);

  return (
    <Dialog open maxWidth="md" fullWidth>
      <EmailAudienceInsightsDialog
        onClose={closeDialog}
        // @ts-ignore
        data={{ name: audienceData.audienceName, fileType }}
        actionBtnDisabled={actionBtnDisabled}
        action={onSendEmail}
        isActionLoading={getSendEmailMutation.isLoading}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        selectedLogoMethod={selectedLogoMethod}
        setSelectedLogoMethod={setSelectedLogoMethod}
        receptianEmails={recipientEmails}
        setReceptiantEmails={setRecipientEmails}
      />
    </Dialog>
  );
};
