import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { Dialog } from 'factor';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { applicationActions, ResetSidebar } from 'store/app/actions';
import {
  CreateRetargetedAudience as ICreateRetargetedAudience,
  retargetedAudienceActions,
  UpdateRetargetedAudience,
  UpdateTagScript,
} from 'store/retargetedAudience/actions';
import { tableActions, UpdateSorting } from 'store/table/actions';
import { AppState } from 'store';
import { Option } from 'models/Option';
import { pluralize } from 'utils/pluralize';
import { EditRetargetedAudienceRouteState } from 'models/RetargetedAudience';
import { TDialog } from 'store/app/reducer';
import StepIndicator from 'components/StepIndicator';
import { SORTING_VALUE, SORTING_DIRECTION } from 'constants/audiences';
import { RetargetedAudienceTagIntegration } from '../RetargetedAudienceTagIntegration';
import RetargetedAudienceCreation from '../RetargetedAudienceCreation';
import { ONE_DAY_SECONDS } from '../DatePicker';
import { SaveConfirmationDialog } from '../SaveConfirmationDialog/index';

import styles from './styles.module.scss';

interface Props
  extends ResetSidebar,
    ICreateRetargetedAudience,
    UpdateRetargetedAudience,
    UpdateSorting,
    UpdateTagScript {
  handleClose: () => void;
  dialog: TDialog;
  submitInProgress: boolean;
  title: string;
  tagScript: string;
}

const PERIOD_SWITCH = [
  { title: 'Months', value: 'Months' },
  { title: 'Dates', value: 'Dates' },
];

const dataCollectionOptions: Option<number>[] = [...Array(13).keys()]
  .slice(1)
  .map((num: number) => ({
    value: num,
    label: `${num} ${pluralize('Month', num)}`,
  }));

const RetargetedAudienceDialogComponent = (props: Props) => {
  const {
    handleClose,
    updateRetargetedAudience,
    updateTagScript,
    createRetargetedAudience,
    resetSidebar,
    updateSorting,
    submitInProgress,
    dialog,
    title,
    tagScript,
  } = props;
  const [dataCollectionPeriod, setDataCollectionPeriod] = useState<Option<number> | null>(null);
  const [audienceName, setAudienceName] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [tagId, setTagId] = useState<string>('');
  const existingAudienceName = useRef<string | null>(null);
  const existingDateType = useRef<string | null>(null);
  const existingDataCollectionPeriod = useRef<Option<number> | null>(null);
  const existingDatesRange = useRef<(number | null)[] | null>(null);
  const [dataCollectionType, setDataCollectionType] = useState<'month' | 'date_range'>('month');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isAudienceEdited, setIsAudienceEdited] = useState<boolean>(false);
  const isDateRange = useCallback(() => dataCollectionType === 'date_range', [dataCollectionType]);
  const history = useHistory();
  const [step, setStep] = useState<1 | 2>(1);
  const [reasonValidity, setReasonValidity] = useState<boolean>(false);
  const [checkDateNull, setCheckDateNull] = useState<boolean>(false);
  const [saveconfirm, setSaveConfirm] = useState<boolean>(false);
  const [editName, setEditName] = useState<string>('');
  const [selectedAudienceStatus, setSelectedAudienceStatus] = useState<string | undefined>('');

  const { type, audienceId } = dialog;
  const isDuplicateAudience = type === 'duplicate';

  useEffect(() => {
    if (audienceId && history.location.state) {
      if (!isDuplicateAudience) {
        setIsEdit(true);
      }
      const { audienceData } = history.location.state as EditRetargetedAudienceRouteState;
      existingDateType.current = audienceData.tagDuration.toLowerCase();
      switch (audienceData.tagDuration.toLowerCase()) {
        case 'month':
          /* eslint-disable-next-line no-case-declarations */
          const dataCollection = {
            label: `${audienceData.time} ${pluralize('Month', audienceData.time)}`,
            value: audienceData.time,
          };
          setDataCollectionPeriod(dataCollection);
          existingDataCollectionPeriod.current = dataCollection;
          break;
        case 'date_range':
          setDataCollectionType('date_range');
          setStartDate(new Date(audienceData.startDate));
          if (audienceData.endDate) {
            setEndDate(new Date(audienceData.endDate));
          }
          existingDatesRange.current = [audienceData.startDate, audienceData.endDate || null];
          break;
        default:
      }
      setAudienceName(audienceData.audienceName);
      setEditName(audienceData.audienceName);
      setTagId(audienceData.tagId);
      setSelectedAudienceStatus(audienceData.status);

      existingAudienceName.current = audienceData.audienceName;
    }
  }, [audienceId, history.location.state, isDuplicateAudience]);

  useEffect(() => {
    if (history.location.state) {
      const { showTags } = history.location.state as EditRetargetedAudienceRouteState;
      if (showTags && tagId) {
        updateTagScript(`<script src='https://pxl.iqm.com/c/${tagId}' async></script>`);
      }
    }
  }, [history.location.state, tagId, updateTagScript]);

  const handleChangeDataCollectionPeriod = (value: Option<number>) => {
    setDataCollectionPeriod(value);
  };

  const handleDataCollectionBy = ({ value }: { value: string }) => {
    setDataCollectionType(value === PERIOD_SWITCH[1].value ? 'date_range' : 'month');
  };

  const isDataNotModified = useCallback(() => {
    if (
      existingAudienceName.current !== audienceName ||
      existingDateType.current !== dataCollectionType
    ) {
      return false;
    }
    switch (existingDateType.current) {
      case 'month':
        return existingDataCollectionPeriod.current?.value === dataCollectionPeriod?.value;
      case 'date_range':
        return (
          existingDatesRange.current &&
          existingDatesRange.current[0] === startDate.getTime() &&
          ((existingDatesRange.current[1] === null && endDate === null) ||
            existingDatesRange.current[1] === endDate?.getTime())
        );
      default:
        return true;
    }
  }, [audienceName, dataCollectionType, dataCollectionPeriod, startDate, endDate]);

  const saveAudience = useCallback(() => {
    if (dataCollectionPeriod || isDateRange()) {
      /* eslint-disable-next-line camelcase */
      const tag_duration = dataCollectionType;
      const requestObj = {
        tag_duration,
        tag_name: audienceName,
        ...(isDateRange() ? {} : { tag_time: dataCollectionPeriod?.value }),
        ...(isDateRange()
          ? {
              start_date: startDate.getTime(),
              end_date: endDate ? endDate.getTime() : 0,
            }
          : {}),
      };

      if (isEdit && audienceId) {
        if (isDataNotModified()) {
          updateTagScript(`<script src='https://pxl.iqm.com/c/${tagId}' async></script>`);
        } else {
          updateRetargetedAudience(requestObj, audienceId);
        }
      } else {
        createRetargetedAudience(requestObj);
      }
      updateSorting({
        field: SORTING_VALUE.CREATED_ON,
        direction: SORTING_DIRECTION.DESC,
      });
      setStep(2);
    }
  }, [
    audienceId,
    audienceName,
    createRetargetedAudience,
    dataCollectionPeriod,
    dataCollectionType,
    endDate,
    isDataNotModified,
    isDateRange,
    isEdit,
    startDate,
    tagId,
    updateRetargetedAudience,
    updateTagScript,
    updateSorting,
  ]);

  const handleSubmit = useCallback(() => {
    if (isEdit && !isAudienceEdited) {
      handleClose();
      history.goBack();
      return;
    }
    if (isEdit && isAudienceEdited && selectedAudienceStatus === 'Ready') {
      setSaveConfirm(true);
      return;
    }
    saveAudience();
  }, [handleClose, saveAudience, isAudienceEdited, isEdit, history, selectedAudienceStatus]);

  const isSubmitDisabled = useMemo(() => {
    if (isEdit) {
      return (dataCollectionType === 'month' && !dataCollectionPeriod) || !isAudienceEdited;
    }
    if (dataCollectionType === 'month' && audienceName && !dataCollectionPeriod) {
      setDataCollectionPeriod(dataCollectionOptions[0]);
      return false;
    }

    return !audienceName.trim() || !(isDateRange() || dataCollectionPeriod) || submitInProgress;
  }, [
    isEdit,
    audienceName,
    isDateRange,
    dataCollectionPeriod,
    submitInProgress,
    dataCollectionType,
    isAudienceEdited,
  ]);

  const resetAndClose = useCallback(() => {
    handleClose();
    resetSidebar();
    if (isEdit || isDuplicateAudience) {
      history.goBack();
    }
  }, [handleClose, history, isEdit, resetSidebar, isDuplicateAudience]);

  const actionButtons = useMemo(
    () =>
      step === 1
        ? [
            {
              variant: 'secondary',
              title: 'Cancel',
              handler: resetAndClose,
            },
            {
              title: isEdit && !isDuplicateAudience ? 'Save' : 'Create',
              handler: handleSubmit,
              disabled: isSubmitDisabled || reasonValidity || checkDateNull,
              ...(isEdit && !isDuplicateAudience
                ? {}
                : { 'data-dd-action-name': 'Create Retargeted Audience' }),
            },
          ]
        : [
            {
              title: 'Done',
              handler: resetAndClose,
              disabled: isSubmitDisabled,
              ...{ 'data-dd-action-name': 'Done [Retargeted Audience]' },
            },
          ],
    [
      resetAndClose,
      handleSubmit,
      isSubmitDisabled,
      isEdit,
      step,
      isDuplicateAudience,
      reasonValidity,
      checkDateNull,
    ],
  );

  const onEndDateChange = (date: Moment) => {
    setEndDate(new Date(date.unix() * 1000));
  };

  const onStartDateChange = (date: Moment) => {
    setStartDate(new Date(date.unix() * 1000));

    if (endDate !== null && endDate.getTime() - date.unix() * 1000 <= ONE_DAY_SECONDS) {
      onEndDateChange(date.clone().add(1, 'day'));
    }
  };

  const getTitle = useMemo(() => {
    return step === 1 ? title : 'Integrate Tag';
  }, [step, title]);

  const closeSaveConfirmation = (): void => {
    setSaveConfirm(false);
  };

  useEffect(() => {
    if (isDateRange() && endDate === null) {
      setCheckDateNull(true);
    } else {
      setCheckDateNull(false);
    }
  }, [endDate, setCheckDateNull, isDateRange]);

  return (
    <>
      <Dialog
        dialogTitle={getTitle}
        open={!isEmpty(dialog)}
        crossButton
        headerFooterBorders
        className={`${styles.container} ${saveconfirm && 'invisible'}`}
        onCrossButtonClick={resetAndClose}
        actionButtons={actionButtons}
        footerLeft={<StepIndicator step={step} maxStep={2} />}
      >
        {step === 1 ? (
          <RetargetedAudienceCreation
            audienceName={audienceName}
            dataCollectionPeriod={dataCollectionPeriod}
            endDate={endDate}
            setIsAudienceEdited={setIsAudienceEdited}
            handleChangeDataCollectionPeriod={handleChangeDataCollectionPeriod}
            handleTabs={handleDataCollectionBy}
            handleEndDate={onEndDateChange}
            handleStartDate={onStartDateChange}
            handleAudienceName={setAudienceName}
            startDate={startDate}
            tabValue={PERIOD_SWITCH[isDateRange() ? 1 : 0].value}
            tabs={PERIOD_SWITCH}
            dataCollectionOptions={dataCollectionOptions}
            isDateRange={isDateRange()}
            isDuplicateAudience={isDuplicateAudience}
            setReasonValidity={setReasonValidity}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            isEdit={isEdit}
          />
        ) : (
          <RetargetedAudienceTagIntegration tagScript={tagScript} />
        )}
      </Dialog>
      <SaveConfirmationDialog
        open={saveconfirm}
        handleSubmit={saveAudience}
        handleClose={closeSaveConfirmation}
        editName={editName}
      />
    </>
  );
};

const mapState = (state: AppState) => ({
  submitInProgress: state.retargetedAudience.submitInProgress,
  tagScript: state.retargetedAudience.tagScript,
});

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  createRetargetedAudience: retargetedAudienceActions.createRetargetedAudience,
  updateRetargetedAudience: retargetedAudienceActions.updateRetargetedAudience,
  updateTagScript: retargetedAudienceActions.updateTagScript,
  updateSorting: tableActions.updateSorting,
};

const RetargetedAudienceDialog = connect(mapState, mapAction)(RetargetedAudienceDialogComponent);

export default RetargetedAudienceDialog;
