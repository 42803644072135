import { CreateGeofarmedAudienceData, GeoframedAudienceFormData } from 'models/GeofarmedAudience';

export const transformGeofarmedAudienceCreateData = (
  data: GeoframedAudienceFormData,
): CreateGeofarmedAudienceData => {
  return {
    audienceName: data.audienceName?.trim(),
    frequency: !Number.isNaN(Number.parseInt(data.frequency, 10))
      ? Number.parseInt(data.frequency, 10)
      : 0,
    frequencyType: data.frequencyType.value,
    startDate: Math.floor(data.startDate.valueOf() / 1000),
    endDate: Math.floor((data.endDate?.valueOf() ?? 0) / 1000),
    timezone: data.timezone.id,
    timeRange: data.timeRange,
    removedLocationIds: data.locations.removedLocationIds,
    locations: {
      country: data.locations.countryId,
      included: data.locations.included,
      excluded: data.locations.excluded,
      locationFileIds: data.locations.locationFileIds,
    },
  };
};
