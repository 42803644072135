import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { AudienceTypeId } from 'models/Table';
import { AudienceType } from 'api/Table';
import { routes } from 'routes';
import { Route } from 'models/Route';
import styles from './styles.module.scss';
import { NavigationItem } from './NavigationItem';
import { AppState } from '../../store';
import {
  tableActions,
  SetSelectedAudiencesStatuses,
  SetSearchOpen,
  SetAudienceTypeIds,
} from '../../store/table/actions';

interface Props extends SetSearchOpen, SetSelectedAudiencesStatuses, SetAudienceTypeIds {
  audiencesByType: AudienceType[];
  audienceTypeIds: AudienceTypeId;
  tableTotalItems: number;
  loading: boolean;
  selectedAudienceStatus: { [key in AudienceTypeId]: string };
  setSearchOpen: (isOpen: boolean) => void;
}

const SidebarComponent = (props: Props) => {
  const {
    audiencesByType,
    audienceTypeIds,
    tableTotalItems,
    loading,
    setSelectedAudiencesStatuses,
    selectedAudienceStatus,
    setAudienceTypeIds,
    setSearchOpen,
  } = props;

  const history = useHistory();
  const initPathRef = useRef<boolean>(false);

  const initialRoute = history.location.pathname as Route['path'];

  const [selectedRoute, setSelectedRoute] = useState<Route['path']>(initialRoute);

  useEffect(() => {
    if (history.location.pathname !== selectedRoute) {
      history.push(selectedRoute);
    }
  }, [history, selectedRoute]);

  const resetTableParams = useCallback(
    (value: AudienceTypeId) => {
      if (audienceTypeIds !== value) {
        setSearchOpen(false);
        setSelectedAudiencesStatuses({
          ...selectedAudienceStatus,
          [audienceTypeIds]: '',
        });
      }
    },
    [audienceTypeIds, setSearchOpen, setSelectedAudiencesStatuses, selectedAudienceStatus],
  );

  const onClick = useCallback(
    ({ path, value }: Route) => () => {
      if (value !== undefined && audienceTypeIds !== value) {
        setSelectedRoute(path);
        resetTableParams(value);
        setAudienceTypeIds(value);
      }
    },
    [resetTableParams, setAudienceTypeIds, audienceTypeIds],
  );

  const audiences = useMemo(() => {
    const totalCount = audiencesByType.reduce((acc: number, cur: AudienceType) => {
      return acc + Number(cur.audienceCount);
    }, 0);
    return routes
      .map((route) => {
        let count: number | undefined = totalCount;
        if (route.value !== '') {
          count =
            audiencesByType.find((audience) => Number(route.value) === audience.audienceTypeId)
              ?.audienceCount || 0;
        }
        if (loading && audienceTypeIds === route.value) {
          count = tableTotalItems;
        }
        return { ...route, title: `${route.title} (${count})` };
      })
      .filter((audience) => typeof audience.value === 'string');
  }, [audiencesByType, loading, tableTotalItems, audienceTypeIds]);

  const getActiveSelection = (route: string) =>
    route === '/' ? selectedRoute === '/' : selectedRoute.includes(route);

  /* the fix to make sure when URL is reloaded from browser we set proper aduence type and dont show all audience */

  useEffect(() => {
    const path = window.location.hash.split('/')[1];
    if (path !== '/' && !initPathRef.current) {
      initPathRef.current = true;
      if (selectedRoute.includes(`/${path}`)) {
        const result: Route = audiences.filter((route) => {
          return route.path === `/${path}`;
        })[0];
        const { value } = result;
        if (value !== undefined && audienceTypeIds !== value) {
          setSelectedRoute((window.location.hash ?? '/').replace('#', '') as any);
          resetTableParams(value);
          setAudienceTypeIds(value);
        }
      }
    }
  }, [audienceTypeIds, audiences, resetTableParams, selectedRoute, setAudienceTypeIds]);

  return (
    <div className={styles.routesWrapper}>
      {audiences.map((route) => (
        <NavigationItem
          key={route.path}
          isActive={getActiveSelection(route.path)}
          title={route.title}
          onClick={onClick(route)}
          icon={route.icon || ''}
          description={route.description}
        />
      ))}
    </div>
  );
};

const mapState = (state: AppState) => ({
  audiencesByType: state.table.audiencesByType,
  audienceTypeIds: state.table.audienceTypeIds,
  tableTotalItems: state.table.tableTotalItems,
  loading: state.table.loading,
  selectedAudienceStatus: state.table.selectedAudienceStatus,
});

const mapActions = {
  setSearchOpen: tableActions.setSearchOpen,
  setSelectedAudiencesStatuses: tableActions.setSelectedAudiencesStatuses,
  setAudienceTypeIds: tableActions.setAudienceTypeIds,
};

export const Sidebar = connect(mapState, mapActions)(SidebarComponent);
