import React from 'react';

import styles from './styles.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Skeleton = ({ children, className }: Props) => {
  return <div className={`${styles.skeleton} ${className}`}>{children}</div>;
};

export default Skeleton;
