import { OptionData } from './Option';
import { TableSortingParams } from './Table';

export interface ColumnMapping {
  columnLabel: string;
  uniqueId: string;
  selectedOption?: OptionData<string[]>;
  columnKey: string;
  isVoterID?: boolean;
  hasError?: boolean;
  order?: number;
  selectedAdditionalSetting?: DataPartners & {
    label: string;
    value: string;
  };
}

export interface MatchedAudienceColumn {
  id: number;
  columnLabel: string;
  columnKey: string;
}

export interface Column {
  id: number;
  label: string;
  key: string;
  order: number;
  isSingleColumn: boolean;
}

export interface ColumnsToSend {
  [key: string]: string;
}

export const EXCEL_FORMATS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];
export const CSV_FORMAT = 'text/csv';

export interface DownloadFileAPIResponse {
  url: string;
}

export interface DataPartners {
  id: number;
  label: string;
  keyName: string;
  audienceColumnId: number;
}

export type DataFormats = Omit<DataPartners, 'audienceColumnId'>;

export interface MatchedAudienceMetadata {
  columns: string[];
  fileName: string;
  fileSize: number;
  columnValues: { [key: string]: string[] };
  rows: number;
}

export interface SubmitMatchedAudienceData {
  file: File;
  columns: ColumnsToSend;
  audienceName: string;
  metadata: MatchedAudienceMetadata;
  fileTotalCount: number;
  isSingleColumnEnabled?: boolean;
  columnSettings: ColumnsToSend;
}

export interface UpdateMatchedAudienceData {
  id: number;
  rawS3URL: string | null;
  columns: ColumnsToSend;
  audienceName: string;
}

export interface EditableMatchedAudience {
  audienceName: string;
  existingColumnMatching: ColumnsToSend;
  id: number;
  metadata: MatchedAudienceMetadata | null;
  rawS3URL: string | null;
  isSingleColumnEnabled: boolean;
  columnSettings?: ColumnsToSend;
  fileTotalCount: number;
}

export interface IMatchedAudience {
  id: number;
  audienceName: string;
  status: string;
  matchRate: number;
  dataCost: number;
  s3FileName: string;
  createdDate: number;
  metadata?: any;
  uniques?: number;
  fileTotalCount: number;
}

export type CreateOrDuplicateMatchedAudience = {
  isDuplicateAudience: boolean;
  audienceId?: number;
  audienceName?: string;
  details?: SubmitMatchedAudienceData;
  resetSetup: () => void;
  closeDialog: () => void;
  updateSorting: (newSorting: TableSortingParams) => void;
};

export interface MappedColumns {
  reactLabel: React.JSX.Element;
  value: string;
  isDisable: boolean;
  id: number;
  label: string;
  key: string;
  order: number;
  isSingleColumn: boolean;
}
