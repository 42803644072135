export const lookalikeAudienceConstants = {
  START_CREATING: 'START_CREATING_LOOKALIKE',
  SET_AUDIENCE_NAME: 'SET_LOOKALIKE_AUDIENCE_NAME',
  SET_SOURCE_AUDIENCE_INFO: 'SET_SOURCE_AUDIENCE_INFO',
  SET_AUDIENCE_INFO: 'SET_AUDIENCE_INFO',
  SET_GEO_POLITICAL_INFO: 'SET_GEO_POLITICAL_INFO',
  SET_GEO_POLITICAL_INFO_ERRORS: 'SET_GEO_POLITICAL_INFO_ERRORS',
  END_CREATING: 'END_CREATING_LOOKALIKE',
  RESET: 'RESET_LOOKALIKE',
};
