import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { Dialog, ProgressCircle } from 'factor';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { applicationActions, ResetSidebar } from 'store/app/actions';
import {
  CreateContextualAudience as ICreateContextualAudience,
  UpdateContextualAudience as IUpdateContextualAudience,
  contextualAudienceActions,
} from 'store/contextualAudience/actions';
import { SORTING_VALUE, SORTING_DIRECTION } from 'constants/audiences';
import { tableActions, UpdateSorting } from 'store/table/actions';
import { AppState } from 'store';
import { TDialog } from 'store/app/reducer';
import StepIndicator from 'components/StepIndicator';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { API } from 'api';
import { ContextualAudienceData } from 'models/ContextualAudience';
import ContextualAudienceInfo from '../ContextualAudienceInfo';
import ContextualAudienceKeywordsAndUrls from '../ContextualAudienceKeywordsAndUrls';

import styles from './styles.module.scss';

interface IUrlObj {
  url: string;
  validating: boolean;
  isValid: boolean;
}

interface Props
  extends ResetSidebar,
    OpenSnackbar,
    ICreateContextualAudience,
    UpdateSorting,
    IUpdateContextualAudience {
  handleClose: () => void;
  dialog: TDialog;
  submitInProgress: boolean;
  title: string;
  isEdit?: boolean;
}

const ContextualAudienceDialogComponent = (props: Props) => {
  const {
    handleClose,
    createContextualAudience,
    updateContextualAudience,
    resetSidebar,
    updateSorting,
    openSnackbar,
    submitInProgress,
    dialog,
    title,
    isEdit,
  } = props;

  const [step, setStep] = useState<1 | 2>(1);
  const [audience, setAudience] = useState<ContextualAudienceData | undefined>();

  const [addedUrls, setAddedUrls] = useState<string[]>([]);
  const [addedKeywords, setAddedKeywords] = useState<string[]>([]);
  const [addedRecommendedKeywords, setAddedRecommendedKeywords] = useState<string[]>([]);
  const [validatedUrlsObj, setValidatedUrlsObj] = useState<IUrlObj[]>([]);
  const alreadyValidatedUrls = useRef<string[]>([]);

  const [name, setName] = useState<string>('');
  const [frequency, setFrequency] = useState<string>('1');
  const [startDate, setStartDate] = useState<number>(
    moment
      .tz(moment().utc(), 'UTC')
      .tz(moment.tz.guess())
      .add(-29, 'day')
      .startOf('day')
      .unix() * 1000,
  );
  const [endDate, setEndDate] = useState<number>(
    moment
      .tz(moment().utc(), 'UTC')
      .tz(moment.tz.guess())
      .endOf('day')
      .unix() * 1000,
  );
  const [isNameValid, setIsNameValid] = useState<boolean>(true);
  const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

  const history = useHistory();

  useEffect(() => {
    if (dialog.audienceId && (isEdit || dialog.type === 'duplicate')) {
      API.ContextualAudience.getAudience(dialog.audienceId).then(({ data, success }) => {
        if (data && success) {
          setAudience(data);
          setAddedUrls((data?.urls?.length && data?.urls?.split(',')) || []);
          setAddedKeywords((data?.keywords?.length && data?.keywords.split(',')) || []);
          setName(dialog.type === 'duplicate' ? `Copy of ${data?.name}` : data?.name);
          setFrequency(data?.frequency.toString());
          setStartDate(data?.startDate);
          setEndDate(data?.endDate);
        }
      });
    }
  }, [dialog, isEdit]);

  const resetAndClose = useCallback(() => {
    handleClose();
    resetSidebar();
    if (isEdit) {
      history.goBack();
    }
  }, [handleClose, resetSidebar, history, isEdit]);

  const createAudience = useCallback(async () => {
    if (name && addedKeywords && addedUrls && startDate && endDate && frequency) {
      await createContextualAudience({
        audienceName: name.trim(),
        frequency: Number(frequency),
        startDate,
        endDate,
        // Regex which for removing non-ascii characters
        // eslint-disable-next-line
        keywords: addedKeywords.join(',').replace(/[^\x00-\xff]/g, ''),
        urls: addedUrls.join(','),
        addedRecommendedKeywords,
      });
      updateSorting({
        field: SORTING_VALUE.CREATED_ON,
        direction: SORTING_DIRECTION.DESC,
      });
      resetAndClose();
    }
  }, [
    addedKeywords,
    addedUrls,
    createContextualAudience,
    name,
    resetAndClose,
    updateSorting,
    addedRecommendedKeywords,
    startDate,
    endDate,
    frequency,
  ]);

  const isValidatingUrl = useMemo(() => {
    return validatedUrlsObj.some((obj) => obj.validating);
  }, [validatedUrlsObj]);

  const actionButtons = useMemo(
    () =>
      step === 1
        ? [
            {
              variant: 'secondary',
              title: 'Cancel',
              handler: resetAndClose,
            },
            {
              title: 'Next',
              handler: () => setStep(2),
              disabled: (!addedUrls.length && !addedKeywords.length) || isValidatingUrl,
            },
          ]
        : [
            {
              variant: 'secondary',
              title: 'Previous',
              handler: () => setStep(1),
            },
            isEdit
              ? {
                  title: 'Save',
                  handler: async () => {
                    if (audience) {
                      await updateContextualAudience({
                        audienceName: name.trim(),
                        audienceId: audience.id,
                        audienceTypeId: audience.type,
                      });
                      resetAndClose();
                    }
                  },
                  disabled:
                    submitInProgress ||
                    !name.trim() ||
                    !isNameValid ||
                    !audience ||
                    name === audience.name,
                }
              : {
                  title: 'Create Audience',
                  handler: createAudience,
                  disabled: submitInProgress || !name.trim() || !isNameValid || !isDateRangeValid,
                  ...{ 'data-dd-action-name': 'Create Contextual Audience' },
                },
          ],
    [
      step,
      resetAndClose,
      addedUrls,
      addedKeywords,
      isEdit,
      submitInProgress,
      name,
      isNameValid,
      audience,
      updateContextualAudience,
      createAudience,
      isValidatingUrl,
      isDateRangeValid,
    ],
  );

  return (
    <Dialog
      dialogTitle={title}
      open={!isEmpty(dialog)}
      crossButton
      headerFooterBorders
      className={styles.container}
      onCrossButtonClick={resetAndClose}
      actionButtons={actionButtons}
      footerLeft={<StepIndicator step={step} maxStep={2} />}
    >
      {(isEdit && audience) || !isEdit ? (
        <>
          {step === 1 && (
            <ContextualAudienceKeywordsAndUrls
              openSnackbar={openSnackbar}
              setAddedUrls={setAddedUrls}
              setAddedKeywords={setAddedKeywords}
              setAddedRecommendedKeywords={setAddedRecommendedKeywords}
              addedKeywords={addedKeywords}
              addedUrls={addedUrls}
              addedRecommendedKeywords={addedRecommendedKeywords}
              isEdit={isEdit}
              validatedUrlsObj={validatedUrlsObj}
              setValidatedUrlsObj={setValidatedUrlsObj}
              alreadyValidatedUrls={alreadyValidatedUrls}
            />
          )}
          {step === 2 && (
            <ContextualAudienceInfo
              addedKeywords={addedKeywords}
              addedUrls={addedUrls}
              isEdit={isEdit}
              name={name}
              isDuplicate={dialog.type === 'duplicate'}
              setName={setName}
              setIsNameValid={setIsNameValid}
              frequency={frequency}
              setFrequency={setFrequency}
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              endDate={endDate}
              isDateRangeValid={isDateRangeValid}
              setIsDateRangeValid={setIsDateRangeValid}
            />
          )}
        </>
      ) : (
        <div className={styles.loading}>
          <ProgressCircle />
        </div>
      )}
    </Dialog>
  );
};

const mapState = (state: AppState) => ({
  submitInProgress: state.contextualAudience.submitInProgress,
});

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  createContextualAudience: contextualAudienceActions.createContextualAudience,
  updateContextualAudience: contextualAudienceActions.updateContextualAudience,
  openSnackbar: snackbarActions.openSnackbar,
  updateSorting: tableActions.updateSorting,
};

const ContextualAudienceDialog = connect(mapState, mapAction)(ContextualAudienceDialogComponent);

export default ContextualAudienceDialog;
