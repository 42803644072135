import * as React from 'react';
import { Box, CircularProgress, Typography, sx } from '@applift/factor';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { GetSegmentAudienceBreakdownWidgetResponse } from 'api/SegmentedAudience';
import { ReachFormatter as NumberFormatter } from 'utils/format';
import { NoData, Timeout, ErrorComponent } from './GraphsErrorState';
import { externalGraphTooltip } from './component/Tooltip';

export interface DeviceBarChartsProps {
  isPlaceholder?: boolean;
  isLoading?: boolean;
  deviceTypeData?: GetSegmentAudienceBreakdownWidgetResponse['data'];
  errorType?: 'Timeout' | 'Error' | '';
  refetchAudienceBreakdownInfo: () => void;
}

const PLACEHOLDER_VALUES = [85, 70, 85, 80, 60];

export const DeviceBarChart = (props: DeviceBarChartsProps) => {
  Chart.register(...registerables);

  const {
    isPlaceholder,
    deviceTypeData,
    isLoading,
    refetchAudienceBreakdownInfo,
    errorType,
  } = props;

  const backgroundColor = React.useMemo(() => (isPlaceholder ? ['#E6E6E6'] : ['#75A1FF']), [
    isPlaceholder,
  ]);

  const graphLabels = React.useMemo(() => {
    return deviceTypeData?.map((val) => val?.deviceType);
  }, [deviceTypeData]);

  const graphValues = React.useMemo(() => {
    return deviceTypeData
      ?.filter((val) => val['audienceValue(%)'] || false)
      .map((val) => {
        return val['audienceValue(%)'];
      });
  }, [deviceTypeData]);

  const renderChart = React.useCallback(() => {
    if (isLoading && !isPlaceholder) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <CircularProgress thickness={3} />
          <Typography sx={{ mt: 16 }} variant="p">
            Loading...
          </Typography>
        </Box>
      );
    }

    if (errorType === 'Timeout') {
      return <Timeout onRefresh={refetchAudienceBreakdownInfo} />;
    }

    if (errorType === 'Error') {
      return <ErrorComponent />;
    }

    if (!graphValues?.length && !isPlaceholder) {
      return <NoData />;
    }

    return (
      <Bar
        className={sx({ width: 100, mt: 16 })}
        options={{
          scales: {
            x: {
              ticks: { display: !isPlaceholder },
            },
            y: {
              max: 100,
              ticks: {
                display: !isPlaceholder,
                callback: (val) => NumberFormatter.format(+val || 0),
              },
            },
          },
          aspectRatio: 50 / 50,
          responsive: true,
          plugins: {
            legend: { display: false },
            tooltip: {
              intersect: false,
              callbacks: {
                title: (e) => 'Device',
                label: (e) => {
                  return `${e.label}: ${NumberFormatter.format((e.raw as number) || 0)}%`;
                },
              },
              external: (ctx) => externalGraphTooltip(ctx),
              enabled: false,
              borderWidth: 0.2,
            },
          },
        }}
        data={{
          labels: isPlaceholder ? PLACEHOLDER_VALUES.map(String) : (graphLabels as string[]),
          datasets: [
            {
              data: isPlaceholder ? PLACEHOLDER_VALUES : graphValues,
              backgroundColor,
              barPercentage: 1,
              borderRadius: 2,
            },
          ],
        }}
      />
    );
  }, [
    backgroundColor,
    errorType,
    graphLabels,
    graphValues,
    isLoading,
    isPlaceholder,
    refetchAudienceBreakdownInfo,
  ]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 100,
        }}
        style={{ aspectRatio: '1/1', pointerEvents: isPlaceholder ? 'none' : 'auto' }}
      >
        {renderChart()}
      </Box>
      {!isPlaceholder && !isLoading && !errorType && graphValues?.length ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          <Box
            sx={{ borderRadius: 'pill', flexShrink: 0 }}
            style={{ width: '12px', height: '6px', backgroundColor: '#75A1FF' }}
          />
          <Typography
            component="span"
            variant="label"
            gutterBottom={false}
            sx={{ textColor: 'neutral-600' }}
            lineHeight="single-line"
          >
            Devices
          </Typography>
        </Box>
      ) : null}
    </>
  );
};
