export interface ContactList {
  id: number;
  portalId: number;
  name: string;
  dynamic: boolean;
  size: number;
  created: number;
  updated: number;
  label?: string;
  isSelected?: boolean;
}

interface IsAuthorized {
  authorized: boolean;
}

type GetContactLists =
  | {
      success: true;
      data: ContactList[];
    }
  | {
      success: false;
      errorObjects: { error: string }[];
    };

export class RequestError extends Error {
  constructor(public response: Response) {
    super();
  }
}

export const isAuthorized = async (
  hostname: string,
  owId: number,
  token: string,
  signal?: AbortSignal,
) => {
  const res = await fetch(
    `${process.env.REACT_APP_HUBSPOT_API_URL}/is-authorized?hostname=${hostname}&token=${token}&owid=${owId}`,
    { signal },
  );
  if (!res.ok) throw new RequestError(res);
  return (await res.json()) as IsAuthorized;
};

export const getContactLists = async (
  hostname: string,
  owId: number,
  token: string,
  signal?: AbortSignal,
) => {
  const res = await fetch(
    `${process.env.REACT_APP_HUBSPOT_API_URL}/lists?hostname=${hostname}&token=${token}&owid=${owId}`,
    { signal },
  );
  if (!res.ok) throw new RequestError(res);
  return (await res.json()) as GetContactLists;
};

export const getContacts = async (
  hostname: string,
  owId: number,
  token: string,
  lists: string,
  signal?: AbortSignal,
) => {
  const res = await fetch(
    `${process.env.REACT_APP_HUBSPOT_API_URL}/contacts?hostname=${hostname}&token=${token}&owid=${owId}&lists=${lists}`,
    { signal },
  );
  if (!res.ok) throw new RequestError(res);
  return res;
};
