import { datadogRum } from 'iqm-framework';

import { CreateLookalikeAudienceData } from 'models/LookalikeAudience';

import { SUCCESS, FAILED, service } from '../../constants';
import { TActionParams } from '../../types';
import { actionTypes } from '../actionTypes';

const actions = {
  createLookalikeAudience: {
    id: 'createLookalikeAudience',
    text: 'Create Lookalike Audience',
  },
};

interface TCreateLookalikeAudienceParams extends TActionParams {
  createAudienceData: CreateLookalikeAudienceData;
}

export const createLookalikeAudience = (params: TCreateLookalikeAudienceParams) => {
  const inputMatchedAudiencesCount = params.createAudienceData?.sourceAudienceIds?.length
    ? params.createAudienceData.sourceAudienceIds.split(',').length
    : null;
  datadogRum.addAction(
    `${actions.createLookalikeAudience.text} ${params.success ? SUCCESS : FAILED}`,
    {
      action: `${service}/${actions.createLookalikeAudience.id}`,
      actionType: `${service}/${actionTypes.createAudience.id}`,
      success: params.success,
      errorMsg: params.errorMsg,
      latency: params.latency,
      statusCode: params.statusCode,
      info: {
        inputMatchedAudiencesCount,
      },
    },
  );
};
