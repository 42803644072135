import React from 'react';

import styles from './styles.module.scss';

interface Props {
  step: number;
  maxStep: number;
}

const StepIndicator = ({ step, maxStep }: Props) => (
  <div className={styles.stepIndicator}>
    <div className={styles.stepLines}>
      {[...Array(maxStep).keys()].map((i) => (
        <hr className={i + 1 <= step ? styles.lineActive : ''} key={i} />
      ))}
    </div>
    <div className={styles.stepIndicatorText}>
      <span>{step}</span> out of <span>2</span> Steps
    </div>
  </div>
);

export default StepIndicator;
