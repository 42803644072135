import { datadogRum } from 'iqm-framework';
import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import {
  CreateGeofarmedAudienceResponse,
  CreateGeofarmedAudienceData,
  CustomAreaType,
  CustomArea,
  FilterStore,
  LocationDetailsFileType,
  LocationDetailsValue,
} from 'models/GeofarmedAudience';
import { WithResponse } from 'models/Response';

import { SUCCESS, FAILED, service } from '../../constants';
import { actionTypes } from '../actionTypes';
import { getApiErrorMsg } from '../../utils';

const actions = {
  createGeofarmedAudience: {
    id: 'createGeofarmedAudience',
    text: 'Create Geofarmed Audience',
  },
};

interface RumIncludedExcludedLocations {
  zipcodes: number;
  customAreas: { [key in CustomAreaType]: number };
  file: { [key in LocationDetailsFileType]: number };
}

interface RumGeofarmedAudienceInfo {
  numLocationFiles: number;
  excluded: RumIncludedExcludedLocations;
  included: RumIncludedExcludedLocations;
}

const defaultIncludedExcluded: RumIncludedExcludedLocations = {
  zipcodes: 0,
  customAreas: {
    circle: 0,
    custom: 0,
    square: 0,
  },
  file: {
    polygons: 0,
    addresses: 0,
    locations: 0,
  },
};

interface TCreateGeofarmedParams {
  response: AxiosResponse<WithResponse<CreateGeofarmedAudienceResponse>>;
  audienceData: CreateGeofarmedAudienceData;
  filterStore: FilterStore;
  success: boolean;
}

export const createGeofarmedAudience = (params: TCreateGeofarmedParams) => {
  const { audienceData, filterStore, success, response } = params;

  const info: RumGeofarmedAudienceInfo = {
    numLocationFiles: audienceData?.locations?.locationFileIds?.length || 0,
    included: cloneDeep(defaultIncludedExcluded),
    excluded: cloneDeep(defaultIncludedExcluded),
  };
  const included = audienceData?.locations?.included || {};
  const excluded = audienceData?.locations?.excluded || {};

  if (included.customAreas?.length) {
    included.customAreas.forEach((area: CustomArea) => {
      info.included.customAreas[area.type] += 1;
    });
  }
  if (excluded.customAreas?.length) {
    excluded.customAreas.forEach((area: CustomArea) => {
      info.excluded.customAreas[area.type] += 1;
    });
  }

  info.included.zipcodes = included.zipCodes?.length || 0;
  info.excluded.zipcodes = excluded.zipCodes?.length || 0;

  const includedLocationsFromFile = new Set(included.locationIds || []);
  const excludedLocationsFromFile = new Set(excluded.locationIds || []);

  if (filterStore?.locationDetails) {
    Object.values(filterStore?.locationDetails).forEach((locationFile: LocationDetailsValue) => {
      if (locationFile.validLocations?.length) {
        const includedLocations = locationFile.validLocations.filter((location: CustomArea) =>
          includedLocationsFromFile.has(location.locationId),
        ).length;
        info.included.file[locationFile.fileType] += includedLocations;
        const excludedLocations = locationFile.validLocations.filter((location: CustomArea) =>
          excludedLocationsFromFile.has(location.locationId),
        ).length;
        info.excluded.file[locationFile.fileType] += excludedLocations;
      }
    });
  }

  datadogRum.addAction(
    `${actions.createGeofarmedAudience.text} ${params.success ? SUCCESS : FAILED}`,
    {
      action: `${service}/${actions.createGeofarmedAudience.id}`,
      actionType: `${service}/${actionTypes.createAudience.id}`,
      latency: get(response, 'data.latency'),
      statusCode: get(response, 'status'),
      errorMsg: !success ? getApiErrorMsg(response) : null,
      success,
      info,
    },
  );
};
