import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { LazySelect } from 'iqm-framework';
import { TextField, Icon } from 'factor';
import { Box, Typography } from '@applift/factor';
import { ClipboardClose } from '@applift/icons';

import { AppState } from 'store';
import { SourceAudienceInfo } from 'models/LookalikeAudience';
import { audienceNameValidationRule } from 'utils/validation';
import { REG_EXP } from 'utils/regExp';
import { SelectedAudiencesTable } from './SelectedAudienceTable';

import styles from './styles.module.scss';

type Options = {
  label: string;
  value: number;
  reactLabel: JSX.Element;
  matchRate: string;
  uniques: string;
  audienceName: string;
  created: string;
};

type SelectFieldData = {
  audienceName: string;
  id: number;
  uniques: number;
  matchRate: number;
  createdOn: number;
};

interface Props {
  setAudienceName: React.Dispatch<React.SetStateAction<string>>;
  setNameValidity: React.Dispatch<React.SetStateAction<boolean>>;
  audienceName: string;
  setSelectedAudiences: React.Dispatch<React.SetStateAction<any>>;
  selectedAudiences: Options[];
  sourceAudienceInfo?: SourceAudienceInfo | null;
  isAudienceNameEdited: React.MutableRefObject<boolean>;
  isEdit: boolean;
  isDuplicate: boolean;
}

const LookalikeAudienceSelectionComponent = (props: Props) => {
  const {
    setAudienceName,
    audienceName,
    setNameValidity,
    setSelectedAudiences,
    selectedAudiences,
    sourceAudienceInfo,
    isAudienceNameEdited,
    isEdit,
    isDuplicate,
  } = props;

  const TextFieldInputRef = useRef<HTMLInputElement | null>(null);

  const getInputRef = useCallback((ref: HTMLInputElement) => {
    TextFieldInputRef.current = ref;
  }, []);

  useEffect(() => {
    if (TextFieldInputRef.current && !isEdit) {
      TextFieldInputRef.current.focus();
    }
  }, [TextFieldInputRef, isEdit]);

  const mapSelectFieldData = (data: SelectFieldData[]) =>
    data.map((audience) => ({
      label: audience.audienceName,
      value: audience.id,
      uniques: audience.uniques,
      matchRate: audience.matchRate,
      created: audience.createdOn,
      audienceName: audience.audienceName,
      reactLabel: (
        <span className={styles.audienceOption}>
          <i className={styles.audienceIcon}>
            <Icon name="AudienceMatched" className="_size-20" />
          </i>
          <span className={styles.audienceLabel}>
            {audience.audienceName.length > 100
              ? `${audience.audienceName.substring(0, 99)}...`
              : audience.audienceName}
          </span>
        </span>
      ),
    }));

  useEffect(() => {
    if ((isEdit || isDuplicate) && sourceAudienceInfo?.length) {
      const mappedData = mapSelectFieldData(sourceAudienceInfo);
      setSelectedAudiences(mappedData);
    }
  }, [isEdit, sourceAudienceInfo, setSelectedAudiences, isDuplicate]);

  const getSelectionDetails = useCallback(
    (selectedAudienceCount: number) => {
      if (selectedAudiences) {
        const audienceLabel = selectedAudiences[0].label;
        return (
          <>
            <span className={styles.selectedAudience}>{audienceLabel}</span>
            {selectedAudiences.length > 1 && (
              <>
                <span className={styles.and}>{selectedAudiences.length > 1 && '& '}</span>
                <span className={styles.additionalAudienceCount}>
                  {`${selectedAudienceCount - 1} more`}
                </span>
              </>
            )}
          </>
        );
      }
      return null;
    },
    [selectedAudiences],
  );

  const apiParams = useMemo(() => {
    const urlParams = new URLSearchParams();
    urlParams.append('sortBy', 'createdOn');
    urlParams.append('sortType', 'desc');
    urlParams.append('audienceTypeIds', '1');
    urlParams.append('status', 'ready');
    return urlParams.toString();
  }, []);

  const mapResponseData = (response: any) => {
    const data = response.data || response.responseObject.data;
    return {
      data: mapSelectFieldData(data),
      totalRecords: response.totalRecords || response.responseObject.totalRecords,
      filteredRecords: response.filteredRecords || response.responseObject.filteredRecords,
    };
  };

  const onNameChange = (name: string) => {
    isAudienceNameEdited.current = true;
    setAudienceName(name.replace(REG_EXP.TWO_SPACES, ' '));
  };

  const emptyDropdownLabel = (
    <Box
      style={{ height: '200px' }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <ClipboardClose fontSize={40} sx={{ mb: 16, textColor: 'neutral-500' }} />
      <Typography component={'p'} variant="bodySmall" gutterBottom={false}>
        At the moment, there's no content to display here.
      </Typography>
    </Box>
  );

  return (
    <>
      <div className="row">
        <div className="col-6">
          <TextField
            variant="withoutTickbox"
            placeholder="Enter audience name"
            label="Audience Name"
            value={audienceName}
            onChange={onNameChange}
            validationRules={audienceNameValidationRule}
            onValidate={setNameValidity}
            inputRef={getInputRef}
          />
        </div>
        <div className={`col-6 ${isEdit ? styles.disableSelect : ''}`}>
          <LazySelect
            onChange={setSelectedAudiences}
            apiPath={`api/v2/audience/detailed/list?${apiParams}`}
            httpMethod="get"
            transformPagingParameters={(state: any) => ({
              noOfEntries: state.noOfEntries,
              pageNo: state.pageNo,
              searchField: state.searchField,
            })}
            mapServerResponseData={mapResponseData}
            value={selectedAudiences ?? []}
            totalRecordsPropName="totalRecords"
            numberOfEntries={50}
            showErrorLabel
            selectSpecificProps={{
              placeholder: 'Select Matched Audiences',
              label: 'Matched Audience',
              showLabelAlways: true,
              className: styles.selectWrapper,
              multiPlaceholder: getSelectionDetails,
              allSelectable: !isEdit,
              isClearable: !isEdit,
              isReadOnly: isEdit,
              isSearchClearable: true,
              emptyDropdownLabel,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className={`col-12 ${styles.tableColumn}`}>
          {selectedAudiences?.length ? (
            <SelectedAudiencesTable
              selectedAudiences={selectedAudiences as Options[]}
              setSelectedAudiences={setSelectedAudiences}
              isEdit={isEdit}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapState = (state: AppState) => ({
  sourceAudienceInfo: state.lookalikeAudience.sourceAudienceInfo,
});

const LookalikeAudienceSelection = connect(mapState, null)(LookalikeAudienceSelectionComponent);

export default LookalikeAudienceSelection;
