import * as React from 'react';
import { Box, Typography, IconButton } from '@applift/factor';
import { Hourglass, Redo } from '@applift/icons';

export interface TimeoutPropTypes {
  onRefresh: () => void;
}

export const Timeout = (props: TimeoutPropTypes) => {
  const { onRefresh } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Box>
        <Hourglass sx={{ textColor: 'neutral-500', mb: 12 }} fontSize={40} />
      </Box>
      <Typography variant="p">It's taking more time than usual.</Typography>
      <Box sx={{ textAlign: 'center', width: 75 }}>
        <Typography component="span" variant="p">
          Please
        </Typography>
        <IconButton onClick={onRefresh}>
          <Redo fontSize={18} />
          <Typography variant="bodySmall" sx={{ my: 'auto' }}>
            Refresh
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};
