import React from 'react';
import { Icon } from 'factor';
import { snackbarActions } from 'store/snackbar/actions';
import { store } from 'store';
import { API } from 'api';
import get from 'lodash/get';

import styles from './styles.module.scss';

interface IProps {
  className?: string;
  s3FileName: string;
  audienceId: number;
}

const downloadFile = (item: number) => async () => {
  try {
    const url = await API.MatchedAudience.editDownloadFileURL(item);
    const popout = window.open(url, '_blank');
    window.setTimeout(() => {
      if (popout) {
        popout.close();
      }
    }, 2500);
  } catch (e) {
    store.dispatch(
      snackbarActions.openSnackbar({
        message: get(e, 'responseObject.errorMsg', ''),
        type: 'error',
      }),
    );
  }
};

const DownloadCsvLabel = (props: IProps) => {
  const { s3FileName, audienceId } = props;

  return (
    <div className={styles.container}>
      <div className={styles.name}>{s3FileName}</div>
      <div className={styles.downloadIcon} onClick={downloadFile(audienceId)}>
        <Icon className={styles.iconS} name="DownloadRounded" />
      </div>
    </div>
  );
};

export default DownloadCsvLabel;
