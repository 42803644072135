import { StickedOption, AudienceTypeId } from '../models/Table';

export function getSortingColumnOptions(audienceTypeIds: AudienceTypeId): StickedOption[] {
  return [
    {
      label: 'ID',
      value: 'id',
      alwaysEnabled: true,
    },
    {
      label: 'Audience Name',
      value: 'audienceName',
      alwaysEnabled: true,
    },
    {
      label: 'Created',
      value: 'created',
    },
    ...(!['8'].includes(audienceTypeIds)
      ? [
          {
            label: 'Reach',
            value: 'uniques',
          },
        ]
      : []),
    ...(['3', '4'].includes(audienceTypeIds)
      ? [
          {
            label: 'Data Collection Period',
            value: 'period',
          },
        ]
      : []),
    ...(audienceTypeIds === '1'
      ? [
          {
            label: 'Records',
            value: 'records',
          },
          {
            label: 'Match Rate',
            value: 'matchRate',
          },
        ]
      : []),
    {
      label: 'Cost (CPM)',
      value: 'cost',
    },
    ...(!['8'].includes(audienceTypeIds)
      ? [
          {
            label: 'Status',
            value: 'status',
          },
          {
            label: 'Allowlist/Blocklist',
            value: 'incExc',
          },
        ]
      : []),
    {
      value: 'Audience',
      label: 'Audience',
      isGroupTitle: true,
    },
    ...(audienceTypeIds === '1'
      ? [
          {
            label: 'Insights',
            value: 'insights',
          },
        ]
      : []),
  ];
}
