import { reducerFromMap } from '../../utils/actions';
import { Action } from '../../models/Action';
import { geofarmedAudienceConstants } from './constants';

export interface GeofarmedAudienceState {
  submitInProgress: boolean;
}

const defaultGeofarmedAudienceState: GeofarmedAudienceState = {
  submitInProgress: false,
};

const startCreating = (state: GeofarmedAudienceState): GeofarmedAudienceState => {
  return {
    ...state,
    submitInProgress: true,
  };
};

const endCreating = (state: GeofarmedAudienceState): GeofarmedAudienceState => {
  return {
    ...state,
    submitInProgress: false,
  };
};

const startUpdating = (state: GeofarmedAudienceState): GeofarmedAudienceState => {
  return {
    ...state,
    submitInProgress: true,
  };
};

const endUpdating = (state: GeofarmedAudienceState): GeofarmedAudienceState => {
  return {
    ...state,
    submitInProgress: false,
  };
};

const reducer = reducerFromMap<GeofarmedAudienceState>(defaultGeofarmedAudienceState, {
  [geofarmedAudienceConstants.START_CREATING]: startCreating,
  [geofarmedAudienceConstants.END_CREATING]: endCreating,
  [geofarmedAudienceConstants.START_UPDATING]: startUpdating,
  [geofarmedAudienceConstants.END_UPDATING]: endUpdating,
});

export const geofarmedAudience = (
  state: GeofarmedAudienceState = defaultGeofarmedAudienceState,
  action: Action<any>,
) => reducer(state, action);
