import React from 'react';
import { Box, CircularProgress, Typography, sx } from '@applift/factor';
import { Pie } from 'react-chartjs-2';
import { ArcElement, Chart } from 'chart.js';
import { GetSegmentAudienceBreakdownWidgetResponse } from 'api/SegmentedAudience';
import { ErrorComponent, NoData, Timeout } from './GraphsErrorState';
import { externalGraphTooltip } from './component/Tooltip';

export interface GenderPieChartProps {
  isPlaceholder?: boolean;
  isLoading?: boolean;
  errorType?: 'Timeout' | 'Error' | '';
  genderTypeData?: GetSegmentAudienceBreakdownWidgetResponse['data'];
  refetchAudienceBreakdownInfo: () => void;
}

Chart.register(ArcElement);

const PLACEHOLDER_VALUES = [25, 50, 25];

export const GenderPieChart = (props: GenderPieChartProps) => {
  const {
    isPlaceholder,
    isLoading,
    genderTypeData,
    errorType,
    refetchAudienceBreakdownInfo,
  } = props;

  const backgroundColor = React.useMemo(
    () => (isPlaceholder ? ['#999999', '#CCCCCC', '#E6E6E6'] : ['#75A1FF', '#FF9494', '#FFD17A']),
    [isPlaceholder],
  );

  const graphInfo = React.useMemo(() => {
    return genderTypeData
      ?.filter((val) => val['audienceValue(%)'] || false)
      .map((val) => {
        return {
          value: val['audienceValue(%)'],
          label: val?.gender,
        };
      });
  }, [genderTypeData]);

  const renderChart = React.useCallback(() => {
    if (isLoading && !isPlaceholder) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <CircularProgress thickness={3} />
          <Typography sx={{ mt: 16 }} variant="p">
            Loading...
          </Typography>
        </Box>
      );
    }

    if (errorType === 'Timeout') {
      return <Timeout onRefresh={refetchAudienceBreakdownInfo} />;
    }

    if (errorType === 'Error') {
      return <ErrorComponent />;
    }

    if (!graphInfo?.length && !isPlaceholder) {
      return <NoData />;
    }

    return (
      <Pie
        className={sx({ width: 100, height: 100 })}
        data={{
          labels: graphInfo?.map((val: { label: any }) => val.label),
          datasets: [
            {
              data: isPlaceholder
                ? PLACEHOLDER_VALUES
                : graphInfo?.map((val: { value: any }) => val.value),
              backgroundColor,
              borderWidth: 1,
              hoverOffset: 0,
            },
          ],
        }}
        options={{
          aspectRatio: 50 / 50,
          plugins: {
            tooltip: {
              external: (ctx) => externalGraphTooltip(ctx),
              callbacks: {
                title: (e) => {
                  return e[0].label;
                },
                label: (e) => {
                  const total = e.dataset.data.reduce((a, b) => a + +b, 0);
                  return `${Math.abs(((e.raw as number) / total) * 100).toFixed(2)}%`;
                },
              },
              enabled: false,
              backgroundColor: 'orange',
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    );
  }, [
    backgroundColor,
    errorType,
    graphInfo,
    isLoading,
    isPlaceholder,
    refetchAudienceBreakdownInfo,
  ]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 100,
        }}
        style={{ aspectRatio: '1/1', pointerEvents: isPlaceholder ? 'none' : 'auto' }}
      >
        {renderChart()}
      </Box>
      {!isPlaceholder && !isLoading && graphInfo?.length && !errorType ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          {graphInfo?.map((val, idx: number) => (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <Box
                sx={{ borderRadius: 'pill', flexShrink: 0 }}
                style={{ width: '12px', height: '6px', backgroundColor: backgroundColor[idx] }}
              />
              <Typography
                component="span"
                variant="label"
                gutterBottom={false}
                sx={{ textColor: 'neutral-600' }}
                lineHeight="single-line"
              >
                {val.label}: {val.value.toFixed(2)}%
              </Typography>
            </Box>
          ))}
        </Box>
      ) : null}
    </>
  );
};
