import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import get from 'lodash/get';

import { tableComponentInstanceRef } from '../../components/Audiences/AudiencesTable';
import {
  StickedOption,
  TableSortingParams,
  TableAudienceItem,
  UpdateAudienceNameResponse,
  AudienceTypeId,
  InsightsData,
} from '../../models/Table';
import { createAction } from '../../utils/actions';
import { tableConstants } from './constants';
import { API } from '../../api';
import { AudienceStatus, AudienceType } from '../../api/Table';
import { AppState } from '../index';
import { snackbarActions } from '../snackbar/actions';

export interface ChangeSortingColumns {
  changeSortingColumns: (newColumns: { [key in AudienceTypeId]: StickedOption[] }) => void;
}

export interface SetSelectedAudiences {
  setSelectedAudiences: (audiences: TableAudienceItem[]) => void;
}

export interface DeleteSelectedAudiences {
  deleteSelectedAudiences: (audiences: TableAudienceItem[], tableInstance: any) => void;
}

export interface UpdateSorting {
  updateSorting: (newSorting: TableSortingParams) => void;
}

export interface UpdateSearch {
  updateSearch: (searchStr: string) => void;
}

export interface SetSearchOpen {
  setSearchOpen: (isOpen: boolean) => void;
}
export interface SetTableData {
  setTableData: (data: TableAudienceItem[]) => void;
}

export interface SetInsightsData {
  setInsightsData: (data: InsightsData) => void;
}

export interface SetTableTotalItems {
  setTableTotalItems: (count: number) => void;
}

export interface UpdateAudienceName {
  updateAudienceName: (
    audienceName: string,
    audienceTypeId: number,
    audienceId: number,
  ) => Promise<void>;
}

export interface SetAudienceTypeIds {
  setAudienceTypeIds: (tabId: AudienceTypeId) => void;
}

export interface SetAudiencesByType {
  setAudiencesByType: () => void;
}

export interface SetAudiencesStatuses {
  setAudiencesStatuses: () => void;
}

export interface SetSelectedAudiencesStatuses {
  setSelectedAudiencesStatuses: (selected: { [key in AudienceTypeId]: string }) => void;
}

export interface SetLoadingStatus {
  setLoadingStatus: (value: boolean) => void;
}

export const tableActions = {
  setTableData(data: TableAudienceItem[]) {
    return createAction<TableAudienceItem[]>(tableConstants.SET_TABLE_DATA, data);
  },
  setTableTotalItems(count: number) {
    return createAction<number>(tableConstants.SET_TABLE_TOTAL_ITEMS, count);
  },
  changeSortingColumns(newColumns: { [key in AudienceTypeId]: StickedOption[] }) {
    return createAction<{ [key in AudienceTypeId]: StickedOption[] }>(
      tableConstants.CHANGE_SORTING_COLUMNS,
      newColumns,
    );
  },
  setSelectedAudiences(audiences: TableAudienceItem[]) {
    return createAction<TableAudienceItem[]>(tableConstants.SET_SELECTED_AUDIENCES, audiences);
  },
  deleteSelectedAudiences(audiences: TableAudienceItem[], tableInstance: any) {
    return async (dispatch: Dispatch) => {
      dispatch(createAction<boolean>(tableConstants.UPDATE_DELETE_PROGRESS, true));
      const requestData = { audienceIds: audiences.map(({ id }) => id).join(',') };

      try {
        const responseData = await API.Table.deleteAudiences(requestData);
        const allSuccess =
          Object.entries(responseData).filter(
            (item: [string, number[]]) =>
              item[0].toLowerCase().includes('failed') && item[1].length,
          ).length === 0;
        dispatch(
          snackbarActions.openSnackbar({
            message: responseData.message,
            type: allSuccess ? 'success' : 'error',
          }),
        );
        if (allSuccess) {
          (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesByType());
        }
        if (tableInstance) {
          tableInstance.clearSelected();
          tableInstance.getNewData();
        }
      } catch (e) {
        dispatch(
          snackbarActions.openSnackbar({
            message: e,
            type: 'error',
          }),
        );
      } finally {
        dispatch(createAction<boolean>(tableConstants.UPDATE_DELETE_PROGRESS, false));
      }
    };
  },
  updateSorting(newSorting: TableSortingParams) {
    return createAction<TableSortingParams>(tableConstants.UPDATE_SORTING, newSorting);
  },
  updateSearch(searchStr: string) {
    tableComponentInstanceRef?.current.clearSelected();
    return createAction<string>(tableConstants.UPDATE_SEARCHTEXT, searchStr);
  },
  setSearchOpen(isOpen: boolean) {
    return createAction<boolean>(tableConstants.SET_SEARCH_OPEN, isOpen);
  },
  updateAudienceName(audienceName: string, audienceTypeId: number, audienceId: number) {
    return async (dispatch: Dispatch) => {
      try {
        const result: UpdateAudienceNameResponse = await API.Table.updateAudienceName(
          audienceName,
          audienceTypeId,
          audienceId,
        );

        if (result) {
          dispatch(
            createAction<{ audienceId: number; audienceName: string }>(
              tableConstants.UPDATE_AUDIENCE_NAME,
              { audienceId, audienceName },
            ),
          );
        }
      } catch (e) {
        dispatch(
          snackbarActions.openSnackbar({
            message: get(e, 'message', ''),
            type: 'error',
          }),
        );
        /* eslint-disable-next-line no-console */
        console.log('Error while updating audience name', e);
      }
    };
  },
  setAudienceTypeIds(tableAudienceTypeId: AudienceTypeId) {
    return (dispatch: Dispatch) => {
      dispatch(
        createAction<AudienceTypeId>(tableConstants.SET_AUDIENCE_TYPE_IDS, tableAudienceTypeId),
      );
      dispatch(createAction<[]>(tableConstants.SET_SELECTED_AUDIENCES, []));
      (dispatch as ThunkDispatch<AppState, void, AnyAction>)(tableActions.setAudiencesStatuses());
    };
  },
  setAudiencesByType() {
    return async (dispatch: Dispatch) => {
      const result: AudienceType[] | null = await API.Table.getAudienceCountByType(
        '1,2,3,4,5,6,7,8',
        '',
      );
      if (result) {
        dispatch(createAction<AudienceType[]>(tableConstants.SET_AUDIENCES_BY_TYPE, result));
      }
    };
  },
  setAudiencesStatuses() {
    return async (dispatch: Dispatch, getState: () => AppState) => {
      const { audienceTypeIds } = getState().table;
      const result: AudienceStatus[] | null = await API.Table.getAudiencesStatuses(audienceTypeIds);
      if (result) {
        dispatch(createAction<AudienceStatus[]>(tableConstants.SET_AUDIENCES_STATUSES, result));
      }
    };
  },
  setSelectedAudiencesStatuses(selected: { [key in AudienceTypeId]: string }) {
    return (dispatch: Dispatch) => {
      dispatch(createAction<[]>(tableConstants.SET_SELECTED_AUDIENCES, []));
      dispatch(
        createAction<{ [key in AudienceTypeId]: string }>(
          tableConstants.SET_AUDIENCES_SELECTED_STATUSES,
          selected,
        ),
      );
    };
  },
  setLoadingStatus(value: boolean) {
    return createAction<boolean>(tableConstants.SET_LOADING_STATUS, value);
  },
  setInsightsData(value: InsightsData) {
    return createAction<InsightsData>(tableConstants.SET_INSIGHTS_DATA, value);
  },
};
