import { AxiosResponse } from 'axios';
import get from 'lodash/get';

import { WithResponse } from 'models/Response';

export const getApiErrorMsg = (response: AxiosResponse<WithResponse<any>>) => {
  return (
    get(response, 'data.errorObjects[0].error') ||
    get(response, 'data.responseObject.errorMsg') ||
    `API Error: ${response?.status || 'No Status Code Found'}`
  );
};
