import React, { useEffect, useCallback, useRef, useState } from 'react';
import { TextField, Tooltip, Icon } from 'factor';
import { Alert, sx } from '@applift/factor';
import { audienceNameValidationRule } from 'utils/validation';
import { REG_EXP } from 'utils/regExp';
import moment from 'moment';

import { NonEditableTextField } from './NonEditableTextField';
import { formatDate } from '../../../../../utils/format';
import styles from './styles.module.scss';
import { DateRangePicker } from './DateRangePicker';

interface Props {
  name: string;
  frequency: string;
  startDate: number;
  endDate: number;
  addedKeywords: string[];
  addedUrls: string[];
  setName: (name: string) => void;
  setFrequency: (name: string) => void;
  setStartDate: (epoch: number) => void;
  setEndDate: (epoch: number) => void;
  isEdit?: boolean;
  isDuplicate?: boolean;
  setIsNameValid: (isValid: boolean) => void;
  isDateRangeValid: boolean;
  setIsDateRangeValid: (isValid: boolean) => void;
}

const ContextualAudienceInfo = (props: Props) => {
  const {
    addedKeywords,
    addedUrls,
    name,
    isEdit,
    isDuplicate,
    setName,
    setIsNameValid,
    startDate,
    endDate,
    frequency,
    setFrequency,
    setStartDate,
    setEndDate,
    isDateRangeValid,
    setIsDateRangeValid,
  } = props;

  const [prevFrequency, setPrevFrequency] = useState('1');

  const TextFieldInputRef = useRef<HTMLInputElement | null>(null);

  const getInputRef = useCallback((ref: HTMLInputElement) => {
    TextFieldInputRef.current = ref;
  }, []);

  useEffect(() => {
    if (TextFieldInputRef.current && !isEdit) {
      TextFieldInputRef.current.focus();
    }
  }, [TextFieldInputRef, isEdit]);

  const onNameChange = (newName: string) => {
    setName(newName.replace(REG_EXP.TWO_SPACES, ' '));
  };

  const onFrequencyChange = (newFrequency: string) => {
    const isFieldEmpty = newFrequency === '' || newFrequency === '0';
    if (!Number.isNaN(newFrequency) && !isFieldEmpty) {
      if (Number(newFrequency) <= 10) {
        setFrequency(Number(newFrequency).toString());
        setPrevFrequency(Number(newFrequency).toString());
      }
    }
    if (isFieldEmpty) {
      setFrequency('');
    }
  };

  const onFrequencyBlur = () => {
    if (frequency === '') {
      setFrequency(prevFrequency);
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.audienceInfo} row`}>
        <div className="col-4">
          <TextField
            className={styles.nameInput}
            inputRef={getInputRef}
            label="Audience Name"
            placeholder="Add audience name"
            value={name}
            onChange={onNameChange}
            variant="withoutTickbox"
            underline={false}
            validationRules={audienceNameValidationRule}
            onValidate={setIsNameValid}
          />
        </div>
        <div className="col-3">
          {isEdit ? (
            <NonEditableTextField data={frequency} label={'Frequency'} />
          ) : (
            <TextField
              className={styles.editableTextField}
              variant="withoutTickbox"
              underline={false}
              label={
                <div className="d-flex align-items-center">
                  Frequency
                  <Tooltip
                    label="Frequency value must be between 1-10"
                    position="left"
                    className="ml-1"
                    auto={false}
                  >
                    <Icon className={styles.statInfoTooltipIcon} name="Info" />
                  </Tooltip>
                </div>
              }
              value={frequency}
              onChange={onFrequencyChange}
              onBlur={onFrequencyBlur}
            />
          )}
        </div>
        <div className="col-4" style={{ display: 'flex', flexDirection: 'column' }}>
          {isEdit ? (
            <NonEditableTextField
              startIcon="DateRange"
              data={`${formatDate((startDate / 1000) as number, moment.tz.guess())} - ${formatDate(
                (endDate / 1000) as number,
                moment.tz.guess(),
              )}`}
              label={'Data Collection Period'}
            />
          ) : (
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              isDateRangeValid={isDateRangeValid}
              setIsDateRangeValid={setIsDateRangeValid}
            />
          )}
        </div>
      </div>
      <div className={styles.contextualInfoContainer}>
        <div className={styles.contextualInfoTitle}>Contextual Information</div>
        <div className={styles.contextualInfo}>
          {addedKeywords.length > 0 && (
            <div className={styles.contextualInfoItem}>{`${addedKeywords.length} Keywords`}</div>
          )}
          {addedUrls.length > 0 && (
            <div className={styles.contextualInfoItem}>{`${addedUrls.length} URLs`}</div>
          )}
        </div>
      </div>

      {!isDuplicate && (
        <div className={styles.note}>
          <Alert
            severity="info"
            classes={{
              message: sx({
                display: 'flex',
                justifyContent: 'between',
                alignItems: 'center',
                width: 100,
                p: 0,
              }),
            }}
          >
            <div>
              For details on frequency and data collection period, see additional information.
            </div>
            <div
              className={styles.externalLink}
              onClick={() =>
                window.open(
                  'https://help.iqm.com/en/articles/7018329-how-can-i-create-a-contextual-audience',
                  '_blank',
                )
              }
            >
              <Icon name={'OpenLink'} className={styles.externalLinkIcon} />
              Learn More
            </div>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default ContextualAudienceInfo;
