import React from 'react';
import { Header } from 'iqm-framework';

const NavbarComponent = () => {
  return (
    <Header backButton={false} title="Audiences" leftLogo="Audience Builder" isBeta bootIntercom />
  );
};

export const Navbar = NavbarComponent;
