import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  Typography,
  FormControlLabel,
} from '@applift/factor';
import {
  REMEMBER_SAVE_SEGMENTED_CONFIRMATION,
  getLocal,
  removeLocal,
  saveLocal,
} from 'services/localStorage';

interface Props {
  audienceName: string;
  handleClose: () => void;
  handleSave: () => void;
}

export const ConfirmSaveReadyAudienceDialog = (props: Props) => {
  const { audienceName, handleClose, handleSave: handleSaveProp } = props;
  const [isRememberChecked, setIsRememberChecked] = React.useState<boolean>(false);

  const handleSave = () => {
    if (isRememberChecked) {
      saveLocal(REMEMBER_SAVE_SEGMENTED_CONFIRMATION, true);
    } else {
      removeLocal(REMEMBER_SAVE_SEGMENTED_CONFIRMATION);
    }
    handleSaveProp();
  };

  React.useEffect(() => {
    setIsRememberChecked(!!getLocal(REMEMBER_SAVE_SEGMENTED_CONFIRMATION));
  }, []);

  const handleRememberCheckboxChange = () => {
    setIsRememberChecked((v) => !v);
  };

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle onClose={handleClose}>Save Audience</DialogTitle>
      <DialogContent>
        <Typography component="p" sx={{ mb: 16 }}>
          Are you sure you want to save these changes for the audience
          <Typography weight="demi"> {audienceName}</Typography>?
        </Typography>
        <Typography component="p" sx={{ textColor: 'neutral-400' }}>
          Note: This action will affect the running campaign's performance?
        </Typography>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={<Checkbox />}
          value={isRememberChecked}
          label="Don't ask me again"
          onChange={handleRememberCheckboxChange}
          sx={{ mr: 'auto' }}
        />
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
