import React from 'react';
import { connect } from 'react-redux';
import { IQMRouter } from 'iqm-framework';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CssBaseline, ThemeProvider, SnackbarProvider } from '@applift/factor';

import { User } from 'models/User';
import { BASE_URL } from 'config';
import { Snackbar } from 'components/Snackbar';
import { ViewAudience } from 'pages/ViewAudience';

import { AppWrapper } from './AppWrapper';
import { authActions, Authorized, Logout } from '../../store/auth/actions';
import { PrivateRoutes } from '../PrivateRoutes';
import { Navbar } from '../Navbar';
import { createIqmInstance } from '../../api/Instance';
import { queryClient } from '../../cache';

import './App.scss';

interface Props extends Authorized, Logout {}

declare global {
  interface Window {
    Intercom: any;
  }
}

const AppComponent = (props: Props) => {
  const { authorized, logout } = props;

  const isViewAudience = window.location.href.includes('view-audience');

  if (isViewAudience) {
    createIqmInstance(
      JSON.parse(window.localStorage.getItem('authorizedUser') as string).apiToken,
      true,
    );
    return (
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider domRoot={document.body}>
          <CssBaseline />
          <ThemeProvider variant="green" className="themeProvider">
            <ViewAudience iframe />
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} panelPosition="right" position="top-right" />
        </SnackbarProvider>
      </QueryClientProvider>
    );
  }

  const onSuccessLogin = (data: User) => {
    authorized(data);
    // Audiences app is being loaded in iframe from another app
    createIqmInstance(data.apiToken);
  };

  const onLogout = () => logout();

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider domRoot={document.body}>
        <CssBaseline />
        <ThemeProvider variant="green" className="themeProvider">
          <IQMRouter
            documentTitle="Organization"
            successLoginRedirectPath={BASE_URL}
            onSuccessLogin={onSuccessLogin}
            onLogout={onLogout}
          >
            <>
              <Navbar />
              <AppWrapper>
                <PrivateRoutes />
              </AppWrapper>
            </>
            <Snackbar />
          </IQMRouter>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} panelPosition="right" position="top-right" />
      </SnackbarProvider>
    </QueryClientProvider>
  );
};

const mapAction = {
  authorized: authActions.authorized,
  logout: authActions.logout,
};

export const App = connect(null, mapAction)(AppComponent);
