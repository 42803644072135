import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import get from 'lodash/get';
import {
  ContextualAudienceData,
  CreateContextualAudienceData,
  CreateContextualAudienceResponse,
  RecommendedKeywordsResponse,
  SearchKeywordsResponse,
  UpdateContextualAudienceData,
  UpdateContextualAudienceResponse,
  URLPreviewMetadata,
} from 'models/ContextualAudience';
import { Response, WithResponse } from 'models/Response';
import { getInstance } from './Instance';

export const createAudience = async (
  createAudienceData: CreateContextualAudienceData,
): Promise<AxiosResponse<WithResponse<CreateContextualAudienceResponse>>> => {
  try {
    const response: AxiosResponse<WithResponse<
      CreateContextualAudienceResponse
    >> = await getInstance().post(`/v3/audience/contextual/add`, createAudienceData);
    return response;
  } catch (error) {
    return Promise.reject(get(error, 'response', error));
  }
};

export const searchKeywords = async (
  searchKeyword: string,
): Promise<WithResponse<SearchKeywordsResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<SearchKeywordsResponse>> = await getInstance().get(
      `/v3/inv/contextual/autosuggest?keyword=${searchKeyword}`,
    );
    return response.data;
  } catch (error) {
    // @ts-ignore
    return get(error, 'response.data', error);
  }
};

export const getRecommendedKeywords = async (
  keyword: string,
): Promise<WithResponse<RecommendedKeywordsResponse>> => {
  try {
    const response: AxiosResponse<WithResponse<
      RecommendedKeywordsResponse
    >> = await getInstance().get(`/v3/inv/contextual/recommend?keyword=${keyword}`);
    return response.data;
  } catch (error) {
    // @ts-ignore
    return get(error, 'response.data', error);
  }
};

export const getAudience = async (id: number): Promise<WithResponse<ContextualAudienceData>> => {
  try {
    const response: AxiosResponse<WithResponse<ContextualAudienceData>> = await getInstance().get(
      `/v3/audience/contextual/${id}`,
    );
    return response.data;
  } catch (error) {
    return Promise.reject(get(error, 'response.data', error));
  }
};

export const updateAudience = async (
  updateAudienceData: UpdateContextualAudienceData,
): Promise<UpdateContextualAudienceResponse> => {
  try {
    const { audienceId, audienceName, audienceTypeId } = updateAudienceData;
    const response: AxiosResponse<Response<
      UpdateContextualAudienceResponse
    >> = await getInstance().patch(`/v2/audience/updateName/${audienceId}`, {
      audienceName,
      audienceTypeId,
    });
    // @ts-ignore
    return get(response, 'data.responseObject', {});
  } catch (e) {
    return Promise.reject(get(e, 'response.data.responseObject', e));
  }
};

export const getURLPreview = async (
  url: string,
  source: CancelTokenSource,
): Promise<WithResponse<URLPreviewMetadata>> => {
  try {
    const response: AxiosResponse<WithResponse<URLPreviewMetadata>> = await getInstance().post(
      `/v1/ms/url-preview/generate`,
      { url },
      {
        cancelToken: source?.token,
      },
    );
    if (response.status === 200) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    if (Axios.isCancel(error)) {
      return Promise.reject(error);
    }
    return Promise.reject(new Error('failed to fetch Requested URL'));
  }
};

export const validateUrl = async (
  url: string[],
  source: CancelTokenSource,
): Promise<WithResponse<{ [key: string]: boolean }>> => {
  try {
    const response: AxiosResponse<WithResponse<{
      [key: string]: boolean;
    }>> = await getInstance().post(`/v3/audience/contextual/programmatic/url/validate`, url, {
      cancelToken: source?.token,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error();
  } catch (error) {
    if (Axios.isCancel(error)) {
      return Promise.reject(error);
    }
    return Promise.reject(new Error('failed to fetch Requested URL'));
  }
};
