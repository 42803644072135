import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { isArray, isNumber } from 'lodash';
import { AppState } from 'store';
import { Icon, Tooltip, ProportionalSelect } from 'factor';
import { LazySelect } from 'iqm-framework';

import { CreateLookalikeAudienceData, GeoPoliticalInfo } from 'models/LookalikeAudience';
import { COUNTRY_CODES } from 'components/consts';

import styles from './styles.module.scss';

interface Props {
  setCreateAudienceInfo: React.Dispatch<React.SetStateAction<Partial<CreateLookalikeAudienceData>>>;
  isEdit: boolean;
  geoPoliticalInfo?: GeoPoliticalInfo | null;
  isDuplicate: boolean;
}

type Options = {
  label: string;
  value: number;
};

type DropDownKeys = 'cities' | 'stateHouses' | 'congressionalDistricts' | 'counties' | 'senates';

type MultipleSelection = { [key in DropDownKeys]: Options[] | Options };

const GeoExpansionAndPoliticalInformationComponent = (props: Props) => {
  const { setCreateAudienceInfo, isEdit, geoPoliticalInfo, isDuplicate } = props;

  const [selectedCountry, setSelectedCountry] = useState<Options>();
  const [audienceSize, setAudienceSize] = useState<number>();
  const [selectedStates, setSelectedStates] = useState<Options[]>([]);
  const [dropDownValues, setDropDownValues] = useState<Partial<MultipleSelection>>({});

  const options = useMemo(
    () => [
      {
        topLabel: '1',
        bottomLabel: '10',
        value: 1,
      },
      {
        topLabel: '2',
        bottomLabel: '9',
        value: 2,
      },
      {
        topLabel: '3',
        bottomLabel: '8',
        value: 3,
      },
      {
        topLabel: '4',
        bottomLabel: '7',
        value: 4,
      },
      {
        topLabel: '5',
        bottomLabel: '6',
        value: 5,
      },
      {
        topLabel: '6',
        bottomLabel: '5',
        value: 6,
      },
      {
        topLabel: '7',
        bottomLabel: '4',
        value: 7,
      },
      {
        topLabel: '8',
        bottomLabel: '3',
        value: 8,
      },
      {
        topLabel: '9',
        bottomLabel: '2',
        value: 9,
      },
      {
        topLabel: '10',
        bottomLabel: '1',
        value: 10,
      },
    ],
    [],
  );

  const setValues = (field: string, values: Options[]) =>
    setDropDownValues((prevState) => ({
      ...prevState,
      [field]: isArray(values) ? values : [values],
    }));

  const setCountrySelection = useCallback(
    (selection: Options) => {
      if (selection?.value !== selectedCountry?.value) {
        setSelectedStates([]);
        setDropDownValues({});
        setSelectedCountry({ label: selection.label, value: selection.value });
      }
    },
    [selectedCountry],
  );

  const setStatesSelection = useCallback((selection: Options[]) => {
    setDropDownValues({});
    setSelectedStates(isArray(selection) ? selection : [selection]);
  }, []);

  const getCommaSeparatedValues = (array: Options[]) => {
    return array && array.length ? array.map((element) => element.value).join(',') : '';
  };

  const selectedStatesString = useMemo(() => {
    return selectedStates.length ? getCommaSeparatedValues(selectedStates) : '';
  }, [selectedStates]);

  useEffect(() => {
    setCreateAudienceInfo({
      countryIds: selectedCountry?.value.toString(),
      stateIds: selectedStatesString,
      cityIds: getCommaSeparatedValues(dropDownValues.cities as Options[]),
      congressionalDistrictIds: getCommaSeparatedValues(
        dropDownValues.congressionalDistricts as Options[],
      ),
      senateDistrictIds: getCommaSeparatedValues(dropDownValues.senates as Options[]),
      houseDistrictIds: getCommaSeparatedValues(dropDownValues.stateHouses as Options[]),
      countyIds: getCommaSeparatedValues(dropDownValues.counties as Options[]),
      audienceSize,
    });
  }, [dropDownValues, setCreateAudienceInfo, selectedStatesString, selectedCountry, audienceSize]);

  useEffect(() => {
    if (!isEdit) {
      setSelectedCountry({ label: COUNTRY_CODES.US.LABEL, value: COUNTRY_CODES.US.CODE });
    }
  }, [isEdit, setSelectedCountry]);

  const mapDropDownValues = (info: any) =>
    info.map((val: { name: string; id: number }) => ({
      label: val.name,
      value: val.id,
    }));

  useEffect(() => {
    if ((isEdit || isDuplicate) && geoPoliticalInfo) {
      if (isNumber(geoPoliticalInfo.audienceSize)) {
        setAudienceSize(geoPoliticalInfo.audienceSize);
      }
      setCountrySelection({
        label: geoPoliticalInfo.country[0].name,
        value: geoPoliticalInfo.country[0].id,
      });
      setStatesSelection(mapDropDownValues(geoPoliticalInfo.states));
      setDropDownValues((prevState) => ({
        ...prevState,
        ...(geoPoliticalInfo.cities && { cities: mapDropDownValues(geoPoliticalInfo.cities) }),
        ...(geoPoliticalInfo.stateHouses && {
          stateHouses: mapDropDownValues(geoPoliticalInfo.stateHouses),
        }),
        ...(geoPoliticalInfo.congressionalDistricts && {
          congressionalDistricts: mapDropDownValues(geoPoliticalInfo.congressionalDistricts),
        }),
        ...(geoPoliticalInfo.counties && {
          counties: mapDropDownValues(geoPoliticalInfo.counties),
        }),
        ...(geoPoliticalInfo.senates && { senates: mapDropDownValues(geoPoliticalInfo.senates) }),
      }));
    }
  }, [isEdit, geoPoliticalInfo, setCountrySelection, setStatesSelection, isDuplicate]);

  const getSelectionDetails = useCallback(
    (selectedFieldCount: number, field: DropDownKeys | 'states') => {
      const fieldValues =
        field === 'states' ? selectedStates : (dropDownValues[field] as Options[]);
      if (fieldValues && fieldValues.length) {
        return (
          <>
            <span className={styles.dropDownLabel}>{fieldValues[0].label}</span>
            {fieldValues.length > 1 && (
              <>
                <span className={styles.and}>{fieldValues.length > 1 && '& '}</span>
                <span className={styles.additionalValueCount}>
                  {`${selectedFieldCount - 1} more`}
                </span>
              </>
            )}
          </>
        );
      }
      return null;
    },
    [dropDownValues, selectedStates],
  );

  const apiParams = useMemo(() => {
    const urlParams = new URLSearchParams();
    if (selectedStatesString) {
      urlParams.append('stateIds', selectedStatesString);
    }
    return urlParams.toString();
  }, [selectedStatesString]);

  const transformRequestParams = (state: any) => ({
    pageNo: state.pageNo,
    noOfEntries: state.noOfEntries,
    searchField: state.searchField,
  });

  const mapResponseData = (response: any) => {
    const data = response.data || response.responseObject.data;
    return {
      data: data.map((item: any) => ({
        label: item.name,
        value: item.id,
      })),
      totalRecords: response.totalRecords || response.responseObject.totalRecords,
      filteredRecords: response.filteredRecords || response.responseObject.filteredRecords,
    };
  };

  const renderLazySelect = useCallback(
    (
      field: DropDownKeys | 'country' | 'states',
      onChangeFunction: any,
      apiPath: string,
      placeholder: string,
      label: string,
      selectedValues: Partial<Options[]>,
    ) => (
      <LazySelect
        key={field !== 'country' && field !== 'states' ? selectedStatesString : field}
        onChange={onChangeFunction}
        apiPath={apiPath}
        httpMethod="get"
        transformPagingParameters={transformRequestParams}
        mapServerResponseData={mapResponseData}
        value={selectedValues ?? []}
        totalRecordsPropName="totalRecords"
        numberOfEntries={50}
        selectSpecificProps={{
          placeholder,
          label,
          showLabelAlways: true,
          isReadOnly: field === 'country',
          isSearchable: field !== 'country',
          allSelectable: !isEdit && field !== 'country',
          isClearable: !isEdit && field !== 'country',
          isMulti: field !== 'country',
          className: styles.selectWrapper,
          multiPlaceholder: (selectedValuesCount: number) =>
            getSelectionDetails(selectedValuesCount, field as DropDownKeys | 'states'),
          isSearchClearable: true,
        }}
      />
    ),
    [selectedStatesString, getSelectionDetails, isEdit],
  );

  const getTooltipLabel = () => {
    return (
      <>
        <div className={styles.toolTipText}>
          Audience size is in inverse proportion to the similarity levels.
        </div>
        <div className={`mt-1 ${styles.toolTipText}`}>
          A highly similar and close to your source audience will have limited expansion in audience
          size.
        </div>
        <div className={`mt-1 ${styles.toolTipText}`}>
          The large audience size is achievable by considering the basic
          <br /> similarity level.
        </div>
      </>
    );
  };

  const renderProportionalSelect = useCallback(
    () => (
      <ProportionalSelect
        value={audienceSize}
        onChange={(option: { value: React.SetStateAction<number> }) =>
          setAudienceSize(option.value as number)
        }
        options={options}
        topLabel="Audience Size"
        bottomLabel="Similarity"
        isActive={(option: { value: number }, selectedOption: number) =>
          option.value === selectedOption
        }
        labelAfter
        gap={12}
        disabled={isEdit}
      />
    ),
    [setAudienceSize, options, audienceSize, isEdit],
  );

  const getRemainingSelection = (selectedValues: Options[]) =>
    selectedValues.map((value) => value.label).join(' ');

  const getSelectedValues = (selectedValues: Options[], field: string) => {
    if (selectedValues && selectedValues.length) {
      if (selectedValues.length === 1) {
        return <span className={styles.fieldValue}>{selectedValues[0].label} </span>;
      }
      if (selectedValues.length > 1) {
        return (
          <div className={styles.multipleValues}>
            <span className={styles.fieldValue}>{selectedValues[0].label}</span>
            &nbsp;
            <Tooltip
              className={styles.selectionTooltip}
              label={getRemainingSelection(selectedValues.slice(1))}
              auto={false}
              position={
                ['states', 'counties', 'congressionalDistricts'].includes(field) ? 'left' : 'right'
              }
            >
              {`& ${selectedValues.length - 1} more`}
            </Tooltip>
          </div>
        );
      }
    }
    return <span className={styles.fieldValue}>-</span>;
  };

  return (
    <>
      {isEdit ? (
        geoPoliticalInfo && (
          <>
            <div className={`${styles.accuracyContainer} row`}>
              <div className={`${styles.settingsTitle} col-6`}>Geo Expansion Settings</div>
              <div className={`${styles.allowedAccuracy} col-6`}>
                <span className={styles.allowedAccuracyEdit}>Audience Size & Similarity</span>
                <Tooltip
                  className={styles.toolTip}
                  label={getTooltipLabel()}
                  auto={false}
                  position="bottom"
                  labelMaxWidth={400}
                >
                  <Icon name="Info" className="ml-1" />
                </Tooltip>
              </div>
              <div className="col-6" />
              <div className={`${styles.proportionalSelect} col-6`}>
                {renderProportionalSelect()}
              </div>
            </div>
            <div className={`${styles.infoContainer} row`}>
              <div className="col-6">
                <div className={styles.infoContainerLeft}>
                  <div className={styles.topField}>
                    <span className={styles.field}>Country: </span>
                    <span className={styles.fieldValue}>{selectedCountry?.label} </span>
                  </div>
                  <div className={styles.bottomField}>
                    <span className={styles.field}>Cities: </span>
                    <span className={styles.fieldValue}>
                      {getSelectedValues(dropDownValues.cities as Options[], 'cities')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className={styles.infoContainerRight}>
                  <div className={styles.topField}>
                    <span className={styles.field}>States: </span>
                    <span className={styles.fieldValue}>
                      {getSelectedValues(selectedStates, 'states')}{' '}
                    </span>
                  </div>
                  <div className={styles.bottomField}>
                    <span className={styles.field}>Counties: </span>
                    <span className={styles.fieldValue}>
                      {getSelectedValues(dropDownValues.counties as Options[], 'counties')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={`${styles.sectionTitle} col-12`}>Political Information</div>
            </div>
            <div className={`${styles.infoContainer} row`}>
              <div className="col-6">
                <div className={styles.infoContainerLeft}>
                  <div className={styles.topField}>
                    <span className={styles.field}>Senates: </span>
                    <span className={styles.fieldValue}>
                      {getSelectedValues(dropDownValues.senates as Options[], 'senates')}
                    </span>
                  </div>
                  <div className={styles.bottomField}>
                    <span className={styles.field}>State Houses: </span>
                    <span className={styles.fieldValue}>
                      {getSelectedValues(dropDownValues.stateHouses as Options[], 'stateHouses')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className={styles.infoContainerRight}>
                  <div className={styles.topField}>
                    <span className={styles.field}>Congressional Districts: </span>
                    <span className={styles.fieldValue}>
                      {getSelectedValues(
                        dropDownValues.congressionalDistricts as Options[],
                        'congressionalDistricts',
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      ) : (
        <>
          <div className={`${styles.accuracyContainer} row`}>
            <div className={`${styles.settingsTitle} col-6`}>Geo Expansion Settings</div>
            <div className={`${styles.allowedAccuracy} col-6`}>
              <span className={styles.allowedAccuracyText}>Audience Size & Similarity</span>
              <Tooltip
                className={styles.toolTip}
                label={getTooltipLabel()}
                auto={false}
                position="bottom"
                labelMaxWidth={400}
              >
                <Icon name="Info" className="ml-1" />
              </Tooltip>
            </div>
            <div className="col-6" />
            <div className={`${styles.proportionalSelect} col-6`}>{renderProportionalSelect()}</div>
          </div>
          <div className="row">
            <div className="col-6">
              {renderLazySelect(
                'country',
                setCountrySelection,
                `api/v2/master/segment/country?`,
                'Select Country',
                'Country',
                selectedCountry ? [selectedCountry] : [],
              )}
            </div>
            <div className="col-6">
              {renderLazySelect(
                'states',
                setStatesSelection,
                `api/v2/master/segment/states?countryIds=${selectedCountry?.value ||
                  COUNTRY_CODES.US.CODE}`,
                'Select States',
                'States',
                selectedStates,
              )}
            </div>
          </div>
          {(selectedStates.length > 0 || isEdit) && (
            <>
              <div className="row">
                <div className="col-6">
                  {renderLazySelect(
                    'cities',
                    (selectedCities: Options[]) => setValues('cities', selectedCities),
                    `api/v2/master/segment/city?${apiParams}`,
                    'Select Cities',
                    'Cities',
                    dropDownValues.cities as Options[],
                  )}
                </div>
                <div className="col-6">
                  {renderLazySelect(
                    'counties',
                    (selectedCounties: Options[]) => setValues('counties', selectedCounties),
                    `api/v2/master/segment/county?${apiParams}`,
                    'Select Counties',
                    'Counties',
                    dropDownValues.counties as Options[],
                  )}
                </div>
              </div>
              <div className="row">
                <div className={`${styles.titleText} col-12`}>Political Information</div>
              </div>
              <div className="row">
                <div className="col-6">
                  {renderLazySelect(
                    'senates',
                    (selectedSenates: Options[]) => setValues('senates', selectedSenates),
                    `api/v2/master/segment/senate-district?${apiParams}`,
                    'Select Senates',
                    'Senates',
                    dropDownValues.senates as Options[],
                  )}
                </div>
                <div className="col-6">
                  {renderLazySelect(
                    'congressionalDistricts',
                    (selectedDistricts: Options[]) =>
                      setValues('congressionalDistricts', selectedDistricts),
                    `api/v2/master/segment/congressional-district?${apiParams}`,
                    'Select Congressional Districts',
                    'Congressional Districts',
                    dropDownValues.congressionalDistricts as Options[],
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  {renderLazySelect(
                    'stateHouses',
                    (selectedStateHouses: Options[]) =>
                      setValues('stateHouses', selectedStateHouses),
                    `api/v2/master/segment/house-district?${apiParams}`,
                    'Select State Houses',
                    'State Houses',
                    dropDownValues.stateHouses as Options[],
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapState = (state: AppState) => ({
  geoPoliticalInfo: state.lookalikeAudience.geoPoliticalInfo,
});

const GeoExpansionAndPoliticalInformation = connect(
  mapState,
  null,
)(GeoExpansionAndPoliticalInformationComponent);

export default GeoExpansionAndPoliticalInformation;
