import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'factor';

import { TagsInput } from 'components/TagsInput';
import { applicationActions, ResetSidebar } from 'store/app/actions';
import { retargetedAudienceActions, SendEmailInstruction } from 'store/retargetedAudience/actions';
import { EmailInstructionRequestData } from 'models/RetargetedAudience';
import PixelScriptCode from 'components/PixelScriptCode';
import { EMAIL_REGEX } from 'constants/RegExp';

import styles from './styles.module.scss';

interface Props extends ResetSidebar, SendEmailInstruction {
  tagScript: string;
  resetRetargetedAudience: () => void;
}

const RetargetedAudienceTagIntegrationComponent = (props: Props) => {
  const { tagScript, sendEmailInstruction } = props;
  const [recipients, setRecipients] = useState<string[]>([]);

  const handleChangeRecipients = (value: string[]) => {
    setRecipients(value);
  };

  const handleSendEmail = () => {
    const requestData: EmailInstructionRequestData = {
      pixelCode: tagScript,
      recipients: recipients.join(),
    };
    sendEmailInstruction(requestData);
  };

  return (
    <>
      <div className={styles.info}>Email the Pixel Code to integrate the tag</div>
      <div className={styles.emailsForm}>
        <TagsInput
          initialItems={recipients}
          onChange={handleChangeRecipients}
          validateRegex={EMAIL_REGEX}
          placeholder="Add recipients email addresses"
          className={styles.emailsInput}
        />
        <Button onClick={handleSendEmail} disabled={!recipients.length} variant="bold-link">
          Send
        </Button>
      </div>
      <hr className={styles.divider} />
      <div className={styles.steps}>
        <h4>Follow the below steps to integrate the tag</h4>
        <ul>
          <li> 1. Copy the pixel script code.</li>
          <li>
            2. Find the header of your website, or locate the header template in your CMS or web
            platform.
          </li>
          <li>
            3. Paste the base code at the bottom of the header section, just above the closing head
            tag.
          </li>
        </ul>
      </div>
      <div className={styles.codeWrapper}>
        <PixelScriptCode tagScript={tagScript} />
        <div className={styles.exampleBlock}>
          <div className={styles.dark}>{'<!--Example -->'}</div>
          <div className={styles.grey}>{'<!DOCTYPE html>'}</div>
          <div className={styles.grey}>{'<html lang=en>'}</div>
          <div className={`${styles.dark} ml-2`}>{'<head>'}</div>
          <div className={`${styles.grey} ml-4`}>{'<script>...</script>'}</div>
          <div className={`${styles.blue} ml-4`}>Insert_pixel_code_here</div>
          <div className={`${styles.dark} ml-2`}>{'</head>'}</div>
        </div>
      </div>
    </>
  );
};

const mapAction = {
  resetSidebar: applicationActions.resetSidebar,
  resetRetargetedAudience: retargetedAudienceActions.reset,
  sendEmailInstruction: retargetedAudienceActions.sendEmailInstruction,
};

export const RetargetedAudienceTagIntegration = connect(
  null,
  mapAction,
)(RetargetedAudienceTagIntegrationComponent);
