import React from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { ICloudProviderProps } from 'models/Cloud';
import { ICloudFile } from 'api/Cloud';
import { getOwId } from 'iqm-framework';
import { connect } from 'react-redux';
import { AppState } from 'store';
import GoogleDriveIcon from './GoogleDrive.svg';
import { authorize, getCredentials } from './google';
import styles from './styles.module.scss';

const mimeTypesMap = new Map([
  ['.csv', 'text/csv'],
  ['.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
]);

const GoogleDriveUploader = (props: ICloudProviderProps & { user: AppState['auth']['user'] }) => {
  const { acceptFormats, handleCloudFileUpload, select, user } = props;
  const acceptMimeTypes = acceptFormats
    .split(', ')
    .map((format: any) => mimeTypesMap.get(format))
    .join(', ');
  const [openPicker] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view

  const { apiToken } = user!;

  const getToken = async () => {
    try {
      const auth = await authorize(['https://www.googleapis.com/auth/drive.file'], {
        owId: getOwId(),
        apiToken,
      });
      if (!auth.scopes.includes('https://www.googleapis.com/auth/drive.file')) return null;
      return auth.access_token;
    } catch (e) {
      if (e instanceof Object && 'type' in e && 'message' in e) {
        if (e.type === 'popup_failed_to_open' || e.type === 'popup_closed') {
          return null;
        }
      }
      throw e;
    }
  };

  const handleOpenPicker = async () => {
    const credentialsPromise = getCredentials({ owId: getOwId(), apiToken });
    const tokenPromise = getToken();
    const credentials = await credentialsPromise;
    const token = await tokenPromise;

    if (!credentials) return;
    if (!token) return;

    openPicker({
      appId: credentials.client_id.split('-')[0],
      clientId: credentials.client_id,
      developerKey: credentials.developer_key,
      viewId: 'DOCS',
      token,
      supportDrives: true,
      setIncludeFolders: true,
      multiselect: true,
      viewMimeTypes: acceptMimeTypes,
      callbackFunction: (data) => {
        if (data.action === 'picked') {
          const selectedFiles: Array<ICloudFile> = [];
          data.docs.forEach((file) => {
            selectedFiles.push({
              name: file.name,
              url: `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
              type: file.mimeType,
              token,
            });
          });
          handleCloudFileUpload(selectedFiles[0]);
        }
      },
    });
  };

  return (
    <button type="button" className={styles.button} onClick={handleOpenPicker}>
      <img src={GoogleDriveIcon} alt="Google Drive Logo" style={{ height: 20 }} />
      {select && 'Google Drive'}
    </button>
  );
};

export default connect((state: AppState) => ({ user: state.auth.user }))(GoogleDriveUploader);
