import React, { useState, useMemo } from 'react';
import { isNumber } from 'lodash';
import { connect } from 'react-redux';
import { Dialog, SVG_HOST_URL } from 'factor';

import {
  CAMPAIGN_AUDIENCE_TYPE_ID,
  CONTEXTUAL_AUDIENCE_TYPE_ID,
  GEOFARMED_AUDIENCE_TYPE_ID,
  LOOKALIKE_AUDIENCE_TYPE_ID,
  MATCHED_AUDIENCE_TYPE_ID,
  PRE_BID_AUDIENCE_TYPE_ID,
  RETARGETED_AUDIENCE_TYPE_ID,
  SEGMENTED_AUDIENCE_TYPE_ID,
} from 'constants/audiences';
import { DESCRIPTION_TEXTS } from 'components/consts';
import { TDialog } from 'store/app/reducer';
import { applicationActions, OpenDialog } from 'store/app/actions';

import styles from './styles.module.scss';

interface Props extends OpenDialog {
  handleClose: () => void;
  dialog: TDialog;
}

const CommonCreateAudienceDialogComponent = (props: Props) => {
  const { handleClose, dialog, openDialog } = props;
  const [selectedAudienceTypeId, selectAudienceTypeId] = useState<number>();

  const audienceCardsInfo = useMemo(
    () => [
      {
        audienceTypeId: MATCHED_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/matched-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.MATCHED_AUDIENCE,
        title: 'Matched Audience',
      },
      {
        audienceTypeId: SEGMENTED_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/segmented-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.SEGMENTED_AUDIENCE,
        title: 'Segmented Audience',
      },
      {
        audienceTypeId: RETARGETED_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/retargeted-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.RETARGETED_AUDIENCE,
        title: 'Retargeted Audience',
      },
      {
        audienceTypeId: GEOFARMED_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/geofarmed-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.GEOFARMED_AUDIENCE,
        title: 'Geofarmed Audience',
      },
      {
        audienceTypeId: CONTEXTUAL_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/contextual-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.CONTEXTUAL_AUDIENCE,
        title: 'Contexual Audience',
      },
      {
        audienceTypeId: LOOKALIKE_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/lookalike-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.LOOKALIKE_AUDIENCE,
        title: 'Lookalike Audience',
      },
      {
        audienceTypeId: CAMPAIGN_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/campaign-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.CAMPAIGN_AUDIENCE,
        title: 'Campaign Audience',
      },
      {
        audienceTypeId: PRE_BID_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/pre-bid-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.PRE_BID_AUDIENCE,
        title: 'Pre-bid Audience',
      },
    ],
    [],
  );

  const actionButtons = useMemo(
    () => [
      {
        variant: 'secondary',
        title: 'Cancel',
        handler: handleClose,
      },
      {
        title: 'Create',
        handler: () =>
          openDialog({ audienceTypeId: selectedAudienceTypeId as number, type: 'create' }),
        disabled: !isNumber(selectedAudienceTypeId),
      },
    ],
    [handleClose, openDialog, selectedAudienceTypeId],
  );

  return (
    <Dialog
      dialogTitle="Create Audience"
      open={dialog}
      crossButton
      headerFooterBorders
      className={styles.dialog}
      onCrossButtonClick={handleClose}
      actionButtons={actionButtons}
    >
      <div className={styles.selectText}>
        Select any one type of audience which you want to create
      </div>
      <div className={styles.cardWrapper}>
        {audienceCardsInfo.map((card) => (
          <div
            className={`${
              selectedAudienceTypeId === card.audienceTypeId
                ? styles.selectedCard
                : styles.normalCard
            }`}
            key={card.audienceTypeId}
            onClick={() => selectAudienceTypeId(card.audienceTypeId)}
          >
            <div className={styles.image}>
              <img src={card.imagePath} alt="" />
            </div>
            <div className={styles.title}>{card.title}</div>
            <div className={styles.info}>{card.infoText}</div>
          </div>
        ))}
      </div>
    </Dialog>
  );
};

const mapAction = {
  openDialog: applicationActions.openDialog,
};

export const CommonCreateAudienceDialog = connect(
  null,
  mapAction,
)(CommonCreateAudienceDialogComponent);
