export const Logger = {
  log: (message: string, params?: any) => {
    // TODO: Maybe we will show some errors in future
    // eslint-disable-next-line no-console
    console.log(message, params);
  },
};

export const logWarningXSS = () => {
  const styleHeading = 'font-size: 38px; color: red; text-shadow: 1px 1px 1px black;';
  const styleText =
    'font-size: 30px; color: red; text-shadow: 1px 1px 1px black; background: yellow;';
  /* eslint-disable-next-line */
  console.log(
    `%c⚠️ WARNING ⚠️\n` +
      `%cPlease do not copy and paste anything here; ` +
      `you might become a victim of a hacker attack. ` +
      `Learn more about Self-XSS: https://en.wikipedia.org/wiki/Self-XSS`,
    styleHeading,
    styleText,
  );
};
