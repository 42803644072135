import { enqueueSnackbar } from '@applift/factor';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createPreBidAudience,
  editPreBidAudience,
  fetchPreBidAudienceInfo,
} from 'api/PreBidAudience';
import { getPreBidAudienceInfoQueryKey } from 'api/queryKey';
import { WithResponse } from '../models/Response';

const onError = (e: WithResponse) => {
  enqueueSnackbar(e.errorObjects ? (e.errorObjects[0]?.error as string) : 'Something went wrong!', {
    variant: 'error',
  });
};

export const usePreBidAudienceInfo = (
  audienceId: number,
  options?: {
    enabled?: boolean;
  },
) => {
  const data = useQuery(
    getPreBidAudienceInfoQueryKey.keys({
      scope: 'fetchPreBidProviders',
      audienceId,
    }),
    fetchPreBidAudienceInfo,
    {
      onError,
      enabled: options?.enabled,
      cacheTime: 0,
    },
  );
  return data;
};

export const useCreatePreBidAudience = (options: { onSuccess: (data: any) => void }) => {
  const createPreBidAudienceMutation = useMutation(createPreBidAudience, {
    mutationKey: ['createPreBidAudience'],
    onSuccess: options.onSuccess,
    onError,
  });
  return createPreBidAudienceMutation;
};

export const useEditPreBidAudience = (options: { onSuccess: (data: any) => void }) => {
  const editPreBidAudienceMutation = useMutation(editPreBidAudience, {
    mutationKey: ['editPreBidAudience'],
    onSuccess: options.onSuccess,
    onError,
  });
  return editPreBidAudienceMutation;
};
