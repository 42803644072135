import axios, { AxiosInstance } from 'axios';
import { ORIGIN_URL, getOwId, getPathNumber } from 'iqm-framework';
import { attachInstance } from '@applift/insight-app';
import { attachInstance as attachAppliftPlatformInstance } from '@applift/platform';
import get from 'lodash/get';

import { localStorageService } from '../services/localStorage';

export type Params = { [key: string]: string | number };

let IQMInstance: AxiosInstance;

const getLatency = (response: any, responseTimestamp: number) => {
  const requestTimestamp =
    get(response, 'config.meta.requestTimestamp') ||
    get(response, 'response.config.meta.requestTimestamp');
  return requestTimestamp ? responseTimestamp - requestTimestamp : null;
};

export function createIqmInstance(token: string, shouldAddCustomHeader: boolean = false) {
  const instance = axios.create({
    baseURL: `${ORIGIN_URL}/api`,
    headers: {
      authorization: `Bearer ${token}`,
      'x-iaa-ow-id': getOwId(),
      ...(shouldAddCustomHeader
        ? { 'X-IAA-REFERER': `${window.location.origin}/customers/u/${getPathNumber()}` }
        : {}),
    },
  });

  instance.interceptors.request.use((request: any) => {
    request.meta = {
      ...(request.meta || {}),
      requestTimestamp: Date.now(),
    };
    return request;
  });

  instance.interceptors.response.use(
    (response: any) => {
      if (response.data && typeof response.data === 'object') {
        response.data.latency = getLatency(response, Date.now());
      }
      return response;
    },
    (response: any) => {
      if (axios.isCancel(response)) {
        const res = {
          response: {
            data: response,
          },
        };

        throw res;
      }
      if (response.response?.status === 401) {
        localStorageService.removeBaseInfo();
        window.location.reload();
      } else if (response.response?.status === 403) {
        window.location.href = '/#/404';
      }

      if (response.response?.data && typeof response.response?.data === 'object') {
        response.response.data.latency = getLatency(response, Date.now());
      }
      return Promise.reject(response);
    },
  );

  IQMInstance = instance;
  // @ts-ignore
  attachInstance(instance);
  // @ts-ignore
  attachAppliftPlatformInstance(instance);
}

export function getInstance(): AxiosInstance {
  return IQMInstance || axios;
}
