import * as React from 'react';
import { Box, Tooltip, Typography } from '@applift/factor';
import { SemanticWarning } from '@applift/icons';
import { NumberFormatter } from 'factor';

import { TableAudienceItem } from 'models/Table';
import { CurrencyFormat } from 'utils/format';
import {
  AUDIENCE_STATUSES,
  PRE_BID_AUDIENCE_TYPE_ID,
  SEGMENTED_AUDIENCE_TYPE_ID,
} from 'constants/audiences';

import styles from './styles.module.scss';

export const getDataCostCell = (data: TableAudienceItem) => {
  if (data.audienceTypeId === PRE_BID_AUDIENCE_TYPE_ID && !data.dataCost) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'between', alignItems: 'center' }}>
        <Box sx={{ marginRight: 'auto' }}>
          <Tooltip
            title="Total cost couldn't be calculated as added segments are from different creative types."
            arrow
            placement="top"
          >
            <SemanticWarning color="warning" fontSize={24} />
          </Tooltip>
        </Box>
        <Box>
          <Typography weight="demi">—</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'between', alignItems: 'center' }}>
      <Box sx={{ visibility: 'hidden' }} />
      <Box>
        {typeof data.dataCost === 'number' && data.status === AUDIENCE_STATUSES.READY
          ? CurrencyFormat.format(data.dataCost)
          : '—'}
      </Box>
    </Box>
  );
};

export const getReachCell = (data: TableAudienceItem) => {
  if (
    data.audienceTypeId === SEGMENTED_AUDIENCE_TYPE_ID &&
    data.status?.toLowerCase() === 'processing'
  ) {
    return '—';
  }

  return data.uniques !== undefined ? <NumberFormatter>{data.uniques}</NumberFormatter> : '—';
};

export const getAllBodyMapper = () => ({
  cost: {
    key: getDataCostCell,
    className: `_right ${styles.boldtext} _dataCost`,
  },
  uniques: {
    key: getReachCell,
    className: `_right ${styles.boldtext}`,
  },
});
