import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { isNumber } from 'lodash';
import { SVG_HOST_URL } from 'factor';
import { AppState } from 'store';
import { AudienceTypeId } from 'models/Table';
import {
  SetAudienceTypeIds,
  tableActions,
  SetAudiencesByType,
  SetAudiencesStatuses,
} from 'store/table/actions';
import {
  AudiencesTable,
  AudiencesHeader,
  AudiencesSearch,
  AudiencesStatus,
  TableSelectedActions,
} from 'components/Audiences';
import {
  TDialog,
  DialogType,
  RetargetedDialogType,
  MatchedDialogType,
  SegmentedDialogType,
  LookalikeDialogType,
  ContextualDialogType,
  CampaignDialogType,
  GeofarmedDialogType,
  AddToCampaignDialogType,
  InsightActionsDialogType,
  PreBidDialogType,
} from 'store/app/reducer';
import {
  CAMPAIGN_AUDIENCE_TYPE_ID,
  CONTEXTUAL_AUDIENCE_TYPE_ID,
  MATCHED_AUDIENCE_TYPE_ID,
  RETARGETED_AUDIENCE_TYPE_ID,
  LOOKALIKE_AUDIENCE_TYPE_ID,
  SEGMENTED_AUDIENCE_TYPE_ID,
  GEOFARMED_AUDIENCE_TYPE_ID,
  PRE_BID_AUDIENCE_TYPE_ID,
} from 'constants/audiences';
import { CommonCreateAudienceDialog } from 'components/Audiences/AllAudience/CommonAudienceCreation/CommonCreateAudienceDialog';
import { routes } from 'routes';
import { AllAudienceBlankPage } from 'pages/BlankPage/AllAudienceBlankPage';
import { AudienceBlankPage } from 'pages/BlankPage/AudienceBlankPage';
import AddToCampaignDialog from 'components/Audiences/AddToCampaignDialog';
import { applicationActions, OpenDialog } from 'store/app/actions';
import CreateMatchedAudience from 'components/Audiences/MatchedAudience/CreateMatchedAudience';
import { CreateOrEditSegmentedAudience } from 'components/Audiences/SegmentedAudience/CreateOrEditSegmentedAudience';
import CreateRetargetedAudience from 'components/Audiences/RetargetedAudience/CreateRetargetedAudience';
import CreateLookalikeAudience from 'components/Audiences/LookalikeAudience/CreateLookalikeAudience';
import CreateGeofarmedAudience from 'components/Audiences/GeofarmedAudience/CreateGeofarmedAudience';
import CreateContextualAudience from 'components/Audiences/ContextualAudience/CreateContextualAudience';
import CreateCampaignAudience from 'components/Audiences/CampaignAudience/CreateCampaignAudience';
import { CreatePreBidAudience } from 'components/Audiences/PreBidAudience/CreatePreBidAudience/index';
import EditMatchedAudience from 'components/Audiences/MatchedAudience/EditMatchedAudience';
import EditRetargetedAudience from 'components/Audiences/RetargetedAudience/EditRetargetedAudience';
import EditLookalikeAudience from 'components/Audiences/LookalikeAudience/EditLookalikeAudience';
import EditGeofarmedAudience from 'components/Audiences/GeofarmedAudience/EditGeofarmedAudience';
import EditContextualAudience from 'components/Audiences/ContextualAudience/EditContextualAudience';
import EditCampaignAudience from 'components/Audiences/CampaignAudience/EditCampaignAudience';
import { EditPreBidAudience } from 'components/Audiences/PreBidAudience/EditPreBidAudience/index';
import ViewSegmentedAudience from 'components/Audiences/SegmentedAudience/ViewSegmentedAudience';
import ViewRetargetedAudience from 'components/Audiences/RetargetedAudience/ViewRetargetedAudience';
import ViewMatchedAudience from 'components/Audiences/MatchedAudience/ViewMatchedAudience';
import ViewLookalikeAudience from 'components/Audiences/LookalikeAudience/ViewLookalikeAudience';
import ViewGeofarmedAudience from 'components/Audiences/GeofarmedAudience/ViewGeofarmedAudience';
import ViewContextualAudience from 'components/Audiences/ContextualAudience/ViewContextualAudience';
import ViewCampaignAudience from 'components/Audiences/CampaignAudience/ViewCampaignAudience';
import { ViewPreBidAudience } from 'components/Audiences/PreBidAudience/ViewPreBidAudience/index';
import DuplicateContextualAudience from 'components/Audiences/ContextualAudience/DuplicateContextualAudience';
import DuplicateCampaignAudience from 'components/Audiences/CampaignAudience/DuplicateCampaignAudience';
import DuplicateGeofarmedAudience from 'components/Audiences/GeofarmedAudience/DuplicateGeofarmedAudience';
import DuplicateRetargetedAudience from 'components/Audiences/RetargetedAudience/DuplicateRetargetedAudience';
import DuplicateMatchedAudience from 'components/Audiences/MatchedAudience/DuplicateMatchedAudience';
import DuplicateLookalikeAudience from 'components/Audiences/LookalikeAudience/DuplicateLookalikeAudience';
import { DuplicatePreBidAudience } from 'components/Audiences/PreBidAudience/DuplicatePreBidAudience/index';
import TagRetargetedAudience from 'components/Audiences/RetargetedAudience/TagRetargetedAudience';
import { DESCRIPTION_TEXTS } from 'components/consts';
import { GenerateInsight } from 'components/InsightDialogs/GenerateInsight/index';
import { DownloadInsight } from 'components/InsightDialogs/DownloadInsight/index';
import { EmailInsight } from 'components/InsightDialogs/EmailInsight/index';
import { AudienceType } from 'api/Table';

import styles from './styles.module.scss';

interface Props extends SetAudienceTypeIds, OpenDialog, SetAudiencesByType, SetAudiencesStatuses {
  dialog: TDialog;
  audienceTypeIds: AudienceTypeId;
  audiencesByType: AudienceType[];
  isLoading: boolean;
  matchedAudienceSubmitInProgress: boolean;
  retargetedAudienceSubmitInProgress: boolean;
  lookalikeAudienceSubmitInProgress: boolean;
  campaignAudienceSubmitInProgress: boolean;
  segmentedAudienceSubmitInProgress: boolean;
  geofarmedAudienceSubmitInProgress: boolean;
  contextualAudienceSubmitInProgress: boolean;
}

const AudiencesComponent = (props: Props) => {
  const {
    dialog,
    setAudienceTypeIds,
    openDialog,
    audienceTypeIds,
    setAudiencesByType,
    setAudiencesStatuses,
    audiencesByType,
    isLoading,
  } = props;

  const history = useHistory();

  const setAudienceTypeIdByPathname = useCallback(() => {
    const newAudienceTypeId = routes.find(({ path }) => path === history.location.pathname)?.value;
    if (newAudienceTypeId && newAudienceTypeId !== audienceTypeIds) {
      setAudienceTypeIds(newAudienceTypeId);
    }
  }, [history.location.pathname, setAudienceTypeIds, audienceTypeIds]);

  useEffect(() => {
    setAudienceTypeIdByPathname();
  }, [setAudienceTypeIdByPathname]);

  const closeCreateAudiencePopup = useCallback(() => {
    openDialog({});
  }, [openDialog]);

  const matchedAudienceDialogs: { [dialogType in MatchedDialogType]: JSX.Element } = {
    create: <CreateMatchedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    edit: <EditMatchedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    view: <ViewMatchedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    duplicate: <DuplicateMatchedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
  };

  const segmentededAudienceDialogs: { [dialogType in SegmentedDialogType]: JSX.Element } = {
    create: (
      <CreateOrEditSegmentedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />
    ),
    edit: <CreateOrEditSegmentedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    view: <ViewSegmentedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    duplicate: (
      <CreateOrEditSegmentedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />
    ),
  };

  const retargetedAudienceDialogs: { [dialogType in RetargetedDialogType]: JSX.Element } = {
    create: <CreateRetargetedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    edit: <EditRetargetedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    view: <ViewRetargetedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    duplicate: (
      <DuplicateRetargetedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />
    ),
    tag: <TagRetargetedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
  };

  const lookalikeAudienceDialogs: { [dialogType in LookalikeDialogType]: JSX.Element } = {
    create: <CreateLookalikeAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    edit: <EditLookalikeAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    view: <ViewLookalikeAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    duplicate: (
      <DuplicateLookalikeAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />
    ),
  };

  const contextualAudienceDialogs: { [dialogType in ContextualDialogType]: JSX.Element } = {
    create: <CreateContextualAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    edit: <EditContextualAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    view: <ViewContextualAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    duplicate: (
      <DuplicateContextualAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />
    ),
  };

  const campaignAudienceDialogs: { [dialogType in ContextualDialogType]: JSX.Element } = {
    create: <CreateCampaignAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    edit: <EditCampaignAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    view: <ViewCampaignAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    duplicate: <DuplicateCampaignAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
  };

  const geofarmedAudienceDialogs: { [dialogType in GeofarmedDialogType]: JSX.Element } = {
    create: <CreateGeofarmedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    view: <ViewGeofarmedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    edit: <EditGeofarmedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    duplicate: (
      <DuplicateGeofarmedAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />
    ),
  };

  const preBidAudienceDialogs: { [dialogType in PreBidDialogType]: JSX.Element } = {
    create: <CreatePreBidAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    view: <ViewPreBidAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    edit: <EditPreBidAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
    duplicate: <DuplicatePreBidAudience handleClose={closeCreateAudiencePopup} dialog={dialog} />,
  };

  const addToCampaignDialog: { [dialogType in AddToCampaignDialogType]: JSX.Element } = {
    addToCampaign: <AddToCampaignDialog handleClose={closeCreateAudiencePopup} dialog={dialog} />,
  };

  const renderDialog = (audienceTypeId?: number, dialogType?: DialogType, otherInfo?: any) => {
    if (dialogType === 'addToCampaign') {
      return createPortal(
        addToCampaignDialog[dialogType as AddToCampaignDialogType],
        document.body,
      );
    }
    if (dialogType === 'insights') {
      switch (otherInfo.action as InsightActionsDialogType) {
        case 'generate':
          return (
            <GenerateInsight
              closeDialog={closeCreateAudiencePopup}
              generateData={otherInfo.generateData}
            />
          );
        case 'email':
          return (
            <EmailInsight
              closeDialog={closeCreateAudiencePopup}
              audienceData={otherInfo.audienceData}
              fileType={otherInfo.fileType}
            />
          );
        case 'download':
          return (
            <DownloadInsight
              closeDialog={closeCreateAudiencePopup}
              audienceData={otherInfo.audienceData}
            />
          );
        default:
          return null;
      }
    }

    if (isNumber(audienceTypeId) && dialogType) {
      switch (audienceTypeId) {
        case 0:
          return createPortal(
            <CommonCreateAudienceDialog handleClose={closeCreateAudiencePopup} dialog={dialog} />,
            document.body,
          );
        case MATCHED_AUDIENCE_TYPE_ID:
          return createPortal(
            matchedAudienceDialogs[dialogType as MatchedDialogType],
            document.body,
          );
        case SEGMENTED_AUDIENCE_TYPE_ID:
          return createPortal(
            segmentededAudienceDialogs[dialogType as SegmentedDialogType],
            document.body,
          );
        case RETARGETED_AUDIENCE_TYPE_ID:
          return createPortal(
            retargetedAudienceDialogs[dialogType as RetargetedDialogType],
            document.body,
          );
        case LOOKALIKE_AUDIENCE_TYPE_ID:
          return createPortal(
            lookalikeAudienceDialogs[dialogType as LookalikeDialogType],
            document.body,
          );
        case CONTEXTUAL_AUDIENCE_TYPE_ID:
          return createPortal(
            contextualAudienceDialogs[dialogType as ContextualDialogType],
            document.body,
          );
        case CAMPAIGN_AUDIENCE_TYPE_ID:
          return createPortal(
            campaignAudienceDialogs[dialogType as CampaignDialogType],
            document.body,
          );
        case GEOFARMED_AUDIENCE_TYPE_ID:
          return createPortal(
            geofarmedAudienceDialogs[dialogType as GeofarmedDialogType],
            document.body,
          );
        case PRE_BID_AUDIENCE_TYPE_ID:
          return createPortal(
            preBidAudienceDialogs[dialogType as GeofarmedDialogType],
            document.body,
          );
        default:
          return null;
      }
    }
    return null;
  };

  const controls = useCallback(
    (tableComponentInstanceR: any) => (
      <>
        <TableSelectedActions
          className="mr-auto"
          tableComponentInstance={tableComponentInstanceR}
        />
        <div className={styles.statusAndSearchContainer}>
          <AudiencesSearch />
          <AudiencesStatus />
        </div>
      </>
    ),
    [],
  );

  const audienceCardsInfo = useMemo(
    () => [
      {
        audienceTypeId: MATCHED_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/matched-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.MATCHED_AUDIENCE,
        title: 'Matched Audience',
        handler: () =>
          openDialog({ audienceTypeId: MATCHED_AUDIENCE_TYPE_ID as number, type: 'create' }),
        subItem: [
          {
            iconName: 'FileUpload',
            title: 'Upload List',
            description:
              'Upload a voter list or CRM file containing First Name, Last Name, Zip Code, and State.',
          },
          {
            iconName: 'TableCell',
            title: 'Match fields',
            description: 'Match each column with the respective fields. Complete the upload.',
          },
          {
            iconName: 'UserTarget',
            title: 'Reach Audience',
            description:
              'Get the match rates and a list of unique target audience for each file you upload, and start reaching out to them.',
          },
        ],
      },
      {
        audienceTypeId: SEGMENTED_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/segmented-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.SEGMENTED_AUDIENCE,
        title: 'Segmented Audience',
        handler: () =>
          openDialog({ audienceTypeId: SEGMENTED_AUDIENCE_TYPE_ID as number, type: 'create' }),
        subItem: [
          {
            iconName: 'Segment',
            title: 'Explore Segments',
            description:
              'Explore the pre-created audience segments made for the political and issue advocacy domains.',
          },
          {
            iconName: 'MultiPeople',
            title: 'Select Segments',
            description:
              'Select the segments that best fit your requirements. You can also create custom audience segments from thousands of audience attributes.',
          },
          {
            iconName: 'UserTarget',
            title: 'Reach Audience',
            description:
              'Get the lists of the target audience by individual segments, or group them using simple AND/OR functions. Start reaching out to this curated audience.',
          },
        ],
      },
      {
        audienceTypeId: RETARGETED_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/retargeted-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.RETARGETED_AUDIENCE,
        title: 'Retargeted Audience',
        handler: () =>
          openDialog({ audienceTypeId: RETARGETED_AUDIENCE_TYPE_ID as number, type: 'create' }),
        subItem: [
          {
            iconName: 'Code',
            title: 'Generate Pixel',
            description:
              'Generate retargeting pixel by specifying audience name and the time period. Copy the pixel script code generated.',
          },
          {
            iconName: 'Website',
            title: 'Integrate Pixel',
            description:
              'Paste the pixel script code into the header of your website or platform, just above the closing </head> tag. Paste the pixel code into every single page, or into your header template if you’re using one.',
          },
          {
            iconName: 'WebsiteClick',
            title: 'Retarget Visitors',
            description:
              'Re-engage with the people who have seen or interacted with your pixel by clicking on your link or opening your webpage, throughout their online journey.',
          },
        ],
      },
      {
        audienceTypeId: LOOKALIKE_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/lookalike-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.LOOKALIKE_AUDIENCE,
        title: 'Lookalike Audience',
        handler: () =>
          openDialog({ audienceTypeId: LOOKALIKE_AUDIENCE_TYPE_ID as number, type: 'create' }),
        subItem: [
          {
            iconName: 'PickUsers',
            title: 'Select Source',
            description: 'Select your ideal audience that you would like to create looalike for.',
          },
          {
            iconName: 'Equilizer',
            title: 'Specify Parameters',
            description:
              'Get the match rate and unique counts. Specify the level of similarity and audience size. Note that similarity and audience size are inversly proportional.',
          },
          {
            iconName: 'UserTarget',
            title: 'Reach Audience',
            description: 'Get the list of unique target audience, and start reaching out to them.',
          },
        ],
      },
      {
        audienceTypeId: CONTEXTUAL_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/contextual-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.CONTEXTUAL_AUDIENCE,
        title: 'Contexual Audience',
        handler: () =>
          openDialog({ audienceTypeId: CONTEXTUAL_AUDIENCE_TYPE_ID as number, type: 'create' }),
        subItem: [
          {
            iconName: 'UserTag',
            title: 'Specify Keywords',
            description:
              'Specify the relevant keywords and phrases based on which you would like to target users. ',
          },
          {
            iconName: 'UserList',
            title: 'Generate List',
            description:
              'Generate the list of users who have shown an interest in these keywords and phrases in the past.',
          },
          {
            iconName: 'UserTarget',
            title: 'Reach Audience',
            description: 'Get the list of unique target audience, and start reaching out to them.',
          },
        ],
      },
      {
        audienceTypeId: GEOFARMED_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/geofarmed-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.GEOFARMED_AUDIENCE,
        title: 'Geofarmed Audience',
        handler: () =>
          openDialog({ audienceTypeId: GEOFARMED_AUDIENCE_TYPE_ID as number, type: 'create' }),
        subItem: [
          {
            iconName: 'MapPin',
            title: 'Define Area',
            description:
              'Select the area or create a virtual boundary on the map. Users who visit this area will be added into the new audience.',
          },
          {
            iconName: 'GeofenceUser',
            title: 'Select Frequency',
            description: 'Select the frequency of the users visiting this area.',
          },
          {
            iconName: 'UserTarget',
            title: 'Reach Audience',
            description: 'Get the list of unique target audience, and start reaching out to them.',
          },
        ],
      },
      {
        audienceTypeId: CAMPAIGN_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/campaign-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.CAMPAIGN_AUDIENCE,
        title: 'Campaign Audience',
        handler: () =>
          openDialog({ audienceTypeId: CAMPAIGN_AUDIENCE_TYPE_ID as number, type: 'create' }),
        subItem: [
          {
            iconName: 'Ads',
            title: 'Specify Users',
            description: 'Select the users who viewed or engaged with your previous ads',
          },
          {
            iconName: 'CalendarEvent',
            title: 'Select Date',
            description: 'Select the date range for which you want to collect the audience.',
          },
          {
            iconName: 'UserTarget',
            title: 'Reach Audience',
            description: 'Get the list of unique target audience, and start reaching out to them.',
          },
        ],
      },
      {
        audienceTypeId: PRE_BID_AUDIENCE_TYPE_ID,
        imagePath: `${SVG_HOST_URL}/audience/pre-bid-illustration.svg`,
        infoText: DESCRIPTION_TEXTS.PRE_BID_AUDIENCE,
        title: 'Pre-bid Audience',
        handler: () =>
          openDialog({ audienceTypeId: PRE_BID_AUDIENCE_TYPE_ID as number, type: 'create' }),
        subItem: [
          {
            iconName: 'DesktopShield',
            title: 'Safeguard Brand Integrity',
            description: 'Ensure that ads will be appeared in a brand-safe environment.',
          },
          {
            iconName: 'WebsiteSearchInsights',
            title: 'Maximise Ad Visibility',
            description:
              'Optimize your ad placements to the inventory generating maximum visibility.',
          },
          {
            iconName: 'LockStar',
            title: 'Enhance Integrity',
            description:
              'Mitigate ad fraud risks by leveraging precise fraud & IVT pre-bid segments.',
          },
        ],
      },
    ],
    [openDialog],
  );

  useEffect(() => {
    setAudiencesByType();
    setAudiencesStatuses();
  }, [setAudiencesByType, setAudiencesStatuses]);

  const blankPage = useCallback(() => {
    if (audienceTypeIds === '' && !isLoading) {
      return <AllAudienceBlankPage audienceCardsInfo={audienceCardsInfo} />;
    }
    return (
      <AudienceBlankPage
        audienceInfo={audienceCardsInfo.filter(
          (info) => info.audienceTypeId === parseInt(audienceTypeIds, 10),
        )}
      />
    );
  }, [audienceTypeIds, audienceCardsInfo, isLoading]);

  const content = useCallback(() => {
    const totalCount = audiencesByType.reduce((acc: number, cur: AudienceType) => {
      return acc + Number(cur.audienceCount);
    }, 0);
    let currentTabCount;

    if (audienceTypeIds === '') {
      currentTabCount = totalCount;
      return totalCount === 0 && !isLoading ? (
        blankPage()
      ) : (
        <>
          <AudiencesHeader />
          <AudiencesTable controls={controls} />
        </>
      );
    }
    currentTabCount =
      audiencesByType.filter(
        (audience) => audience.audienceTypeId === parseInt(audienceTypeIds, 10),
      )[0]?.audienceCount || 0;
    return currentTabCount === 0 && !isLoading ? (
      blankPage()
    ) : (
      <>
        <AudiencesHeader />
        <AudiencesTable controls={controls} />
      </>
    );
  }, [audiencesByType, audienceTypeIds, blankPage, controls, isLoading]);

  return (
    <div className={styles.container}>
      {content()}
      {renderDialog(dialog.audienceTypeId, dialog.type, dialog.otherInfo)}
    </div>
  );
};

const mapState = (state: AppState) => ({
  isLoading: state.table.loading,
  audiencesByType: state.table.audiencesByType,
  dialog: state.app.dialog,
  audienceTypeIds: state.table.audienceTypeIds,
  matchedAudienceSubmitInProgress: state.matchedAudience.submitInProgress,
  retargetedAudienceSubmitInProgress: state.retargetedAudience.submitInProgress,
  lookalikeAudienceSubmitInProgress: state.lookalikeAudience.submitInProgress,
  campaignAudienceSubmitInProgress: state.campaignAudience.submitInProgress,
  segmentedAudienceSubmitInProgress: state.segmentedAudience.submitInProgress,
  geofarmedAudienceSubmitInProgress: state.geofarmedAudience.submitInProgress,
  contextualAudienceSubmitInProgress: state.contextualAudience.submitInProgress,
});

const mapActions = {
  setAudienceTypeIds: tableActions.setAudienceTypeIds,
  openDialog: applicationActions.openDialog,
  setAudiencesByType: tableActions.setAudiencesByType,
  setAudiencesStatuses: tableActions.setAudiencesStatuses,
};

export const Audiences = connect(mapState, mapActions)(AudiencesComponent);
