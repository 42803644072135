import { ParseResult } from 'papaparse';

export const removeNullColumns = (data: ParseResult<Array<string>>) => {
  const preserve = data.data[0].map(() => false);
  data.data.forEach((row, j) => {
    if (j === 0) return;
    for (let i = 0; i < row.length; ++i) {
      if (row[i] !== '') preserve[i] = true;
    }
  });
  data.data.forEach((row) => {
    for (let i = row.length - 1; i >= 0; --i) {
      if (!preserve[i]) row.splice(i, 1);
    }
  });
};
