import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Icon, Button } from 'factor';
import { useHistory } from 'react-router-dom';

import { applicationActions, OpenDialog } from 'store/app/actions';
import { ROUTE_NAMES, RouteName } from 'models/Route';

import styles from './styles.module.scss';

interface Props extends OpenDialog {
  icon: string;
  title: string;
  isActive?: boolean;
  onClick: () => void;
  description?: string;
}

const NavigationItemComponent = (props: Props) => {
  const { icon, title, isActive, onClick, description, openDialog } = props;

  const history = useHistory();

  const createAudience = useCallback(() => {
    const { pathname } = history.location;
    const routeIndex = ROUTE_NAMES.indexOf(pathname as RouteName);
    openDialog({ audienceTypeId: routeIndex, type: 'create' });
    if (routeIndex === 1) {
      // eslint-disable-next-line
      window.Intercom?.('trackEvent', 'create-matched-audience');
    }
  }, [history, openDialog]);

  return isActive ? (
    <div
      className={`${styles.activeWrapper} ${isActive ? styles.isActive : ''}`}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <div className={styles.titleContainer}>
        <Icon name={icon} className={styles.statusIcon} />
        <span className={styles.navRoute}>{title}</span>
      </div>
      <div className={styles.description}>{description}</div>
      <div className={styles.btnContainer}>
        <Button iconName="Plus" onClick={createAudience} className={styles.createButton}>
          Create Audience
        </Button>
      </div>
    </div>
  ) : (
    <div className={`${styles.wrapper}`} role="button" tabIndex={0} onClick={onClick}>
      <Icon name={icon} className={styles.statusIcon} />
      <span className={styles.navRoute}>{title}</span>
    </div>
  );
};

const mapAction = {
  openDialog: applicationActions.openDialog,
};

export const NavigationItem = connect(null, mapAction)(NavigationItemComponent);
