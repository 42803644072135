import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dialog, Icon, ProgressCircle, NumberFormatter, Tooltip, Pill } from 'factor';
import moment from 'moment';
import { TimezoneProvider, TimezonePicker } from 'iqm-framework';
import Postmate, { ParentAPI } from 'postmate';
import { isBoolean, isEmpty } from 'lodash';
import get from 'lodash/get';

import { TDialog } from 'store/app/reducer';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { API } from 'api';
import { tableStatusIconsMap } from 'components/consts';
import { COMMON_DIALOG_MODAL_PROPS } from 'constants/audiences';
import { SOMETHING_WENT_WRONG_MSG, FAILED_TO_LOAD_MSG } from 'constants/errors';
import { getCampaignUrl } from 'utils/helpers';
import { Option, OptionId } from 'models/Option';

import { useLocationDetails } from '../components/useLocationDetails';
import styles from './styles.module.scss';

interface Props extends OpenSnackbar {
  dialog: TDialog;
  handleClose?: () => void;
  dialogVisibility?: boolean;
  isIncluded?: boolean;
}

const ViewGeofarmedAudience = (props: Props) => {
  const { dialog, handleClose, dialogVisibility = true, isIncluded, openSnackbar } = props;
  const [audienceFrequencyOptions, setAudienceFrequencyOptions] = useState<
    Option<number>[] | undefined
  >([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState<OptionId[]>([]);
  const [hasFrequencyOptionsError, setHasFrequencyOptionsError] = useState(false);
  const [hasTimezoneError, setHasTimezoneError] = useState(false);
  const mapRef = useRef<ParentAPI | null>(null);
  const mapContainerRef = useRef<any>(null);

  const { location, audience, audienceError, countryError } = useLocationDetails(dialog.audienceId);

  useEffect(() => {
    API.GeofarmedAudience.getFrequencyOptions()
      .then((res) => setAudienceFrequencyOptions(res))
      .catch((err) => {
        openSnackbar({
          message: get(err, 'errorObjects[0].error', SOMETHING_WENT_WRONG_MSG),
          type: 'error',
        });
        setHasFrequencyOptionsError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (audienceError) {
      openSnackbar({
        message: get(audienceError, 'errorObjects[0].error', SOMETHING_WENT_WRONG_MSG),
        type: 'error',
      });
      if (handleClose) {
        handleClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audienceError]);

  useEffect(() => {
    if (countryError) {
      openSnackbar({
        message: get(countryError, 'data.responseObject.errorMsg', SOMETHING_WENT_WRONG_MSG),
        type: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryError]);

  const actionButtons = useMemo(
    () => [
      {
        title: 'Done',
        handler: handleClose,
      },
    ],
    [handleClose],
  );

  useEffect(() => {
    if (mapContainerRef.current && location) {
      const initHandShake = async () => {
        mapRef.current = await new Postmate({
          container: mapContainerRef.current,
          url: getCampaignUrl('maps-with-tabs'),
          name: 'location-block',
          classListArray: [dialogVisibility ? styles.iframeSize : styles.nestedIFrameSize],
        });
      };
      const parentAppErrors = { ...location.errors };
      const locationParam = { ...location, errors: undefined };
      const storeParams = { ...locationParam, parentAppErrors, parentApp: 'audiences' };
      initHandShake().then(() => mapRef.current?.call('initStore', JSON.stringify(storeParams)));
      return () => mapRef.current?.destroy?.();
    }
    return () => {};
  }, [audience, location, dialogVisibility]);

  const frequencyTypeValue = useMemo(() => {
    if (audience?.frequencyType && audienceFrequencyOptions?.length && !hasFrequencyOptionsError) {
      const result = audienceFrequencyOptions.filter((one) => one.value === audience.frequencyType);
      const str = result?.[0]?.label ?? '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    if (hasFrequencyOptionsError) {
      return FAILED_TO_LOAD_MSG;
    }
    return '';
  }, [audienceFrequencyOptions, audience, hasFrequencyOptionsError]);

  const timeZoneValue = useMemo(() => {
    if (audience?.timezone && timeZoneOptions?.length) {
      const result = timeZoneOptions.filter((one) => one.id === audience.timezone);
      return result?.[0]?.label ?? '';
    }
    return '';
  }, [timeZoneOptions, audience]);

  const currentTimeZone = useMemo(() => {
    if (audience?.timezone && timeZoneOptions?.length) {
      const result = timeZoneOptions.filter((one) => one.id === audience.timezone);
      return result?.[0]?.value ?? 'US/Eastern';
    }
    return '';
  }, [timeZoneOptions, audience]);

  const onTimezonePickerError = useCallback(
    (e: any) => {
      openSnackbar({
        message: get(e, 'response.data.responseObject.errorMsg', SOMETHING_WENT_WRONG_MSG),
        type: 'error',
      });
      setHasTimezoneError(true);
    },
    [openSnackbar],
  );

  const dataCollectionPeriod = useMemo(() => {
    if (!audience) {
      return '';
    }
    if (hasTimezoneError) {
      return FAILED_TO_LOAD_MSG;
    }
    // eslint-disable-next-line no-extra-boolean-cast
    return Boolean(audience.endDate)
      ? `${moment(audience?.startDate * 1000)
          ?.tz(currentTimeZone)
          ?.format('MM/DD/yyyy') ?? ''}-${moment(audience.endDate * 1000)
          ?.tz(currentTimeZone)
          ?.format('MM/DD/yyyy') ?? ''}`
      : `From ${moment(audience?.startDate * 1000)
          ?.tz(currentTimeZone)
          ?.format('MM/DD/yyyy') ?? ''}`;
  }, [audience, currentTimeZone, hasTimezoneError]);

  const startDate = useMemo(() => {
    if (!audience) {
      return '';
    }
    if (hasTimezoneError) {
      return FAILED_TO_LOAD_MSG;
    }
    return (
      moment(audience?.startDate * 1000)
        .tz(currentTimeZone)
        ?.format('MM/DD/yyyy HH:mm') ?? ''
    );
  }, [audience, currentTimeZone, hasTimezoneError]);

  const endDate = useMemo(() => {
    if (!audience || !audience?.endDate) {
      return '';
    }
    if (hasTimezoneError) {
      return FAILED_TO_LOAD_MSG;
    }
    return (
      moment(audience?.endDate * 1000)
        .tz(currentTimeZone)
        ?.format('MM/DD/yyyy HH:mm') ?? ''
    );
  }, [audience, currentTimeZone, hasTimezoneError]);

  const renderContent =
    audience && location ? (
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.audienceIcon}>
            <Icon name="AudienceGeo" />
          </div>
          {audience.name.length > 60 ? (
            <Tooltip label={audience.name} position="bottom" auto={false}>
              <div className={`${styles.name} ${styles.restrictName}`}>{audience.name}</div>
            </Tooltip>
          ) : (
            <div className={styles.name}>{audience.name}</div>
          )}
          {isBoolean(isIncluded) && (
            <Pill
              className={isIncluded ? styles.successPill : styles.dangerPill}
              label={isIncluded ? 'Allowed' : 'Blocked'}
            />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.infoWrapper}>
            <div className={styles.infoLabel}>Created on:</div>
            <div className={styles.infoText}>
              {hasTimezoneError
                ? FAILED_TO_LOAD_MSG
                : moment(audience.createdOn * 1000)
                    ?.tz(currentTimeZone)
                    ?.format('MM/DD/yyyy')}
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.infoLabel}>Data Collection Period:</div>
            <div className={styles.infoText}>{dataCollectionPeriod}</div>
          </div>
          <div className={styles.infoWrapper}>
            <div className={styles.infoLabel}>Reach</div>
            <div className={styles.infoText}>
              <NumberFormatter>{audience.uniques}</NumberFormatter>
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <Icon name={tableStatusIconsMap[audience.status]} className={styles.statusIcon} />
            <div className={styles.infoStatus}>{audience.status}</div>
          </div>
        </div>

        <div ref={mapContainerRef} className={styles.mapBoxContainer} />

        <div className={styles.titleContainer}>
          <div className={styles.name}>Audience Information</div>
        </div>
        <div>
          <div className="row">
            <div className={`col-4 mb-3 ${styles.audienceInfo}`}>
              <div className={styles.audienceInfoLabel}>Frequency:</div>
              <div className={styles.audienceInfoText}>{audience.frequency}</div>
            </div>
            <div className={`col-4 mb-3 ${styles.audienceInfo}`}>
              <div className={styles.audienceInfoLabel}>Frequency Type:</div>
              <div className={styles.audienceInfoText}>{frequencyTypeValue}</div>
            </div>
            <div className={`col-4 mb-3 ${styles.audienceInfo}`}>
              <div className={styles.audienceInfoLabel}>TimeZone:</div>
              <div className={styles.audienceInfoText}>
                <Icon className="_size-20 timezone-picker__icon" fill="#a2a2a2" name="Timezone" />
                {hasTimezoneError ? (
                  <span className={styles.audienceInfoText}>
                    &nbsp;
                    {`${FAILED_TO_LOAD_MSG}`}
                  </span>
                ) : (
                  timeZoneValue
                )}
              </div>
              <div className={styles.audienceInfoHidden}>
                <TimezoneProvider>
                  <TimezonePicker onLoaded={setTimeZoneOptions} onError={onTimezonePickerError} />
                </TimezoneProvider>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`col-4 ${styles.audienceInfo}`}>
              <div className={styles.audienceInfoLabel}>Start Date:</div>
              <div className={styles.audienceInfoText}>{startDate}</div>
            </div>
            <div className={`col-4  ${styles.audienceInfo}`}>
              <div className={styles.audienceInfoLabel}>End Date:</div>
              <div className={styles.audienceInfoText}>{endDate}</div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={styles.loading}>
        <ProgressCircle />
      </div>
    );

  return dialogVisibility ? (
    <Dialog
      dialogTitle="Audience Details"
      open={!isEmpty(dialog)}
      crossButton
      headerFooterBorders
      modalProps={COMMON_DIALOG_MODAL_PROPS}
      className={styles.dialog}
      onCrossButtonClick={handleClose}
      actionButtons={actionButtons}
    >
      {renderContent}
    </Dialog>
  ) : (
    <>{renderContent}</>
  );
};

const mapAction = {
  openSnackbar: snackbarActions.openSnackbar,
};

export default connect(null, mapAction)(ViewGeofarmedAudience);
