import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import Axios from 'axios';
import {
  Dialog,
  Select,
  Icon,
  Table,
  Tooltip,
  Tumbler,
  ProgressCircle,
  SORT_DIRECTION,
  AllowlistBlocklistTooltip,
  EmptyDropdownLabel,
} from 'factor';
import { Tooltip as AppliftTooltip, Box } from '@applift/factor';
import { WaitIndicator, GENERAL_API_ERROR, LoadingStatus, IOBudgetTypeMapper } from 'iqm-framework';
import { connect } from 'react-redux';
import { isEmpty, capitalize, uniqBy, uniq, isEqual } from 'lodash';

import { CampaignSimple, GroupsList } from 'models/CampaignAudience';
import { OptionPayload } from 'models/Option';
import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { TDialog } from 'store/app/reducer';
import { API } from 'api';
import { tableComponentInstanceRef } from 'components/Audiences/AudiencesTable';
import { IAddAudienceToCampaign } from 'api/AddToCampaign';
import { STATUS_MAPPER } from 'components/CampaignNameWithIcons';
import { creativeIconMap, CAMPAIGN_STATUSES } from 'components/consts';
import { CAMPAIGN_TYPE_BY_NAME } from 'constants/campaigns';
import {
  AUDIENCE_STATUSES,
  CAMPAIGN_MAX_AUDIENCE_COUNT,
  COMMON_DIALOG_MODAL_PROPS,
} from 'constants/audiences';
import { extractCampaignIOOption, extractCampaignOption } from 'utils/option';
import { Header } from './SelectCampaignOptions/Header';

import styles from './styles.module.scss';
import { Option } from './SelectCampaignOptions/Option';

interface Props extends OpenSnackbar {
  handleClose: () => void;
  dialog: TDialog;
}

const initTableSorting = {
  field: 'order',
  direction: 'desc',
};

const AddToCampaignDialogComponent = (props: Props) => {
  const { handleClose, dialog, openSnackbar } = props;
  const { otherInfo } = dialog;

  const { selectedAudienceIds } = otherInfo as { selectedAudienceIds: number[] };

  const [groupList, setGroupList] = useState<GroupsList[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<OptionPayload<CampaignSimple>[]>([]);

  const excludedCampaignIdsRef = useRef<number[]>([...otherInfo.excludedCampaignIds]);
  const [excludedCampaignIds, _setExcludedCampaignIds] = useState<number[]>(
    excludedCampaignIdsRef.current,
  );
  const setExcludedCampaignIds = (ids: number[]) => {
    excludedCampaignIdsRef.current = ids;
    _setExcludedCampaignIds(ids);
  };
  const [loading, setLoading] = useState(LoadingStatus.PENDING);
  const [expAndDelCmps, setExpAndDelCmps] = useState<OptionPayload<CampaignSimple>[]>([]);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [enableUpdateButton, setUpdateBtnStatus] = useState(false);
  const showWaitScreen = useRef(false);
  const cancelTokenSource = useRef(Axios.CancelToken.source());

  const incExcFeatureAllowed = React.useMemo(() => otherInfo.fetchAssociatedCampaigns, [otherInfo]); // fetchAssociatedCampaigns will be false if any prebid audience is selected, hence making use of it to show/hide inc/exc column

  const [tableConfig, setTableConfig] = useState<{
    header: unknown[];
    body: unknown[];
    data: unknown[];
  }>({
    header: [],
    body: [],
    data: [],
  });

  const closeDialog = useCallback(() => {
    if (cancelTokenSource.current?.cancel) {
      cancelTokenSource.current.cancel();
    }
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    const fetchCampaignInfo = async (campaignIds: number[]) => {
      const response = await API.AddToCampaign.getCampaignsBasicInfo(
        campaignIds,
        {
          sortBy: 'campaignId',
          order: 'desc',
        },
        [CAMPAIGN_TYPE_BY_NAME.advanced],
      );
      if (response.data?.length) {
        const expAndDels: OptionPayload<CampaignSimple>[] = [];
        response.data.forEach((campaign) => {
          if (
            campaign.status === CAMPAIGN_STATUSES.EXPIRED ||
            campaign.status === CAMPAIGN_STATUSES.DELETED
          ) {
            expAndDels.push(extractCampaignIOOption(campaign));
          }
        });
        setUpdateBtnStatus(true);
        setExpAndDelCmps(expAndDels);
        setSelectedCampaigns(response.data.map(extractCampaignIOOption));
      }
      setIsTableLoading(false);
    };

    if (
      (otherInfo.includedCampaignIds.length || otherInfo.excludedCampaignIds.length) &&
      otherInfo.fetchAssociatedCampaigns
    ) {
      setIsTableLoading(true);
      fetchCampaignInfo([...otherInfo.includedCampaignIds, ...otherInfo.excludedCampaignIds]);
    }
  }, [otherInfo]);

  const getCampaignsList = useCallback(async () => {
    try {
      setLoading(LoadingStatus.LOADING);
      const response = await API.AddToCampaign.getCampaignsByInsertionOrder({
        status: ['running', 'paused', 'expired', 'draft', 'pending', 'rejected', 'deleted'],
        pageNo: -1,
        sortBy: '-ioId,-campaignId',
        includeTargetedAudienceList: true,
        campaignTypeIds: [CAMPAIGN_TYPE_BY_NAME.advanced],
      });
      setUpdateBtnStatus(true);
      if (response.length) {
        const filteredCampaignGroups: GroupsList[] = response
          .map((insertionOrder) => {
            const validGroupData: OptionPayload<CampaignSimple>[] = insertionOrder.campaigns
              ?.filter(
                (campaign) =>
                  ![CAMPAIGN_STATUSES.EXPIRED, CAMPAIGN_STATUSES.DELETED].includes(
                    campaign.status,
                  ) ||
                  [...otherInfo.includedCampaignIds, ...otherInfo.excludedCampaignIds].includes(
                    campaign.id,
                  ),
              )
              .map(extractCampaignOption)
              .map((opt) => {
                const isCampaignDisabled =
                  selectedAudienceIds.length + (opt.payload.targetedAudienceIds?.length ?? 0) > 20;
                return {
                  ...opt,
                  payload: {
                    ...opt.payload,
                    ioId: insertionOrder.ioId,
                    ioName: insertionOrder.ioName,
                    ioBudgetTypeId: insertionOrder.ioBudgetTypeId,
                    ioStatusId: insertionOrder.ioStatusId,
                    isCampaignDisabled,
                  },
                  isDisable: isCampaignDisabled,
                  reactLabel: isCampaignDisabled ? (
                    <AppliftTooltip
                      title={`Maximum limit of ${CAMPAIGN_MAX_AUDIENCE_COUNT} audiences per campaign is reached`}
                      placement="bottom-start"
                      arrow
                    >
                      <Box>
                        <Option campaign={opt.payload} />
                      </Box>
                    </AppliftTooltip>
                  ) : (
                    <Option campaign={opt.payload} />
                  ),
                };
              });
            return {
              label: insertionOrder.ioName,
              value: `${insertionOrder.ioId} ${insertionOrder.ioName}`,
              reactLabel: (
                <Header
                  ioId={insertionOrder.ioId}
                  ioName={insertionOrder.ioName}
                  ioBudgetTypeId={insertionOrder.ioBudgetTypeId}
                />
              ),
              id: insertionOrder.ioId,
              options: validGroupData || [],
            };
          })
          .filter((grp) => !!grp.options.length);
        setGroupList(filteredCampaignGroups);
      }

      setLoading(LoadingStatus.PENDING);
    } catch (err) {
      setLoading(LoadingStatus.ERROR);
    }
  }, [otherInfo.excludedCampaignIds, otherInfo.includedCampaignIds, selectedAudienceIds.length]);

  useEffect(() => {
    getCampaignsList();
  }, [getCampaignsList]);

  const [audienceCountStatus, setAudienceCountStatus] = useState<
    'pending' | 'to_many_audiences' | 'good'
  >('good');

  useEffect(() => {
    const source = Axios.CancelToken.source();
    const { token } = source;
    setAudienceCountStatus('pending');

    (async () => {
      try {
        setAudienceCountStatus(
          (
            await Promise.all(
              selectedCampaigns.map(async ({ id }) => {
                const campaign = await API.AddToCampaign.getCampaign(id, token);
                const allAudiences = new Set<number>();
                if (campaign.whiteListedAudienceIds)
                  campaign.whiteListedAudienceIds
                    .split(',')
                    .map(Number)
                    .forEach((aud) => allAudiences.add(aud));
                if (campaign.blackListedAudienceIds)
                  campaign.blackListedAudienceIds
                    .split(',')
                    .map(Number)
                    .forEach((aud) => allAudiences.add(aud));
                selectedAudienceIds.forEach((aud) => allAudiences.add(aud));
                return allAudiences.size;
              }),
            )
          ).every((s) => s <= CAMPAIGN_MAX_AUDIENCE_COUNT)
            ? 'good'
            : 'to_many_audiences',
        );
      } catch (e) {
        if (Axios.isCancel(e)) return;
        throw e;
      }
    })();

    return () => source.cancel();
  }, [selectedCampaigns, selectedAudienceIds]);

  const selectedAndExistingCampaignIds = useMemo(
    () =>
      uniq(
        selectedCampaigns
          .map((opt) => opt.id)
          .concat([...otherInfo.includedCampaignIds, ...otherInfo.excludedCampaignIds]),
      ),
    [otherInfo, selectedCampaigns],
  );

  const actionButtons = useMemo(
    () => [
      {
        variant: 'secondary',
        title: 'Cancel',
        handler: closeDialog,
      },
      {
        title: 'Update',
        handler: async () => {
          showWaitScreen.current = true;
          setUpdateBtnStatus(false);
          let requestData: IAddAudienceToCampaign = {};
          selectedAndExistingCampaignIds
            .filter((id) => !expAndDelCmps.find((cmp) => cmp.id === id))
            .forEach((id) => {
              const isRemoved = !selectedCampaigns.map((campaign) => campaign.id).includes(id);
              const isCurrentlyIncluded = !excludedCampaignIds.includes(id) && !isRemoved;
              const isCurrentlyExcluded = excludedCampaignIds.includes(id) && !isRemoved;
              const isAlreadyIncluded = [...otherInfo.includedCampaignIds].includes(id);
              const isAlreadyExcluded = [...otherInfo.excludedCampaignIds].includes(id);
              if (
                isRemoved ||
                !(isCurrentlyExcluded === isAlreadyExcluded) ||
                !(isCurrentlyIncluded === isAlreadyIncluded)
              ) {
                requestData = {
                  ...requestData,
                  ...{
                    [id]: {
                      includedAudienceList: isCurrentlyIncluded
                        ? otherInfo.selectedAudienceIds
                        : [],
                      excludedAudienceList: isCurrentlyExcluded
                        ? otherInfo.selectedAudienceIds
                        : [],
                      removedAudienceList: isRemoved ? otherInfo.selectedAudienceIds : [],
                    },
                  },
                };
              }
            });
          try {
            const response = await API.AddToCampaign.addAudienceToCampaign(
              requestData,
              cancelTokenSource.current,
            );
            if (response.success && response.data) {
              openSnackbar({ message: response.data.message, type: 'success' });
              if (tableComponentInstanceRef?.current) {
                tableComponentInstanceRef.current.clearSelected();
                tableComponentInstanceRef.current.getNewData();
              }
            }
          } catch (e) {
            openSnackbar({
              message: (e as any)?.errorObjects?.[0]?.error || GENERAL_API_ERROR,
              type: 'error',
            });
          }
          handleClose();
        },
        disabled:
          (isEqual(
            selectedCampaigns.map((campaign) => campaign.id).sort(),
            [...otherInfo.includedCampaignIds, ...otherInfo.excludedCampaignIds].sort(),
          ) &&
            isEqual(excludedCampaignIds.sort(), [...otherInfo.excludedCampaignIds].sort())) ||
          !enableUpdateButton ||
          audienceCountStatus !== 'good',
      },
    ],
    [
      handleClose,
      closeDialog,
      otherInfo,
      selectedCampaigns,
      excludedCampaignIds,
      openSnackbar,
      selectedAndExistingCampaignIds,
      enableUpdateButton,
      expAndDelCmps,
      audienceCountStatus,
    ],
  );

  const handleCampaignSelect = useCallback(
    async (selectedOptions: OptionPayload<CampaignSimple>[] | GroupsList[]) => {
      const data: OptionPayload<CampaignSimple>[] = uniqBy(
        (selectedOptions as any).filter((opt: any) => !Array.isArray(opt.options) && opt.payload),
        (cmp: OptionPayload<CampaignSimple>) => cmp.id,
      );
      setExcludedCampaignIds(
        excludedCampaignIds.filter((id) => !!data.find((opt) => opt.id === id)),
      );

      if (!data.length && !expAndDelCmps.length) {
        return setSelectedCampaigns([]);
      }

      // to prevent adding campaigns to rejected and failed audiences
      if (
        [AUDIENCE_STATUSES.REJECTED, AUDIENCE_STATUSES.FAILED].includes(otherInfo.audienceStatus) &&
        !data
          .map((item) => item.id)
          .every((id) =>
            [...otherInfo.includedCampaignIds, ...otherInfo.excludedCampaignIds].includes(id),
          )
      ) {
        if (isEmpty(otherInfo.includedCampaignIds) && isEmpty(otherInfo.excludedCampaignIds)) {
          return setSelectedCampaigns([]);
        }
        if (selectedCampaigns.length < data.length) {
          return setSelectedCampaigns([...selectedCampaigns]);
        }
      }
      const dataAfterRemovingInvalidCampaigns = data.filter((val) => {
        const isCampaignDisabled =
          selectedAudienceIds.length + (val.payload.targetedAudienceIds?.length ?? 0) > 20;
        return !isCampaignDisabled;
      });
      if (dataAfterRemovingInvalidCampaigns.length || expAndDelCmps.length) {
        setSelectedCampaigns(
          uniqBy(
            [...(dataAfterRemovingInvalidCampaigns || []), ...(expAndDelCmps || [])],
            (cmp) => cmp.id,
          ),
        );
      }
      return null;
    },
    [
      excludedCampaignIds,
      expAndDelCmps,
      otherInfo.audienceStatus,
      otherInfo.includedCampaignIds,
      otherInfo.excludedCampaignIds,
      selectedCampaigns,
      selectedAudienceIds.length,
    ],
  );

  const getTumblerStyle = (status: string) => {
    if (status === 'includedExpired') {
      return styles.includedExpired;
    }
    if (status === 'includedNotExpired') {
      return styles.includedNotExpired;
    }
    return status === 'excludedExpired' ? styles.excludedExpired : styles.excludedNotExpired;
  };

  const getTumbler = useCallback(
    (isExcluded: boolean, campaign: CampaignSimple) => {
      const isCampaignExpiredOrDeleted =
        campaign.status === CAMPAIGN_STATUSES.EXPIRED ||
        campaign.status === CAMPAIGN_STATUSES.DELETED;
      return (
        <Tumbler
          className={getTumblerStyle(
            `${isExcluded ? 'excluded' : 'included'}${
              isCampaignExpiredOrDeleted ? 'Expired' : 'NotExpired'
            }`,
          )}
          on={!isExcluded}
          disabled={isCampaignExpiredOrDeleted}
          onChange={() => {
            if (!isCampaignExpiredOrDeleted) {
              let ids = excludedCampaignIds;
              if (isExcluded) {
                ids = ids.filter((val) => val !== campaign.id);
              } else {
                ids.push(campaign.id);
              }
              setExcludedCampaignIds([...ids]);
              setSelectedCampaigns((cmps) => [...cmps]);
            }
          }}
        />
      );
    },
    [excludedCampaignIds],
  );

  const sortingFunc = useCallback(
    (field: keyof CampaignSimple | 'incExc') => (
      sortingType: typeof SORT_DIRECTION,
      a: OptionPayload<CampaignSimple>,
      b: OptionPayload<CampaignSimple>,
    ) => {
      if (field === 'incExc') {
        if (sortingType === SORT_DIRECTION.ASC) {
          return !excludedCampaignIdsRef.current.includes(a.id) &&
            excludedCampaignIdsRef.current.includes(b.id)
            ? 1
            : -1;
        }
        return excludedCampaignIdsRef.current.includes(a.id) &&
          !excludedCampaignIdsRef.current.includes(b.id)
          ? 1
          : -1;
      }

      const first =
        typeof a.payload[field] === 'string'
          ? (a.payload[field] as string).toLowerCase()
          : a.payload[field];
      const second =
        typeof b.payload[field] === 'string'
          ? (b.payload[field] as string).toLowerCase()
          : b.payload[field];

      if (sortingType === SORT_DIRECTION.ASC) {
        // @ts-ignore
        return first > second ? 1 : -1;
      }
      // @ts-ignore
      return first < second ? 1 : -1;
    },
    [],
  );

  const loadTableData = useCallback(() => {
    setTableConfig({
      header: [
        { label: 'Campaign ID', sortingKey: 'id', className: 'w-120-120' },
        {
          label: 'Campaign Name',
          className: '_campaignName w-200-450',
          compareFunc: sortingFunc('name'),
          sortingKey: 'name',
        },
        {
          label: 'Campaign Status',
          className: 'w-150-200',
          compareFunc: sortingFunc('status'),
          sortingKey: 'status',
        },
        {
          label: 'Insertion Order ID',
          className: 'w-150-200',
          compareFunc: sortingFunc('ioId'),
          sortingKey: 'ioId',
        },
        {
          label: 'Insertion Order Name',
          className: incExcFeatureAllowed ? 'w-200-450' : '_ioName',
          compareFunc: sortingFunc('ioName'),
          sortingKey: 'ioName',
        },
        ...(incExcFeatureAllowed
          ? [
              {
                label: (
                  <AllowlistBlocklistTooltip
                    labels={[
                      'Campaign targeted for this audience',
                      'Campaign blocked for this audience',
                    ]}
                    tooltipProps={{ disableAnimation: true, delayTime: 0 }}
                  />
                ),
                className: '_incExc',
                compareFunc: sortingFunc('incExc'),
                sortingKey: 'incExc',
              },
            ]
          : []),
        {
          label: 'Action',
          className: 'w-80-80',
        },
      ],
      body: [
        {
          key: (campaign: OptionPayload<CampaignSimple>) => (
            <span className={`${excludedCampaignIds.includes(campaign.id) ? styles.expired : ''}`}>
              {campaign.id}
            </span>
          ),
          className: 'w-120-120',
        },
        {
          key: (campaign: OptionPayload<CampaignSimple>) => {
            return (
              <Tooltip
                label={campaign.payload.name}
                auto={false}
                position="top-left"
                labelMaxWidth={400}
                portal
                className={styles.tooltipWithEllipses}
              >
                <>
                  <span className="mr-2 d-inline-flex align-items-center">
                    {campaign.payload.creativeTypeId ? (
                      <Icon name={creativeIconMap[campaign.payload.creativeTypeId] || 'NoImage'} />
                    ) : null}
                  </span>
                  <span
                    className={`${styles.nameInner} ${
                      excludedCampaignIds.includes(campaign.id) ? styles.expired : ''
                    }`}
                  >
                    {campaign.payload.name}
                  </span>
                </>
              </Tooltip>
            );
          },
          className: `${styles.campaignNameRow} _campaignName w-200-450`,
        },
        {
          key: (campaign: OptionPayload<CampaignSimple>) =>
            campaign.payload.status ? (
              <span
                className={`${styles.status} ${
                  excludedCampaignIds.includes(campaign.id) ? styles.expired : ''
                }`}
              >
                <Icon name={STATUS_MAPPER[campaign.payload.status]} className="_size-20" />
                <span className="ml-1">{capitalize(campaign.payload.status)}</span>
              </span>
            ) : (
              ''
            ),
          className: 'w-150-200',
        },
        {
          key: (campaign: OptionPayload<CampaignSimple>) => (
            <span className={`${excludedCampaignIds.includes(campaign.id) ? styles.expired : ''}`}>
              {campaign.payload?.ioId || '-'}
            </span>
          ),
          className: 'w-150-200',
        },
        {
          key: (campaign: OptionPayload<CampaignSimple>) => {
            const budgetIcon = IOBudgetTypeMapper[campaign.payload.ioBudgetTypeId]?.icon;
            return (
              <Tooltip
                label={campaign.payload.ioName}
                auto={false}
                position="top"
                portal
                className={styles.tooltipWithEllipses}
              >
                <div className="w-100 d-flex align-items-center">
                  <span className="mr-2 d-inline-flex align-items-center">
                    {!!budgetIcon && <Icon name={budgetIcon} />}
                  </span>
                  <span
                    className={`${styles.ioName} ${
                      excludedCampaignIds.includes(campaign.id) ? styles.expired : ''
                    }`}
                  >
                    {campaign.payload.ioName}
                  </span>
                </div>
              </Tooltip>
            );
          },
          className: incExcFeatureAllowed ? 'w-200-450' : '_ioName',
        },
        ...(incExcFeatureAllowed
          ? [
              {
                key: (campaign: OptionPayload<CampaignSimple>) => {
                  const isExcluded = excludedCampaignIds.includes(campaign.id);
                  const tumbler = getTumbler(isExcluded, campaign.payload);
                  const isCampaignExpiredOrDeleted =
                    campaign.payload.status === CAMPAIGN_STATUSES.EXPIRED ||
                    campaign.payload.status === CAMPAIGN_STATUSES.DELETED;
                  return (
                    <>
                      {isCampaignExpiredOrDeleted ? (
                        <Tooltip
                          label={`Can't toggle as it is an ${
                            campaign.payload.status === CAMPAIGN_STATUSES.EXPIRED
                              ? 'Expired'
                              : 'Deleted'
                          } campaign`}
                          labelMaxWidth={150}
                          portal
                        >
                          {tumbler}
                        </Tooltip>
                      ) : (
                        tumbler
                      )}
                    </>
                  );
                },
                className: '_incExc',
              },
            ]
          : []),
        {
          key: (campaign: OptionPayload<CampaignSimple>) => {
            const deleteIcon = <Icon name="DeleteAlt" />;
            const isCampaignExpiredOrDeleted =
              campaign.payload.status === CAMPAIGN_STATUSES.EXPIRED ||
              campaign.payload.status === CAMPAIGN_STATUSES.DELETED;
            return (
              <span
                className={isCampaignExpiredOrDeleted ? styles.disabled : styles.deleteIcon}
                onClick={() => {
                  if (!isCampaignExpiredOrDeleted) {
                    setSelectedCampaigns((selections) =>
                      selections.filter((selection) => selection.id !== campaign.id),
                    );
                    setExcludedCampaignIds(
                      excludedCampaignIdsRef.current.filter((id) => id !== campaign.id),
                    );
                  }
                }}
              >
                {isCampaignExpiredOrDeleted ? (
                  <Tooltip
                    label={`Can't delete as it is an ${
                      campaign.payload.status === CAMPAIGN_STATUSES.EXPIRED ? 'Expired' : 'Deleted'
                    } campaign`}
                    labelMaxWidth={150}
                    portal
                  >
                    {deleteIcon}
                  </Tooltip>
                ) : (
                  deleteIcon
                )}
              </span>
            );
          },
          className: 'w-80-80 _actions',
        },
      ],
      data: selectedCampaigns,
    });
  }, [selectedCampaigns, excludedCampaignIds, getTumbler, sortingFunc, incExcFeatureAllowed]);

  useEffect(() => {
    loadTableData();
  }, [loadTableData]);

  const selectedGroups = useMemo(() => {
    return groupList?.filter(
      (grp) =>
        grp.options?.length &&
        grp.options.every((opt) => !!selectedCampaigns.find((cmp) => cmp.id === +opt.id)),
    );
  }, [groupList, selectedCampaigns]);

  const allSelectedOptions = useMemo(() => {
    return [...selectedCampaigns, ...(selectedGroups || [])];
  }, [selectedGroups, selectedCampaigns]);

  const allowClearable = useMemo(
    () =>
      !selectedCampaigns?.every(
        (cmp) =>
          cmp.payload.status === CAMPAIGN_STATUSES.EXPIRED ||
          cmp.payload.status === CAMPAIGN_STATUSES.DELETED,
      ),
    [selectedCampaigns],
  );

  const renderEmptyDropdownLabel = useCallback(
    ({ search }: { search: string }) => (
      <EmptyDropdownLabel
        searchTerm={search}
        isLoading={loading === LoadingStatus.LOADING}
        isError={loading === LoadingStatus.ERROR}
      />
    ),
    [loading],
  );

  return (
    <Dialog
      dialogTitle="Add To Campaign"
      open={!isEmpty(dialog)}
      crossButton
      headerFooterBorders
      className={styles.container}
      onCrossButtonClick={closeDialog}
      actionButtons={actionButtons}
      modalProps={COMMON_DIALOG_MODAL_PROPS}
      waitScreenContent={
        showWaitScreen.current ? (
          <WaitIndicator
            message="Assigning Audiences"
            subMessage="The audiences are being assigned to the campaigns. Please wait..."
            theme="teal"
          />
        ) : null
      }
    >
      <div className={styles.titleText}>
        Select the campaigns below for which you want to add{' '}
        {otherInfo.selectedAudienceIds?.length === 1
          ? 'the audience'
          : 'all the selected audiences'}{' '}
        {otherInfo.selectedAudienceIds?.length === 1 ? (
          <Tooltip label={otherInfo.audienceName} portal>
            <span className={styles.audienceName}>{otherInfo.audienceName}: </span>
          </Tooltip>
        ) : null}
      </div>
      <div className={styles.selectContainer}>
        <Select
          onChange={handleCampaignSelect}
          value={allSelectedOptions}
          placeholder="Select Campaigns"
          options={groupList}
          label="Campaigns"
          renderEmptyDropdownLabel={renderEmptyDropdownLabel}
          searchPlaceholder="Search by IO Name, IO ID, Campaign ID, Campaign Name, Status"
          allSelectable={
            ![AUDIENCE_STATUSES.REJECTED, AUDIENCE_STATUSES.FAILED].includes(
              otherInfo.audienceStatus,
            )
          }
          isMulti
          isSearchable
          isSearchClearable
          searchByValue
          isClearable
          hideClearAllButton={!allowClearable}
          showLabelAlways
          virtualizationProps={{
            height: 300,
            width: 530,
            itemSize: (item: any) => (item.deep ? 48 : 32),
            overscanCount: 5,
          }}
          fitContainer
        />
      </div>
      {!isTableLoading ? (
        !!selectedCampaigns.length && (
          <Table
            className={styles.table}
            data={tableConfig.data}
            header={tableConfig.header}
            sorting={initTableSorting}
            body={tableConfig.body}
            rowKeyExtractor={(data: any) => data.id}
            tbodyRowHeight={40}
            theadRowHeight={40}
          />
        )
      ) : (
        <div className={styles.loader}>
          <ProgressCircle size={40} borderWidth={2} />
        </div>
      )}
    </Dialog>
  );
};

const mapAction = {
  openSnackbar: snackbarActions.openSnackbar,
};

const AddToCampaignDialog = connect(null, mapAction)(AddToCampaignDialogComponent);

export default AddToCampaignDialog;
