import React from 'react';
import { NumberFormatter } from 'factor';
import moment from 'moment';

import { TableAudienceItem } from 'models/Table';
import { pluralize } from 'utils/pluralize';

import styles from './styles.module.scss';

const getRetargetedBodyMapper = () => ({
  period: {
    key: (data: TableAudienceItem) => {
      if (!data.tagDuration) {
        return <>- </>;
      }
      switch (data.tagDuration.toLowerCase()) {
        case 'month':
          return (
            <>
              {data.tagTime} {pluralize(data.tagDuration.toLowerCase(), data.tagTime)}
            </>
          );

        case 'date_range': {
          const hasEndDate = data.endDate !== undefined;
          const dateData = `${!hasEndDate ? 'From ' : ''}
                ${moment(new Date(data.startDate)).format('MM/DD/yyyy')}
                ${hasEndDate ? ' - ' : ''}
                ${hasEndDate ? moment(new Date(data.endDate)).format('MM/DD/yyyy') : ''}`;
          return (
            <div className="d-flex align-items-center">
              <div>{dateData}</div>
            </div>
          );
        }
        default:
          return <>- </>;
      }
    },
    className: styles.period,
  },
  uniques: {
    key: (data: TableAudienceItem) => {
      return <NumberFormatter>{data.uniques}</NumberFormatter>;
    },
    className: `_right ${styles.boldtext}`,
  },
});

export default getRetargetedBodyMapper;
