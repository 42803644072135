import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import hasIn from 'lodash/hasIn';
import isBoolean from 'lodash/isBoolean';
import { TDialog } from 'store/app/reducer';
import { Dialog, Icon, ProgressCircle, NumberFormatter, Tooltip, Pill } from 'factor';
import moment from 'moment';
import get from 'lodash/get';

import { OpenSnackbar, snackbarActions } from 'store/snackbar/actions';
import { IMatchedAudience } from 'models/MatchedAudience';
import { getMatchedAudience } from 'api/MatchedAudience';
import { COMMON_DIALOG_MODAL_PROPS } from 'constants/audiences';
import { tableStatusIconsMap } from 'components/consts';
import { SOMETHING_WENT_WRONG_MSG } from 'constants/errors';
import { RUMLogger } from 'services/RUMLogger';
import { getApiErrorMsg } from 'services/RUMLogger/utils';

import DownloadCsvLabel from '../components/DownloadCsvLabel/index';

import styles from './styles.module.scss';

interface Props extends OpenSnackbar {
  dialog: TDialog;
  handleClose?: () => void;
  dialogVisibility?: boolean;
  isIncluded?: boolean;
}

const ViewMatchedAudience = (props: Props) => {
  const { dialog, handleClose, dialogVisibility = true, isIncluded, openSnackbar } = props;

  const [audience, setAudience] = useState<IMatchedAudience | undefined>();

  useEffect(() => {
    if (dialog?.audienceId) {
      getMatchedAudience(dialog.audienceId)
        .then((res) => {
          RUMLogger.viewMatchedAudience({
            statusCode: res.axiosResponse.status,
            latency: res.axiosResponse.data.latency,
            success: true,
            audienceStatus: dialog.status,
          });
          setAudience(res);
        })
        .catch((err) => {
          const message = get(err, 'responseObject.errorMsg', SOMETHING_WENT_WRONG_MSG);
          openSnackbar({
            message,
            type: 'error',
          });
          RUMLogger.viewMatchedAudience({
            errorMsg: getApiErrorMsg(err?.axiosResponse),
            success: false,
            latency: get(err, 'axiosResponse.data.latency'),
            statusCode: get(err, 'axiosResponse.status'),
            audienceStatus: dialog.status,
          });
          if (handleClose) {
            handleClose();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, dialogVisibility]);

  const actionButtons = useMemo(
    () => [
      {
        title: 'Done',
        handler: handleClose,
      },
    ],
    [handleClose],
  );

  const renderContent = audience ? (
    <div className={styles.content}>
      <div className={styles.titleContainer}>
        <div className={styles.icon}>
          <Icon name="AudienceMatched" />
        </div>
        {audience.audienceName.length > 60 ? (
          <Tooltip
            className={styles.toolTip}
            label={audience.audienceName}
            position="bottom"
            auto={false}
          >
            <div className={`${styles.name} ${styles.restrictName}`}>{audience.audienceName}</div>
          </Tooltip>
        ) : (
          <div className={styles.name}>{audience.audienceName}</div>
        )}
        {isBoolean(isIncluded) && (
          <Pill
            className={isIncluded ? styles.successPill : styles.dangerPill}
            label={isIncluded ? 'Allowed' : 'Blocked'}
          />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Created on:</div>
          <div className={styles.infoText}>
            {moment.unix(audience.createdDate).format('MM/DD/yyyy')}
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Records: </div>
          <div className={styles.infoText}>{audience.fileTotalCount}</div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Match Rate: </div>
          <div className={styles.infoText}>
            {hasIn(audience, 'matchRate') ? `${audience.matchRate}%` : '-'}
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Cost(CPM): </div>
          <div className={styles.infoText}>
            {hasIn(audience, 'dataCost') ? `$${audience.dataCost}` : '-'}
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoLabel}>Reach</div>
          <div className={styles.infoText}>
            <NumberFormatter>{audience.uniques}</NumberFormatter>
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <Icon name={tableStatusIconsMap[audience.status]} className={styles.statusIcon} />
          <div className={styles.infoStatus}>{audience.status}</div>
        </div>
      </div>
      <DownloadCsvLabel s3FileName={audience.s3FileName} audienceId={audience.id} />
    </div>
  ) : (
    <div className={dialogVisibility ? styles.loadingWithDialog : styles.loadingWithoutDialog}>
      <ProgressCircle />
    </div>
  );

  return dialogVisibility ? (
    <Dialog
      dialogTitle="Audience Details"
      open={dialog}
      crossButton
      headerFooterBorders
      modalProps={COMMON_DIALOG_MODAL_PROPS}
      className={styles.container}
      onCrossButtonClick={handleClose}
      actionButtons={actionButtons}
    >
      {renderContent}
    </Dialog>
  ) : (
    <>{renderContent}</>
  );
};

const mapActions = {
  openSnackbar: snackbarActions.openSnackbar,
};

export default connect(null, mapActions)(ViewMatchedAudience);
